export const CHECK_TENANT_REQUEST = 'CHECK_TENANT_REQUEST';
export const CHECK_TENANT_SUCCESS = 'CHECK_TENANT_SUCCESS';
export const CHECK_TENANT_ERROR = 'CHECK_TENANT_ERROR';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export function checkTenantRequest() {
  return { type: CHECK_TENANT_REQUEST };
}
export function checkTenantSuccess(data) {
  return { type: CHECK_TENANT_SUCCESS, data };
}
export function checkTenantError(error) {
  return { type: CHECK_TENANT_ERROR, error };
}
export function loginRequest() {
  return { type: LOGIN_REQUEST };
}
export function loginSuccess(data) {
  return { type: LOGIN_SUCCESS, data };
}
export function loginError(error) {
  return { type: LOGIN_ERROR, error };
}
