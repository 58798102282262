/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { DateRange, Pagination, DurationPagination } from '_components';
import { fetchMemoList } from '_services';

class MemoContentsView extends Component {
  constructor(props) {
    super(props);

    const now = moment();
    this.state = {
      startDate: moment(now).add(-3, 'month').format('YYYY-MM-DD'),
      endDate: moment(now).format('YYYY-MM-DD'),
      pageSize: 10,
      pageNo: 1,
      memoFilter: '',
    };

    this._handleChangeStartDate = this._handleChangeStartDate.bind(this);
    this._handleChangeEndDate = this._handleChangeEndDate.bind(this);
    this._handleChangeFilter = this._handleChangeFilter.bind(this);
    this._handlePageMove = this._handlePageMove.bind(this);
  }

  componentDidMount() {
    window.$('.jquery_scrollbar').scrollbar();
    this._loadData();
  }
  _loadData() {
    const { startDate, endDate, pageNo } = this.state;
    this._loadMemoListData(startDate, endDate, pageNo);
  }
  _loadMemoListData(startDate, endDate, pageNo) {
    const { data } = this.props.location.state;
    const { pageSize } = this.state;
    const params = { userId: data.userId, startDate, endDate, pageNo, pageSize };

    this.props.fetchMemoList(params);
  }
  _handleChangeStartDate(date) {
    const { endDate } = this.state;
    this.setState({
      ...this.state,
      startDate: date,
      pageNo: 1,
    });
    this._loadMemoListData(date, endDate, 1);
  }
  _handleChangeEndDate(date) {
    const { startDate } = this.state;
    this.setState({
      ...this.state,
      endDate: date,
      pageNo: 1,
    });
    this._loadMemoListData(startDate, date, 1);
  }
  _handleChangeFilter(e) {
    const { value } = e.target;
    this.setState({ ...this.state, memoFilter: value });
  }
  _handlePageMove(pageNo) {
    const { startDate, endDate } = this.state;
    this.setState({
      ...this.state,
      pageNo: pageNo,
    });
    this._loadMemoListData(startDate, endDate, pageNo);
  }
  _getRatingLabel(rating, memoFilter) {
    const { t } = this.props;
    console.log(memoFilter)
    if (memoFilter === 'dizziness') {
      switch (rating) {
        case 1:
          return t('낮음(어지럼증)');
        case 2:
          return t('조금낮음(어지럼증)');
        case 3:
          return t('보통(어지럼증)');
        case 4:
          return t('조금높음(어지럼증)');
        case 5:
          return t('높음(어지럼증)');
        default:
          return '-';
      }
    } else {
      switch (rating) {
        case 1:
          return t('낮음');
        case 2:
          return t('조금낮음');
        case 3:
          return t('보통');
        case 4:
          return t('조금높음');
        case 5:
          return t('높음');
        default:
          return '-';
      }
    }
  }

  render() {
    const { t } = this.props;
    const { memoListData } = this.props;
    const { startDate, endDate, pageSize, pageNo, memoFilter } = this.state;
    const pagination = memoListData ? memoListData.pagination : null;
    const dateList = [];
    if (memoListData) {
      memoListData.data.forEach(item => {
        dateList.push(moment(item.createdTimeDate).format('YYYY-MM-DD'));
      });
    }

    return (
      <>
        <div className="card detail_memo">
          <div className="card_header">
            <h3 className="title">{t('운동 메모')}</h3>
          </div>
          <div className="card_body">
            <div className="contents_tool">
              <div className="main">
                <div className="form-group">
                  <label>{t('질문 항목 설정')}</label>
                  <select id="memoFilter" className="form-select" value={memoFilter} onChange={this._handleChangeFilter}>
                    <option value="">{t('전체 보기')}</option>
                    <option value="dizziness">{t('운동 중 어지럼증의 정도')}</option>
                    <option value="userCondition">{t('회원의 컨디션 정도')}</option>
                    <option value="concentration">{t('운동의 집중 수준')}</option>
                    <option value="kneeMovementAccuracy">{t('회원의 무릎 운동 정확성')}</option>
                    <option value="breath">{t('회원의 운동 중 숨이 차는 정도')}</option>
                    <option value="overall">{t('운동의 전반적인 평가 정도')}</option>
                  </select>
                </div>
                <div className="date_range">
                  <h4 className="title">{t('기간 설정')}</h4>
                  <DateRange
                    className="date start"
                    minYear={2020}
                    maxYear={2040}
                    date={startDate}
                    onChange={this._handleChangeStartDate}
                  />
                  <DateRange
                    className="date end"
                    minYear={2020}
                    maxYear={2040}
                    date={endDate}
                    onChange={this._handleChangeEndDate}
                  />
                </div>
              </div>
            </div>
            <DurationPagination
              dateList={dateList}
              totalCount={pagination ? pagination.totalCount : 0}
              rowCount={pagination ? pagination.pageSize : pageSize}
              page={pagination ? pagination.pageNo : pageNo}
              initStartDate={startDate}
              initEndDate={endDate}
              onPageMove={this._handlePageMove}
            />
            <ul className="table_list">
              <li className="lh">
                <div className="title">{t('등록 날짜')}</div>
                <div className="item">{t('질문항목')}</div>
                <div className="evaluation">{t('평가')}</div>
                <div className="memo">{t('상세메모')}</div>
              </li>
              {memoListData && memoListData.data.length > 0 ? (
                memoListData.data.map((item, index) => (
                  <li key={index} className="ld">
                    <div className="title">
                      <p>
                        <span className="name">{item.classNm}</span>
                        <span className="session">
                          {item.classUnitNo}
                          {t('회차')}
                        </span>
                        <span className="date">{moment(item.createdTimeDate).format('YYYY-MM-DD')}</span>
                      </p>
                    </div>
                    <div className="item">
                      {(memoFilter === '' || memoFilter === 'dizziness') && <p>{t('운동 중 어지럼증의 정도')}</p>}
                      {(memoFilter === '' || memoFilter === 'userCondition') && <p>{t('회원의 컨디션 정도')}</p>}
                      {(memoFilter === '' || memoFilter === 'concentration') && <p>{t('운동의 집중 수준')}</p>}
                      {(memoFilter === '' || memoFilter === 'kneeMovementAccuracy') && <p>{t('회원의 무릎 운동 정확성')}</p>}
                      {(memoFilter === '' || memoFilter === 'breath') && <p>{t('회원의 운동 중 숨이 차는 정도')}</p>}
                      {(memoFilter === '' || memoFilter === 'overall') && <p>{t('운동의 전반적인 평가 정도')}</p>}
                    </div>
                    <div className="evaluation">
                      {(memoFilter === '' || memoFilter === 'dizziness') && <p>{this._getRatingLabel(item.dizziness, 'dizziness')}</p>}
                      {(memoFilter === '' || memoFilter === 'userCondition') && (
                        <p>{this._getRatingLabel(item.userCondition)}</p>
                      )}
                      {(memoFilter === '' || memoFilter === 'concentration') && (
                        <p>{this._getRatingLabel(item.concentration)}</p>
                      )}
                      {(memoFilter === '' || memoFilter === 'kneeMovementAccuracy') && (
                        <p>{this._getRatingLabel(item.kneeMovementAccuracy)}</p>
                      )}
                      {(memoFilter === '' || memoFilter === 'breath') && (
                        <p>{this._getRatingLabel(item.breath)}</p>
                      )}
                      {(memoFilter === '' || memoFilter === 'overall') && <p>{this._getRatingLabel(item.overall)}</p>}
                    </div>
                    <div className="memo">
                      <p dangerouslySetInnerHTML={{ __html: item.memo }}></p>
                    </div>
                  </li>
                ))
              ) : (
                <li>
                  <div className="none_data">{t('운동메모가 없습니다.')}</div>
                </li>
              )}
            </ul>
            <Pagination
              totalCount={pagination ? pagination.totalCount : 0}
              rowCount={pagination ? pagination.pageSize : pageSize}
              page={pagination ? pagination.pageNo : pageNo}
              onPageMove={this._handlePageMove}
            />
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { memo } = state;
  return {
    memoListData: memo.memoListData,
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchMemoList,
      dispatch,
    },
    dispatch,
  );

const connected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MemoContentsView));

export { connected as MemoContentsView };
