/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { openAlertDialog } from '_actions';
import {
  logout,
  getLocalTenant,
  getLocalSession,
  checkAuthSystemManager,
  checkAuthTenantManager,
  checkAuthTrainer,
  getUserPictureLink,
} from '_services';
import { pathConstant } from '_constants';
import logoImg from '_asset/images/logo_top.svg';
import moreDotImg from '_asset/images/ico-more_dot.svg';

class Header extends Component {
  constructor(props) {
    super(props);

    this._handleLogout = this._handleLogout.bind(this);
  }
  _handleLogout(e) {
    e.preventDefault();

    const { t } = this.props;
    this.props.dispatch(
      openAlertDialog(
        t('로그아웃 하시겠습니까?'),
        'alert',
        true,
        null,
        null,
        () => {
          logout();
          this.props.history.replace(pathConstant.PATH_AUTH_CHECKTENANT);
        },
        null,
      ),
    );
  }
  render() {
    const { t } = this.props;
    const { breadcrumb } = this.props;
    const tenant = getLocalTenant();
    const session = getLocalSession();
    return (
      <div className="top_bar">
        <a
          href=""
          className="logo"
          onClick={e => {
            e.preventDefault();
            this.props.history.push(pathConstant.PATH_DASHBOARD_DASHBOARD);
          }}
        >
          <img src={logoImg} alt="Mostep Admin" />
        </a>
        <div className="top_main">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">HOME</li>
            {breadcrumb.map((item, index) => (
              <li
                key={index}
                className={`breadcrumb-item ${index + 1 === breadcrumb.length ? 'active' : ''}`}
                aria-current="page"
              >
                {item}
              </li>
            ))}
          </ol>
          <h2 className="page_title">{breadcrumb[breadcrumb.length - 1]}</h2>
          <ul className="top_menu">
            <li className="account">
              <div className="avatar">
                <img src={getUserPictureLink(session.userId)} className="avatar" alt="사용자 이미지" />
              </div>
              <div className="info">
                <p className="name">{session.userNm}</p>
                <p className="sub">
                  <span className="position">
                    {checkAuthSystemManager()
                      ? t('시스템관리자')
                      : checkAuthTenantManager()
                      ? t('기관관리자')
                      : checkAuthTrainer()
                      ? t('트레이너')
                      : t('회원')}
                  </span>
                  <span className="organization">{tenant.tenantNm}</span>
                </p>
              </div>
            </li>
            <li className="dropdown">
              <a href="" className="nav-link" data-bs-toggle="dropdown" role="button">
                <img src={moreDotImg} className="avatar" alt="더보기" />
              </a>
              <div className="dropdown-menu dropdown-menu-animated" tabIndex="-1">
                <a href="" className="dropdown-item" onClick={this._handleLogout}>
                  <span className="ico logout"></span>
                  <span>{t('로그아웃')}</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
const connected = withTranslation()(Header);
export { connected as Header };
