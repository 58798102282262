export const FETCH_RECENT_EXERCISE_CLASSUNITS_REQUEST = 'FETCH_RECENT_EXERCISE_CLASSUNITS_REQUEST';
export const FETCH_RECENT_EXERCISE_CLASSUNITS_SUCCESS = 'FETCH_RECENT_EXERCISE_CLASSUNITS_SUCCESS';
export const FETCH_RECENT_EXERCISE_CLASSUNITS_ERROR = 'FETCH_RECENT_EXERCISE_CLASSUNITS_ERROR';
export const FETCH_RECENT_EXERCISE_MEMBERS_REQUEST = 'FETCH_RECENT_EXERCISE_MEMBERS_REQUEST';
export const FETCH_RECENT_EXERCISE_MEMBERS_SUCCESS = 'FETCH_RECENT_EXERCISE_MEMBERS_SUCCESS';
export const FETCH_RECENT_EXERCISE_MEMBERS_ERROR = 'FETCH_RECENT_EXERCISE_MEMBERS_ERROR';
export const FETCH_CLASS_LIST_REQUEST = 'FETCH_CLASS_LIST_REQUEST';
export const FETCH_CLASS_LIST_SUCCESS = 'FETCH_CLASS_LIST_SUCCESS';
export const FETCH_CLASS_LIST_ERROR = 'FETCH_CLASS_LIST_ERROR';
export const FETCH_CLASS_REQUEST = 'FETCH_CLASS_REQUEST';
export const FETCH_CLASS_SUCCESS = 'FETCH_CLASS_SUCCESS';
export const FETCH_CLASS_ERROR = 'FETCH_CLASS_ERROR';
export const FETCH_CLASS_EXERCISE_SUMMARY_REQUEST = 'FETCH_CLASS_EXERCISE_SUMMARY_REQUEST';
export const FETCH_CLASS_EXERCISE_SUMMARY_SUCCESS = 'FETCH_CLASS_EXERCISE_SUMMARY_SUCCESS';
export const FETCH_CLASS_EXERCISE_SUMMARY_ERROR = 'FETCH_CLASS_EXERCISE_SUMMARY_ERROR';
export const FETCH_MEMBERS_OF_CLASS_REQUEST = 'FETCH_MEMBERS_OF_CLASS_REQUEST';
export const FETCH_MEMBERS_OF_CLASS_SUCCESS = 'FETCH_MEMBERS_OF_CLASS_SUCCESS';
export const FETCH_MEMBERS_OF_CLASS_ERROR = 'FETCH_MEMBERS_OF_CLASS_ERROR';
export const FETCH_RECENT_EXERCISE_CLASSUNITS_OF_CLASS_REQUEST = 'FETCH_RECENT_EXERCISE_CLASSUNITS_OF_CLASS_REQUEST';
export const FETCH_RECENT_EXERCISE_CLASSUNITS_OF_CLASS_SUCCESS = 'FETCH_RECENT_EXERCISE_CLASSUNITS_OF_CLASS_SUCCESS';
export const FETCH_RECENT_EXERCISE_CLASSUNITS_OF_CLASS_ERROR = 'FETCH_RECENT_EXERCISE_CLASSUNITS_OF_CLASS_ERROR';
export const ADD_CLASS_REQUEST = 'ADD_CLASS_REQUEST';
export const ADD_CLASS_SUCCESS = 'ADD_CLASS_SUCCESS';
export const ADD_CLASS_ERROR = 'ADD_CLASS_ERROR';
export const MODIFY_CLASS_REQUEST = 'MODIFY_CLASS_REQUEST';
export const MODIFY_CLASS_SUCCESS = 'MODIFY_CLASS_SUCCESS';
export const MODIFY_CLASS_ERROR = 'MODIFY_CLASS_ERROR';
export const REMOVE_CLASS_REQUEST = 'REMOVE_CLASS_REQUEST';
export const REMOVE_CLASS_SUCCESS = 'REMOVE_CLASS_SUCCESS';
export const REMOVE_CLASS_ERROR = 'REMOVE_CLASS_ERROR';

export function fetchRecentExerciseClassUnitsRequest() {
  return { type: FETCH_RECENT_EXERCISE_CLASSUNITS_REQUEST };
}
export function fetchRecentExerciseClassUnitsSuccess(data) {
  return { type: FETCH_RECENT_EXERCISE_CLASSUNITS_SUCCESS, data };
}
export function fetchRecentExerciseClassUnitsError(error) {
  return { type: FETCH_RECENT_EXERCISE_CLASSUNITS_ERROR, error };
}
export function fetchRecentExerciseMembersRequest() {
  return { type: FETCH_RECENT_EXERCISE_MEMBERS_REQUEST };
}
export function fetchRecentExerciseMembersSuccess(data) {
  return { type: FETCH_RECENT_EXERCISE_MEMBERS_SUCCESS, data };
}
export function fetchRecentExerciseMembersError(error) {
  return { type: FETCH_RECENT_EXERCISE_MEMBERS_ERROR, error };
}
export function fetchClassListRequest() {
  return { type: FETCH_CLASS_LIST_REQUEST };
}
export function fetchClassListSuccess(data) {
  return { type: FETCH_CLASS_LIST_SUCCESS, data };
}
export function fetchClassListError(error) {
  return { type: FETCH_CLASS_LIST_ERROR, error };
}
export function fetchClassRequest() {
  return { type: FETCH_CLASS_REQUEST };
}
export function fetchClassSuccess(data) {
  return { type: FETCH_CLASS_SUCCESS, data };
}
export function fetchClassError(error) {
  return { type: FETCH_CLASS_ERROR, error };
}
export function fetchClassExerciseSummaryRequest() {
  return { type: FETCH_CLASS_EXERCISE_SUMMARY_REQUEST };
}
export function fetchClassExerciseSummarySuccess(data) {
  return { type: FETCH_CLASS_EXERCISE_SUMMARY_SUCCESS, data };
}
export function fetchClassExerciseSummaryError(error) {
  return { type: FETCH_CLASS_EXERCISE_SUMMARY_ERROR, error };
}
export function fetchMembersOfClassRequest() {
  return { type: FETCH_MEMBERS_OF_CLASS_REQUEST };
}
export function fetchMembersOfClassSuccess(data) {
  return { type: FETCH_MEMBERS_OF_CLASS_SUCCESS, data };
}
export function fetchMembersOfClassError(error) {
  return { type: FETCH_MEMBERS_OF_CLASS_ERROR, error };
}
export function fetchRecentExerciseClassUnitsOfClassRequest() {
  return { type: FETCH_RECENT_EXERCISE_CLASSUNITS_OF_CLASS_REQUEST };
}
export function fetchRecentExerciseClassUnitsOfClassSuccess(data) {
  return { type: FETCH_RECENT_EXERCISE_CLASSUNITS_OF_CLASS_SUCCESS, data };
}
export function fetchRecentExerciseClassUnitsOfClassError(error) {
  return { type: FETCH_RECENT_EXERCISE_CLASSUNITS_OF_CLASS_ERROR, error };
}
export function addClassRequest() {
  return { type: ADD_CLASS_REQUEST };
}
export function addClassSuccess(data) {
  return { type: ADD_CLASS_SUCCESS, data };
}
export function addClassError(error) {
  return { type: ADD_CLASS_ERROR, error };
}
export function modifyClassRequest() {
  return { type: MODIFY_CLASS_REQUEST };
}
export function modifyClassSuccess(data) {
  return { type: MODIFY_CLASS_SUCCESS, data };
}
export function modifyClassError(error) {
  return { type: MODIFY_CLASS_ERROR, error };
}
export function removeClassRequest() {
  return { type: REMOVE_CLASS_REQUEST };
}
export function removeClassSuccess(data) {
  return { type: REMOVE_CLASS_SUCCESS, data };
}
export function removeClassError(error) {
  return { type: REMOVE_CLASS_ERROR, error };
}
