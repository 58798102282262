import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openAlertDialog } from '_actions';
import { login, logout, getLocalTenant, checkAuthSystemManager } from '_services';
import { pathConstant } from '_constants';
import logoImg from '_asset/images/logo.svg';

class Login extends Component {
  constructor(props) {
    super(props);

    logout();

    this.state = {
      loginId: '',
      password: '',
      loginError: false,
    };

    this._handleChange = this._handleChange.bind(this);
    this._handleLogin = this._handleLogin.bind(this);
    this._handleForgotPassword = this._handleForgotPassword.bind(this);
  }
  _handleChange(e) {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value, loginError: false });
  }
  _handleLogin(e) {
    e.preventDefault();

    const { loginId, password } = this.state;
    const params = {
      tenantId: getLocalTenant().tenantId,
      loginId,
      password,
    };
    this.props.login(params, (error, data) => {
      if (!error) {
        this.setState({
          ...this.state,
          loginError: false,
        });
        checkAuthSystemManager()
          ? this.props.history.replace(pathConstant.PATH_TENANT_MGT)
          : this.props.history.replace(pathConstant.PATH_DASHBOARD_DASHBOARD);
      } else {
        this.setState({
          ...this.state,
          loginError: true,
        });
      }
    });
  }
  _handleForgotPassword(e) {
    e.preventDefault();

    const { t } = this.props;
    this.props.dispatch(
      openAlertDialog(
        t('시설 관리자에게 문의하세요.'),
        'help',
        false,
        null,
        null,
        () => {
          this.props.history.replace(pathConstant.PATH_AUTH_LOGIN);
        },
        () => {
          this.props.history.replace(pathConstant.PATH_AUTH_LOGIN);
        },
      ),
    );
  }
  render() {
    const { t } = this.props;
    const { loginId, password, loginError } = this.state;

    document.title = `${t('로그인')} | ${t('모스텝')}`;
    return (
      <div className="wrapper">
        <div className="login">
          <h1 className="brand">
            <img src={logoImg} alt="Mostep Admin" />
          </h1>
          <div className="form_box">
            <form onSubmit={this._handleLogin}>
              <div>
                <label htmlFor="loginId" className="visually-hidden">
                  아이디
                </label>
                <input
                  id="loginId"
                  className={`form-control ${loginError ? 'is-invalid' : ''}`}
                  type="text"
                  placeholder={t('아이디를 입력해주세요.')}
                  value={loginId}
                  onChange={this._handleChange}
                />
              </div>
              <div>
                <label htmlFor="password" className="visually-hidden">
                  비밀번호
                </label>
                <input
                  id="password"
                  className={`form-control ${loginError ? 'is-invalid' : ''}`}
                  type="password"
                  placeholder={t('비밀번호를 입력하세요.')}
                  value={password}
                  onChange={this._handleChange}
                />
                <p className="invalid-feedback">{t('아이디 또는 비밀번호가 잘못되었습니다.')}</p>
              </div>

              <div className="btn_area">
                <button
                  type="submit"
                  className={`btn auth_btn ${loginId && password ? 'active' : ''}`}
                  disabled={!loginId || !password}
                >
                  {t('로그인')}
                </button>
                <button type="button" className="btn btn-sm" onClick={this._handleForgotPassword}>
                  {t('비밀번호 찾기')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login,
      dispatch,
    },
    dispatch,
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Login));
export { connected as Login };
