export const FETCH_CLASSES_OF_TRAINER_REQUEST = 'FETCH_CLASSES_OF_TRAINER_REQUEST';
export const FETCH_CLASSES_OF_TRAINER_SUCCESS = 'FETCH_CLASSES_OF_TRAINER_SUCCESS';
export const FETCH_CLASSES_OF_TRAINER_ERROR = 'FETCH_CLASSES_OF_TRAINER_ERROR';
export const FETCH_TRAINER_LIST_REQUEST = 'FETCH_TRAINER_LIST_REQUEST';
export const FETCH_TRAINER_LIST_SUCCESS = 'FETCH_TRAINER_LIST_SUCCESS';
export const FETCH_TRAINER_LIST_ERROR = 'FETCH_TRAINER_LIST_ERROR';
export const ADD_TRAINER_REQUEST = 'ADD_TRAINER_REQUEST';
export const ADD_TRAINER_SUCCESS = 'ADD_TRAINER_SUCCESS';
export const ADD_TRAINER_ERROR = 'ADD_TRAINER_ERROR';
export const MODIFY_TRAINER_REQUEST = 'MODIFY_TRAINER_REQUEST';
export const MODIFY_TRAINER_SUCCESS = 'MODIFY_TRAINER_SUCCESS';
export const MODIFY_TRAINER_ERROR = 'MODIFY_TRAINER_ERROR';
export const REMOVE_TRAINER_REQUEST = 'REMOVE_TRAINER_REQUEST';
export const REMOVE_TRAINER_SUCCESS = 'REMOVE_TRAINER_SUCCESS';
export const REMOVE_TRAINER_ERROR = 'REMOVE_TRAINER_ERROR';

export function fetchClassesOfTrainerRequest() {
  return { type: FETCH_CLASSES_OF_TRAINER_REQUEST };
}
export function fetchClassesOfTrainerSuccess(data) {
  return { type: FETCH_CLASSES_OF_TRAINER_SUCCESS, data };
}
export function fetchClassesOfTrainerError(error) {
  return { type: FETCH_CLASSES_OF_TRAINER_ERROR, error };
}
export function fetchTrainerListRequest() {
  return { type: FETCH_TRAINER_LIST_REQUEST };
}
export function fetchTrainerListSuccess(data) {
  return { type: FETCH_TRAINER_LIST_SUCCESS, data };
}
export function fetchTrainerListError(error) {
  return { type: FETCH_TRAINER_LIST_ERROR, error };
}
export function addTrainerRequest() {
  return { type: ADD_TRAINER_REQUEST };
}
export function addTrainerSuccess(data) {
  return { type: ADD_TRAINER_SUCCESS, data };
}
export function addTrainerError(error) {
  return { type: ADD_TRAINER_ERROR, error };
}
export function modifyTrainerRequest() {
  return { type: MODIFY_TRAINER_REQUEST };
}
export function modifyTrainerSuccess(data) {
  return { type: MODIFY_TRAINER_SUCCESS, data };
}
export function modifyTrainerError(error) {
  return { type: MODIFY_TRAINER_ERROR, error };
}
export function removeTrainerRequest() {
  return { type: REMOVE_TRAINER_REQUEST };
}
export function removeTrainerSuccess(data) {
  return { type: REMOVE_TRAINER_SUCCESS, data };
}
export function removeTrainerError(error) {
  return { type: REMOVE_TRAINER_ERROR, error };
}
