import {
  CHECK_LOGIN_ID_REQUEST,
  CHECK_LOGIN_ID_REQUEST_SUCCESS,
  CHECK_LOGIN_ID_REQUEST_ERROR,
  CHECK_EMAIL_REQUEST,
  CHECK_EMAIL_REQUEST_SUCCESS,
  CHECK_EMAIL_REQUEST_ERROR,
  CHANGEPASSWORD_USER_REQUEST,
  CHANGEPASSWORD_USER_SUCCESS,
  CHANGEPASSWORD_USER_ERROR,
} from '_actions';

const initialState = {
  pending: false,
  checkLoginIdData: null,
  checkEmailData: null,
  error: null,
};

export function user(state = initialState, action) {
  switch (action.type) {
    case CHECK_LOGIN_ID_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CHECK_LOGIN_ID_REQUEST_SUCCESS:
      return {
        ...state,
        pending: false,
        checkLoginIdData: action.data,
      };
    case CHECK_LOGIN_ID_REQUEST_ERROR:
      return {
        ...state,
        pending: false,
        checkLoginIdData: null,
        error: action.error,
      };
    case CHECK_EMAIL_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CHECK_EMAIL_REQUEST_SUCCESS:
      return {
        ...state,
        pending: false,
        checkEmailData: action.data,
      };
    case CHECK_EMAIL_REQUEST_ERROR:
      return {
        ...state,
        pending: false,
        checkEmailData: null,
        error: action.error,
      };

    case CHANGEPASSWORD_USER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CHANGEPASSWORD_USER_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case CHANGEPASSWORD_USER_ERROR:
      return {
        ...state,
        pending: false,
      };
    default:
      return state;
  }
}
