export const chartColors = {
  indigo: '#1949D8',
  lightgray: '#D9D9D9',
  black: '#000000',
  white: '#FFFFFF',
  gray: '#AAAAAA',
  point: '#444444',
  background: 'rgba(170, 214, 255, 0.2)',
  border: '#B4C2CC',
  blue: 'rgb(54, 162, 235)',
};
