/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';

export class DurationPagination extends Component {
  constructor(props) {
    super(props);

    this._handlePageMove = this._handlePageMove.bind(this);
  }
  _handlePageMove(e, page) {
    e.preventDefault();
    if (page) {
      this.props.onPageMove(page);
    }
  }
  render() {
    const { dateList, totalCount, rowCount, page, initStartDate, initEndDate } = this.props;
    const startDate = dateList && dateList.length > 0 ? dateList[0] : initStartDate;
    const endDate = dateList && dateList.length > 0 ? dateList[dateList.length - 1] : initEndDate;
    const pageCount = Math.ceil(totalCount / rowCount);
    return (
      <>
        <ul className="pagination phase">
          <li className="prev">
            <a href="" onClick={page === 1 ? e => this._handlePageMove(e) : e => this._handlePageMove(e, page - 1)}></a>
          </li>
          <li className="date">
            {startDate} ~ {endDate}
          </li>
          <li className="next">
            <a
              href=""
              onClick={page === pageCount ? e => this._handlePageMove(e) : e => this._handlePageMove(e, page + 1)}
            ></a>
          </li>
        </ul>
      </>
    );
  }
}
