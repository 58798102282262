export const ADD_PATTERNGROUP_REQUEST = 'ADD_PATTERNGROUP_REQUEST';
export const ADD_PATTERNGROUP_SUCCESS = 'ADD_PATTERNGROUP_SUCCESS';
export const ADD_PATTERNGROUP_ERROR = 'ADD_PATTERNGROUP_ERROR';
export const MODIFY_PATTERNGROUP_REQUEST = 'MODIFY_PATTERNGROUP_REQUEST';
export const MODIFY_PATTERNGROUP_SUCCESS = 'MODIFY_PATTERNGROUP_SUCCESS';
export const MODIFY_PATTERNGROUP_ERROR = 'MODIFY_PATTERNGROUP_ERROR';
export const REMOVE_PATTERNGROUP_REQUEST = 'REMOVE_PATTERNGROUP_REQUEST';
export const REMOVE_PATTERNGROUP_SUCCESS = 'REMOVE_PATTERNGROUP_SUCCESS';
export const REMOVE_PATTERNGROUP_ERROR = 'REMOVE_PATTERNGROUP_ERROR';
export const FETCH_PATTERNGROUP_LIST_REQUEST = 'FETCH_PATTERNGROUP_LIST_REQUEST';
export const FETCH_PATTERNGROUP_LIST_SUCCESS = 'FETCH_PATTERNGROUP_LIST_SUCCESS';
export const FETCH_PATTERNGROUP_LIST_ERROR = 'FETCH_PATTERNGROUP_LIST_ERROR';
export const FETCH_PATTERNGROUP_REQUEST = 'FETCH_PATTERNGROUP_REQUEST';
export const FETCH_PATTERNGROUP_SUCCESS = 'FETCH_PATTERNGROUP_SUCCESS';
export const FETCH_PATTERNGROUP_ERROR = 'FETCH_PATTERNGROUP_ERROR';
export const FETCH_PATTERN_LIST_REQUEST = 'FETCH_PATTERN_LIST_REQUEST';
export const FETCH_PATTERN_LIST_SUCCESS = 'FETCH_PATTERN_LIST_SUCCESS';
export const FETCH_PATTERN_LIST_ERROR = 'FETCH_PATTERN_LIST_ERROR';
export const FETCH_PATTERNLEVEL_LIST_REQUEST = 'FETCH_PATTERNLEVEL_LIST_REQUEST';
export const FETCH_PATTERNLEVEL_LIST_SUCCESS = 'FETCH_PATTERNLEVEL_LIST_SUCCESS';
export const FETCH_PATTERNLEVEL_LIST_ERROR = 'FETCH_PATTERNLEVEL_LIST_ERROR';
export const FETCH_RECOMMEND_PATTERN_REQUEST = 'FETCH_RECOMMEND_PATTERN_REQUEST';
export const FETCH_RECOMMEND_PATTERN_SUCCESS = 'FETCH_RECOMMEND_PATTERN_SUCCESS';
export const FETCH_RECOMMEND_PATTERN_ERROR = 'FETCH_RECOMMEND_PATTERN_ERROR';
export const FETCH_PATTERNRE_QUEST = 'FETCH_PATTERNRE_QUEST';
export const FETCH_PATTERN_SUCCESS = 'FETCH_PATTERN_SUCCESS';
export const FETCH_PATTERN_ERROR = 'FETCH_PATTERN_ERROR';

export function addPatternGroupRequest() {
  return { type: ADD_PATTERNGROUP_REQUEST };
}
export function addPatternGroupSuccess(data) {
  return { type: ADD_PATTERNGROUP_SUCCESS, data };
}
export function addPatternGroupError(error) {
  return { type: ADD_PATTERNGROUP_ERROR, error };
}
export function modifyPatternGroupRequest() {
  return { type: MODIFY_PATTERNGROUP_REQUEST };
}
export function modifyPatternGroupSuccess(data) {
  return { type: MODIFY_PATTERNGROUP_SUCCESS, data };
}
export function modifyPatternGroupError(error) {
  return { type: MODIFY_PATTERNGROUP_ERROR, error };
}
export function removePatternGroupRequest() {
  return { type: REMOVE_PATTERNGROUP_REQUEST };
}
export function removePatternGroupSuccess(data) {
  return { type: REMOVE_PATTERNGROUP_SUCCESS, data };
}
export function removePatternGroupError(error) {
  return { type: REMOVE_PATTERNGROUP_ERROR, error };
}
export function fetchPatternGroupListRequest() {
  return { type: FETCH_PATTERNGROUP_LIST_REQUEST };
}
export function fetchPatternGroupListSuccess(data) {
  return { type: FETCH_PATTERNGROUP_LIST_SUCCESS, data };
}
export function fetchPatternGroupListError(error) {
  return { type: FETCH_PATTERNGROUP_LIST_ERROR, error };
}
export function fetchPatternGroupRequest() {
  return { type: FETCH_PATTERNGROUP_REQUEST };
}
export function fetchPatternGroupSuccess(data) {
  return { type: FETCH_PATTERNGROUP_SUCCESS, data };
}
export function fetchPatternGroupError(error) {
  return { type: FETCH_PATTERNGROUP_ERROR, error };
}
export function fetchPatternListRequest() {
  return { type: FETCH_PATTERN_LIST_REQUEST };
}
export function fetchPatternListSuccess(data) {
  return { type: FETCH_PATTERN_LIST_SUCCESS, data };
}
export function fetchPatternListError(error) {
  return { type: FETCH_PATTERN_LIST_ERROR, error };
}
export function fetchPatternLevelListRequest() {
  return { type: FETCH_PATTERNLEVEL_LIST_REQUEST };
}
export function fetchPatternLevelListSuccess(data) {
  return { type: FETCH_PATTERNLEVEL_LIST_SUCCESS, data };
}
export function fetchPatternLevelListError(error) {
  return { type: FETCH_PATTERNLEVEL_LIST_ERROR, error };
}

export function fetchRecommendPatternRequest() {
  return { type: FETCH_RECOMMEND_PATTERN_REQUEST };
}

export function fetchRecommendPatternSuccess(data) {
  return { type: FETCH_RECOMMEND_PATTERN_SUCCESS, data };
}

export function fetchRecommendPatternError(error) {
  return { type: FETCH_RECOMMEND_PATTERN_ERROR, error };
}

export function fetchPatternRequest() {
  return { type: FETCH_PATTERNRE_QUEST };
}

export function fetchPatternSuccess(data) {
  return { type: FETCH_PATTERN_SUCCESS, data };
}

export function fetchPatternError(error) {
  return { type: FETCH_PATTERN_ERROR, error };
}
