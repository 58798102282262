/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Header, SideBar } from '_components';
import { openAlertDialog } from '_actions';
import { removeBoard } from '_services';
import { pathConstant } from '_constants';

class BoardDetail extends Component {
  constructor(props) {
    super(props);

    this._handleRemove = this._handleRemove.bind(this);
    this._handleModify = this._handleModify.bind(this);
  }
  componentDidMount() {
    window.$('.jquery_scrollbar').scrollbar();
  }
  _handleRemove() {
    const { t } = this.props;
    this.props.dispatch(
      openAlertDialog(t('정말로 삭제하시겠습니까?'), 'alert', true, null, null, () => {
        this._removeBoard();
      }),
    );
  }
  _removeBoard() {
    const { t } = this.props;
    const { boardType, data, caller } = this.props.location.state;
    this.props.removeBoard(data.boardId, {}, error => {
      if (!error) {
        this.props.dispatch(
          openAlertDialog(t('삭제되었습니다.'), 'success', false, null, null, () => {
            const pathname = caller === 'boardmgt' ? pathConstant.PATH_BOARD_MGT : pathConstant.PATH_SYSTEMBOARD_MGT;
            this.props.history.replace({ pathname, state: { boardType } });
          }),
        );
      }
    });
  }
  _handleModify() {
    const { caller, boardType, data } = this.props.location.state;
    this.props.history.push({ pathname: pathConstant.PATH_BOARD_EDIT, state: { caller, mode: 'modify', boardType, data } });
  }
  render() {
    const { t } = this.props;
    const { caller, boardType, data } = this.props.location.state;
    const boardName = boardType === 'C' ? t('클래스') : boardType === 'T' ? t('시설') : t('시스템');
    document.title = `${t('공지사항')} | ${t('모스텝')}`;
    const breadcrumb = [
      caller === 'boardmgt' || caller === 'systemboardmgt' ? t('공지사항 관리') : t('대시보드'),
      `${boardName} ${t('공지사항')}`,
    ];
    return (
      <>
        <Header {...this.props} breadcrumb={breadcrumb} />
        <SideBar {...this.props} />

        <main className="contents_wrapper">
          <div className="jquery_scrollbar">
            <div className="contents">
              <div className="contents_body card">
                <div className="card_header vstack">
                  <h3 className="title">{data.title}</h3>
                  <div className="writing_info">
                    {boardType === 'C' && (
                      <p>
                        {t('클래스')} : {data.classNm}
                      </p>
                    )}
                    <p>
                      {t('작성자2')} : {data.userNm}
                    </p>
                    <p>
                      {t('작성일')} : {data.createdTime}
                    </p>
                  </div>
                </div>
                <div className="card_body">
                  <div className="textarea" dangerouslySetInnerHTML={{ __html: data.contents }}></div>
                </div>
                <div className="card_footer">
                  {(caller === 'boardmgt' || caller === 'systemboardmgt') && (
                    <div className="btn_area">
                      <button type="button" className="btn btn-lg btn-outline-danger active" onClick={this._handleRemove}>
                        {t('삭제')}
                      </button>
                      <button type="button" className="btn btn-lg btn-outline-secondary" onClick={this._handleModify}>
                        {t('수정')}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      removeBoard,
      dispatch,
    },
    dispatch,
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BoardDetail));
export { connected as BoardDetail };
