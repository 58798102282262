import {
  DashBoard,
  MemberMgt,
  MemberEdit,
  MemberDetail,
  BoardMgt,
  BoardDetail,
  BoardEdit,
  SystemBoardMgt,
  PatternGroupMgt,
  PatternGroupEdit,
  ClassMgt,
  ClassDetail,
  ClassEdit,
  TrainerMgt,
  TrainerEdit,
  ChangePassword,
  TenantMgt,
  TenantEdit,
  MemberPrint,
} from '_containers';
import { pathConstant } from '_constants';

export const routes = [
  {
    path: '/',
    name: 'DashBoard',
    component: DashBoard,
    exact: true,
  },
  {
    path: pathConstant.PATH_DASHBOARD_DASHBOARD,
    name: 'DashBoard',
    component: DashBoard,
    exact: true,
  },
  {
    path: pathConstant.PATH_MEMBER_MGT,
    name: 'MemberMgt',
    component: MemberMgt,
    exact: true,
  },
  {
    path: pathConstant.PATH_MEMBER_EDIT,
    name: 'MemberEdit',
    component: MemberEdit,
    exact: true,
  },
  {
    path: pathConstant.PATH_MEMBER_DETAIL,
    name: 'MemberDetail',
    component: MemberDetail,
    exact: true,
  },
  {
    path: pathConstant.PATH_MEMBER_PRINT,
    name: 'MemberPrint',
    component: MemberPrint,
    exact: true,
  },
  {
    path: pathConstant.PATH_MEMBER_PASSWORD,
    name: 'ChangePassword',
    component: ChangePassword,
    exact: true,
  },
  {
    path: pathConstant.PATH_BOARD_MGT,
    name: 'BoardMgt',
    component: BoardMgt,
    exact: true,
  },
  {
    path: pathConstant.PATH_BOARD_DETAIL,
    name: 'BoardDetail',
    component: BoardDetail,
    exact: true,
  },
  {
    path: pathConstant.PATH_BOARD_EDIT,
    name: 'BoardEdit',
    component: BoardEdit,
    exact: true,
  },
  {
    path: pathConstant.PATH_SYSTEMBOARD_MGT,
    name: 'BoardMgt',
    component: SystemBoardMgt,
    exact: true,
  },
  {
    path: pathConstant.PATH_SYSTEMBOARD_DETAIL,
    name: 'BoardDetail',
    component: BoardDetail,
    exact: true,
  },
  {
    path: pathConstant.PATH_SYSTEMBOARD_EDIT,
    name: 'BoardEdit',
    component: BoardEdit,
    exact: true,
  },
  {
    path: pathConstant.PATH_PATTERNGROUP_MGT,
    name: 'PatternGroupMgt',
    component: PatternGroupMgt,
    exact: true,
  },
  {
    path: pathConstant.PATH_PATTERNGROUP_EDIT,
    name: 'PatternGroupEdit',
    component: PatternGroupEdit,
    exact: true,
  },
  {
    path: pathConstant.PATH_CLASS_MGT,
    name: 'ClassMgt',
    component: ClassMgt,
    exact: true,
  },
  {
    path: pathConstant.PATH_CLASS_DETAIL,
    name: 'ClassDetail',
    component: ClassDetail,
    exact: true,
  },
  {
    path: pathConstant.PATH_CLASS_EDIT,
    name: 'ClassEdit',
    component: ClassEdit,
    exact: true,
  },
  {
    path: pathConstant.PATH_TRAINER_MGT,
    name: 'TrainerMgt',
    component: TrainerMgt,
    exact: true,
  },
  {
    path: pathConstant.PATH_TRAINER_EDIT,
    name: 'TrainerEdit',
    component: TrainerEdit,
    exact: true,
  },
  {
    path: pathConstant.PATH_TRAINER_PASSWORD,
    name: 'ChangePassword',
    component: ChangePassword,
    exact: true,
  },
  {
    path: pathConstant.PATH_TENANT_MGT,
    name: 'TenantMgt',
    component: TenantMgt,
    exact: true,
  },
  {
    path: pathConstant.PATH_TENANT_EDIT,
    name: 'TenantEdit',
    component: TenantEdit,
    exact: true,
  },
  {
    path: pathConstant.PATH_TENANT_MANAGER_PASSWORD,
    name: 'ChangePassword',
    component: ChangePassword,
    exact: true,
  },
];
