import React, { Component } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route, Redirect } from 'react-router-dom';
import { history } from '_helpers';
import { getLocalTenant, getLocalSession } from '_services';
import { Alert, Loading } from '_components';
import { pathConstant } from '_constants';
import { AppLayout, CheckTenant, Login } from '_containers';
import { withCookies } from 'react-cookie';
import '_asset/css/common.css';

class App extends Component {
  render() {
    return (
      <ConnectedRouter history={history}>
        <Loading />
        <Alert />
        <Switch>
          <Route
            exact
            path={pathConstant.PATH_AUTH_CHECKTENANT}
            name="CheckTenant"
            render={props => <CheckTenant {...props} />}
          />
          <Route
            exact
            path={pathConstant.PATH_AUTH_LOGIN}
            name="Login"
            render={props =>
              getLocalTenant() ? (
                <Login {...props} />
              ) : (
                <Redirect to={{ pathname: pathConstant.PATH_AUTH_CHECKTENANT }} {...props} />
              )
            }
          />
          <Route
            path="/"
            name="AppLayout"
            render={props =>
              getLocalTenant() && getLocalSession() ? (
                <AppLayout {...props} />
              ) : (
                <Redirect to={{ pathname: pathConstant.PATH_AUTH_CHECKTENANT }} {...props} />
              )
            }
          />
        </Switch>
      </ConnectedRouter>
    );
  }
}

export default withCookies(App);
