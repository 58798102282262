/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';

export class Pagination extends Component {
  /**
   *
   * @param {*} props
   * {
   *  totalCount: 10,
   *  rowCount: 10,
   *  page: 1
   *  onPageMove: () => {}
   * }
   */
  constructor(props) {
    super(props);

    this._handlePageMove = this._handlePageMove.bind(this);
  }
  _handlePageMove(e, page) {
    e.preventDefault();
    if (page) {
      this.props.onPageMove(page);
    }
  }
  render() {
    const { totalCount, rowCount, page } = this.props;
    const pageCount = Math.ceil(totalCount / rowCount);
    let maxPage = Math.ceil(page / 10) * 10;
    if (maxPage > pageCount) {
      maxPage = pageCount;
    }
    let minPage = Math.ceil(maxPage / 10) * 10 - 9;
    let pages = [];
    if (maxPage > 0) {
      for (let i = minPage; i <= maxPage; i++) {
        pages.push(i);
      }
    } else {
      minPage = 1;
      pages.push(1);
    }
    return (
      <>
        <div className="pagination_wrapper">
          <ul className="pagination">
            <li className={'first ' + (page === 1 ? 'disabled' : '')}>
              <a href="" onClick={page === 1 ? e => this._handlePageMove(e) : e => this._handlePageMove(e, 1)}></a>
            </li>
            <li className={'prev ' + (page === 1 ? 'disabled' : '')}>
              <a href="" onClick={page === 1 ? e => this._handlePageMove(e) : e => this._handlePageMove(e, page - 1)}></a>
            </li>

            {pages.map((item, index) => (
              <li key={index} className={item === page ? 'active' : ''}>
                <a href="" onClick={page === item ? e => this._handlePageMove(e) : e => this._handlePageMove(e, item)}>
                  {item}
                </a>
              </li>
            ))}

            <li className={'next ' + (page >= pageCount ? 'disabled' : '')}>
              <a
                href=""
                onClick={page >= pageCount ? e => this._handlePageMove(e) : e => this._handlePageMove(e, page + 1)}
              ></a>
            </li>
            <li className={'last ' + (page >= pageCount ? 'disabled' : '')}>
              <a
                href=""
                onClick={page >= pageCount ? e => this._handlePageMove(e) : e => this._handlePageMove(e, pageCount)}
              ></a>
            </li>
          </ul>
        </div>
      </>
    );
  }
}
