export const FETCH_TENANT_LIST_REQUEST = 'FETCH_TENANT_LIST_REQUEST';
export const FETCH_TENANT_LIST_SUCCESS = 'FETCH_TENANT_LIST_SUCCESS';
export const FETCH_TENANT_LIST_ERROR = 'FETCH_TENANT_LIST_ERROR';
export const ADD_TENANT_REQUEST = 'ADD_TENANT_REQUEST';
export const ADD_TENANT_SUCCESS = 'ADD_TENANT_SUCCESS';
export const ADD_TENANT_ERROR = 'ADD_TENANT_ERROR';
export const MODIFY_TENANT_REQUEST = 'MODIFY_TENANT_REQUEST';
export const MODIFY_TENANT_SUCCESS = 'MODIFY_TENANT_SUCCESS';
export const MODIFY_TENANT_ERROR = 'MODIFY_TENANT_ERROR';
export const REMOVE_TENANT_REQUEST = 'REMOVE_TENANT_REQUEST';
export const REMOVE_TENANT_SUCCESS = 'REMOVE_TENANT_SUCCESS';
export const REMOVE_TENANT_ERROR = 'REMOVE_TENANT_ERROR';
export function fetchTenantListRequest() {
  return { type: FETCH_TENANT_LIST_REQUEST };
}
export function fetchTenantListSuccess(data) {
  return { type: FETCH_TENANT_LIST_SUCCESS, data };
}
export function fetchTenantListError(error) {
  return { type: FETCH_TENANT_LIST_ERROR, error };
}
export function addTenantRequest() {
  return { type: ADD_TENANT_REQUEST };
}
export function addTenantSuccess(data) {
  return { type: ADD_TENANT_SUCCESS, data };
}
export function addTenantError(error) {
  return { type: ADD_TENANT_ERROR, error };
}
export function modifyTenantRequest() {
  return { type: MODIFY_TENANT_REQUEST };
}
export function modifyTenantSuccess(data) {
  return { type: MODIFY_TENANT_SUCCESS, data };
}
export function modifyTenantError(error) {
  return { type: MODIFY_TENANT_ERROR, error };
}
export function removeTenantRequest() {
  return { type: REMOVE_TENANT_REQUEST };
}
export function removeTenantSuccess(data) {
  return { type: REMOVE_TENANT_SUCCESS, data };
}
export function removeTenantError(error) {
  return { type: REMOVE_TENANT_ERROR, error };
}
