import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openAlertDialog } from '_actions';
import { checkTenant, logout } from '_services';
import { pathConstant } from '_constants';
import logoImg from '_asset/images/logo.svg';

class CheckTenant extends Component {
  constructor(props) {
    super(props);

    logout(true);

    this.state = {
      tenantCode: '',
      checkError: false,
    };

    this._handleChange = this._handleChange.bind(this);
    this._handleCheckTenant = this._handleCheckTenant.bind(this);
  }
  _handleChange(e) {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value, checkError: false });
  }
  _handleCheckTenant(e) {
    e.preventDefault();

    const { t } = this.props;
    const { tenantCode } = this.state;
    this.props.checkTenant({ tenantCode }, (error, data) => {
      if (!error) {
        this.setState({
          ...this.state,
          checkError: false,
        });
        this.props.dispatch(
          openAlertDialog(
            `<p class="text"><span class="text-primary">${data.tenantNm}</span> ${t('기관 인증이 확인되었습니다.')}</p>`,
            'success',
            false,
            null,
            null,
            () => {
              this.props.history.replace(pathConstant.PATH_AUTH_LOGIN);
            },
            () => {
              this.props.history.replace(pathConstant.PATH_AUTH_LOGIN);
            },
          ),
        );
      } else {
        this.setState({
          ...this.state,
          checkError: true,
        });
      }
    });
  }
  render() {
    const { t } = this.props;
    const { tenantCode, checkError } = this.state;

    document.title = `${t('기관코드')} | ${t('모스텝')}`;

    return (
      <div className="wrapper">
        <div className="login">
          <h1 className="brand">
            <img src={logoImg} alt="Mostep Admin" />
          </h1>
          <div className="form_box">
            <form onSubmit={this._handleCheckTenant}>
              <div>
                <label htmlFor="orgCode" className="visually-hidden">
                  기관코드
                </label>
                <input
                  id="tenantCode"
                  className={`form-control ${checkError ? 'is-invalid' : ''}`}
                  type="text"
                  placeholder={t('기관코드를 입력해주세요.')}
                  value={tenantCode}
                  onChange={this._handleChange}
                />
                <p className="invalid-feedback">{t('입력하신 정보와 맞는 기관코드가 없습니다.')}</p>
              </div>

              <div className="btn_area">
                <button type="submit" className={`btn auth_btn ${tenantCode ? 'active' : ''}`} disabled={!tenantCode}>
                  {t('인증하기')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      checkTenant,
      dispatch,
    },
    dispatch,
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CheckTenant));
export { connected as CheckTenant };
