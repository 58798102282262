/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Header, SideBar } from '_components';
import { commonUtils } from '_helpers';
import { openAlertDialog } from '_actions';
import { changePasswordUser } from '_services';

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordConfirm: '',
      passwordValid: '',
      passwordConfirmValid: '',
    };
    this._handleChangePassword = this._handleChangePassword.bind(this);
    this._handleChangePasswordConfirm = this._handleChangePasswordConfirm.bind(this);
    this._handleSave = this._handleSave.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
  }
  componentDidMount() {
    window.$('.jquery_scrollbar').scrollbar();
  }
  _handleChangePassword(e) {
    const { value } = e.target;
    let passwordValid = '';
    if (value !== '' && !commonUtils.isValidPassword(value)) {
      passwordValid = 'invalid';
    }
    this.setState({ ...this.state, password: value, passwordValid, passwordConfirm: '', passwordConfirmValid: '' });
  }
  _handleChangePasswordConfirm(e) {
    const { value } = e.target;
    const { password } = this.state;
    let passwordConfirmValid = '';
    if (value !== '' && password !== value) {
      passwordConfirmValid = 'invalid';
    }
    this.setState({ ...this.state, passwordConfirm: value, passwordConfirmValid });
  }
  _handleSave() {
    const { t } = this.props;
    const { password, passwordValid, passwordConfirm, passwordConfirmValid } = this.state;
    const { data, userType } = this.props.location.state;

    if (password === '') {
      this.props.dispatch(openAlertDialog(t('비밀번호를 입력하세요.')));
      return;
    }
    if (passwordValid === 'invalid') {
      this.props.dispatch(openAlertDialog(t('비밀번호가 유효하지 않습니다.')));
      return;
    }
    if (passwordConfirm === '') {
      this.props.dispatch(openAlertDialog(t('비밀번호 재입력을 입력하세요.')));
      return;
    }
    if (passwordConfirmValid === 'invalid') {
      this.props.dispatch(openAlertDialog(t('비밀번호가 일치하지 않습니다.')));
      return;
    }
    const params = { password };
    this._changePasswordUser(userType === 'TM' ? data.tenantManager.userId : data.userId, params);
  }
  _changePasswordUser(userId, params) {
    const { t } = this.props;
    this.props.changePasswordUser(userId, params, error => {
      if (!error) {
        this.props.dispatch(
          openAlertDialog(t('수정되었습니다.'), 'success', false, null, null, () => {
            this.props.history.goBack();
          }),
        );
      }
    });
  }
  _handleCancel() {
    this.props.history.goBack();
  }
  render() {
    const { t } = this.props;
    const { data, userType } = this.props.location.state;
    const { password, passwordConfirm, passwordValid, passwordConfirmValid } = this.state;
    const upperMenuName = userType === 'MB' ? t('사용자') : userType === 'TR' ? t('트레이너') : t('시설');
    const userTypeName = userType === 'MB' ? t('사용자') : userType === 'TR' ? t('트레이너') : t('시설 담당자');
    document.title = `${userTypeName} ${t('암호변경')} | ${t('모스텝')}`;
    const lang = commonUtils.getBrowserLanguage();
    let breadcrumb = '';
    if (lang === 'ko') {
      breadcrumb = [`${upperMenuName} ${t('관리')}`, `${upperMenuName} ${t('수정')}`, `${userTypeName} ${t('암호변경')}`];
    } else {
      breadcrumb = [`${upperMenuName} ${t('관리')}`, `${t('수정')} ${upperMenuName}`, `${userTypeName} ${t('암호변경')}`];
    }
    return (
      <>
        <Header {...this.props} breadcrumb={breadcrumb} />
        <SideBar {...this.props} />
        <main className="contents_wrapper">
          <div className="jquery_scrollbar">
            <div className="contents">
              <div className="contents_body card">
                <div className="card_header">
                  <h3 className="title">
                    <span>{userType === 'TM' ? data.tenantManager.loginId : data.loginId}</span>
                    <span className="helper_text">
                      (<sup className="ico asterisk"></sup>
                      {t('표시는 필수입력항목입니다.')})
                    </span>
                  </h3>
                </div>
                <div className="card_body form">
                  <ul className="form_section">
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('비밀번호')}
                        <sup className="ico asterisk"></sup>
                      </label>
                      <input
                        id="password"
                        className={`form-control ${passwordValid === 'invalid' ? 'is-invalid' : ''}`}
                        type="password"
                        placeholder=""
                        value={password}
                        onChange={this._handleChangePassword}
                      />
                      {passwordValid === 'invalid' && (
                        <p className="invalid-feedback">{t('특수문자, 영문대소문자, 숫자로 8글자 이상.')}</p>
                      )}
                    </li>
                    <li className="form-group">
                      <label htmlFor="passwordConfirm" className="form-label">
                        {t('비밀번호 재입력')}
                        <sup className="ico asterisk"></sup>
                      </label>
                      <input
                        id="passwordConfirm"
                        className={`form-control ${passwordConfirmValid === 'invalid' ? 'is-invalid' : ''}`}
                        type="password"
                        placeholder=""
                        value={passwordConfirm}
                        onChange={this._handleChangePasswordConfirm}
                      />
                      {passwordConfirmValid === 'invalid' && (
                        <p className="invalid-feedback">{t('비밀번호가 일치하지 않습니다.')}</p>
                      )}
                    </li>
                  </ul>
                </div>
                <div className="card_footer">
                  <div className="btn_area">
                    <button type="button" className="btn btn-lg btn-primary" onClick={this._handleSave}>
                      {t('저장')}
                    </button>
                    <button type="button" className="btn btn-lg btn-outline-primary" onClick={this._handleCancel}>
                      {t('취소')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePasswordUser,
      dispatch,
    },
    dispatch,
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChangePassword));
export { connected as ChangePassword };
