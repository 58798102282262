import { requestUrl, requestHeaders, requestParam2, handleResponse } from '_helpers';
import {
  fetchClassAiLrbiasDiagnosisListRequest,
  fetchClassAiLrbiasDiagnosisListSuccess,
  fetchClassAiLrbiasDiagnosisListError,
  fetchClassAiMomentumDiagnosisListRequest,
  fetchClassAiMomentumDiagnosisListSuccess,
  fetchClassAiMomentumDiagnosisListError,
  fetchClassAiAwarenessDiagnosisListRequest,
  fetchClassAiAwarenessDiagnosisListSuccess,
  fetchClassAiAwarenessDiagnosisListError,
  fetchClassAiReactionDiagnosisListRequest,
  fetchClassAiReactionDiagnosisListSuccess,
  fetchClassAiReactionDiagnosisListError,
  fetchClassAiMemoryDiagnosisListRequest,
  fetchClassAiMemoryDiagnosisListSuccess,
  fetchClassAiMemoryDiagnosisListError,
} from '_actions';

// 클래스AI 진단결과 좌우편향성 목록 조회
export function fetchClassAiLrbiasDiagnosisList(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(
      `${process.env.REACT_APP_APIURL}/api/classaimodel/users/${userId}/lrbias-diagnosis/history?${requestParam2(params)}`,
    );
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchClassAiLrbiasDiagnosisListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchClassAiLrbiasDiagnosisListSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchClassAiLrbiasDiagnosisListError(error));
      });
  };
}

// 클래스AI 진단결과 운동량 목록 조회
export function fetchClassAiMomentumDiagnosisList(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(
      `${process.env.REACT_APP_APIURL}/api/classaimodel/users/${userId}/momentum-diagnosis/history?${requestParam2(params)}`,
    );
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchClassAiMomentumDiagnosisListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchClassAiMomentumDiagnosisListSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchClassAiMomentumDiagnosisListError(error));
      });
  };
}

// 클래스AI 진단결과 인지도 목록 조회
export function fetchClassAiAwarenessDiagnosisList(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(
      `${process.env.REACT_APP_APIURL}/api/classaimodel/users/${userId}/awareness-diagnosis/history?${requestParam2(
        params,
      )}`,
    );
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchClassAiAwarenessDiagnosisListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchClassAiAwarenessDiagnosisListSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchClassAiAwarenessDiagnosisListError(error));
      });
  };
}

// 클래스AI 진단결과 반응성 목록 조회
export function fetchClassAiReactionDiagnosisList(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(
      `${process.env.REACT_APP_APIURL}/api/classaimodel/users/${userId}/reaction-diagnosis/history?${requestParam2(params)}`,
    );
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchClassAiReactionDiagnosisListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchClassAiReactionDiagnosisListSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchClassAiReactionDiagnosisListError(error));
      });
  };
}

// 클래스AI 진단결과 기억력 목록 조회
export function fetchClassAiMemoryDiagnosisList(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(
      `${process.env.REACT_APP_APIURL}/api/classaimodel/users/${userId}/memory-diagnosis/history?${requestParam2(params)}`,
    );
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchClassAiMemoryDiagnosisListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchClassAiMemoryDiagnosisListSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchClassAiMemoryDiagnosisListError(error));
      });
  };
}
