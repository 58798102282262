/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import 'moment/locale/ko';
import { Header, SideBar, DateRange } from '_components';
import { commonUtils } from '_helpers';
import { openAlertDialog } from '_actions';
import {
  getLocalTenant,
  fetchClass,
  fetchTrainerList,
  fetchPatternGroupList,
  fetchMemberList,
  addClass,
  modifyClass,
} from '_services';

class ClassEdit extends Component {
  constructor(props) {
    super(props);

    const now = moment();
    this.timeArr = [
      { startTime: '10:00', endTime: '11:30' },
      { startTime: '12:00', endTime: '13:30' },
      { startTime: '14:00', endTime: '15:30' },
      { startTime: '16:00', endTime: '17:30' },
      { startTime: '18:00', endTime: '19:30' },
    ];
    this.state = {
      classNm: '',
      description: '',
      classTrainerList: [],
      allTrainerList: [],
      startDate: now.format('YYYY-MM-DD'),
      endDate: now.add(2, 'months').format('YYYY-MM-DD'),
      dayOfWeek: commonUtils.getDayOfWeekChecked(''),
      startTime: '',
      endTime: '',
      classUnitList: [],
      classMemberList: [],
      allMemberList: [],
      keywordTrainer: '',
      keywordMember: '',
      isChanged: false,
      isDateChanged: false,
    };
    this._handleChange = this._handleChange.bind(this);
    this._handleSearchTrainer = this._handleSearchTrainer.bind(this);
    this._handleCheckAllTrainer = this._handleCheckAllTrainer.bind(this);
    this._handleCheckTrainer = this._handleCheckTrainer.bind(this);
    this._handleLeftTrainer = this._handleLeftTrainer.bind(this);
    this._handleRightTrainer = this._handleRightTrainer.bind(this);
    this._handleChangeStartDate = this._handleChangeStartDate.bind(this);
    this._handleChangeEndDate = this._handleChangeEndDate.bind(this);
    this._handleCheckWeek = this._handleCheckWeek.bind(this);
    this._handleChangeStartTime = this._handleChangeStartTime.bind(this);
    this._handleChangeEndTime = this._handleChangeEndTime.bind(this);
    this._handleChangePatternGroup = this._handleChangePatternGroup.bind(this);
    this._handleBatchCreate = this._handleBatchCreate.bind(this);
    this._handleSearchMember = this._handleSearchMember.bind(this);
    this._handleCheckMember = this._handleCheckMember.bind(this);
    this._handleCheckAllMember = this._handleCheckAllMember.bind(this);
    this._handleLeftMember = this._handleLeftMember.bind(this);
    this._handleRightMember = this._handleRightMember.bind(this);
    this._handleSave = this._handleSave.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
  }
  componentDidMount() {
    window.$('.jquery_scrollbar').scrollbar();
    this._loadData();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.trainerListData !== this.props.trainerListData) {
      this.props.trainerListData.data.forEach(e => {
        e.checked = false;
      });
      this.setState({
        ...this.state,
        allTrainerList: this.props.trainerListData.data,
      });
    }
    if (prevProps.memberListData !== this.props.memberListData) {
      this.props.memberListData.data.forEach(e => {
        e.checked = false;
      });
      this.setState({
        ...this.state,
        allMemberList: this.props.memberListData.data,
      });
    }
    if (prevProps.classData !== this.props.classData) {
      this.props.classData.classTrainerList.forEach(e => {
        e.checked = false;
      });
      this.props.classData.classMemberList.forEach(e => {
        e.checked = false;
      });
      this.setState({
        ...this.state,
        classNm: this.props.classData.classNm,
        description: this.props.classData.description,
        classTrainerList: this.props.classData.classTrainerList,
        classMemberList: this.props.classData.classMemberList,
        startDate: this.props.classData.startDate,
        endDate: this.props.classData.endDate,
        dayOfWeek: commonUtils.getDayOfWeekChecked(this.props.classData.dayOfWeek),
        startTime: this.props.classData.startTime,
        endTime: this.props.classData.endTime,
        classUnitList: this.props.classData.classUnitList,
      });
    }
  }
  _loadData() {
    this._loadTrainerList('');
    this._loadClass();
    this._loadPatternGroupList();
    this._loadMemberList('');
  }
  _loadTrainerList(keyword) {
    const params = { simpleYn: 'Y', keyword };
    this.props.fetchTrainerList(params);
  }
  _loadClass() {
    const { data } = this.props.location.state;
    if (data) {
      this.props.fetchClass(data.classId);
    }
  }
  _loadPatternGroupList() {
    this.props.fetchPatternGroupList({});
  }
  _loadMemberList(keyword) {
    const params = { simpleYn: 'Y', keyword };
    this.props.fetchMemberList(params);
  }
  _handleChange(e) {
    const { id, value } = e.target;
    this.setState({
      ...this.state,
      [id]: value,
      isChanged: id === 'keywordTrainer' || id === 'keywordMember' ? this.state.isChanged : true,
    });
  }
  _handleSearchTrainer(e) {
    e.preventDefault();
    const { keywordTrainer } = this.state;
    this._loadTrainerList(keywordTrainer);
  }
  _handleCheckAllTrainer(e, index) {
    const { allTrainerList } = this.state;

    allTrainerList[index].checked = e.target.checked;
    this.setState({
      ...this.state,
      allTrainerList,
    });
  }
  _handleCheckTrainer(e, index) {
    const { classTrainerList } = this.state;

    classTrainerList[index].checked = e.target.checked;
    this.setState({
      ...this.state,
      classTrainerList,
    });
  }
  _handleLeftTrainer() {
    const { classTrainerList } = this.state;
    for (var i = classTrainerList.length - 1; i >= 0; i--) {
      if (classTrainerList[i].checked) {
        classTrainerList.splice(i, 1);
      }
    }
    this.setState({
      ...this.state,
      classTrainerList,
      isChanged: true,
    });
  }
  _handleRightTrainer() {
    const { allTrainerList, classTrainerList } = this.state;

    allTrainerList.forEach((item, index) => {
      if (item.checked) {
        if (!classTrainerList.find(e => e.userId === item.userId)) {
          const trainer = Object.assign({}, item);
          trainer.checked = false;
          classTrainerList.push(trainer);
        }
      }
    });
    this.setState({
      ...this.state,
      classTrainerList,
      isChanged: true,
    });
  }
  _handleChangeStartDate(date) {
    this.setState({ ...this.state, startDate: date, isChanged: true, isDateChanged: true });
  }
  _handleChangeEndDate(date) {
    this.setState({ ...this.state, endDate: date, isChanged: true, isDateChanged: true });
  }
  _handleCheckWeek(e, index) {
    const { dayOfWeek } = this.state;
    dayOfWeek[index] = e.target.checked;
    this.setState({
      ...this.state,
      dayOfWeek,
      isChanged: true,
      isDateChanged: true,
    });
  }
  _handleChangeStartTime(e) {
    this.setState({
      ...this.state,
      startTime: e.target.value,
      isChanged: true,
      isDateChanged: true,
    });
  }
  _handleChangeEndTime(e) {
    this.setState({
      ...this.state,
      endTime: e.target.value,
      isChanged: true,
      isDateChanged: true,
    });
  }
  _handleChangePatternGroup(e, classUnitIndex) {
    const { classUnitList } = this.state;
    classUnitList[classUnitIndex].patternGroupId = e.target.value;
    this.setState({
      ...this.state,
      classUnitList,
      isChanged: true,
    });
  }
  _handleBatchCreate() {
    const { t } = this.props;
    const { startDate, endDate, startTime, endTime, dayOfWeek } = this.state;

    const selectedWeek = dayOfWeek.filter(e => e === true);
    if (selectedWeek.length === 0) {
      this.props.dispatch(openAlertDialog(t('요일을 선택하세요.')));
      return;
    }
    if (startTime === '' || endTime === '') {
      this.props.dispatch(openAlertDialog(t('수업시간을 선택하세요.')));
      return;
    }

    const checkStartTime = moment(startTime, 'HH:mm');
    const checkEndTime = moment(endTime, 'HH:mm');

    if (endTime !== '00:00' && (checkStartTime.isSame(checkEndTime) || checkStartTime.isAfter(checkEndTime))) {
      this.props.dispatch(openAlertDialog(t('시간설정이 잘못되었습니다.')));
      return;
    }

    let { classUnitList } = this.state;
    let index = 0;
    let mCurrentDate = moment(startDate);
    const mEndDate = moment(endDate);
    let newClassUnitList = [];
    while (true) {
      if (mCurrentDate.isAfter(mEndDate)) {
        break;
      }
      if (dayOfWeek[mCurrentDate.day()]) {
        const startDateTime = mCurrentDate.format('YYYY-MM-DD') + ' ' + startTime;
        let endDateTime = mCurrentDate.format('YYYY-MM-DD') + ' ' + endTime;
        if (endTime === '00:00') {
          endDateTime = moment(endDateTime).add(1, 'day').format('YYYY-MM-DD HH:mm');
        }
        let classUnit = classUnitList[index++];
        if (classUnit) {
          classUnit.classUnitNo = index;
          classUnit.startDateTime = startDateTime;
          classUnit.endDateTime = endDateTime;
          newClassUnitList.push(classUnit);
        } else {
          newClassUnitList.push({ classUnitNo: index, startDateTime, endDateTime });
        }
      }
      mCurrentDate.add(1, 'days');
    }
    this.setState({
      classUnitList: newClassUnitList,
      isChanged: true,
      isDateChanged: false,
    });
  }
  _handleSearchMember(e) {
    e.preventDefault();
    const { keywordMember } = this.state;
    this._loadMemberList(keywordMember);
  }
  _handleCheckMember(e, index) {
    const { classMemberList } = this.state;

    classMemberList[index].checked = e.target.checked;
    this.setState({
      ...this.state,
      classMemberList,
    });
  }
  _handleCheckAllMember(e, index) {
    const { allMemberList } = this.state;

    allMemberList[index].checked = e.target.checked;
    this.setState({
      ...this.state,
      allMemberList,
    });
  }
  _handleLeftMember() {
    const { classMemberList } = this.state;
    for (var i = classMemberList.length - 1; i >= 0; i--) {
      if (classMemberList[i].checked) {
        classMemberList.splice(i, 1);
      }
    }
    this.setState({
      ...this.state,
      classMemberList,
      isChanged: true,
    });
  }
  _handleRightMember() {
    const { allMemberList, classMemberList } = this.state;

    allMemberList.forEach((item, index) => {
      if (item.checked) {
        if (!classMemberList.find(e => e.userId === item.userId)) {
          const member = Object.assign({}, item);
          member.checked = false;
          classMemberList.push(member);
        }
      }
    });
    this.setState({
      ...this.state,
      classMemberList,
      isChanged: true,
    });
  }
  _handleSave() {
    const { t } = this.props;
    const {
      classNm,
      description,
      classTrainerList,
      startDate,
      endDate,
      dayOfWeek,
      startTime,
      endTime,
      classUnitList,
      classMemberList,
      isChanged,
      isDateChanged,
    } = this.state;
    const { data } = this.props.location.state;

    if (isDateChanged) {
      this.props.dispatch(openAlertDialog(t('기간이 수정되었으므로 일괄생성을 수행하세요.')));
      return;
    }
    if (!isChanged) {
      this.props.dispatch(openAlertDialog(t('수정된 내용이 없습니다.')));
      return;
    }
    if (classNm === '') {
      this.props.dispatch(openAlertDialog(t('클래스 제목을 입력하세요.')));
      return;
    }
    if (description === '') {
      this.props.dispatch(openAlertDialog(t('클래스 상세 설명을 입력하세요.')));
      return;
    }
    if (classTrainerList.length === 0) {
      this.props.dispatch(openAlertDialog(t('트레이너를 선택하세요.')));
      return;
    }
    if (startDate === '' || endDate === '') {
      this.props.dispatch(openAlertDialog(t('날짜를 선택하세요.')));
      return;
    }
    if (!dayOfWeek.filter(e => e === true)) {
      this.props.dispatch(openAlertDialog(t('요일을 선택하세요.')));
      return;
    }
    if (startTime === '' || endTime === '') {
      this.props.dispatch(openAlertDialog(t('수업시간을 선택하세요.')));
      return;
    }
    if (classUnitList.length === 0) {
      this.props.dispatch(openAlertDialog(t('클래스 회차를 설정하세요.')));
      return;
    }
    if (
      classUnitList.find(
        e =>
          !e.classUnitNm ||
          !e.description ||
          !e.patternGroupId ||
          e.classUnitNm === '' ||
          e.description === '' ||
          e.patternGroupId === '',
      )
    ) {
      this.props.dispatch(openAlertDialog(t('클래스 회차 정보를 입력하세요.')));
      return;
    }
    if (classMemberList.length === 0) {
      this.props.dispatch(openAlertDialog(t('회원을 선택하세요.')));
      return;
    }
    const params = {
      classNm,
      description,
      classTrainerList,
      startDate,
      endDate,
      dayOfWeek: commonUtils.getDayOfWeek(dayOfWeek),
      startTime,
      endTime,
      classMemberList,
      classUnitList,
    };
    if (data) {
      this._modifyClass(data.classId, params);
    } else {
      this._addClass(params);
    }
  }
  _modifyClass(classId, params) {
    const { t } = this.props;
    this.props.modifyClass(classId, params, error => {
      if (!error) {
        this.props.dispatch(
          openAlertDialog(t('수정되었습니다.'), 'success', false, null, null, () => {
            this.props.history.goBack();
          }),
        );
      }
    });
  }
  _addClass(params) {
    const { t } = this.props;
    this.props.addClass(params, error => {
      if (!error) {
        this.props.dispatch(
          openAlertDialog(t('등록되었습니다.'), 'success', false, null, null, () => {
            this.props.history.goBack();
          }),
        );
      }
    });
  }
  _handleCancel() {
    const { t } = this.props;
    const { isChanged } = this.state;
    if (isChanged) {
      this.props.dispatch(
        openAlertDialog(
          t('작성중인 내역이 있습니다.<br/>정말로 이전 화면으로 돌아가시겠습니까?'),
          'alert',
          true,
          null,
          null,
          () => {
            this.props.history.goBack();
          },
        ),
      );
    } else {
      this.props.history.goBack();
    }
  }
  render() {
    const { t } = this.props;
    const lang = commonUtils.getBrowserLanguage();
    const { data } = this.props.location.state;
    const {
      classNm,
      description,
      classTrainerList,
      startDate,
      endDate,
      dayOfWeek,
      startTime,
      endTime,
      keywordTrainer,
      keywordMember,
      allTrainerList,
      classUnitList,
      classMemberList,
      allMemberList,
    } = this.state;
    const { patternGroupListData } = this.props;
    const tenant = getLocalTenant();
    let breadcrumb = '';
    if (lang === 'ko') {
      document.title = `${t('클래스')} ${data === null ? t('생성') : t('수정')} | ${t('모스텝')}`;
      breadcrumb = [t('클래스 관리'), `${t('클래스')} ${data === null ? t('생성') : t('수정')}`];
    } else {
      document.title = `${data === null ? t('생성') : t('수정')} ${t('클래스')} | ${t('모스텝')}`;
      breadcrumb = [t('클래스 관리'), `${data === null ? t('생성') : t('수정')} ${t('클래스')}`];
    }

    function _SetSelectOptionTime(type) {
      let isEnd = false;
      let options = [];
      let currentTime = type === 'start' ? '00:00' : '00:30';
      let endTime = type === 'start' ? '23:30' : '00:00';
      options.push(<option value=""></option>);
      options.push(<option value={currentTime}>{currentTime}</option>);
      while (!isEnd) {
        let time = moment().format('YYYY-MM-DD ' + currentTime);
        currentTime = moment(time).add(30, 'minutes').format('HH:mm');
        if (currentTime === '00:00') {
          options.push(<option value={currentTime}>{'24:00'}</option>);
        } else {
          options.push(<option value={currentTime}>{currentTime}</option>);
        }
        if (currentTime === endTime) {
          isEnd = true;
        }
      }
      return options;
    }

    return (
      <>
        <Header {...this.props} breadcrumb={breadcrumb} />
        <SideBar {...this.props} />
        <main className="contents_wrapper">
          <div className="jquery_scrollbar">
            <div className="contents">
              <div className="contents_body card">
                <div className="card_header">
                  <h3 className="title">
                    <span>
                      {lang === 'ko' && `${t('클래스')} ${data === null ? t('생성') : t('수정')}`}
                      {lang === 'en' && `${data === null ? t('생성') : t('수정')} ${t('클래스')}`}
                    </span>
                    <span className="helper_text">
                      (<sup className="ico asterisk"></sup>
                      {t('표시는 필수입력항목입니다.')})
                    </span>
                  </h3>
                </div>
                <div className="card_body form form_wide">
                  <ul className="form_section">
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('클래스 제목')}
                        <sup className="ico asterisk"></sup>
                      </label>
                      <input
                        id="classNm"
                        className="form-control"
                        type="text"
                        placeholder={t('최대 30자까지 입력 가능합니다.')}
                        value={classNm}
                        onChange={this._handleChange}
                      />
                    </li>
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('클래스 상세 설명')}
                        <sup className="ico asterisk"></sup>
                      </label>
                      <textarea
                        id="description"
                        className="form-control"
                        rows="8"
                        value={description ? description.replaceAll('<br/>', '\n') : ''}
                        onChange={this._handleChange}
                      ></textarea>
                    </li>
                  </ul>
                  <ul className="form_section">
                    <li className="form-group vstack">
                      <div htmlFor="" className="form-label">
                        {t('트레이너 선택')}
                        <sup className="ico asterisk"></sup>
                      </div>
                      <div className="d-flex">
                        <form className="search_bar" onSubmit={this._handleSearchTrainer}>
                          <input
                            id="keywordTrainer"
                            className="form-control"
                            type="text"
                            placeholder={t('사용자 이름이나 전화번호, 아이디를 입력해 검색하세요.')}
                            value={keywordTrainer}
                            onChange={this._handleChange}
                          />
                          <button type="submit" className="btn btn-outline-primary">
                            {t('검색')}
                          </button>
                        </form>
                        <div className="sorting_wrapper">
                          <div className="list-group">
                            <h4 className="title">{t('전체 트레이너')}</h4>
                            <div className="jquery_scrollbar">
                              <div className="scroll_inner">
                                <ul>
                                  {allTrainerList.map((item, index) => (
                                    <li key={index}>
                                      <div className="main">
                                        <div className="form-check">
                                          <input
                                            type="checkbox"
                                            id=""
                                            className="form-check-input"
                                            value={index}
                                            checked={item.checked}
                                            onChange={e => this._handleCheckAllTrainer(e, index)}
                                          />
                                        </div>
                                        <span className="name">{item.userNm}</span>
                                        <span>{t('트레이너')}</span>
                                      </div>
                                      <div>{tenant.tenantNm}</div>
                                      <div>{item.tel}</div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="btn_area">
                            <button className="btn">
                              <span className="ico left" onClick={this._handleLeftTrainer}></span>
                            </button>
                            <button className="btn">
                              <span className="ico right" onClick={this._handleRightTrainer}></span>
                            </button>
                          </div>
                          <div className="list-group selected">
                            <h4 className="title">{t('선택 트레이너')}</h4>
                            <div className="jquery_scrollbar">
                              <div className="scroll_inner">
                                <ul>
                                  {classTrainerList.map((item, index) => (
                                    <li key={index}>
                                      <div className="main">
                                        <div className="form-check">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            value={index}
                                            checked={item.checked}
                                            onChange={e => this._handleCheckTrainer(e, index)}
                                          />
                                        </div>
                                        <span className="name">{item.userNm}</span>
                                        <span>{t('트레이너')}</span>
                                      </div>
                                      <div>{tenant.tenantNm}</div>
                                      <div>{item.tel}</div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul className="form_section">
                    <li className="form-group">
                      <div htmlFor="" className="form-label">
                        {t('클래스 기간')}
                        <sup className="ico asterisk"></sup>
                      </div>
                      <div className="d-flex">
                        <div className="date_range">
                          <h4 className="title">{t('시작 날짜')}</h4>
                          <DateRange
                            className="date start"
                            minYear={2020}
                            maxYear={2040}
                            date={startDate}
                            onChange={this._handleChangeStartDate}
                          />
                          <h4 className="title">{t('종료 날짜')}</h4>
                          <DateRange
                            className="date end"
                            minYear={2020}
                            maxYear={2040}
                            date={endDate}
                            onChange={this._handleChangeEndDate}
                          />
                        </div>
                        <div className="week_check">
                          <h4 className="title">{t('요일 선택')}</h4>
                          <ul>
                            <li>
                              <div className="form-check input_btn">
                                <input
                                  id="moday"
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={dayOfWeek[1]}
                                  onChange={e => this._handleCheckWeek(e, 1)}
                                />
                                <label htmlFor="moday" className="form-check-label">
                                  {t('월')}
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check input_btn">
                                <input
                                  id="tuesday"
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={dayOfWeek[2]}
                                  onChange={e => this._handleCheckWeek(e, 2)}
                                />
                                <label htmlFor="tuesday" className="form-check-label">
                                  {t('화')}
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check input_btn">
                                <input
                                  id="wednesday"
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={dayOfWeek[3]}
                                  onChange={e => this._handleCheckWeek(e, 3)}
                                />
                                <label htmlFor="wednesday" className="form-check-label">
                                  {t('수')}
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check input_btn">
                                <input
                                  id="thursday"
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={dayOfWeek[4]}
                                  onChange={e => this._handleCheckWeek(e, 4)}
                                />
                                <label htmlFor="thursday" className="form-check-label">
                                  {t('목')}
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check input_btn">
                                <input
                                  id="friday"
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={dayOfWeek[5]}
                                  onChange={e => this._handleCheckWeek(e, 5)}
                                />
                                <label htmlFor="friday" className="form-check-label">
                                  {t('금')}
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check input_btn">
                                <input
                                  id="saturday"
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={dayOfWeek[6]}
                                  onChange={e => this._handleCheckWeek(e, 6)}
                                />
                                <label htmlFor="saturday" className="form-check-label">
                                  {t('토')}
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check input_btn">
                                <input
                                  id="sunday"
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={dayOfWeek[0]}
                                  onChange={e => this._handleCheckWeek(e, 0)}
                                />
                                <label htmlFor="sunday" className="form-check-label">
                                  {t('일')}
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="time_check">
                          <div className="time_range">
                            <h4 className="title">{t('운동 시간')}</h4>
                            <div className="time start">
                              <select className="form-select" onChange={this._handleChangeStartTime} value={startTime}>
                                {_SetSelectOptionTime('start')}
                              </select>
                            </div>
                            <div className="time end">
                              <select className="form-select" onChange={this._handleChangeEndTime} value={endTime}>
                                {_SetSelectOptionTime('end')}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="btn_area">
                          <button type="button" className="btn btn-primary" onClick={this._handleBatchCreate}>
                            {t('일괄생성')}
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul className="form_section">
                    <li className="form-group">
                      <div htmlFor="" className="form-label">
                        {t('클래스 회차 설정')}
                        <sup className="ico asterisk"></sup>
                      </div>
                      <div className="session_setting">
                        <div className="jquery_scrollbar">
                          <div className="scroll_inner">
                            <ul>
                              {classUnitList.map((item, index) => (
                                <li key={index}>
                                  <button className="btn" type="button">
                                    <span className="ico hamburger"></span>
                                  </button>
                                  <div className="main">
                                    <h4 className="title">
                                      {item.classUnitNo}
                                      {t('회차')}
                                    </h4>
                                    <div className="d-flex">
                                      <div className="form-group">
                                        <label htmlFor="" className="form-label">
                                          {t('운동 날짜 및 시간')}
                                        </label>
                                        <label htmlFor="" className="form-label">
                                          {moment(item.startDateTime).format('YYYY-MM-DD')}(
                                          {t(moment(item.startDateTime).format('dd'))}){' '}
                                          {moment(item.startDateTime).format('HH:mm')} ~
                                          {moment(item.endDateTime).format('HH:mm') === '00:00'
                                            ? '24:00'
                                            : moment(item.endDateTime).format('HH:mm')}
                                        </label>
                                      </div>
                                      <div className="form-group">
                                        <label htmlFor="" className="form-label">
                                          {t('회차 제목')}
                                          <sup className="ico asterisk"></sup>
                                        </label>
                                        <input
                                          id=""
                                          className="form-control"
                                          type="text"
                                          placeholder=""
                                          value={item.classUnitNm}
                                          onChange={e => {
                                            classUnitList[index].classUnitNm = e.target.value;
                                            this.setState({
                                              ...this.state,
                                              classUnitList,
                                              isChanged: true,
                                            });
                                          }}
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label htmlFor="" className="form-label">
                                          {t('회차 설명')}
                                          <sup className="ico asterisk"></sup>
                                        </label>
                                        <textarea
                                          id=""
                                          className="form-control"
                                          rows="8"
                                          value={item.description ? item.description.replaceAll('<br/>', '\n') : ''}
                                          onChange={e => {
                                            classUnitList[index].description = e.target.value;
                                            this.setState({
                                              ...this.state,
                                              description,
                                              isChanged: true,
                                            });
                                          }}
                                        ></textarea>
                                      </div>
                                      <div className="form-group">
                                        <label htmlFor="" className="form-label">
                                          {t('패턴 그룹')}
                                          <sup className="ico asterisk"></sup>
                                        </label>
                                        <select
                                          id=""
                                          className="form-select"
                                          onChange={e => this._handleChangePatternGroup(e, index)}
                                          value={item.patternGroupId}
                                        >
                                          <option value=""></option>
                                          {patternGroupListData &&
                                            patternGroupListData.data.map((pItem, pIndex) => (
                                              <option key={pIndex} value={pItem.patternGroupId}>
                                                {pItem.patternGroupNm}
                                              </option>
                                            ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul className="form_section">
                    <li className="form-group vstack">
                      <div htmlFor="" className="form-label">
                        {t('회원 선택')}
                        <sup className="ico asterisk"></sup>
                      </div>
                      <div className="d-flex">
                        <form className="search_bar" onSubmit={this._handleSearchMember}>
                          <input
                            id="keywordMember"
                            className="form-control"
                            type="text"
                            placeholder={t('사용자 이름이나 전화번호, 아이디를 입력해 검색하세요.')}
                            value={keywordMember}
                            onChange={this._handleChange}
                          />
                          <button type="submit" className="btn btn-outline-primary">
                            {t('검색')}
                          </button>
                        </form>
                        <div className="sorting_wrapper">
                          <div className="list-group">
                            <h4 className="title">{t('전체 회원')}</h4>
                            <div className="jquery_scrollbar">
                              <div className="scroll_inner">
                                <ul>
                                  {allMemberList.map((item, index) => (
                                    <li key={index}>
                                      <div className="main">
                                        <div className="form-check">
                                          <input
                                            type="checkbox"
                                            id=""
                                            className="form-check-input"
                                            value={index}
                                            checked={item.checked}
                                            onChange={e => this._handleCheckAllMember(e, index)}
                                          />
                                        </div>
                                        <p className="name">{item.userNm}</p>
                                        <p className="age">
                                          {item.age}
                                          {t('세')}
                                        </p>
                                        <p className={`badge ${item.gender === 'M' ? 'male' : 'female'}`}>
                                          {item.gender === 'M' ? t('남') : t('여')}
                                        </p>
                                      </div>
                                      <p>{item.loginId}</p>
                                      <p>{item.tel}</p>
                                      <p>{item.createdTime}</p>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="btn_area">
                            <button className="btn">
                              <span className="ico left" onClick={this._handleLeftMember}></span>
                            </button>
                            <button className="btn">
                              <span className="ico right" onClick={this._handleRightMember}></span>
                            </button>
                          </div>
                          <div className="list-group selected">
                            <h4 className="title"> {t('선택 회원')}</h4>
                            <div className="jquery_scrollbar">
                              <div className="scroll_inner">
                                <ul>
                                  {classMemberList.map((item, index) => (
                                    <li key={index}>
                                      <div className="main">
                                        <div className="form-check">
                                          <input
                                            type="checkbox"
                                            id=""
                                            className="form-check-input"
                                            value={index}
                                            checked={item.checked}
                                            onChange={e => this._handleCheckMember(e, index)}
                                          />
                                        </div>
                                        <p className="name">{item.userNm}</p>
                                        <p className="age">
                                          {item.age}
                                          {t('세')}
                                        </p>
                                        <p className={`badge ${item.gender === 'M' ? 'male' : 'female'}`}>
                                          {item.gender === 'M' ? t('남') : t('여')}
                                        </p>
                                      </div>
                                      <p>{item.loginId}</p>
                                      <p>{item.tel}</p>
                                      <p>{item.createdTime}</p>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="card_footer">
                  <div className="btn_area">
                    <button type="button" className="btn btn-lg btn-primary" onClick={this._handleSave}>
                      {t('저장')}
                    </button>
                    <button type="button" className="btn btn-lg btn-outline-primary" onClick={this._handleCancel}>
                      {t('취소')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { exclass, trainer, pattern, member } = state;
  return {
    classData: exclass.classData,
    trainerListData: trainer.trainerListData,
    patternGroupListData: pattern.patternGroupListData,
    memberListData: member.memberListData,
  };
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchClass,
      fetchTrainerList,
      fetchPatternGroupList,
      fetchMemberList,
      addClass,
      modifyClass,
      dispatch,
    },
    dispatch,
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClassEdit));
export { connected as ClassEdit };
