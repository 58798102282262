/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Header, SideBar, DateRange, PostCodeModal } from '_components';
import { openAlertDialog } from '_actions';
import { commonUtils } from '_helpers';
import { checkLoginId, checkEmail, addMember, modifyMember, getUserPictureLink } from '_services';
import { pathConstant } from '_constants';
import userDefaultImg from '_asset/images/img-user_default.png';

class MemberEdit extends Component {
  constructor(props) {
    super(props);

    const { data } = this.props.location.state;
    this.state = {
      userNm: data ? data.userNm : '',
      birthday: data ? data.birthday : '1940-01-01',
      gender: data ? data.gender : 'M',
      tel: data ? data.tel : '',
      loginId: data ? data.loginId : '',
      loginIdValid: '',
      password: '',
      passwordConfirm: '',
      passwordValid: '',
      passwordConfirmValid: '',
      email: data ? (data.email ? data.email : '') : '',
      emailValid: '',
      address1: data ? data.address1 : '',
      address2: data ? data.address2 : '',
      zipcode: data ? data.zipcode : '',
      guardianNm: data ? data.guardianNm : '',
      guardianTel: data ? data.guardianTel : '',
      pictureFile: null,
      picturePreviewUrl: data ? getUserPictureLink(data.userId) : '',
      nfcId: data ? data.nfcId : '',
      isChanged: false,
      postCodeOpen: false,
    };

    this._handleChange = this._handleChange.bind(this);
    this._handleChangeLoginId = this._handleChangeLoginId.bind(this);
    this._handleLoginIdBlur = this._handleLoginIdBlur.bind(this);
    this._handleChangeEmail = this._handleChangeEmail.bind(this);
    this._handleEmailBlur = this._handleEmailBlur.bind(this);
    this._handleChangePassword = this._handleChangePassword.bind(this);
    this._handleChangePasswordConfirm = this._handleChangePasswordConfirm.bind(this);
    this._handleModifyPassword = this._handleModifyPassword.bind(this);
    this._handlePictureChange = this._handlePictureChange.bind(this);
    this._handleSave = this._handleSave.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
    this._handlePostCodeClose = this._handlePostCodeClose.bind(this);
  }
  componentDidMount() {
    window.$('.jquery_scrollbar').scrollbar();
  }
  _handleChange(e) {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value, isChanged: true });
  }
  _handleChangeLoginId(e) {
    const { value } = e.target;
    this.setState({ ...this.state, loginId: value, loginIdValid: '', isChanged: true });
  }
  _handleLoginIdBlur() {
    const { loginId } = this.state;
    if (loginId === '') {
      return;
    }
    const { data } = this.props.location.state;
    const params = { userId: data ? data.userId : '', loginId };
    this.props.checkLoginId(params, (error, result) => {
      let loginIdValid = '';
      if (!error) {
        if (result.used) {
          loginIdValid = 'invalid';
        } else {
          loginIdValid = 'valid';
        }
      }
      this.setState({
        ...this.state,
        loginIdValid,
      });
    });
  }
  _handleChangeEmail(e) {
    const { value } = e.target;
    let emailValid = '';
    if (value !== '' && !commonUtils.isValidEmail(value)) {
      emailValid = 'invalidemail';
      this.setState({
        ...this.state,
        emailValid,
      });
    }
    this.setState({ ...this.state, email: value, emailValid, isChanged: true });
  }
  _handleEmailBlur() {
    const { email } = this.state;
    let { emailValid } = this.state;
    if (email === '') {
      return;
    }
    if (emailValid !== 'invalidemail') {
      const { data } = this.props.location.state;
      const params = { userId: data ? data.userId : '', email };
      this.props.checkEmail(params, (error, result) => {
        if (!error) {
          if (result.used) {
            emailValid = 'invalid';
          } else {
            emailValid = 'valid';
          }
        }
        this.setState({
          ...this.state,
          emailValid,
        });
      });
    }
  }
  _handleChangePassword(e) {
    const { value } = e.target;
    let passwordValid = '';
    if (value !== '' && !commonUtils.isValidPassword(value)) {
      passwordValid = 'invalid';
    }
    this.setState({
      ...this.state,
      password: value,
      passwordValid,
      passwordConfirm: '',
      passwordConfirmValid: '',
      isChanged: true,
    });
  }
  _handleChangePasswordConfirm(e) {
    const { value } = e.target;
    const { password } = this.state;
    let passwordConfirmValid = '';
    if (value !== '' && password !== value) {
      passwordConfirmValid = 'invalid';
    }
    this.setState({ ...this.state, passwordConfirm: value, passwordConfirmValid, isChanged: true });
  }
  _handlePostCodeClose(data) {
    if (data && data.zonecode) {
      this.setState({
        ...this.state,
        postCodeOpen: false,
        zipcode: data.zonecode,
        address1: data.address,
      });
    } else {
      this.setState({
        ...this.state,
        postCodeOpen: false,
      });
    }
  }
  _handlePictureChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        ...this.state,
        pictureFile: file,
        picturePreviewUrl: reader.result,
        isChanged: true,
      });
    };
    reader.readAsDataURL(file);
  }
  _handleSave() {
    const { t } = this.props;
    const {
      userNm,
      birthday,
      gender,
      tel,
      loginId,
      loginIdValid,
      password,
      passwordValid,
      passwordConfirm,
      passwordConfirmValid,
      email,
      emailValid,
      address1,
      address2,
      zipcode,
      guardianNm,
      guardianTel,
      nfcId,
      pictureFile,
      isChanged,
    } = this.state;
    const { data } = this.props.location.state;

    if (!isChanged) {
      this.props.dispatch(openAlertDialog(t('수정된 내용이 없습니다.')));
      return;
    }
    if (userNm === '') {
      this.props.dispatch(openAlertDialog(t('이름을 입력하세요.')));
      return;
    }
    if (birthday === '') {
      this.props.dispatch(openAlertDialog(t('생년월일을 선택하세요.')));
      return;
    }
    if (tel === '') {
      this.props.dispatch(openAlertDialog(t('연락처를 입력하세요.')));
      return;
    }
    if (loginId === '') {
      this.props.dispatch(openAlertDialog(t('아이디를 입력하세요.')));
      return;
    }
    if ((data && loginIdValid === 'invalid') || (!data && loginIdValid !== 'valid')) {
      this.props.dispatch(openAlertDialog(t('아이디가 유효하지 않습니다.')));
      return;
    }
    if (!data) {
      if (password === '') {
        this.props.dispatch(openAlertDialog(t('비밀번호를 입력하세요.')));
        return;
      }
      if (passwordValid === 'invalid') {
        this.props.dispatch(openAlertDialog(t('비밀번호가 유효하지 않습니다.')));
        return;
      }
      if (passwordConfirm === '') {
        this.props.dispatch(openAlertDialog(t('비밀번호 재입력을 입력하세요.')));
        return;
      }
      if (passwordConfirmValid === 'invalid') {
        this.props.dispatch(openAlertDialog(t('비밀번호가 일치하지 않습니다.')));
        return;
      }
    }
    if (email !== '' && !data && emailValid !== 'valid') {
      this.props.dispatch(openAlertDialog(t('이메일이 유효하지 않습니다.')));
      return;
    }
    if (email !== '' && data && email !== data.email && emailValid !== 'valid') {
      this.props.dispatch(openAlertDialog(t('이메일이 유효하지 않습니다.')));
      return;
    }
    if (nfcId === '') {
      this.props.dispatch(openAlertDialog(t('nfcId를 입력하세요.')));
      return;
    }
    const params = {
      userNm,
      birthday,
      gender,
      tel,
      loginId,
      email,
      address1,
      address2,
      zipcode,
      guardianNm,
      guardianTel,
      nfcId,
    };
    if (pictureFile) {
      params['pictureFile'] = pictureFile;
    }

    if (data) {
      this._modifyMember(data.userId, params);
    } else {
      params.password = password;
      this._addMember(params);
    }
  }
  _modifyMember(userId, params) {
    const { t } = this.props;
    this.props.modifyMember(userId, params, error => {
      if (!error) {
        this.props.dispatch(
          openAlertDialog(t('수정되었습니다.'), 'success', false, null, null, () => {
            // daum post code 검색시 history.length가 증가하므로 history.goBack()을 쓸수 없음.
            // this.props.history.goBack();
            this._goBack();
          }),
        );
      }
    });
  }
  _addMember(params) {
    const { t } = this.props;
    this.props.addMember(params, error => {
      if (!error) {
        this.props.dispatch(
          openAlertDialog(t('등록되었습니다.'), 'success', false, null, null, () => {
            // this.props.history.goBack();
            this._goBack();
          }),
        );
      }
    });
  }
  _handleCancel() {
    const { t } = this.props;
    const { isChanged } = this.state;
    if (isChanged) {
      this.props.dispatch(
        openAlertDialog(
          t('작성중인 내역이 있습니다.<br/>정말로 이전 화면으로 돌아가시겠습니까?'),
          'alert',
          true,
          null,
          null,
          () => {
            // this.props.history.goBack();
            this._goBack();
          },
        ),
      );
    } else {
      this._goBack();
    }
  }
  _goBack() {
    const { data, caller } = this.props.location.state;
    if (caller === 'mgt') {
      this.props.history.replace(pathConstant.PATH_MEMBER_MGT);
    } else {
      this.props.history.replace({ pathname: pathConstant.PATH_MEMBER_DETAIL, state: { data } });
    }
  }
  _handleModifyPassword() {
    this.props.history.push({
      pathname: pathConstant.PATH_MEMBER_PASSWORD,
      state: { data: this.props.location.state.data, userType: 'MB' },
    });
  }
  render() {
    const { t } = this.props;
    const { data } = this.props.location.state;
    const {
      userNm,
      birthday,
      gender,
      tel,
      loginId,
      loginIdValid,
      password,
      passwordConfirm,
      passwordValid,
      passwordConfirmValid,
      email,
      emailValid,
      address1,
      address2,
      guardianNm,
      guardianTel,
      picturePreviewUrl,
      nfcId,
      postCodeOpen,
    } = this.state;
    const lang = commonUtils.getBrowserLanguage();
    let breadcrumb = '';
    if (lang === 'ko') {
      document.title = `${t('사용자')} ${data === null ? t('등록') : t('수정')} | ${t('모스텝')}`;
      breadcrumb = [t('사용자 관리'), `${t('사용자')} ${data === null ? t('등록') : t('수정')}`];
    } else {
      document.title = `${data === null ? t('등록') : t('수정')} ${t('사용자')} | ${t('모스텝')}`;
      breadcrumb = [t('사용자 관리'), `${data === null ? t('등록') : t('수정')} ${t('사용자')}`];
    }
    return (
      <>
        <Header {...this.props} breadcrumb={breadcrumb} />
        <SideBar {...this.props} />
        <main className="contents_wrapper">
          <div className="jquery_scrollbar">
            <div className="contents">
              <div className="contents_body card">
                <div className="card_header">
                  <h3 className="title">
                    <span>
                      {lang === 'ko' && `${t('사용자')} ${data === null ? t('등록') : t('수정')}`}
                      {lang === 'en' && `${data === null ? t('등록') : t('수정')} ${t('사용자')}`}
                    </span>
                    <span className="helper_text">
                      (<sup className="ico asterisk"></sup>
                      {t('표시는 필수입력항목입니다.')})
                    </span>
                  </h3>
                </div>
                <div className="card_body form">
                  <ul className="form_section">
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('이름')}
                        <sup className="ico asterisk"></sup>
                      </label>
                      <input
                        id="userNm"
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={userNm}
                        onChange={this._handleChange}
                      />
                    </li>
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('생년월일')}
                        <sup className="ico asterisk"></sup>
                      </label>
                      <DateRange
                        className="birth_group"
                        minYear={1900}
                        maxYear={2020}
                        date={birthday}
                        onChange={date => {
                          this.setState({
                            ...this.state,
                            birthday: date,
                            isChanged: true,
                          });
                        }}
                      />
                    </li>
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('성별')}
                        <sup className="ico asterisk"></sup>
                      </label>
                      <div className="d-flex">
                        <div className="custom-check input_btn">
                          <input
                            id="male"
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            value="M"
                            onChange={e => {
                              this.setState({
                                ...this.state,
                                gender: e.target.value,
                                isChanged: true,
                              });
                            }}
                            checked={gender === 'M' ? true : false}
                          />
                          <label htmlFor="male" className="form-check-label">
                            {t('남성')}
                          </label>
                        </div>
                        <div className="custom-control input_btn">
                          <input
                            id="female"
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            value="F"
                            onChange={e => {
                              this.setState({
                                ...this.state,
                                gender: e.target.value,
                                isChanged: true,
                              });
                            }}
                            checked={gender === 'F' ? true : false}
                          />
                          <label htmlFor="female" className="form-check-label">
                            {t('여성')}
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('연락처(휴대전화)')}
                        <sup className="ico asterisk"></sup>
                      </label>
                      <input
                        id="tel"
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={tel}
                        onChange={this._handleChange}
                      />
                    </li>
                    <li className="edit_img">
                      <img
                        src={picturePreviewUrl ? picturePreviewUrl : userDefaultImg}
                        className="user_img"
                        alt="사용자 사진"
                      />
                      <div className="input_file">
                        <label htmlFor="imgUpload" className="btn btn-outline-secondary">
                          {t('사진 업로드')}
                        </label>
                        <input
                          id="imgUpload"
                          className="input_hide"
                          type="file"
                          accept="image/*"
                          onChange={this._handlePictureChange}
                        />
                      </div>
                    </li>
                  </ul>
                  <ul className="form_section">
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('아이디')}
                        <sup className="ico asterisk"></sup>
                      </label>
                      <input
                        id="loginId"
                        className={`form-control ${
                          loginIdValid === 'valid' ? 'is-valid' : loginIdValid === 'invalid' ? 'is-invalid' : ''
                        }`}
                        type="text"
                        placeholder=""
                        value={loginId}
                        onChange={this._handleChangeLoginId}
                        onBlur={this._handleLoginIdBlur}
                      />
                      {loginIdValid === 'valid' && <p className="valid-feedback">{t('사용 가능한 아이디입니다.')}</p>}
                      {loginIdValid === 'invalid' && (
                        <p className="invalid-feedback">{t('이미 사용 중인 아이디가 존재합니다.')}</p>
                      )}
                    </li>
                    {!data && (
                      <>
                        <li className="form-group">
                          <label htmlFor="" className="form-label">
                            {t('비밀번호')}
                            <sup className="ico asterisk"></sup>
                          </label>
                          <input
                            id="password"
                            className={`form-control ${passwordValid === 'invalid' ? 'is-invalid' : ''}`}
                            type="password"
                            placeholder=""
                            value={password}
                            onChange={this._handleChangePassword}
                          />
                          {passwordValid === 'invalid' && (
                            <p className="invalid-feedback">{t('특수문자, 영문대소문자, 숫자로 8글자 이상.')}</p>
                          )}
                        </li>
                        <li className="form-group">
                          <label htmlFor="passwordConfirm" className="form-label">
                            {t('비밀번호 재입력')}
                            <sup className="ico asterisk"></sup>
                          </label>
                          <input
                            id="passwordConfirm"
                            className={`form-control ${passwordConfirmValid === 'invalid' ? 'is-invalid' : ''}`}
                            type="password"
                            placeholder=""
                            value={passwordConfirm}
                            onChange={this._handleChangePasswordConfirm}
                          />
                          {passwordConfirmValid === 'invalid' && (
                            <p className="invalid-feedback">{t('비밀번호가 일치하지 않습니다.')}</p>
                          )}
                        </li>
                      </>
                    )}
                  </ul>
                  <ul className="form_section">
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('이메일')}
                      </label>
                      <input
                        id="email"
                        className={`form-control ${
                          emailValid === 'valid'
                            ? 'is-valid'
                            : emailValid === 'invalid' || emailValid === 'invalidemail'
                            ? 'is-invalid'
                            : ''
                        }`}
                        type="text"
                        placeholder=""
                        value={email}
                        onChange={this._handleChangeEmail}
                        onBlur={this._handleEmailBlur}
                      />
                      {emailValid === 'valid' && <p className="valid-feedback">{t('사용 가능한 이메일입니다.')}</p>}
                      {emailValid === 'invalid' && (
                        <p className="invalid-feedback">{t('이미 사용 중인 이메일이 존재합니다.')}</p>
                      )}
                      {emailValid === 'invalidemail' && <p className="invalid-feedback">{t('이메일 형식이 아닙니다.')}</p>}
                    </li>
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('주소')}
                      </label>
                      <div className="address">
                        <input
                          id=""
                          onChange={event => this.setState({ ...this.state, address1: event.target.value })}
                          className="form-control"
                          type="text"
                          placeholder={t('주소를 검색해 주세요')}
                          value={address1}
                        />
                        {commonUtils.getBrowserLanguageCountry() === 'ko_KR' && (
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                postCodeOpen: true,
                              });
                            }}
                          >
                            {t('검색')}
                          </button>
                        )}
                      </div>
                    </li>
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('상세주소')}
                      </label>
                      <div className="address">
                        <input
                          id="address2"
                          className="form-control"
                          type="text"
                          placeholder=""
                          value={address2}
                          onChange={this._handleChange}
                        />
                      </div>
                    </li>
                    <PostCodeModal open={postCodeOpen} onClose={this._handlePostCodeClose} />
                  </ul>
                  <ul className="form_section">
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('보호자명')}
                      </label>
                      <input
                        id="guardianNm"
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={guardianNm}
                        onChange={this._handleChange}
                      />
                    </li>
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('보호자 연락처(휴대전화)')}
                      </label>
                      <input
                        id="guardianTel"
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={guardianTel}
                        onChange={this._handleChange}
                      />
                    </li>
                  </ul>
                  <ul className="form_section">
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('NFC 카드 번호')}
                        <sup className="ico asterisk"></sup>
                      </label>
                      <input
                        id="nfcId"
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={nfcId}
                        onChange={this._handleChange}
                      />
                    </li>
                  </ul>
                </div>
                <div className="card_footer">
                  <div className="btn_area">
                    <button type="button" className="btn btn-lg btn-primary" onClick={this._handleSave}>
                      {t('저장')}
                    </button>
                    {data && (
                      <button type="button" className="btn btn-lg btn-primary" onClick={this._handleModifyPassword}>
                        {t('암호변경')}
                      </button>
                    )}
                    <button type="button" className="btn btn-lg btn-outline-primary" onClick={this._handleCancel}>
                      {t('취소')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { user } = state;
  return {
    checkLoginIdData: user.checkLoginIdData,
    checkEmailData: user.checkEmailData,
  };
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      checkLoginId,
      checkEmail,
      addMember,
      modifyMember,
      dispatch,
    },
    dispatch,
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MemberEdit));
export { connected as MemberEdit };
