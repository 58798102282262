export const pathConstant = {
  PATH_AUTH_CHECKTENANT: `/auth/check-tenant`,
  PATH_AUTH_LOGIN: `/auth/login`,
  PATH_DASHBOARD_PREFIX: `/dashboard/`,
  PATH_DASHBOARD_DASHBOARD: `/dashboard/dashboard`,
  PATH_MEMBER_PREFIX: `/member/`,
  PATH_MEMBER_MGT: `/member/mgt`,
  PATH_MEMBER_EDIT: `/member/edit`,
  PATH_MEMBER_DETAIL: `/member/detail`,
  PATH_MEMBER_PASSWORD: `/member/password`,
  PATH_MEMBER_PRINT: `/member/print`,
  PATH_CLASS_PREFIX: `/class/`,
  PATH_CLASS_MGT: `/class/mgt`,
  PATH_CLASS_EDIT: `/class/edit`,
  PATH_CLASS_DETAIL: `/class/detail`,
  PATH_BOARD_PREFIX: `/board/`,
  PATH_BOARD_MGT: `/board/mgt`,
  PATH_BOARD_DETAIL: `/board/detail`,
  PATH_BOARD_EDIT: `/board/edit`,
  PATH_TRAINER_PREFIX: `/trainer/`,
  PATH_TRAINER_MGT: `/trainer/mgt`,
  PATH_TRAINER_EDIT: `/trainer/edit`,
  PATH_TRAINER_PASSWORD: `/trainer/password`,
  PATH_PATTERNGROUP_PREFIX: `/pattern-group/`,
  PATH_PATTERNGROUP_MGT: `/pattern-group/mgt`,
  PATH_PATTERNGROUP_EDIT: `/pattern-group/edit`,
  PATH_TENANT_PREFIX: `/tenant/`,
  PATH_TENANT_MGT: `/tenant/mgt`,
  PATH_TENANT_EDIT: `/tenant/edit`,
  PATH_TENANT_MANAGER_PASSWORD: `/tenant/manager/password`,
  PATH_SYSTEMBOARD_PREFIX: `/system-board/`,
  PATH_SYSTEMBOARD_MGT: `/system-board/mgt`,
  PATH_SYSTEMBOARD_DETAIL: `/system-board/detail`,
  PATH_SYSTEMBOARD_EDIT: `/system-board/edit`,
};
