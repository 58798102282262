import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

class DatePicker extends Component {
  componentDidMount() {
    this.setDatePicker();
  }
  componentDidUpdate() {
    const { id, value } = this.props;
    if (value !== window.$('#' + id).val()) {
      window.$('#' + id).datepicker('setDate', moment(value, 'YYYY-MM-DD').toDate());
    }
  }
  setDatePicker() {
    const { id, type, dateformat, lang, onChange } = this.props;

    window
      .$('#' + id)
      .datepicker({
        format: dateformat,
        language: lang ? this.props.lang : 'ko',
        autoclose: true,
        startView: type === 'date' ? 0 : type === 'month' ? 1 : 2,
        maxViewMode: type === 'date' ? 0 : type === 'month' ? 1 : 2,
        orientation: 'left bottom',
        todayHighlight: true,
        templates: {
          leftArrow: '«',
          rightArrow: '»',
        },
      })
      .on('changeDate', function (e) {
        onChange(e);
        // onChange(moment(e.date).format(type === "date" ? "YY.MM.DD" : type === "month" ? "YY.MM" : "YY"));
      });
  }
  render() {
    const { t } = this.props;
    const { id, value, disabled } = this.props;
    return (
      <>
        <input
          id={id}
          className="form-control datepicker_input"
          placeholder={t('날짜선택')}
          type="text"
          readOnly
          defaultValue={value}
          disabled={disabled}
        />
      </>
    );
  }
}
const connected = withTranslation()(DatePicker);
export { connected as DatePicker };
