/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from '_helpers';
import { pathConstant } from '_constants';
import { checkAuthSystemManager } from '_services';

export class AppLayout extends Component {
  render() {
    const { pathname } = this.props.location;
    return (
      <>
        <Switch>
          {routes.map((route, idx) => {
            return route.component ? (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                render={props =>
                  pathname === '/' ? (
                    checkAuthSystemManager() ? (
                      <Redirect to={{ pathname: pathConstant.PATH_TENANT_MGT }} {...props} />
                    ) : (
                      <Redirect to={{ pathname: pathConstant.PATH_DASHBOARD_DASHBOARD }} {...props} />
                    )
                  ) : (
                    <route.component {...props} />
                  )
                }
              />
            ) : null;
          })}
        </Switch>
      </>
    );
  }
}
