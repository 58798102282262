/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { commonUtils } from '_helpers';
import { Header, SideBar } from '_components';
import { openAlertDialog } from '_actions';
import { addBoard, modifyBoard, fetchClassesOfTrainer, getLocalSession } from '_services';
import { pathConstant } from '_constants';

class BoardEdit extends Component {
  constructor(props) {
    super(props);

    const { data, classId } = this.props.location.state;
    this.state = {
      title: data ? data.title : '',
      contents: data ? data.contents : '',
      classId: data ? data.classId : classId ? classId : '',
      isChanged: false,
    };

    this._handleChange = this._handleChange.bind(this);
    this._handleSave = this._handleSave.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
    this._handleChangeClass = this._handleChangeClass.bind(this);
  }
  componentDidMount() {
    window.$('.jquery_scrollbar').scrollbar();
    this._loadData();
  }
  _loadData() {
    const { boardType } = this.props.location.state;
    const session = getLocalSession();
    if (boardType === 'C') {
      this.props.fetchClassesOfTrainer(session.userId);
    }
  }
  _handleChange(e) {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value, isChanged: true });
  }
  _handleChangeClass(e) {
    const classId = e.target.value;
    this.setState({
      ...this.state,
      classId,
    });
  }
  _handleSave() {
    const { mode } = this.props.location.state;
    if (mode === 'add') {
      this._addBoard();
    } else {
      this._modifyBoard();
    }
  }
  _addBoard() {
    const { t } = this.props;
    const { title, contents, classId } = this.state;
    const { boardType, caller } = this.props.location.state;
    const params = { title, contents, classId };
    this.props.addBoard(boardType, params, error => {
      if (!error) {
        this.props.dispatch(
          openAlertDialog(t('등록되었습니다.'), 'success', false, null, null, () => {
            const pathname = caller === 'boardmgt' ? pathConstant.PATH_BOARD_MGT : pathConstant.PATH_SYSTEMBOARD_MGT;
            this.props.history.replace({ pathname, state: { boardType } });
          }),
        );
      }
    });
  }
  _modifyBoard() {
    const { t } = this.props;
    const { title, contents, classId } = this.state;
    const { boardType, data, caller } = this.props.location.state;
    const params = { title, contents, classId };

    this.props.modifyBoard(data.boardId, params, error => {
      if (!error) {
        this.props.dispatch(
          openAlertDialog(t('수정되었습니다.'), 'success', false, null, null, () => {
            const pathname = caller === 'boardmgt' ? pathConstant.PATH_BOARD_MGT : pathConstant.PATH_SYSTEMBOARD_MGT;
            this.props.history.replace({ pathname, state: { boardType } });
          }),
        );
      }
    });
  }
  _handleCancel() {
    const { t } = this.props;
    const { isChanged } = this.state;

    if (isChanged) {
      this.props.dispatch(
        openAlertDialog(
          t('작성중인 내역이 있습니다.<br/>정말로 이전 화면으로 돌아가시겠습니까?'),
          'alert',
          true,
          null,
          null,
          () => {
            this._goBack();
          },
        ),
      );
    } else {
      this._goBack();
    }
  }
  _goBack() {
    const { mode, boardType, caller } = this.props.location.state;
    if (mode === 'add') {
      const pathname = caller === 'boardmgt' ? pathConstant.PATH_BOARD_MGT : pathConstant.PATH_SYSTEMBOARD_MGT;
      this.props.history.replace({ pathname, state: { boardType } });
    } else {
      this.props.history.goBack();
    }
  }
  render() {
    const { t } = this.props;
    const lang = commonUtils.getBrowserLanguage();
    const { mode, boardType, data } = this.props.location.state;
    const { title, contents, classId } = this.state;
    const { classesOfTrainerData } = this.props;
    const boardName = boardType === 'C' ? t('클래스') : boardType === 'T' ? t('시설') : t('시스템');
    let breadcrumb = '';
    if (lang === 'ko') {
      document.title = `${boardName} ${t('공지사항')} ${mode === 'add' ? t('등록') : t('수정')} | ${t('모스텝')}`;
      breadcrumb = [t('공지사항 관리'), `${boardName} ${t('공지사항')} ${mode === 'add' ? t('등록') : t('수정')}`];
    } else {
      document.title = `${mode === 'add' ? t('등록') : t('수정')} ${boardName} ${t('공지사항')} | ${t('모스텝')}`;
      breadcrumb = [t('공지사항 관리'), `${mode === 'add' ? t('등록') : t('수정')} ${boardName} ${t('공지사항')}`];
    }
    return (
      <>
        <Header {...this.props} breadcrumb={breadcrumb} />
        <SideBar {...this.props} />
        <main className="contents_wrapper">
          <div className="jquery_scrollbar">
            <div className="contents">
              <div className="contents_body card">
                <div className="card_header">
                  <h3 className="title">
                    {lang === 'ko' && `${boardName} ${t('공지사항')} ${mode === 'add' ? t('등록') : t('수정')}`}
                    {lang === 'en' && `${mode === 'add' ? t('등록') : t('수정')} ${boardName} ${t('공지사항')}`}
                  </h3>
                </div>
                <div className="card_body form form_wide">
                  <ul className="form_section">
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('제목')}
                      </label>
                      <input id="title" className="form-control" type="text" value={title} onChange={this._handleChange} />
                    </li>
                    {boardType === 'C' && (
                      <li className="form-group">
                        <label htmlFor="" className="form-label">
                          {t('담당 클래스')}
                        </label>
                        <select id="" className="form-select" onChange={this._handleChangeClass} value={classId}>
                          <option value="">{t('목록 선택')}</option>
                          {classesOfTrainerData &&
                            classesOfTrainerData.map((item, index) => (
                              <option key={index} value={item.classId}>
                                {item.classNm}
                              </option>
                            ))}
                        </select>
                      </li>
                    )}
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('내용')}
                      </label>
                      <textarea
                        id="contents"
                        className="form-control"
                        rows="24"
                        value={contents ? contents.replaceAll('<br/>', '\n') : ''}
                        onChange={this._handleChange}
                      ></textarea>
                    </li>
                    {mode === 'modify' && (
                      <li className="writing_info">
                        {boardType === 'C' && (
                          <p>
                            {t('클래스')} : {data.classNm}
                          </p>
                        )}
                        <p>
                          {t('작성자2')} : {data.userNm}
                        </p>
                        <p>
                          {t('작성일')} : {data.createdTime}
                        </p>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="card_footer">
                  <div className="btn_area">
                    <button
                      type="button"
                      className="btn btn-lg btn-primary"
                      onClick={this._handleSave}
                      disabled={title === '' || contents === '' || (boardType === 'C' && classId === '')}
                    >
                      {mode === 'add' ? t('등록') : t('수정')}
                    </button>
                    <button type="button" className="btn btn-lg btn-outline-secondary" onClick={this._handleCancel}>
                      {t('취소')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { trainer } = state;
  return {
    classesOfTrainerData: trainer.classesOfTrainerData,
  };
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addBoard,
      modifyBoard,
      fetchClassesOfTrainer,
      dispatch,
    },
    dispatch,
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BoardEdit));
export { connected as BoardEdit };
