import {
  FETCH_MEMBER_LIST_REQUEST,
  FETCH_MEMBER_LIST_SUCCESS,
  FETCH_MEMBER_LIST_ERROR,
  FETCH_MEMBER_REQUEST,
  FETCH_MEMBER_SUCCESS,
  FETCH_MEMBER_ERROR,
  ADD_MEMBER_REQUEST,
  ADD_MEMBER_SUCCESS,
  ADD_MEMBER_ERROR,
  MODIFY_MEMBER_REQUEST,
  MODIFY_MEMBER_SUCCESS,
  MODIFY_MEMBER_ERROR,
  REMOVE_MEMBER_REQUEST,
  REMOVE_MEMBER_SUCCESS,
  REMOVE_MEMBER_ERROR,
  FETCH_CLASSES_OF_MEMBER_REQUEST,
  FETCH_CLASSES_OF_MEMBER_SUCCESS,
  FETCH_CLASSES_OF_MEMBER_ERROR,
  FETCH_MEMBER_EXERCISE_SUMMARY_REQUEST,
  FETCH_MEMBER_EXERCISE_SUMMARY_SUCCESS,
  FETCH_MEMBER_EXERCISE_SUMMARY_ERROR,
  FETCH_MEMBER_EXERCISE_SUMMARY_HISTORY_LIST_REQUEST,
  FETCH_MEMBER_EXERCISE_SUMMARY_HISTORY_LIST_SUCCESS,
  FETCH_MEMBER_EXERCISE_SUMMARY_HISTORY_LIST_ERROR,
  FETCH_MEMBER_HEALTH_RECORD_FITNESS_LIST_REQUEST,
  FETCH_MEMBER_HEALTH_RECORD_FITNESS_LIST_SUCCESS,
  FETCH_MEMBER_HEALTH_RECORD_FITNESS_LIST_ERROR,
  FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_AVG_REQUEST,
  FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_AVG_SUCCESS,
  FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_AVG_ERROR,
  FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_RANK_REQUEST,
  FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_RANK_SUCCESS,
  FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_RANK_ERROR,
  FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_LIST_REQUEST,
  FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_LIST_SUCCESS,
  FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_LIST_ERROR,
  FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_AVG_REQUEST,
  FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_AVG_SUCCESS,
  FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_AVG_ERROR,
  FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_RANK_REQUEST,
  FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_RANK_SUCCESS,
  FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_RANK_ERROR,
  FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_LIST_REQUEST,
  FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_LIST_SUCCESS,
  FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_LIST_ERROR,
  FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_AVG_REQUEST,
  FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_AVG_SUCCESS,
  FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_AVG_ERROR,
  FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_RANK_REQUEST,
  FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_RANK_SUCCESS,
  FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_RANK_ERROR,
  FETCH_MEMBER_HEALTH_RECORD_BPI_LIST_REQUEST,
  FETCH_MEMBER_HEALTH_RECORD_BPI_LIST_SUCCESS,
  FETCH_MEMBER_HEALTH_RECORD_BPI_LIST_ERROR,
  FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_AVG_REQUEST,
  FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_AVG_SUCCESS,
  FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_AVG_ERROR,
  FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_RANK_REQUEST,
  FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_RANK_SUCCESS,
  FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_RANK_ERROR,
  FETCH_MEMBER_HEALTH_RECORD_MENTAL_LIST_REQUEST,
  FETCH_MEMBER_HEALTH_RECORD_MENTAL_LIST_SUCCESS,
  FETCH_MEMBER_HEALTH_RECORD_MENTAL_LIST_ERROR,
  FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_AVG_REQUEST,
  FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_AVG_SUCCESS,
  FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_AVG_ERROR,
  FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_RANK_REQUEST,
  FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_RANK_SUCCESS,
  FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_RANK_ERROR,
  FETCH_MEMBER_PRINT_REQUEST,
  FETCH_MEMBER_PRINT_SUCCESS,
  FETCH_MEMBER_PRINT_ERROR,
  ADD_MEMBER_HEALTH_RECORD_FITNESS_REQUEST,
  ADD_MEMBER_HEALTH_RECORD_FITNESS_SUCCESS,
  ADD_MEMBER_HEALTH_RECORD_FITNESS_ERROR,
  MODIFY_MEMBER_HEALTH_RECORD_FITNESS_REQUEST,
  MODIFY_MEMBER_HEALTH_RECORD_FITNESS_SUCCESS,
  MODIFY_MEMBER_HEALTH_RECORD_FITNESS_ERROR,
  REMOVE_MEMBER_HEALTH_RECORD_FITNESS_REQUEST,
  REMOVE_MEMBER_HEALTH_RECORD_FITNESS_SUCCESS,
  REMOVE_MEMBER_HEALTH_RECORD_FITNESS_ERROR,
  ADD_MEMBER_HEALTH_RECORD_LONGEVITY_REQUEST,
  ADD_MEMBER_HEALTH_RECORD_LONGEVITY_SUCCESS,
  ADD_MEMBER_HEALTH_RECORD_LONGEVITY_ERROR,
  MODIFY_MEMBER_HEALTH_RECORD_LONGEVITY_REQUEST,
  MODIFY_MEMBER_HEALTH_RECORD_LONGEVITY_SUCCESS,
  MODIFY_MEMBER_HEALTH_RECORD_LONGEVITY_ERROR,
  REMOVE_MEMBER_HEALTH_RECORD_LONGEVITY_REQUEST,
  REMOVE_MEMBER_HEALTH_RECORD_LONGEVITY_SUCCESS,
  REMOVE_MEMBER_HEALTH_RECORD_LONGEVITY_ERROR,
  ADD_MEMBER_HEALTH_RECORD_HAPPINESS_REQUEST,
  ADD_MEMBER_HEALTH_RECORD_HAPPINESS_SUCCESS,
  ADD_MEMBER_HEALTH_RECORD_HAPPINESS_ERROR,
  MODIFY_MEMBER_HEALTH_RECORD_HAPPINESS_REQUEST,
  MODIFY_MEMBER_HEALTH_RECORD_HAPPINESS_SUCCESS,
  MODIFY_MEMBER_HEALTH_RECORD_HAPPINESS_ERROR,
  REMOVE_MEMBER_HEALTH_RECORD_HAPPINESS_REQUEST,
  REMOVE_MEMBER_HEALTH_RECORD_HAPPINESS_SUCCESS,
  REMOVE_MEMBER_HEALTH_RECORD_HAPPINESS_ERROR,
  ADD_MEMBER_HEALTH_RECORD_BPI_REQUEST,
  ADD_MEMBER_HEALTH_RECORD_BPI_SUCCESS,
  ADD_MEMBER_HEALTH_RECORD_BPI_ERROR,
  MODIFY_MEMBER_HEALTH_RECORD_BPI_REQUEST,
  MODIFY_MEMBER_HEALTH_RECORD_BPI_SUCCESS,
  MODIFY_MEMBER_HEALTH_RECORD_BPI_ERROR,
  REMOVE_MEMBER_HEALTH_RECORD_BPI_REQUEST,
  REMOVE_MEMBER_HEALTH_RECORD_BPI_SUCCESS,
  REMOVE_MEMBER_HEALTH_RECORD_BPI_ERROR,
  ADD_MEMBER_HEALTH_RECORD_MENTAL_REQUEST,
  ADD_MEMBER_HEALTH_RECORD_MENTAL_SUCCESS,
  ADD_MEMBER_HEALTH_RECORD_MENTAL_ERROR,
  MODIFY_MEMBER_HEALTH_RECORD_MENTAL_REQUEST,
  MODIFY_MEMBER_HEALTH_RECORD_MENTAL_SUCCESS,
  MODIFY_MEMBER_HEALTH_RECORD_MENTAL_ERROR,
  REMOVE_MEMBER_HEALTH_RECORD_MENTAL_REQUEST,
  REMOVE_MEMBER_HEALTH_RECORD_MENTAL_SUCCESS,
  REMOVE_MEMBER_HEALTH_RECORD_MENTAL_ERROR,
} from '_actions';

const initialState = {
  pending: false,
  memberListData: null,
  memberData: null,
  classesOfMemberData: null,
  memberExerciseSummaryData: null,
  memberExerciseSummaryHistoryListData: null,
  memberHealthRecordFitnessListData: null,
  memberHealthRecordFitnessAgegroupAvgData: null,
  memberHealthRecordFitnessAgegroupRankData: null,
  memberHealthRecordLongevityListData: null,
  memberHealthRecordLongevityAgegroupAvgData: null,
  memberHealthRecordLongevityAgegroupRankData: null,
  memberHealthRecordHappinessListData: null,
  memberHealthRecordHappinessAgegroupAvgData: null,
  memberHealthRecordHappinessAgegroupRankData: null,
  memberHealthRecordBPIListData: null,
  memberHealthRecordBPIAgegroupAvgData: null,
  memberHealthRecordBPIAgegroupRankData: null,
  memberHealthRecordMentalListData: null,
  memberHealthRecordMentalAgegroupAvgData: null,
  memberHealthRecordMentalAgegroupRankData: null,
  memberPrintData: null,
  error: null,
};

export function member(state = initialState, action) {
  switch (action.type) {
    case FETCH_MEMBER_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        memberListData: action.data,
      };
    case FETCH_MEMBER_LIST_ERROR:
      return {
        ...state,
        pending: false,
        memberListData: null,
        error: action.error,
      };
    case FETCH_MEMBER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        memberData: action.data,
      };
    case FETCH_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        memberData: null,
        error: action.error,
      };
    case ADD_MEMBER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_MEMBER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case MODIFY_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_MEMBER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
      };
    case FETCH_CLASSES_OF_MEMBER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CLASSES_OF_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        classesOfMemberData: action.data,
      };
    case FETCH_CLASSES_OF_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        classesOfMemberData: null,
        error: action.error,
      };
    case FETCH_MEMBER_EXERCISE_SUMMARY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBER_EXERCISE_SUMMARY_SUCCESS:
      return {
        ...state,
        pending: false,
        memberExerciseSummaryData: action.data,
      };
    case FETCH_MEMBER_EXERCISE_SUMMARY_ERROR:
      return {
        ...state,
        pending: false,
        memberExerciseSummaryData: null,
        error: action.error,
      };
    case FETCH_MEMBER_EXERCISE_SUMMARY_HISTORY_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBER_EXERCISE_SUMMARY_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        memberExerciseSummaryHistoryListData: action.data,
      };
    case FETCH_MEMBER_EXERCISE_SUMMARY_HISTORY_LIST_ERROR:
      return {
        ...state,
        pending: false,
        memberExerciseSummaryHistoryListData: null,
        error: action.error,
      };
    case FETCH_MEMBER_HEALTH_RECORD_FITNESS_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBER_HEALTH_RECORD_FITNESS_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        memberHealthRecordFitnessListData: action.data,
      };
    case FETCH_MEMBER_HEALTH_RECORD_FITNESS_LIST_ERROR:
      return {
        ...state,
        pending: false,
        memberHealthRecordFitnessListData: null,
        error: action.error,
      };
    case FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_AVG_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_AVG_SUCCESS:
      return {
        ...state,
        pending: false,
        memberHealthRecordFitnessAgegroupAvgData: action.data,
      };
    case FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_AVG_ERROR:
      return {
        ...state,
        pending: false,
        memberHealthRecordFitnessAgegroupAvgData: null,
        error: action.error,
      };
    case FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_RANK_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_RANK_SUCCESS:
      return {
        ...state,
        pending: false,
        memberHealthRecordFitnessAgegroupRankData: action.data,
      };
    case FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_RANK_ERROR:
      return {
        ...state,
        pending: false,
        memberHealthRecordFitnessAgegroupRankData: null,
        error: action.error,
      };
    case FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        memberHealthRecordLongevityListData: action.data,
      };
    case FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_LIST_ERROR:
      return {
        ...state,
        pending: false,
        memberHealthRecordLongevityListData: null,
        error: action.error,
      };
    case FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_AVG_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_AVG_SUCCESS:
      return {
        ...state,
        pending: false,
        memberHealthRecordLongevityAgegroupAvgData: action.data,
      };
    case FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_AVG_ERROR:
      return {
        ...state,
        pending: false,
        memberHealthRecordLongevityAgegroupAvgData: null,
        error: action.error,
      };
    case FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_RANK_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_RANK_SUCCESS:
      return {
        ...state,
        pending: false,
        memberHealthRecordLongevityAgegroupRankData: action.data,
      };
    case FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_RANK_ERROR:
      return {
        ...state,
        pending: false,
        memberHealthRecordLongevityAgegroupRankData: null,
        error: action.error,
      };
    case FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        memberHealthRecordHappinessListData: action.data,
      };
    case FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_LIST_ERROR:
      return {
        ...state,
        pending: false,
        memberHealthRecordHappinessListData: null,
        error: action.error,
      };
    case FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_AVG_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_AVG_SUCCESS:
      return {
        ...state,
        pending: false,
        memberHealthRecordHappinessAgegroupAvgData: action.data,
      };
    case FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_AVG_ERROR:
      return {
        ...state,
        pending: false,
        memberHealthRecordHappinessAgegroupAvgData: null,
        error: action.error,
      };
    case FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_RANK_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_RANK_SUCCESS:
      return {
        ...state,
        pending: false,
        memberHealthRecordHappinessAgegroupRankData: action.data,
      };
    case FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_RANK_ERROR:
      return {
        ...state,
        pending: false,
        memberHealthRecordHappinessAgegroupRankData: null,
        error: action.error,
      };
    case FETCH_MEMBER_HEALTH_RECORD_BPI_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBER_HEALTH_RECORD_BPI_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        memberHealthRecordBPIListData: action.data,
      };
    case FETCH_MEMBER_HEALTH_RECORD_BPI_LIST_ERROR:
      return {
        ...state,
        pending: false,
        memberHealthRecordBPIListData: null,
        error: action.error,
      };
    case FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_AVG_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_AVG_SUCCESS:
      return {
        ...state,
        pending: false,
        memberHealthRecordBPIAgegroupAvgData: action.data,
      };
    case FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_AVG_ERROR:
      return {
        ...state,
        pending: false,
        memberHealthRecordBPIAgegroupAvgData: null,
        error: action.error,
      };
    case FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_RANK_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_RANK_SUCCESS:
      return {
        ...state,
        pending: false,
        memberHealthRecordBPIAgegroupRankData: action.data,
      };
    case FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_RANK_ERROR:
      return {
        ...state,
        pending: false,
        memberHealthRecordBPIAgegroupRankData: null,
        error: action.error,
      };
    case FETCH_MEMBER_HEALTH_RECORD_MENTAL_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBER_HEALTH_RECORD_MENTAL_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        memberHealthRecordMentalListData: action.data,
      };
    case FETCH_MEMBER_HEALTH_RECORD_MENTAL_LIST_ERROR:
      return {
        ...state,
        pending: false,
        memberHealthRecordMentalListData: null,
        error: action.error,
      };
    case FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_AVG_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_AVG_SUCCESS:
      return {
        ...state,
        pending: false,
        memberHealthRecordMentalAgegroupAvgData: action.data,
      };
    case FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_AVG_ERROR:
      return {
        ...state,
        pending: false,
        memberHealthRecordMentalAgegroupAvgData: null,
        error: action.error,
      };
    case FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_RANK_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_RANK_SUCCESS:
      return {
        ...state,
        pending: false,
        memberHealthRecordMentalAgegroupRankData: action.data,
      };
    case FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_RANK_ERROR:
      return {
        ...state,
        pending: false,
        memberHealthRecordMentalAgegroupRankData: null,
        error: action.error,
      };
    case FETCH_MEMBER_PRINT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBER_PRINT_SUCCESS:
      return {
        ...state,
        pending: false,
        memberPrintData: action.data,
      };
    case FETCH_MEMBER_PRINT_ERROR:
      return {
        ...state,
        pending: false,
        memberPrintData: null,
        error: action.error,
      };
    case ADD_MEMBER_HEALTH_RECORD_FITNESS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_MEMBER_HEALTH_RECORD_FITNESS_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_MEMBER_HEALTH_RECORD_FITNESS_ERROR:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_MEMBER_HEALTH_RECORD_FITNESS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case MODIFY_MEMBER_HEALTH_RECORD_FITNESS_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_MEMBER_HEALTH_RECORD_FITNESS_ERROR:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_MEMBER_HEALTH_RECORD_FITNESS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_MEMBER_HEALTH_RECORD_FITNESS_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_MEMBER_HEALTH_RECORD_FITNESS_ERROR:
      return {
        ...state,
        pending: false,
      };

    case ADD_MEMBER_HEALTH_RECORD_LONGEVITY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_MEMBER_HEALTH_RECORD_LONGEVITY_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_MEMBER_HEALTH_RECORD_LONGEVITY_ERROR:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_MEMBER_HEALTH_RECORD_LONGEVITY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case MODIFY_MEMBER_HEALTH_RECORD_LONGEVITY_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_MEMBER_HEALTH_RECORD_LONGEVITY_ERROR:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_MEMBER_HEALTH_RECORD_LONGEVITY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_MEMBER_HEALTH_RECORD_LONGEVITY_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_MEMBER_HEALTH_RECORD_LONGEVITY_ERROR:
      return {
        ...state,
        pending: false,
      };
    case ADD_MEMBER_HEALTH_RECORD_HAPPINESS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_MEMBER_HEALTH_RECORD_HAPPINESS_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_MEMBER_HEALTH_RECORD_HAPPINESS_ERROR:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_MEMBER_HEALTH_RECORD_HAPPINESS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case MODIFY_MEMBER_HEALTH_RECORD_HAPPINESS_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_MEMBER_HEALTH_RECORD_HAPPINESS_ERROR:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_MEMBER_HEALTH_RECORD_HAPPINESS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_MEMBER_HEALTH_RECORD_HAPPINESS_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_MEMBER_HEALTH_RECORD_HAPPINESS_ERROR:
      return {
        ...state,
        pending: false,
      };
    case ADD_MEMBER_HEALTH_RECORD_BPI_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_MEMBER_HEALTH_RECORD_BPI_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_MEMBER_HEALTH_RECORD_BPI_ERROR:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_MEMBER_HEALTH_RECORD_BPI_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case MODIFY_MEMBER_HEALTH_RECORD_BPI_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_MEMBER_HEALTH_RECORD_BPI_ERROR:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_MEMBER_HEALTH_RECORD_BPI_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_MEMBER_HEALTH_RECORD_BPI_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_MEMBER_HEALTH_RECORD_BPI_ERROR:
      return {
        ...state,
        pending: false,
      };
    case ADD_MEMBER_HEALTH_RECORD_MENTAL_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_MEMBER_HEALTH_RECORD_MENTAL_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_MEMBER_HEALTH_RECORD_MENTAL_ERROR:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_MEMBER_HEALTH_RECORD_MENTAL_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case MODIFY_MEMBER_HEALTH_RECORD_MENTAL_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_MEMBER_HEALTH_RECORD_MENTAL_ERROR:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_MEMBER_HEALTH_RECORD_MENTAL_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_MEMBER_HEALTH_RECORD_MENTAL_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_MEMBER_HEALTH_RECORD_MENTAL_ERROR:
      return {
        ...state,
        pending: false,
      };
    default:
      return state;
  }
}
