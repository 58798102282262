/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchMemberPrint, getUserPictureLink } from '_services';
import { commonUtils } from '_helpers';
import moment from 'moment';

import userRatingWhiteImg from '_asset/images/level-white.svg';
import userRatingYellowImg from '_asset/images/level-yellow.svg';
import userRatingSkyImg from '_asset/images/level-sky.svg';
import userRatingBlueImg from '_asset/images/level-blue.svg';
import userRatingMasterImg from '_asset/images/level-master.svg';

function getUserRatingImage(userRating) {
  switch (userRating) {
    case 'Y':
      return userRatingYellowImg;
    case 'S':
      return userRatingSkyImg;
    case 'B':
      return userRatingBlueImg;
    case 'M':
      return userRatingMasterImg;
    default:
      return userRatingWhiteImg;
  }
}

class MemberPrint extends Component {
  constructor(props) {
    super(props);
    let data = window.data;
    let memberExerciseSummaryData = window.memberExerciseSummaryData;
    let classesOfMemberData = window.classesOfMemberData;
    if (!data || !memberExerciseSummaryData) {
      window.close();
    }
    this.state = {
      data: data,
      memberExerciseSummaryData: memberExerciseSummaryData,
      classesOfMemberData: classesOfMemberData,
    };
  }

  componentDidMount() {
    if (!this.state.data || !this.state.data.userId) {
      return;
    }
    this.props.fetchMemberPrint(this.state.data.userId);
  }

  render() {
    const { t } = this.props;
    const lang = commonUtils.getBrowserLanguage();
    let { data, memberExerciseSummaryData, classesOfMemberData } = this.state;
    let { memberPrintData } = this.props;
    if (!memberPrintData) {
      memberPrintData = {
        before: {},
        recent: {},
      };
    }
    if (!data) {
      data = {};
    }
    const average_age =
      lang === 'ko'
        ? Math.floor((data.age || 0) / 10) * 10 + t('대 평균')
        : t('대 평균') + ' ' + Math.floor((data.age || 0) / 10) * 10 + "'s";
    return (
      <>
        <div className="copy_summary_page">
          <header className="print-area">
            <h1>{t('요약본 미리보기')}</h1>
          </header>
          <div className="print-time">{moment().format('YYYY-MM-DD HH:mm')}</div>
          <main className="contents">
            <div className="contents_header">
              <div className="card summary_user">
                <img src={getUserPictureLink(data.userId)} alt="유저 이미지" />
                <ul className="info_list">
                  <li>
                    <p className="name">{data.userNm}</p>
                    <p className="id">{data.loginId}</p>
                  </li>
                  <li className="division">
                    <p>
                      <span className={`badge ${data.gender === 'M' ? 'male' : 'female'}`}>
                        {data.gender === 'M' ? t('남') : t('여')}
                      </span>
                    </p>
                    <p>{data.height === 0 ? '' : data.height}cm</p>
                    <p>{data.weight === 0 ? '' : data.weight}kg</p>
                    <p>
                      {data.age}
                      {t('세')}
                    </p>
                  </li>
                  <li>
                    <p className="user_phone">{data.tel}</p>
                  </li>
                  <li>
                    <p className="record_date">
                      {data.createdTime} {t('등록 완료')}
                    </p>
                  </li>
                </ul>
              </div>
              <div className="card summary_attendance">
                <h3 className="title">{t('참여 클래스')}</h3>
                <ul className={`info_list ${classesOfMemberData && classesOfMemberData.length > 0 ? '' : 'none_data'}`}>
                  {(!classesOfMemberData || classesOfMemberData.length === 0) && (
                    <li>
                      <p>{t('참여하는 클래스가 없습니다.')}</p>
                    </li>
                  )}
                  {classesOfMemberData &&
                    classesOfMemberData.map((item, index) => (
                      <li key={index}>
                        <a href="javascript:void(0);" className="btn-link btn-sm">
                          {item.classNm}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="card summary_exercise">
                <div className="level">
                  <img src={getUserRatingImage(data.userRating)} alt="등급" />
                  <p className="text">
                    <span>{t(commonUtils.getUserRatingName(data.userRating))}</span>
                    <span
                      id="exerciseLevelTooltip"
                      className="ico info"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      data-bs-html="true"
                      title="<h3>모스텝 운동등급 일람표</h3><ul><li><img src='../images/level-white.svg' alt='운동등급' /><p className='name'>화이트</p><p className='description'>총 운동 횟수 <span className='text-primary'>100회</span> 미만</p></li><li><img src='../images/level-yellow.svg' alt='운동등급' /><p className='name'>옐로우</p><p className='description'>총 운동 횟수 <span className='text-primary'>100회</span> 이상</p></li><li><img src='../images/level-sky.svg' alt='운동등급' /><p className='name'>스카이</p><p className='description'>총 운동 횟수 <span className='text-primary'>500회</span> 이상</p></li><li><img src='../images/level-blue.svg' alt='운동등급' /><p className='name'><span>블</span><span>루</span></p><p className='description'>중,상급 패턴 <span className='text-primary'>2,000회</span> 이상</p></li><li><img src='../images/level-master.svg' alt='운동등급' /><p className='name'>마스터</p><p className='description'>중,상급 패턴 <span className='text-primary'>10,000회</span> 이상</p></li></ul>"
                    ></span>
                  </p>
                  <div className="progress">
                    <div className="progress-bar" style={{ width: '50%' }}></div>
                  </div>
                </div>
                <div className="summary">
                  <h3 className="title">
                    <span>{t('운동 요약')}</span>
                    <span className="helper_text">({t('전체 운동 기간 결과입니다.')})</span>
                  </h3>
                  <ul className="info_list">
                    <li>
                      <p className="name">{t('총 스텝 수')}</p>
                      <p className="count">
                        <span className="num">
                          {commonUtils.numberWithCommas(
                            memberExerciseSummaryData ? memberExerciseSummaryData.totalStepCount : 0,
                          )}
                        </span>
                        <span>{t('회(스텝)')}</span>
                      </p>
                    </li>
                    <li>
                      <p className="name">{t('총 운동 횟수')}</p>
                      <p className="count">
                        <span className="num">
                          {commonUtils.numberWithCommas(
                            memberExerciseSummaryData ? memberExerciseSummaryData.totalExerciseCount : 0,
                          )}
                        </span>
                        <span>{t('회(운동)')}</span>
                      </p>
                    </li>
                    <li>
                      <p className="name">{t('평균 보속')}</p>
                      <p className="count">
                        <span className="num">
                          {commonUtils.numberWithCommas(
                            memberExerciseSummaryData ? memberExerciseSummaryData.walkingSpeed : 0,
                          )}
                        </span>
                        <span>{t('초')}</span>
                      </p>
                    </li>
                    <li>
                      <p className="name">{t('총 운동시간')}</p>
                      <p className="count">
                        <span className="num">
                          {commonUtils.numberWithCommas(
                            memberExerciseSummaryData ? (memberExerciseSummaryData.takenSecond / (60 * 60)).toFixed(2) : 0,
                          )}
                        </span>
                        <span>{t('시간')}</span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="contents_body">
              <ul>
                <li className="card">
                  <div className="card_header">{t('하지근력')}</div>
                  <div className="card_body">
                    <div className="progress_wrapper">
                      <div className="gauge">
                        <span>0</span>
                        <span>150</span>
                        <span>300</span>
                      </div>
                      <ul>
                        <li className="prev">
                          <div className="progress" aria-valuemin="0" aria-valuemax="300">
                            <div
                              className="progress-bar"
                              style={{ width: (memberPrintData.before.lowerLimbStrength || 0) / 3 + '%' }}
                            ></div>
                          </div>
                          <div className="score">
                            <p className="name">{t('이전 기록')}</p>
                            <p>
                              <span className="num">{memberPrintData.before.lowerLimbStrength || 0}</span>
                              <span className="unit">{t('초')}</span>
                            </p>
                          </div>
                        </li>
                        <li className="latest">
                          <div className="progress" aria-valuemin="0" aria-valuemax="300">
                            <div
                              className="progress-bar"
                              style={{ width: (memberPrintData.recent.lowerLimbStrength || 0) / 3 + '%' }}
                            ></div>
                          </div>
                          <div className="score">
                            <p className="name">{t('최근 기록')}</p>
                            <p>
                              <span className="num">{memberPrintData.recent.lowerLimbStrength || 0}</span>
                              <span className="unit">{t('초')}</span>
                            </p>
                          </div>
                        </li>
                      </ul>
                      <div className="average">{average_age}</div>
                    </div>
                  </div>
                </li>
                <li className="card">
                  <div className="card_header">{t('균형감각')}</div>
                  <div className="card_body">
                    <div className="progress_wrapper">
                      <div className="gauge">
                        <span>0</span>
                        <span>50</span>
                        <span>100</span>
                      </div>
                      <ul>
                        <li className="prev">
                          <div className="progress" aria-valuemin="0" aria-valuemax="100">
                            <div className="progress-bar" style={{ width: memberPrintData.before.balance || 0 + '%' }}></div>
                          </div>
                          <div className="score">
                            <p className="name">{t('이전 점수')}</p>
                            <p>
                              <span className="num">{memberPrintData.before.balance || 0}</span>
                              <span className="unit">{t('점')}</span>
                            </p>
                          </div>
                        </li>
                        <li className="latest">
                          <div className="progress" aria-valuemin="0" aria-valuemax="100">
                            <div className="progress-bar" style={{ width: memberPrintData.recent.balance || 0 + '%' }}></div>
                          </div>
                          <div className="score">
                            <p className="name">{t('최근 점수')}</p>
                            <p>
                              <span className="num">{memberPrintData.recent.balance || 0}</span>
                              <span className="unit">{t('점')}</span>
                            </p>
                          </div>
                        </li>
                      </ul>
                      <div className="average">{average_age}</div>
                    </div>
                  </div>
                </li>
                <li className="card">
                  <div className="card_header">{t('집중력')}</div>
                  <div className="card_body">
                    <div className="progress_wrapper">
                      <div className="gauge">
                        <span>0</span>
                        <span>25</span>
                        <span>50</span>
                      </div>
                      <ul>
                        <li className="prev">
                          <div className="progress" aria-valuemin="0" aria-valuemax="50">
                            <div
                              className="progress-bar"
                              style={{ width: (memberPrintData.before.concentration || 0) * 2 + '%' }}
                            ></div>
                          </div>
                          <div className="score">
                            <p className="name">{t('이전 점수')}</p>
                            <p>
                              <span className="num">{memberPrintData.before.concentration || 0}</span>
                              <span className="unit">{t('점')}</span>
                            </p>
                          </div>
                        </li>
                        <li className="latest">
                          <div className="progress" aria-valuemin="0" aria-valuemax="50">
                            <div
                              className="progress-bar"
                              style={{ width: (memberPrintData.recent.concentration || 0) * 2 + '%' }}
                            ></div>
                          </div>
                          <div className="score">
                            <p className="name">{t('최근 점수')}</p>
                            <p>
                              <span className="num">{memberPrintData.recent.concentration || 0}</span>
                              <span className="unit">{t('점')}</span>
                            </p>
                          </div>
                        </li>
                      </ul>
                      <div className="average">{average_age}</div>
                    </div>
                  </div>
                </li>
                <li className="card">
                  <div className="card_header">{t('기억력')}</div>
                  <div className="card_body">
                    <div className="progress_wrapper">
                      <div className="gauge">
                        <span>0</span>
                        <span>25</span>
                        <span>50</span>
                      </div>
                      <ul>
                        <li className="prev">
                          <div className="progress" aria-valuemin="0" aria-valuemax="50">
                            <div
                              className="progress-bar"
                              style={{ width: (memberPrintData.before.memory || 0) * 2 + '%' }}
                            ></div>
                          </div>
                          <div className="score">
                            <p className="name">{t('이전 점수')}</p>
                            <p>
                              <span className="num">{memberPrintData.before.memory || 0}</span>
                              <span className="unit">{t('점')}</span>
                            </p>
                          </div>
                        </li>
                        <li className="latest">
                          <div className="progress" aria-valuemin="0" aria-valuemax="50">
                            <div
                              className="progress-bar"
                              style={{ width: (memberPrintData.recent.memory || 0) * 2 + '%' }}
                            ></div>
                          </div>
                          <div className="score">
                            <p className="name">{t('최근 점수')}</p>
                            <p>
                              <span className="num">{memberPrintData.recent.memory || 0}</span>
                              <span className="unit">{t('점')}</span>
                            </p>
                          </div>
                        </li>
                      </ul>
                      <div className="average">{average_age}</div>
                    </div>
                  </div>
                </li>
                <li className="card">
                  <div className="card_header">{t('뇌나이(BPI)')}</div>
                  <div className="card_body">
                    <div className="progress_wrapper">
                      <div className="gauge">
                        <span>0</span>
                        <span>50</span>
                        <span>100</span>
                      </div>
                      <ul>
                        <li className="prev">
                          <div className="progress" aria-valuemin="0" aria-valuemax="100">
                            <div className="progress-bar" style={{ width: (memberPrintData.before.bpi || 0) + '%' }}></div>
                          </div>
                          <div className="score">
                            <p className="name">{t('이전 점수')}</p>
                            <p>
                              <span className="num">{memberPrintData.before.bpi || 0}</span>
                              <span className="unit">{t('세')}</span>
                            </p>
                          </div>
                        </li>
                        <li className="latest">
                          <div className="progress" aria-valuemin="0" aria-valuemax="100">
                            <div className="progress-bar" style={{ width: (memberPrintData.recent.bpi || 0) + '%' }}></div>
                          </div>
                          <div className="score">
                            <p className="name">{t('최근 점수')}</p>
                            <p>
                              <span className="num">{memberPrintData.recent.bpi || 0}</span>
                              <span className="unit">{t('세')}</span>
                            </p>
                          </div>
                        </li>
                      </ul>
                      <div className="average">{average_age}</div>
                    </div>
                  </div>
                </li>
                <li className="card">
                  <div className="card_header">{t('스트레스지수')}</div>
                  <div className="card_body">
                    <div className="progress_wrapper">
                      <div className="gauge">
                        <span>0</span>
                        <span>50</span>
                        <span>100</span>
                      </div>
                      <ul>
                        <li className="prev">
                          <div className="progress" aria-valuemin="0" aria-valuemax="100">
                            <div
                              className="progress-bar"
                              style={{ width: (memberPrintData.before.stress || 0) + '%' }}
                            ></div>
                          </div>
                          <div className="score">
                            <p className="name">{t('이전 점수')}</p>
                            <p>
                              <span className="num">{memberPrintData.before.stress || 0}</span>
                              <span className="unit">{t('점')}</span>
                            </p>
                          </div>
                        </li>
                        <li className="latest">
                          <div className="progress" aria-valuemin="0" aria-valuemax="100">
                            <div
                              className="progress-bar"
                              style={{ width: (memberPrintData.recent.stress || 0) + '%' }}
                            ></div>
                          </div>
                          <div className="score">
                            <p className="name">{t('최근 점수')}</p>
                            <p>
                              <span className="num">{memberPrintData.recent.stress || 0}</span>
                              <span className="unit">{t('점')}</span>
                            </p>
                          </div>
                        </li>
                      </ul>
                      <div className="average">{average_age}</div>
                    </div>
                  </div>
                </li>
                <li className="card">
                  <div className="card_header">{t('우울정도')}</div>
                  <div className="card_body">
                    <div className="progress_wrapper">
                      <div className="gauge">
                        <span>0</span>
                        <span>15</span>
                        <span>30</span>
                      </div>
                      <ul>
                        <li className="prev">
                          <div className="progress" aria-valuemin="0" aria-valuemax="30">
                            <div
                              className="progress-bar"
                              style={{ width: (memberPrintData.before.depressed || 0) * 3.34 + '%' }}
                            ></div>
                          </div>
                          <div className="score">
                            <p className="name">{t('이전 점수')}</p>
                            <p>
                              <span className="num">{memberPrintData.before.depressed || 0}</span>
                              <span className="unit">{t('점')}</span>
                            </p>
                          </div>
                        </li>
                        <li className="latest">
                          <div className="progress" aria-valuemin="0" aria-valuemax="30">
                            <div
                              className="progress-bar"
                              style={{ width: (memberPrintData.recent.depressed || 0) * 3.34 + '%' }}
                            ></div>
                          </div>
                          <div className="score">
                            <p className="name">{t('최근 점수')}</p>
                            <p>
                              <span className="num">{memberPrintData.recent.depressed || 0}</span>
                              <span className="unit">{t('점')}</span>
                            </p>
                          </div>
                        </li>
                      </ul>
                      <div className="average">{average_age}</div>
                    </div>
                  </div>
                </li>
                <li className="card">
                  <div className="card_header">{t('사회적(행복증진)')}</div>
                  <div className="card_body">
                    <div className="progress_wrapper">
                      <div className="gauge">
                        <span>0</span>
                        <span>5</span>
                        <span>10</span>
                      </div>
                      <ul>
                        <li className="prev">
                          <div className="progress" aria-valuemin="0" aria-valuemax="10">
                            <div
                              className="progress-bar"
                              style={{ width: (memberPrintData.before.social || 0) + '0%' }}
                            ></div>
                          </div>
                          <div className="score">
                            <p className="name">{t('이전 점수')}</p>
                            <p>
                              <span className="num">{memberPrintData.before.social || 0}</span>
                              <span className="unit">{t('점')}</span>
                            </p>
                          </div>
                        </li>
                        <li className="latest">
                          <div className="progress" aria-valuemin="0" aria-valuemax="10">
                            <div
                              className="progress-bar"
                              style={{ width: (memberPrintData.recent.social || 0) + '0%' }}
                            ></div>
                          </div>
                          <div className="score">
                            <p className="name">{t('최근 점수')}</p>
                            <p>
                              <span className="num">{memberPrintData.recent.social || 0}</span>
                              <span className="unit">{t('점')}</span>
                            </p>
                          </div>
                        </li>
                      </ul>
                      <div className="average">{average_age}</div>
                    </div>
                  </div>
                </li>
                <li className="card">
                  <div className="card_header">{t('정서적(행복증진)')}</div>
                  <div className="card_body">
                    <div className="progress_wrapper">
                      <div className="gauge">
                        <span>0</span>
                        <span>5</span>
                        <span>10</span>
                      </div>
                      <ul>
                        <li className="prev">
                          <div className="progress" aria-valuemin="0" aria-valuemax="10">
                            <div
                              className="progress-bar"
                              style={{ width: (memberPrintData.before.emotional || 0) + '0%' }}
                            ></div>
                          </div>
                          <div className="score">
                            <p className="name">{t('이전 점수')}</p>
                            <p>
                              <span className="num">{memberPrintData.before.emotional || 0}</span>
                              <span className="unit">{t('점')}</span>
                            </p>
                          </div>
                        </li>
                        <li className="latest">
                          <div className="progress" aria-valuemin="0" aria-valuemax="10">
                            <div
                              className="progress-bar"
                              style={{ width: (memberPrintData.recent.emotional || 0) + '0%' }}
                            ></div>
                          </div>
                          <div className="score">
                            <p className="name">{t('최근 점수')}</p>
                            <p>
                              <span className="num">{memberPrintData.recent.emotional || 0}</span>
                              <span className="unit">{t('점')}</span>
                            </p>
                          </div>
                        </li>
                      </ul>
                      <div className="average">{average_age}</div>
                    </div>
                  </div>
                </li>
                <li className="card">
                  <div className="card_header">{t('총점(행복증진)')}</div>
                  <div className="card_body">
                    <div className="progress_wrapper">
                      <div className="gauge">
                        <span>0</span>
                        <span>50</span>
                        <span>100</span>
                      </div>
                      <ul>
                        <li className="prev">
                          <div className="progress" aria-valuemin="0" aria-valuemax="100">
                            <div className="progress-bar" style={{ width: (memberPrintData.before.total || 0) + '%' }}></div>
                          </div>
                          <div className="score">
                            <p className="name">{t('이전 점수')}</p>
                            <p>
                              <span className="num">{memberPrintData.before.total || 0}</span>
                              <span className="unit">{t('점')}</span>
                            </p>
                          </div>
                        </li>
                        <li className="latest">
                          <div className="progress" aria-valuemin="0" aria-valuemax="100">
                            <div className="progress-bar" style={{ width: (memberPrintData.recent.total || 0) + '%' }}></div>
                          </div>
                          <div className="score">
                            <p className="name">{t('최근 점수')}</p>
                            <p>
                              <span className="num">{memberPrintData.recent.total || 0}</span>
                              <span className="unit">{t('점')}</span>
                            </p>
                          </div>
                        </li>
                      </ul>
                      <div className="average">{average_age}</div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </main>
          <footer className="btn_area print-area">
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => {
                window.print();
              }}
            >
              {t('인쇄하기')}
            </button>
            <button type="button" className="btn btn-outline-secondary" onClick={window.close}>
              {t('취소')}
            </button>
          </footer>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { member } = state;
  return {
    memberPrintData: member.memberPrintData,
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchMemberPrint,
      dispatch,
    },
    dispatch,
  );

const connected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MemberPrint));

export { connected as MemberPrint };
