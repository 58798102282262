export const commonUtils = {
  getBrowserLanguage,
  getBrowserLanguageCountry,
  isValidNumber,
  isValidEmail,
  isValidPassword,
  numberWithCommas,
  hasWhiteSpace,
  nullToZero,
  getDayOfWeekName,
  getDayOfWeekChecked,
  getDayOfWeek,
  getUserRatingName,
  getUserRatingProgress,
};
function getBrowserLanguage() {
  return navigator.language.substring(0, 2);
}
function getBrowserLanguageCountry() {
  let lang = navigator.language.substring(0, 2);
  if (lang === 'ko') {
    return 'ko_KR';
  } else {
    return 'en_US';
  }
}
function isValidNumber(value) {
  return window.$.isNumeric(value);
}
function isValidEmail(value) {
  var reg = new RegExp('^[\\w\\-]+(\\.[\\w\\-_]+)*@[\\w\\-]+(\\.[\\w\\-]+)*(\\.[a-zA-Z]{2,3})$', 'gi');
  if (reg.test(value)) {
    return true;
  }
  return false;
}
function isValidPassword(value) {
  var reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
  if (reg.test(value)) {
    return true;
  }
  return false;
}
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
function hasWhiteSpace(s) {
  return /\s/g.test(s);
}
function nullToZero(num) {
  if (!num) {
    return 0;
  }
  return num;
}
function getDayOfWeekName(dayOfWeek) {
  const arr = dayOfWeek.split(',');
  const nameArr = [];
  arr.forEach(e => {
    switch (e) {
      case '0':
        nameArr.push('일');
        break;
      case '1':
        nameArr.push('월');
        break;
      case '2':
        nameArr.push('화');
        break;
      case '3':
        nameArr.push('수');
        break;
      case '4':
        nameArr.push('목');
        break;
      case '5':
        nameArr.push('금');
        break;
      case '6':
        nameArr.push('토');
        break;
      default:
        nameArr.push('?');
    }
  });
  return nameArr;
}
function getDayOfWeekChecked(dayOfWeek) {
  const checkedArr = [];
  for (let i = 0; i < 7; i++) {
    checkedArr.push(false);
  }
  const arr = dayOfWeek.split(',');
  arr.forEach(e => {
    checkedArr[e] = true;
  });
  return checkedArr;
}
function getDayOfWeek(dayOfWeekChecked) {
  const arr = [];
  for (let i = 0; i < 7; i++) {
    if (dayOfWeekChecked[i]) {
      arr.push(i);
    }
  }
  return arr.join(',');
}
function getUserRatingName(userRating) {
  switch (userRating) {
    case 'Y':
      return '옐로우';
    case 'S':
      return '스카이';
    case 'B':
      return '블루3';
    case 'M':
      return '마스터';
    default:
      return '화이트';
  }
}
function getUserRatingProgress(userRating, exCount) {
  let nextExCount = 0;
  switch (userRating) {
    case 'W':
      nextExCount = 100;
      break;
    case 'Y':
      nextExCount = 500;
      break;
    case 'S':
      nextExCount = 2000;
      break;
    case 'B':
      nextExCount = 10000;
      break;
    default:
      nextExCount = 100;
  }

  return (exCount / nextExCount) * 100;
}
