import { requestUrl, requestHeaders, requestParam, requestBody, handleResponse } from '_helpers';
import {
  fetchPatternGroupListRequest,
  fetchPatternGroupListSuccess,
  fetchPatternGroupListError,
  fetchPatternGroupRequest,
  fetchPatternGroupSuccess,
  fetchPatternGroupError,
  addPatternGroupRequest,
  addPatternGroupSuccess,
  addPatternGroupError,
  modifyPatternGroupRequest,
  modifyPatternGroupSuccess,
  modifyPatternGroupError,
  removePatternGroupRequest,
  removePatternGroupSuccess,
  removePatternGroupError,
  fetchPatternListRequest,
  fetchPatternListSuccess,
  fetchPatternListError,
  fetchPatternLevelListRequest,
  fetchPatternLevelListSuccess,
  fetchPatternLevelListError,
  fetchRecommendPatternRequest,
  fetchRecommendPatternError,
  fetchPatternRequest,
  fetchPatternSuccess,
  fetchPatternError,
} from '_actions';

// 패턴그룹 목록조회
export function fetchPatternGroupList(params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/patterngroups?${requestParam(params)}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchPatternGroupListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response);
        dispatch(fetchPatternGroupListSuccess(response));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchPatternGroupListError(error));
      });
  };
}
// 패턴그룹 상세조회
export function fetchPatternGroup(patternGroupId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/patterngroups/${patternGroupId}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchPatternGroupRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchPatternGroupSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchPatternGroupError(error));
      });
  };
}
// 패턴그룹 등록
export function addPatternGroup(params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/patterngroups`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(addPatternGroupRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(addPatternGroupSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(addPatternGroupError(error));
      });
  };
}
// 패턴그룹 수정
export function modifyPatternGroup(patternGroupId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/patterngroups/${patternGroupId}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'PUT',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(modifyPatternGroupRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(modifyPatternGroupSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(modifyPatternGroupError(error));
      });
  };
}
// 패턴그룹 삭제
export function removePatternGroup(patternGroupId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/patterngroups/${patternGroupId}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(removePatternGroupRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(removePatternGroupSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(removePatternGroupError(error));
      });
  };
}
// 패턴 목록조회
export function fetchPatternList(params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/patterns?${requestParam(params)}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchPatternListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchPatternListSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchPatternListError(error));
      });
  };
}
// 패턴레벨 목록조회
export function fetchPatternLevelList(callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/patternlevels`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchPatternLevelListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchPatternLevelListSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchPatternLevelListError(error));
      });
  };
}

// 추천 패턴 조회
export function fetchRecommendPattern(userId, callback = (error, result) => {}) {
  // /api/patterns/74
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/classaimodel/users/${userId}/recommend-diagnosis`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchRecommendPatternRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        if (response.data) {
          const patternUrl = requestUrl(`${process.env.REACT_APP_APIURL}/api/patterns/${response.data.result[0]}`);
          dispatch(fetchPatternRequest());
          return fetch(patternUrl, requestOptions)
            .then(patternResponse => handleResponse(patternResponse))
            .then(patternResponse => {
              if (patternResponse.data) {
                dispatch(fetchPatternSuccess(patternResponse.data));
                patternResponse.data.reportTime = response.data.reportTime || '';
                return patternResponse.data;
              } else {
                callback('NOT FOUND');
                dispatch(fetchPatternError('NOT FOUND DATA'));
              }
            });
        }
      })
      .then(result => {
        callback(null, result);
      })
      .catch(error => {
        callback(error);
        dispatch(fetchRecommendPatternError(error));
      });
  };
}
