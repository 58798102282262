export const FETCH_CLASS_AI_LRBIAS_RANGE_LIST_REQUEST = 'FETCH_CLASS_AI_LRBIAS_RANGE_LIST_REQUEST';
export const FETCH_CLASS_AI_LRBIAS_RANGE_LIST_SUCCESS = 'FETCH_CLASS_AI_LRBIAS_RANGE_LIST_SUCCESS';
export const FETCH_CLASS_AI_LRBIAS_RANGE_LIST_ERROR = 'FETCH_CLASS_AI_LRBIAS_RANGE_LIST_ERROR';
export const FETCH_CLASS_AI_MOMENTUM_RANGE_LIST_REQUEST = 'FETCH_CLASS_AI_MOMENTUM_RANGE_LIST_REQUEST';
export const FETCH_CLASS_AI_MOMENTUM_RANGE_LIST_SUCCESS = 'FETCH_CLASS_AI_MOMENTUM_RANGE_LIST_SUCCESS';
export const FETCH_CLASS_AI_MOMENTUM_RANGE_LIST_ERROR = 'FETCH_CLASS_AI_MOMENTUM_RANGE_LIST_ERROR';
export const FETCH_CLASS_AI_AWARENESS_RANGE_LIST_REQUEST = 'FETCH_CLASS_AI_AWARENESS_RANGE_LIST_REQUEST';
export const FETCH_CLASS_AI_AWARENESS_RANGE_LIST_SUCCESS = 'FETCH_CLASS_AI_AWARENESS_RANGE_LIST_SUCCESS';
export const FETCH_CLASS_AI_AWARENESS_RANGE_LIST_ERROR = 'FETCH_CLASS_AI_AWARENESS_RANGE_LIST_ERROR';
export const FETCH_CLASS_AI_REACTION_RANGE_LIST_REQUEST = 'FETCH_CLASS_AI_REACTION_RANGE_LIST_REQUEST';
export const FETCH_CLASS_AI_REACTION_RANGE_LIST_SUCCESS = 'FETCH_CLASS_AI_REACTION_RANGE_LIST_SUCCESS';
export const FETCH_CLASS_AI_REACTION_RANGE_LIST_ERROR = 'FETCH_CLASS_AI_REACTION_RANGE_LIST_ERROR';
export const FETCH_CLASS_AI_MEMORY_RANGE_LIST_REQUEST = 'FETCH_CLASS_AI_MEMORY_RANGE_LIST_REQUEST';
export const FETCH_CLASS_AI_MEMORY_RANGE_LIST_SUCCESS = 'FETCH_CLASS_AI_MEMORY_RANGE_LIST_SUCCESS';
export const FETCH_CLASS_AI_MEMORY_RANGE_LIST_ERROR = 'FETCH_CLASS_AI_MEMORY_RANGE_LIST_ERROR';

export function fetchClassAiLrbiasRangeListRequest() {
  return { type: FETCH_CLASS_AI_LRBIAS_RANGE_LIST_REQUEST };
}
export function fetchClassAiLrbiasRangeListSuccess(data) {
  return { type: FETCH_CLASS_AI_LRBIAS_RANGE_LIST_SUCCESS, data };
}
export function fetchClassAiLrbiasRangeListError(error) {
  return { type: FETCH_CLASS_AI_LRBIAS_RANGE_LIST_ERROR, error };
}

export function fetchClassAiMomentumRangeListRequest() {
  return { type: FETCH_CLASS_AI_MOMENTUM_RANGE_LIST_REQUEST };
}
export function fetchClassAiMomentumRangeListSuccess(data) {
  return { type: FETCH_CLASS_AI_MOMENTUM_RANGE_LIST_SUCCESS, data };
}
export function fetchClassAiMomentumRangeListError(error) {
  return { type: FETCH_CLASS_AI_MOMENTUM_RANGE_LIST_ERROR, error };
}

export function fetchClassAiAwarenessRangeListRequest() {
  return { type: FETCH_CLASS_AI_AWARENESS_RANGE_LIST_REQUEST };
}
export function fetchClassAiAwarenessRangeListSuccess(data) {
  return { type: FETCH_CLASS_AI_AWARENESS_RANGE_LIST_SUCCESS, data };
}
export function fetchClassAiAwarenessRangeListError(error) {
  return { type: FETCH_CLASS_AI_AWARENESS_RANGE_LIST_ERROR, error };
}

export function fetchClassAiReactionRangeListRequest() {
  return { type: FETCH_CLASS_AI_REACTION_RANGE_LIST_REQUEST };
}
export function fetchClassAiReactionRangeListSuccess(data) {
  return { type: FETCH_CLASS_AI_REACTION_RANGE_LIST_SUCCESS, data };
}
export function fetchClassAiReactionRangeListError(error) {
  return { type: FETCH_CLASS_AI_REACTION_RANGE_LIST_ERROR, error };
}

export function fetchClassAiMemoryRangeListRequest() {
  return { type: FETCH_CLASS_AI_MEMORY_RANGE_LIST_REQUEST };
}
export function fetchClassAiMemoryRangeListSuccess(data) {
  return { type: FETCH_CLASS_AI_MEMORY_RANGE_LIST_SUCCESS, data };
}
export function fetchClassAiMemoryRangeListError(error) {
  return { type: FETCH_CLASS_AI_MEMORY_RANGE_LIST_ERROR, error };
}
