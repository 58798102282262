/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Header, SideBar, PaginationMini } from '_components';
import {
  fetchClass,
  fetchClassExerciseSummary,
  fetchMembersOfClass,
  fetchRecentExerciseClassUnitsOfClass,
  fetchBoardList,
  fetchMember,
} from '_services';
import { pathConstant } from '_constants';
import { commonUtils } from '_helpers';

class ClassDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      classUnitNo: '',
      pageSize: 6,
      pageNo: 1,
    };

    this._handleChangeClassUnit = this._handleChangeClassUnit.bind(this);
    this._handlePageMove = this._handlePageMove.bind(this);
    this._handleAddBoard = this._handleAddBoard.bind(this);
    this._handleBoardDetail = this._handleBoardDetail.bind(this);
    this._handleMemberDetail = this._handleMemberDetail.bind(this);
    this._handleModify = this._handleModify.bind(this);
  }
  componentDidMount() {
    window.$('.jquery_scrollbar').scrollbar();
    this._loadData();
  }
  static getDerivedStateFromProps(props, state) {
    const listData = props.tenantBoardListData;
    if (listData && listData.pagination.pageNo !== state.pageNo) {
      return {
        pageNo: listData.pagination.pageNo,
      };
    }
    return null;
  }
  _loadData() {
    this._loadClass();
    this._loadClassExerciseSummary();
    this._loadRecentExerciseClassUnitsOfClass();
    this._loadBoardList(1);
  }
  _loadClass() {
    const { data } = this.props.location.state;
    if (data) {
      this.props.fetchClass(data.classId, (error, result) => {
        if (!error) {
          this.setState({
            ...this.state,
            classUnitNo: result.classUnitList[0].classUnitNo,
          });
          this._loadMembersOfClass();
        }
      });
    }
  }
  _loadClassExerciseSummary() {
    const { data } = this.props.location.state;
    if (data) {
      this.props.fetchClassExerciseSummary(data.classId);
    }
  }
  _loadMembersOfClass() {
    const { data } = this.props.location.state;
    const { classUnitNo } = this.state;
    if (data) {
      const params = { classUnitNo };
      this.props.fetchMembersOfClass(data.classId, params);
    }
  }
  _loadRecentExerciseClassUnitsOfClass() {
    const { data } = this.props.location.state;
    if (data) {
      this.props.fetchRecentExerciseClassUnitsOfClass(data.classId);
    }
  }
  _loadBoardList(pageNo) {
    const { data } = this.props.location.state;
    const { pageSize } = this.state;
    const params = { classId: data.classId, pageNo, pageSize };
    this.props.fetchBoardList('T', params);
  }
  _handleChangeClassUnit(e) {
    const classUnitNo = e.target.value;
    this.setState({
      ...this.state,
      classUnitNo,
    });
  }
  _handlePageMove(pageNo) {
    this._loadBoardList(pageNo);
  }
  _handleAddBoard() {
    const { data } = this.props.location.state;
    this.props.history.push({
      pathname: pathConstant.PATH_BOARD_EDIT,
      state: { caller: 'boardmgt', mode: 'add', boardType: 'C', classId: data.classId },
    });
  }
  _handleBoardDetail(e, item) {
    e.preventDefault();
    this.props.history.push({
      pathname: pathConstant.PATH_BOARD_DETAIL,
      state: { caller: 'boardmgt', boardType: 'C', data: item },
    });
  }
  _handleMemberDetail(e, item) {
    e.preventDefault();

    this.props.fetchMember(item.userId, (error, result) => {
      if (!error) {
        this.props.history.push({ pathname: pathConstant.PATH_MEMBER_DETAIL, state: { data: result } });
      }
    });
  }
  _handleModify(item) {
    this.props.history.push({ pathname: pathConstant.PATH_CLASS_EDIT, state: { data: item } });
  }
  render() {
    const { t } = this.props;
    const {
      classData,
      classExerciseSummaryData,
      membersOfClassData,
      recentExerciseClassUnitsOfClassData,
      tenantBoardListData,
    } = this.props;
    const { classUnitNo, pageSize, pageNo } = this.state;
    document.title = `${t('클래스')} | ${t('모스텝')}`;
    const breadcrumb = [t('클래스 관리')];
    breadcrumb.push(classData ? classData.classNm : '');
    const pagination = tenantBoardListData ? tenantBoardListData.pagination : null;
    const now = moment();
    return (
      <>
        <Header {...this.props} breadcrumb={breadcrumb} />
        <SideBar {...this.props} />

        <main className="contents_wrapper">
          <div className="jquery_scrollbar">
            <div className="contents">
              <div className="contents_header">
                <div className="card summary_class">
                  <h3 className="name">{classData && classData.classNm}</h3>
                  <select id="" className="form-select" onChange={this._handleChangeClassUnit} value={classUnitNo}>
                    {classData &&
                      classData.classUnitList.map((item, index) => (
                        <option key={index} value={item.classUnitNo}>
                          {item.classUnitNo}
                          {t('회차')}
                        </option>
                      ))}
                  </select>
                  <p className="session">
                    <span className="badge">{t('회차 정보')}</span>
                    <span className="description ellipsis">
                      {classData && classUnitNo && classData.classUnitList[classUnitNo - 1].classUnitNm}
                    </span>
                  </p>
                  <p className="time">
                    <span>
                      {classData && commonUtils.getDayOfWeekName(classData.dayOfWeek).map(item => t(item) + ' ')}
                      {classData && classData.startTime}
                    </span>
                    <span className="period">
                      {classData && classData.startDate}~{classData && classData.endDate}
                    </span>
                  </p>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                    onClick={() => this._handleModify(classData)}
                  >
                    <span className="ico edit"></span>
                    <span>{t('수정')}</span>
                  </button>
                </div>
                <div className="card summary_trainer">
                  <div className="d-flex">
                    <h3 className="name">
                      {classData && classData.classTrainerList.length > 0 && classData.classTrainerList[0].userNm}
                    </h3>
                    <span
                      className={`badge ${
                        classData && classData.classTrainerList.length > 0 && classData.classTrainerList[0].gender === 'M'
                          ? 'male'
                          : 'female'
                      }`}
                    >
                      {classData && classData.classTrainerList.length > 0 && classData.classTrainerList[0].gender === 'M'
                        ? t('남')
                        : t('여')}
                    </span>
                    <span className="position">{t('담당 트레이너')}</span>
                  </div>
                  <span className="id">
                    {classData && classData.classTrainerList.length > 0 && classData.classTrainerList[0].loginId}
                  </span>
                  <span className="phone">
                    {classData && classData.classTrainerList.length > 0 && classData.classTrainerList[0].tel}
                  </span>
                </div>
                <div className="card summary_statistic">
                  <div className="summary">
                    <h3 className="title">
                      <span>{t('클래스 통계')}</span>
                      {classExerciseSummaryData && classExerciseSummaryData.startDate && (
                        <span className="helper_text">
                          ({classExerciseSummaryData && moment(classExerciseSummaryData.startDate).format('YYYY-MM-DD')} ~{' '}
                          {classExerciseSummaryData && moment(classExerciseSummaryData.endDate).format('YYYY-MM-DD')}{' '}
                          {t('기간 내의 결과입니다.')})
                        </span>
                      )}
                    </h3>
                    <ul className="info_list">
                      <li>
                        <p className="name">{t('총 운동 회차')}</p>
                        <p className="count">
                          <span className="num">{classExerciseSummaryData && classExerciseSummaryData.exerciseCount}</span>
                          <span>{t('회')}</span>
                        </p>
                      </li>
                      <li>
                        <p className="name">{t('평균 스탭 수')}</p>
                        <p className="count">
                          <span className="num">{classExerciseSummaryData && classExerciseSummaryData.totalStepCount}</span>
                          <span>{t('회')}</span>
                        </p>
                      </li>
                      <li>
                        <p className="name">{t('평균 정확도')}</p>
                        <p className="count">
                          <span className="num">{classExerciseSummaryData && classExerciseSummaryData.accuracy}</span>
                          <span>%</span>
                        </p>
                      </li>
                      <li>
                        <p className="name">{t('평균 보속')}</p>
                        <p className="count">
                          <span className="num">{classExerciseSummaryData && classExerciseSummaryData.walkingSpeed}</span>
                          <span>{t('초')}</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="contents_body">
                <div className="card">
                  <div className="card_header">
                    <h3 className="title">
                      <span>{t('참여인원')}</span>
                      <span className="helper_text">
                        {t('총')} {membersOfClassData && membersOfClassData.length}
                        {t('명2')}
                      </span>
                    </h3>
                    {/* <div className="contents_tool">
                                            <form className="search_bar">
                                                <input
                                                    id="search"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="사용자 이름이나 전화번호, 아이디를 입력해 검색하세요."
                                                />
                                                <button type="submit" className="btn btn-outline-primary">
                                                    검색
                                                </button>
                                            </form>
                                        </div> */}
                  </div>
                  <div className="card_body">
                    <table className="table">
                      <caption className="visually-hidden">{t('테이블')}</caption>
                      <thead>
                        <tr>
                          <th scope="col">
                            <p className="d-flex">
                              <span>{t('이름')}</span>
                              {/* <span className="ico descending"></span> */}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="d-flex">
                              <span>{t('아이디')}</span>
                              {/* <span className="ico descending"></span> */}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="d-flex">
                              <span>{t('나이')}</span>
                              {/* <span className="ico ascending"></span> */}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="d-flex">
                              <span>{t('성별')}</span>
                              {/* <span className="ico descending"></span> */}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="d-flex">
                              <span>{t('연락처')}</span>
                              {/* <span className="ico descending"></span> */}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="d-flex">
                              <span>{t('운동등급')}</span>
                              {/* <span className="ico descending"></span> */}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="d-flex">
                              <span>{t('총 스텝수')}</span>
                              {/* <span className="ico descending"></span> */}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="d-flex">
                              <span>{t('정확도')}</span>
                              {/* <span className="ico descending"></span> */}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="d-flex">
                              <span>{t('최근 회차')}</span>
                              {/* <span className="ico descending"></span> */}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="d-flex">
                              <span>{t('참여 여부')}</span>
                              {/* <span className="ico descending"></span> */}
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {membersOfClassData &&
                          membersOfClassData.map((item, index) => (
                            <tr key={index}>
                              <td>{item.userNm}</td>
                              <td>
                                <a href="" onClick={e => this._handleMemberDetail(e, item)}>
                                  {item.loginId}
                                </a>
                              </td>
                              <td>{item.age}</td>
                              <td className="py-0">
                                <span className={`badge ${item.gender === 'M' ? 'male' : 'female'}`}>
                                  {item.gender === 'M' ? t('남') : t('여')}
                                </span>
                              </td>
                              <td>{item.tel}</td>
                              <td>{t(commonUtils.getUserRatingName(item.userRating))}</td>
                              <td>{commonUtils.numberWithCommas(item.totalStepCount)}</td>
                              <td>{item.accuracy}%</td>
                              <td>{item.classUnitNo}</td>
                              <td>
                                <span>{item.exerciseYn === 'Y' ? t('참여') : t('불참')}</span>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="card latest_session">
                    <div className="card_header">
                      <h3 className="title">
                        <span>{t('최근 회차')}</span>
                        <span className="helper_text">{t('최근 5회차 수업이 표시됩니다.')}</span>
                      </h3>
                    </div>
                    <div className="card_body">
                      <div className="jquery_scrollbar">
                        <div className="scroll_inner">
                          <table
                            className={`table ${
                              !recentExerciseClassUnitsOfClassData || recentExerciseClassUnitsOfClassData.length === 0
                                ? 'none_data'
                                : ''
                            }`}
                          >
                            <caption className="visually-hidden">{t('테이블')}</caption>
                            <thead>
                              <tr>
                                <th scope="col">{t('운동 날짜')}</th>
                                <th scope="col">{t('회차')}</th>
                                <th scope="col">{t('회차 상세')}</th>
                                <th scope="col">{t('패턴 그룹')}</th>
                                <th scope="col">{t('참여인원')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(!recentExerciseClassUnitsOfClassData ||
                                recentExerciseClassUnitsOfClassData.length === 0) && (
                                <tr>
                                  <td colSpan="5">
                                    <p>{t('최근 회차 수업이 없습니다.')}</p>
                                  </td>
                                </tr>
                              )}
                              {recentExerciseClassUnitsOfClassData &&
                                recentExerciseClassUnitsOfClassData.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.exerciseDate}</td>
                                    <td>{item.classUnitNo}</td>
                                    <td>{item.classUnitNm}</td>
                                    <td>{item.patternGroupNm}</td>
                                    <td>{item.exerciseMemberCount}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card notice">
                    <div className="card_header">
                      <h3 className="title">
                        {t('클래스')} {t('공지사항')}
                      </h3>
                      <PaginationMini
                        totalCount={pagination ? pagination.totalCount : 0}
                        rowCount={pagination ? pagination.pageSize : pageSize}
                        page={pagination ? pagination.pageNo : pageNo}
                        onPageMove={this._handlePageMove}
                      />
                      <button type="button" className="btn btn-outline-secondary" onClick={this._handleAddBoard}>
                        <span className="ico edit"></span>
                        <span>{t('신규')}</span>
                      </button>
                    </div>
                    <div className="card_body">
                      <div className="jquery_scrollbar">
                        <div className="scroll_inner">
                          <ul
                            className={`notice_list ${
                              !tenantBoardListData || tenantBoardListData.data.length === 0 ? 'none_data' : ''
                            }`}
                          >
                            {(!tenantBoardListData || tenantBoardListData.data.length === 0) && (
                              <li>
                                <p>{t('공지사항이 없습니다.')}</p>
                              </li>
                            )}
                            {tenantBoardListData &&
                              tenantBoardListData.data.map((item, index) => (
                                <li key={index}>
                                  <a href="" onClick={e => this._handleBoardDetail(e, item)}>
                                    <span className={`title ${now.diff(item.createdTime, 'days') <= 3 ? 'new' : ''}`}>
                                      {item.title}
                                    </span>
                                    <span className="date">{item.createdTime}</span>
                                  </a>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { exclass, board } = state;
  return {
    classData: exclass.classData,
    classExerciseSummaryData: exclass.classExerciseSummaryData,
    membersOfClassData: exclass.membersOfClassData,
    recentExerciseClassUnitsOfClassData: exclass.recentExerciseClassUnitsOfClassData,
    tenantBoardListData: board.tenantBoardListData,
  };
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchClass,
      fetchClassExerciseSummary,
      fetchMembersOfClass,
      fetchRecentExerciseClassUnitsOfClass,
      fetchBoardList,
      fetchMember,
      dispatch,
    },
    dispatch,
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClassDetail));
export { connected as ClassDetail };
