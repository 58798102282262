import { openAlertDialog } from '_actions';
import { pathConstant } from '_constants';
import { commonUtils } from '_helpers';

export function requestUrl(url) {
  return url;
}
export function requestHeaders(headers) {
  return headers;
}
export function requestBody(body) {
  return JSON.stringify(body);
}
export function requestParam(params) {
  const urlParams = new URLSearchParams(params);
  urlParams.append('lang', commonUtils.getBrowserLanguageCountry());
  return urlParams;
}
export function requestParam2(params) {
  const urlParams = new URLSearchParams(params);
  return urlParams;
}
export function requestFormData(params) {
  const formData = new FormData();
  Object.keys(params).forEach(e => {
    formData.append(e, params[e]);
  });
  return formData;
}
export function handleResponse(response, url) {
  return response.text().then(text => {
    if (!response.ok) {
      const error = `HTTP Error : [${response.status}] ${response.statusText} (${text})`;
      console.log(error);
      try {
        const errorData = JSON.parse(text);
        const errorMessage = `${errorData.body.data.errMessage}</br>(${errorData.body.data.errCode})`;
        if (errorData.body.data.errCode === 'AUT0N00-401') {
          window.fetch.dispatch(
            openAlertDialog(errorMessage, 'help', false, null, null, () => {
              window.location.href = pathConstant.PATH_AUTH_CHECKTENANT;
            }),
          );
        } else if (errorData.body.data.errCode === 'AUT0N02-401') {
          // no alert
        } else if (errorData.body.data.errCode === 'AIM0N01-404') {
          // no alert
        } else {
          window.fetch.dispatch(openAlertDialog(errorMessage, 'help'));
        }
        return Promise.reject(true);
      } catch (e) {
        // server shutdown, proxy 등의 오류
        const lang = commonUtils.getBrowserLanguage();
        if (lang === 'ko') {
          window.fetch.dispatch(openAlertDialog('관리자에게 문의하시기 바랍니다.', 'help'));
        } else {
          window.fetch.dispatch(openAlertDialog('Please contact the administrator.', 'help'));
        }
        return Promise.reject(true);
      }
    } else {
      text = escapeSpecialChars(text);
      const data = text && JSON.parse(text);
      return data.body;
    }
  });
}
var escapeSpecialChars = function (value) {
  return value.replace(/\\n/g, '<br/>'); //
  //.replace(/\"/g, '\\"')
  //.replace(/\&/g, "\\&")
  //.replace(/\r/g, "\\r")
  //.replace(/\t/g, "\\t")
  //.replace(/\b/g, "\\b")
  //.replace(/\f/g, "\\f");
};
