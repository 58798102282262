import { requestUrl, requestHeaders, requestParam, requestBody, handleResponse } from '_helpers';
import {
  fetchMemoListRequest,
  fetchMemoListSuccess,
  fetchMemoListError,
  addMemoRequest,
  addMemoSuccess,
  addMemoError,
  modifyMemoRequest,
  modifyMemoSuccess,
  modifyMemoError,
} from '_actions';

// 운동메모 목록 조회
export function fetchMemoList(params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/exercise-memos?${requestParam(params)}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemoListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response);
        dispatch(fetchMemoListSuccess(response));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemoListError(error));
      });
  };
}

// 운동메모 등록
export function addMemo(params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/exercise-memos`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(addMemoRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(addMemoSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(addMemoError(error));
      });
  };
}
// 운동메모 수정
export function modifyMemo(reqParams = {}, bodyParams = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/exercise-memos/?${requestParam(reqParams)}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'PUT',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(bodyParams),
    };
    dispatch(modifyMemoRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(modifyMemoSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(modifyMemoError(error));
      });
  };
}
