import { requestUrl, requestHeaders, requestBody, handleResponse } from '_helpers';
import {
  checkLoginIdRequest,
  checkLoginIdSuccess,
  checkLoginIdError,
  checkEmailRequest,
  checkEmailSuccess,
  checkEmailError,
  changePasswordUserRequest,
  changePasswordUserSuccess,
  changePasswordUserError,
} from '_actions';

// loginId 중복체크
export function checkLoginId(params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/users/check-login-id`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(checkLoginIdRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(checkLoginIdSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(checkLoginIdError(error));
      });
  };
}
// email 중복체크
export function checkEmail(params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/users/check-email`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(checkEmailRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(checkEmailSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(checkEmailError(error));
      });
  };
}
// 사용자 암호변경
export function changePasswordUser(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/users/${userId}/change-password`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(changePasswordUserRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(changePasswordUserSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(changePasswordUserError(error));
      });
  };
}
// 사용자 사진 url
export function getUserPictureLink(userId) {
  return `${process.env.REACT_APP_APIURL}/api/users/${userId}/picture`;
}
