export const codeConstant = {
  healthDataFitness: [
    { id: 'gripLeft', label: '왼손악력', checked: true, unit: 'kg' },
    { id: 'gripRight', label: '오른손악력', checked: true, unit: 'kg' },
    { id: 'armCurl', label: '아령들기', checked: true, unit: '회' },
    { id: 'oneLegBalanceLeft', label: '눈뜨고외발서기(왼발)', checked: true, unit: '초' },
    { id: 'oneLegBalanceRight', label: '눈뜨고외발서기(오른발)', checked: false, unit: '초' },
    { id: 'functionalReach', label: '기능성뻗기', checked: false, unit: 'cm' },
    { id: 'chariReach', label: '유연성(앉아윗몸앞으로굽히기)', checked: false, unit: 'cm' },
    { id: 'layDownAndStandUp', label: '누웠다일어나기', checked: false, unit: '초' },
    { id: 'regEnduranceAgainstWall', label: '벽에서버티기', checked: false, unit: '초' },
    { id: 'carryingBeans', label: '콩옮기기', checked: false, unit: '회' },
    { id: 'walkingEightShaped', label: '8자보행', checked: false, unit: '초' },
    { id: 'chairSitAndStand', label: '의자에서일어나기', checked: false, unit: '회' },
    // 추후 일어나걷기 -> 3m발붙여걷기 로 수정 후 일어나 걷기 새로 추가
    // { id: 'standWalk', label: '일어나걷기', checked: false, unit: '초' },
    { id: 'tandemWalk3m', label: '일어나걷기', checked: false, unit: '초' },
    { id: 'hanitualWalking5m', label: '5m보통보행', checked: false, unit: '초' },
    { id: 'maximumWalking5m', label: '5m최대보행', checked: false, unit: '초' },
    { id: 'pegboard', label: '패그보드', checked: false, unit: '회' },
  ],
  healthDataLongevity: [
    { id: 'bloodPressureSystolic', label: '수축기혈압', checked: true, unit: 'mmHg' },
    { id: 'bloodPressureDiastolic', label: '이완기혈압', checked: true, unit: 'mmHg' },
    { id: 'height', label: '신장', checked: true, unit: 'cm' },
    { id: 'weight', label: '체중', checked: true, unit: 'kg' },
    { id: 'hyperlipidemiaYn', label: '고지혈증 유무', checked: false, unit: '' },
    { id: 'bloodFlowSpeed', label: '혈류속도', checked: false, unit: 'V' },
    { id: 'waistCircumference', label: '허리둘레', checked: false, unit: 'cm' },
  ],
  healthDataBPI: [
    { id: 'concentration', label: '집중력', checked: true, unit: '점' },
    { id: 'memory', label: '기억력', checked: true, unit: '점' },
    { id: 'bpi', label: '뇌나이', checked: true, unit: '세' },
    { id: 'item1', label: '항목1', checked: true, unit: '점' },
    { id: 'item2', label: '항목2', checked: false, unit: '점' },
  ],
  healthDataMental: [
    { id: 'stress', label: '스트레스 지수', checked: true, unit: '점' },
    { id: 'depressed', label: '우울정도', checked: true, unit: '점' },
    { id: 'etc1', label: '기타1', checked: true, unit: '점' },
  ],
  healthDataHappiness: [
    { id: 'physical', label: '신체적', checked: true, unit: '점' },
    { id: 'mental', label: '정신적', checked: true, unit: '점' },
    { id: 'emotional', label: '정서적', checked: true, unit: '점' },
    { id: 'professional', label: '직업적', checked: true, unit: '점' },
    { id: 'intellectual', label: '지적', checked: false, unit: '점' },
    { id: 'environmental', label: '환경적', checked: false, unit: '점' },
    { id: 'economical', label: '경제적', checked: false, unit: '점' },
    { id: 'social', label: '사회적', checked: false, unit: '점' },
    { id: 'spiritual', label: '영적', checked: false, unit: '점' },
    { id: 'medical', label: '의료적', checked: false, unit: '점' },
    { id: 'total', label: '총점', checked: false, unit: '점' },
  ],
};
