import { loadingStart, loadingStop } from '_actions';
import { pathConstant } from '_constants';
import { history } from '_helpers';

export function configureFakeBackend() {
  let realFetch = window.fetch;
  let callCount = 0;
  let isShowProgressDialog = false;
  let lastDelayedStopId = 0;
  window.fetch = function (url, opts) {
    return new Promise((resolve, reject) => {
      if (typeof window.fetch.dispatch === 'function') {
        callCount++;
        if (!isShowProgressDialog) {
          isShowProgressDialog = true;
          window.fetch.dispatch(loadingStart());
        }
      }
      let callback = response => {
        if (typeof window.fetch.dispatch === 'function' && callCount > 0) {
          callCount--;
          /**
           * 로딩바의 잦은 업데이트를 방지하기 위해 150ms동안의 로딩바 종료 유보 시간을 둔다.
           * 유보 시간 이내에 다른 API 호출이,
           * 있는 경우 로딩바 종료 유보 시간은 초기화 되고,(기존 프로세스를 삭제하고 새로운 프로세스 생성)
           * 없는 경우 로딩바는 종료된다.
           */
          if (lastDelayedStopId) {
            clearTimeout(lastDelayedStopId);
          }
          lastDelayedStopId = setTimeout(() => {
            if (callCount === 0) {
              window.fetch.dispatch(loadingStop());
              isShowProgressDialog = false;
            }
          }, 150);
        }
        if (response) return resolve(response);
      };

      try {
        realFetch(url, opts)
          .then(callback)
          .catch(error => {
            // alert(error);
            history.replace(pathConstant.PATH_AUTH_CHECKTENANT);
            callback();
          });
      } catch (e) {
        callback();
      }
    });
  };
}
