export const LOADING_START = 'LOADING_START';
export const LOADING_STOP = 'LOADING_STOP';

export function loadingStart(message) {
  return { type: LOADING_START, message };
}

export function loadingStop(message) {
  return { type: LOADING_STOP, message };
}
