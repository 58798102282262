import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './loading.module.css';

class Loading extends Component {
  constructor(props) {
    super(props);

    window.fetch.dispatch = this.props.dispatch;
  }
  render() {
    const { t } = this.props;
    return this.props.loading ? (
      <div className={styles.loading}>
        <div className={styles.rotate_ring}></div>
        <div className={styles.text}>{t('로딩 중입니다...')}</div>
      </div>
    ) : null;
  }
}

function mapStateToProps(state) {
  return {
    loading: state.loading.isShow,
  };
}
const connected = connect(mapStateToProps)(withTranslation()(Loading));
export { connected as Loading };
