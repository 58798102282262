/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Header, SideBar, Pagination } from '_components';
import { fetchBoardList } from '_services';
import { pathConstant } from '_constants';

class BoardMgt extends Component {
  constructor(props) {
    super(props);

    const boardType = this.props.location.state ? this.props.location.state.boardType : 'C';
    this.state = { tabIndex: boardType === 'T' ? 1 : 0, keyword: '', pageSize: 10, pageNo: 1 };

    this._handleClickTab = this._handleClickTab.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleSearch = this._handleSearch.bind(this);
    this._handlePageMove = this._handlePageMove.bind(this);
    this._handleDetail = this._handleDetail.bind(this);
    this._handleAdd = this._handleAdd.bind(this);
  }
  componentDidMount() {
    window.$('.jquery_scrollbar').scrollbar();
    const { tabIndex, pageNo, keyword } = this.state;
    this._loadData(tabIndex, pageNo, keyword);
  }
  static getDerivedStateFromProps(props, state) {
    const listData = state.tabIndex === 0 ? props.classBoardListData : props.tanentBoardListData;
    if (listData && listData.pagination.pageNo !== state.pageNo) {
      return {
        pageNo: listData.pagination.pageNo,
      };
    }
    return null;
  }
  _loadData(tabIndex, pageNo, keyword) {
    const { pageSize } = this.state;
    const params = { pageNo, pageSize, keyword };
    this.props.fetchBoardList(tabIndex === 0 ? 'C' : 'T', params);
  }
  _handleChange(e) {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value });
  }
  _handleSearch(e) {
    e.preventDefault();
    const { tabIndex, pageNo, keyword } = this.state;
    this._loadData(tabIndex, pageNo, keyword);
  }
  _handlePageMove(pageNo) {
    const { tabIndex, keyword } = this.state;
    this._loadData(tabIndex, pageNo, keyword);
  }
  _handleDetail(e, item) {
    e.preventDefault();
    const { tabIndex } = this.state;
    this.props.history.push({
      pathname: pathConstant.PATH_BOARD_DETAIL,
      state: { caller: 'boardmgt', boardType: tabIndex === 0 ? 'C' : 'T', data: item },
    });
  }
  _handleAdd() {
    const { tabIndex } = this.state;
    this.props.history.push({
      pathname: pathConstant.PATH_BOARD_EDIT,
      state: { caller: 'boardmgt', mode: 'add', boardType: tabIndex === 0 ? 'C' : 'T' },
    });
  }
  _handleClickTab(e, tabIndex) {
    e.preventDefault();

    this.setState({
      ...this.state,
      tabIndex,
      pageNo: 1,
      keyword: '',
    });

    this._loadData(tabIndex, 1, '');
  }
  render() {
    const { t } = this.props;
    document.title = `${t('공지사항 관리')} | ${t('모스텝')}`;
    const breadcrumb = [t('공지사항 관리')];
    const { tabIndex, keyword, pageSize, pageNo } = this.state;
    const { tenantBoardListData, classBoardListData } = this.props;
    const paginationClass = classBoardListData ? classBoardListData.pagination : null;
    const paginationTenant = tenantBoardListData ? tenantBoardListData.pagination : null;
    return (
      <>
        <Header {...this.props} breadcrumb={breadcrumb} />
        <SideBar {...this.props} />

        <main className="contents_wrapper">
          <div className="jquery_scrollbar">
            <div className="contents">
              <div className="contents_header">
                <div className="contents_tool">
                  <form className="search_bar" onSubmit={this._handleSearch}>
                    <input
                      id="keyword"
                      className="form-control"
                      type="text"
                      placeholder={t('제목이나 작성자 이름을 입력해 주세요.')}
                      value={keyword}
                      onChange={this._handleChange}
                    />
                    <button type="submit" className="btn btn-outline-primary">
                      {t('검색')}
                    </button>
                  </form>
                </div>
              </div>
              <div className="contents_body">
                <div className="card">
                  <div className="card_header tab">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a
                          href=""
                          className={`nav-link ${tabIndex === 0 ? 'active' : ''}`}
                          data-bs-toggle="tab"
                          onClick={e => this._handleClickTab(e, 0)}
                        >
                          {t('클래스')}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href=""
                          className={`nav-link ${tabIndex === 1 ? 'active' : ''}`}
                          data-bs-toggle="tab"
                          onClick={e => this._handleClickTab(e, 1)}
                        >
                          {t('시설')}
                        </a>
                      </li>
                    </ul>
                    <div className="btn_area">
                      <button type="button" className="btn btn-outline-secondary" onClick={this._handleAdd}>
                        <span className="ico edit"></span>
                        <span>{t('공지사항 작성')}</span>
                      </button>
                    </div>
                  </div>
                  <div className="card_body">
                    <div className="tab-content">
                      <div id="classNotice" className={`tab-pane fade ${tabIndex === 0 ? 'show active' : ''}`}>
                        <BoardView
                          t={t}
                          data={classBoardListData && classBoardListData.data}
                          pagination={paginationClass}
                          pageSize={pageSize}
                          pageNo={pageNo}
                          onDetail={this._handleDetail}
                          onPageMove={this._handlePageMove}
                        />
                      </div>
                      <div id="facilityNotice" className={`tab-pane fade ${tabIndex === 1 ? 'show active' : ''}`}>
                        <BoardView
                          t={t}
                          data={tenantBoardListData && tenantBoardListData.data}
                          pagination={paginationTenant}
                          pageSize={pageSize}
                          pageNo={pageNo}
                          onDetail={this._handleDetail}
                          onPageMove={this._handlePageMove}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
class BoardView extends Component {
  render() {
    const { t } = this.props;
    const { data, pagination, pageSize, pageNo, onDetail, onPageMove } = this.props;
    return (
      <>
        <table className={`table ${data && data.length > 0 ? '' : 'none_data'}`}>
          <caption className="visually-hidden">테이블</caption>
          <colgroup>
            <col style={{ width: '800px' }} />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">
                <p className="d-flex justify-content-start">{t('제목')}</p>
              </th>
              <th scope="col">{t('작성자')}</th>
              <th scope="col">
                <p className="d-flex">
                  <span>{t('날짜')}</span>
                  {/* <span className="ico descending"></span> */}
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {(!data || data.length === 0) && (
              <tr>
                <td colSpan="3">
                  <p>{t('공지사항이 없습니다.')}</p>
                </td>
              </tr>
            )}
            {data &&
              data.map((item, index) => (
                <tr key={index}>
                  <td className="text-start">
                    <a href="" onClick={e => onDetail(e, item)} className="ellipsis">
                      {item.title}
                    </a>
                  </td>
                  <td>{item.userNm}</td>
                  <td>{item.createdTime}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <Pagination
          totalCount={pagination ? pagination.totalCount : 0}
          rowCount={pagination ? pagination.pageSize : pageSize}
          page={pagination ? pagination.pageNo : pageNo}
          onPageMove={onPageMove}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  const { board } = state;
  return {
    classBoardListData: board.classBoardListData,
    tenantBoardListData: board.tenantBoardListData,
  };
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchBoardList,
      dispatch,
    },
    dispatch,
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BoardMgt));
export { connected as BoardMgt };
