// 회원 목록
export const FETCH_MEMBER_LIST_REQUEST = 'FETCH_MEMBER_LIST_REQUEST';
export const FETCH_MEMBER_LIST_SUCCESS = 'FETCH_MEMBER_LIST_SUCCESS';
export const FETCH_MEMBER_LIST_ERROR = 'FETCH_MEMBER_LIST_ERROR';
// 회원 정보
export const FETCH_MEMBER_REQUEST = 'FETCH_MEMBER_REQUEST';
export const FETCH_MEMBER_SUCCESS = 'FETCH_MEMBER_SUCCESS';
export const FETCH_MEMBER_ERROR = 'FETCH_MEMBER_ERROR';
export const ADD_MEMBER_REQUEST = 'ADD_MEMBER_REQUEST';
export const ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS';
export const ADD_MEMBER_ERROR = 'ADD_MEMBER_ERROR';
export const MODIFY_MEMBER_REQUEST = 'MODIFY_MEMBER_REQUEST';
export const MODIFY_MEMBER_SUCCESS = 'MODIFY_MEMBER_SUCCESS';
export const MODIFY_MEMBER_ERROR = 'MODIFY_MEMBER_ERROR';
export const REMOVE_MEMBER_REQUEST = 'REMOVE_MEMBER_REQUEST';
export const REMOVE_MEMBER_SUCCESS = 'REMOVE_MEMBER_SUCCESS';
export const REMOVE_MEMBER_ERROR = 'REMOVE_MEMBER_ERROR';
// 회원 등급
export const FETCH_CLASSES_OF_MEMBER_REQUEST = 'FETCH_CLASSES_OF_MEMBER_REQUEST';
export const FETCH_CLASSES_OF_MEMBER_SUCCESS = 'FETCH_CLASSES_OF_MEMBER_SUCCESS';
export const FETCH_CLASSES_OF_MEMBER_ERROR = 'FETCH_CLASSES_OF_MEMBER_ERROR';
// 회원 운동 메모 목록 (회원운동정보)
export const FETCH_MEMBER_EXERCISE_SUMMARY_REQUEST = 'FETCH_MEMBER_EXERCISE_SUMMARY_REQUEST';
export const FETCH_MEMBER_EXERCISE_SUMMARY_SUCCESS = 'FETCH_MEMBER_EXERCISE_SUMMARY_SUCCESS';
export const FETCH_MEMBER_EXERCISE_SUMMARY_ERROR = 'FETCH_MEMBER_EXERCISE_SUMMARY_ERROR';
// 회원 운동 기록 요약 목록 (회원운동기록)
export const FETCH_MEMBER_EXERCISE_SUMMARY_HISTORY_LIST_REQUEST = 'FETCH_MEMBER_EXERCISE_SUMMARY_HISTORY_LIST_REQUEST';
export const FETCH_MEMBER_EXERCISE_SUMMARY_HISTORY_LIST_SUCCESS = 'FETCH_MEMBER_EXERCISE_SUMMARY_HISTORY_LIST_SUCCESS';
export const FETCH_MEMBER_EXERCISE_SUMMARY_HISTORY_LIST_ERROR = 'FETCH_MEMBER_EXERCISE_SUMMARY_HISTORY_LIST_ERROR';
// 일상생활체력 (일상생활체력지수)
export const FETCH_MEMBER_HEALTH_RECORD_FITNESS_LIST_REQUEST = 'FETCH_MEMBER_HEALTH_RECORD_FITNESS_LIST_REQUEST';
export const FETCH_MEMBER_HEALTH_RECORD_FITNESS_LIST_SUCCESS = 'FETCH_MEMBER_HEALTH_RECORD_FITNESS_LIST_SUCCESS';
export const FETCH_MEMBER_HEALTH_RECORD_FITNESS_LIST_ERROR = 'FETCH_MEMBER_HEALTH_RECORD_FITNESS_LIST_ERROR';
export const FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_AVG_REQUEST =
  'FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_AVG_REQUEST';
export const FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_AVG_SUCCESS =
  'FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_AVG_SUCCESS';
export const FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_AVG_ERROR = 'FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_AVG_ERROR';
export const FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_RANK_REQUEST =
  'FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_RANK_REQUEST';
export const FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_RANK_SUCCESS =
  'FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_RANK_SUCCESS';
export const FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_RANK_ERROR =
  'FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_RANK_ERROR';
// 장수연령(장수연령)
export const FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_LIST_REQUEST = 'FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_LIST_REQUEST';
export const FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_LIST_SUCCESS = 'FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_LIST_SUCCESS';
export const FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_LIST_ERROR = 'FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_LIST_ERROR';
export const FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_AVG_REQUEST =
  'FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_AVG_REQUEST';
export const FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_AVG_SUCCESS =
  'FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_AVG_SUCCESS';
export const FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_AVG_ERROR =
  'FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_AVG_ERROR';
export const FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_RANK_REQUEST =
  'FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_RANK_REQUEST';
export const FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_RANK_SUCCESS =
  'FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_RANK_SUCCESS';
export const FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_RANK_ERROR =
  'FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_RANK_ERROR';
// 행복증진(행복요인)
export const FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_LIST_REQUEST = 'FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_LIST_REQUEST';
export const FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_LIST_SUCCESS = 'FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_LIST_SUCCESS';
export const FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_LIST_ERROR = 'FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_LIST_ERROR';
export const FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_AVG_REQUEST =
  'FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_AVG_REQUEST';
export const FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_AVG_SUCCESS =
  'FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_AVG_SUCCESS';
export const FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_AVG_ERROR =
  'FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_AVG_ERROR';
export const FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_RANK_REQUEST =
  'FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_RANK_REQUEST';
export const FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_RANK_SUCCESS =
  'FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_RANK_SUCCESS';
export const FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_RANK_ERROR =
  'FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_RANK_ERROR';
// 일상생활체력 (일상생활체력지수)
export const ADD_MEMBER_HEALTH_RECORD_FITNESS_REQUEST = 'ADD_MEMBER_HEALTH_RECORD_FITNESS_REQUEST';
export const ADD_MEMBER_HEALTH_RECORD_FITNESS_SUCCESS = 'ADD_MEMBER_HEALTH_RECORD_FITNESS_SUCCESS';
export const ADD_MEMBER_HEALTH_RECORD_FITNESS_ERROR = 'ADD_MEMBER_HEALTH_RECORD_FITNESS_ERROR';
export const MODIFY_MEMBER_HEALTH_RECORD_FITNESS_REQUEST = 'MODIFY_MEMBER_HEALTH_RECORD_FITNESS_REQUEST';
export const MODIFY_MEMBER_HEALTH_RECORD_FITNESS_SUCCESS = 'MODIFY_MEMBER_HEALTH_RECORD_FITNESS_SUCCESS';
export const MODIFY_MEMBER_HEALTH_RECORD_FITNESS_ERROR = 'MODIFY_MEMBER_HEALTH_RECORD_FITNESS_ERROR';
export const REMOVE_MEMBER_HEALTH_RECORD_FITNESS_REQUEST = 'REMOVE_MEMBER_HEALTH_RECORD_FITNESS_REQUEST';
export const REMOVE_MEMBER_HEALTH_RECORD_FITNESS_SUCCESS = 'REMOVE_MEMBER_HEALTH_RECORD_FITNESS_SUCCESS';
export const REMOVE_MEMBER_HEALTH_RECORD_FITNESS_ERROR = 'REMOVE_MEMBER_HEALTH_RECORD_FITNESS_ERROR';
// 장수연령(장수연령)
export const ADD_MEMBER_HEALTH_RECORD_LONGEVITY_REQUEST = 'ADD_MEMBER_HEALTH_RECORD_LONGEVITY_REQUEST';
export const ADD_MEMBER_HEALTH_RECORD_LONGEVITY_SUCCESS = 'ADD_MEMBER_HEALTH_RECORD_LONGEVITY_SUCCESS';
export const ADD_MEMBER_HEALTH_RECORD_LONGEVITY_ERROR = 'ADD_MEMBER_HEALTH_RECORD_LONGEVITY_ERROR';
export const MODIFY_MEMBER_HEALTH_RECORD_LONGEVITY_REQUEST = 'MODIFY_MEMBER_HEALTH_RECORD_LONGEVITY_REQUEST';
export const MODIFY_MEMBER_HEALTH_RECORD_LONGEVITY_SUCCESS = 'MODIFY_MEMBER_HEALTH_RECORD_LONGEVITY_SUCCESS';
export const MODIFY_MEMBER_HEALTH_RECORD_LONGEVITY_ERROR = 'MODIFY_MEMBER_HEALTH_RECORD_LONGEVITY_ERROR';
export const REMOVE_MEMBER_HEALTH_RECORD_LONGEVITY_REQUEST = 'REMOVE_MEMBER_HEALTH_RECORD_LONGEVITY_REQUEST';
export const REMOVE_MEMBER_HEALTH_RECORD_LONGEVITY_SUCCESS = 'REMOVE_MEMBER_HEALTH_RECORD_LONGEVITY_SUCCESS';
export const REMOVE_MEMBER_HEALTH_RECORD_LONGEVITY_ERROR = 'REMOVE_MEMBER_HEALTH_RECORD_LONGEVITY_ERROR';
// 행복증진(행복요인)
export const ADD_MEMBER_HEALTH_RECORD_HAPPINESS_REQUEST = 'ADD_MEMBER_HEALTH_RECORD_HAPPINESS_REQUEST';
export const ADD_MEMBER_HEALTH_RECORD_HAPPINESS_SUCCESS = 'ADD_MEMBER_HEALTH_RECORD_HAPPINESS_SUCCESS';
export const ADD_MEMBER_HEALTH_RECORD_HAPPINESS_ERROR = 'ADD_MEMBER_HEALTH_RECORD_HAPPINESS_ERROR';
export const MODIFY_MEMBER_HEALTH_RECORD_HAPPINESS_REQUEST = 'MODIFY_MEMBER_HEALTH_RECORD_HAPPINESS_REQUEST';
export const MODIFY_MEMBER_HEALTH_RECORD_HAPPINESS_SUCCESS = 'MODIFY_MEMBER_HEALTH_RECORD_HAPPINESS_SUCCESS';
export const MODIFY_MEMBER_HEALTH_RECORD_HAPPINESS_ERROR = 'MODIFY_MEMBER_HEALTH_RECORD_HAPPINESS_ERROR';
export const REMOVE_MEMBER_HEALTH_RECORD_HAPPINESS_REQUEST = 'REMOVE_MEMBER_HEALTH_RECORD_HAPPINESS_REQUEST';
export const REMOVE_MEMBER_HEALTH_RECORD_HAPPINESS_SUCCESS = 'REMOVE_MEMBER_HEALTH_RECORD_HAPPINESS_SUCCESS';
export const REMOVE_MEMBER_HEALTH_RECORD_HAPPINESS_ERROR = 'REMOVE_MEMBER_HEALTH_RECORD_HAPPINESS_ERROR';

// 정신건강(뇌기능척도-BPI)
export const FETCH_MEMBER_HEALTH_RECORD_BPI_LIST_REQUEST = 'FETCH_MEMBER_HEALTH_RECORD_BPI_LIST_REQUEST';
export const FETCH_MEMBER_HEALTH_RECORD_BPI_LIST_SUCCESS = 'FETCH_MEMBER_HEALTH_RECORD_BPI_LIST_SUCCESS';
export const FETCH_MEMBER_HEALTH_RECORD_BPI_LIST_ERROR = 'FETCH_MEMBER_HEALTH_RECORD_BPI_LIST_ERROR';
export const FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_AVG_REQUEST = 'FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_AVG_REQUEST';
export const FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_AVG_SUCCESS = 'FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_AVG_SUCCESS';
export const FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_AVG_ERROR = 'FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_AVG_ERROR';
export const FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_RANK_REQUEST = 'FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_RANK_REQUEST';
export const FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_RANK_SUCCESS = 'FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_RANK_SUCCESS';
export const FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_RANK_ERROR = 'FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_RANK_ERROR';

// 행복증진(행복요인)
export const FETCH_MEMBER_HEALTH_RECORD_MENTAL_LIST_REQUEST = 'FETCH_MEMBER_HEALTH_RECORD_MENTAL_LIST_REQUEST';
export const FETCH_MEMBER_HEALTH_RECORD_MENTAL_LIST_SUCCESS = 'FETCH_MEMBER_HEALTH_RECORD_MENTAL_LIST_SUCCESS';
export const FETCH_MEMBER_HEALTH_RECORD_MENTAL_LIST_ERROR = 'FETCH_MEMBER_HEALTH_RECORD_MENTAL_LIST_ERROR';
export const FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_AVG_REQUEST =
  'FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_AVG_REQUEST';
export const FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_AVG_SUCCESS =
  'FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_AVG_SUCCESS';
export const FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_AVG_ERROR = 'FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_AVG_ERROR';
export const FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_RANK_REQUEST =
  'FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_RANK_REQUEST';
export const FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_RANK_SUCCESS =
  'FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_RANK_SUCCESS';
export const FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_RANK_ERROR = 'FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_RANK_ERROR';

// 정신건강(뇌기능척도-BPI)
export const ADD_MEMBER_HEALTH_RECORD_BPI_REQUEST = 'ADD_MEMBER_HEALTH_RECORD_BPI_REQUEST';
export const ADD_MEMBER_HEALTH_RECORD_BPI_SUCCESS = 'ADD_MEMBER_HEALTH_RECORD_BPI_SUCCESS';
export const ADD_MEMBER_HEALTH_RECORD_BPI_ERROR = 'ADD_MEMBER_HEALTH_RECORD_BPI_ERROR';
export const MODIFY_MEMBER_HEALTH_RECORD_BPI_REQUEST = 'MODIFY_MEMBER_HEALTH_RECORD_BPI_REQUEST';
export const MODIFY_MEMBER_HEALTH_RECORD_BPI_SUCCESS = 'MODIFY_MEMBER_HEALTH_RECORD_BPI_SUCCESS';
export const MODIFY_MEMBER_HEALTH_RECORD_BPI_ERROR = 'MODIFY_MEMBER_HEALTH_RECORD_BPI_ERROR';
export const REMOVE_MEMBER_HEALTH_RECORD_BPI_REQUEST = 'REMOVE_MEMBER_HEALTH_RECORD_BPI_REQUEST';
export const REMOVE_MEMBER_HEALTH_RECORD_BPI_SUCCESS = 'REMOVE_MEMBER_HEALTH_RECORD_BPI_SUCCESS';
export const REMOVE_MEMBER_HEALTH_RECORD_BPI_ERROR = 'REMOVE_MEMBER_HEALTH_RECORD_BPI_ERROR';
// 행복증진(행복요인)
export const ADD_MEMBER_HEALTH_RECORD_MENTAL_REQUEST = 'ADD_MEMBER_HEALTH_RECORD_MENTAL_REQUEST';
export const ADD_MEMBER_HEALTH_RECORD_MENTAL_SUCCESS = 'ADD_MEMBER_HEALTH_RECORD_MENTAL_SUCCESS';
export const ADD_MEMBER_HEALTH_RECORD_MENTAL_ERROR = 'ADD_MEMBER_HEALTH_RECORD_MENTAL_ERROR';
export const MODIFY_MEMBER_HEALTH_RECORD_MENTAL_REQUEST = 'MODIFY_MEMBER_HEALTH_RECORD_MENTAL_REQUEST';
export const MODIFY_MEMBER_HEALTH_RECORD_MENTAL_SUCCESS = 'MODIFY_MEMBER_HEALTH_RECORD_MENTAL_SUCCESS';
export const MODIFY_MEMBER_HEALTH_RECORD_MENTAL_ERROR = 'MODIFY_MEMBER_HEALTH_RECORD_MENTAL_ERROR';
export const REMOVE_MEMBER_HEALTH_RECORD_MENTAL_REQUEST = 'REMOVE_MEMBER_HEALTH_RECORD_MENTAL_REQUEST';
export const REMOVE_MEMBER_HEALTH_RECORD_MENTAL_SUCCESS = 'REMOVE_MEMBER_HEALTH_RECORD_MENTAL_SUCCESS';
export const REMOVE_MEMBER_HEALTH_RECORD_MENTAL_ERROR = 'REMOVE_MEMBER_HEALTH_RECORD_MENTAL_ERROR';
// 프린트 요약 정보
export const FETCH_MEMBER_PRINT_REQUEST = 'FETCH_MEMBER_PRINT_REQUEST';
export const FETCH_MEMBER_PRINT_SUCCESS = 'FETCH_MEMBER_PRINT_SUCCESS';
export const FETCH_MEMBER_PRINT_ERROR = 'FETCH_MEMBER_PRINT_ERROR';

// 회원 목록
export function fetchMemberListRequest() {
  return { type: FETCH_MEMBER_LIST_REQUEST };
}
export function fetchMemberListSuccess(data) {
  return { type: FETCH_MEMBER_LIST_SUCCESS, data };
}
export function fetchMemberListError(error) {
  return { type: FETCH_MEMBER_LIST_ERROR, error };
}
// 회원 정보
export function fetchMemberRequest() {
  return { type: FETCH_MEMBER_REQUEST };
}
export function fetchMemberSuccess(data) {
  return { type: FETCH_MEMBER_SUCCESS, data };
}
export function fetchMemberError(error) {
  return { type: FETCH_MEMBER_ERROR, error };
}
export function addMemberRequest() {
  return { type: ADD_MEMBER_REQUEST };
}
export function addMemberSuccess(data) {
  return { type: ADD_MEMBER_SUCCESS, data };
}
export function addMemberError(error) {
  return { type: ADD_MEMBER_ERROR, error };
}
export function modifyMemberRequest() {
  return { type: MODIFY_MEMBER_REQUEST };
}
export function modifyMemberSuccess(data) {
  return { type: MODIFY_MEMBER_SUCCESS, data };
}
export function modifyMemberError(error) {
  return { type: MODIFY_MEMBER_ERROR, error };
}
export function removeMemberRequest() {
  return { type: REMOVE_MEMBER_REQUEST };
}
export function removeMemberSuccess(data) {
  return { type: REMOVE_MEMBER_SUCCESS, data };
}
export function removeMemberError(error) {
  return { type: REMOVE_MEMBER_ERROR, error };
}
// 회원 등급
export function fetchClassesOfMemberRequest() {
  return { type: FETCH_CLASSES_OF_MEMBER_REQUEST };
}
export function fetchClassesOfMemberSuccess(data) {
  return { type: FETCH_CLASSES_OF_MEMBER_SUCCESS, data };
}
export function fetchClassesOfMemberError(error) {
  return { type: FETCH_CLASSES_OF_MEMBER_ERROR, error };
}
// 회원 운동 메모 목록 (회원운동정보)
export function fetchMemberExerciseSummaryRequest() {
  return { type: FETCH_MEMBER_EXERCISE_SUMMARY_REQUEST };
}
export function fetchMemberExerciseSummarySuccess(data) {
  return { type: FETCH_MEMBER_EXERCISE_SUMMARY_SUCCESS, data };
}
export function fetchMemberExerciseSummaryError(error) {
  return { type: FETCH_MEMBER_EXERCISE_SUMMARY_ERROR, error };
}
// 회원 운동 기록 요약 목록 (회원운동정보기록)
export function fetchMemberExerciseSummaryHistoryListRequest() {
  return { type: FETCH_MEMBER_EXERCISE_SUMMARY_HISTORY_LIST_REQUEST };
}
export function fetchMemberExerciseSummaryHistoryListSuccess(data) {
  return { type: FETCH_MEMBER_EXERCISE_SUMMARY_HISTORY_LIST_SUCCESS, data };
}
export function fetchMemberExerciseSummaryHistoryListError(error) {
  return { type: FETCH_MEMBER_EXERCISE_SUMMARY_HISTORY_LIST_ERROR, error };
}
// 일상생활체력 (일상생활체력지수)
export function fetchMemberHealthRecordFitnessListRequest() {
  return { type: FETCH_MEMBER_HEALTH_RECORD_FITNESS_LIST_REQUEST };
}
export function fetchMemberHealthRecordFitnessListSuccess(data) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_FITNESS_LIST_SUCCESS, data };
}
export function fetchMemberHealthRecordFitnessListError(error) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_FITNESS_LIST_ERROR, error };
}
export function fetchMemberHealthRecordFitnessAgegroupAvgRequest() {
  return { type: FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_AVG_REQUEST };
}
export function fetchMemberHealthRecordFitnessAgegroupAvgSuccess(data) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_AVG_SUCCESS, data };
}
export function fetchMemberHealthRecordFitnessAgegroupAvgError(error) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_AVG_ERROR, error };
}
export function fetchMemberHealthRecordFitnessAgegroupRankRequest() {
  return { type: FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_RANK_REQUEST };
}
export function fetchMemberHealthRecordFitnessAgegroupRankSuccess(data) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_RANK_SUCCESS, data };
}
export function fetchMemberHealthRecordFitnessAgegroupRankError(error) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_FITNESS_AGEGROUP_RANK_ERROR, error };
}
// 장수연령(장수연령)
export function fetchMemberHealthRecordLongevityListRequest() {
  return { type: FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_LIST_REQUEST };
}
export function fetchMemberHealthRecordLongevityListSuccess(data) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_LIST_SUCCESS, data };
}
export function fetchMemberHealthRecordLongevityListError(error) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_LIST_ERROR, error };
}
export function fetchMemberHealthRecordLongevityAgegroupAvgRequest() {
  return { type: FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_AVG_REQUEST };
}
export function fetchMemberHealthRecordLongevityAgegroupAvgSuccess(data) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_AVG_SUCCESS, data };
}
export function fetchMemberHealthRecordLongevityAgegroupAvgError(error) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_AVG_ERROR, error };
}
export function fetchMemberHealthRecordLongevityAgegroupRankRequest() {
  return { type: FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_RANK_REQUEST };
}
export function fetchMemberHealthRecordLongevityAgegroupRankSuccess(data) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_RANK_SUCCESS, data };
}
export function fetchMemberHealthRecordLongevityAgegroupRankError(error) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_LONGEVITY_AGEGROUP_RANK_ERROR, error };
}
// 행복증진(행복요인)
export function fetchMemberHealthRecordHappinessListRequest() {
  return { type: FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_LIST_REQUEST };
}
export function fetchMemberHealthRecordHappinessListSuccess(data) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_LIST_SUCCESS, data };
}
export function fetchMemberHealthRecordHappinessListError(error) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_LIST_ERROR, error };
}
export function fetchMemberHealthRecordHappinessAgegroupAvgRequest() {
  return { type: FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_AVG_REQUEST };
}
export function fetchMemberHealthRecordHappinessAgegroupAvgSuccess(data) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_AVG_SUCCESS, data };
}
export function fetchMemberHealthRecordHappinessAgegroupAvgError(error) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_AVG_ERROR, error };
}
export function fetchMemberHealthRecordHappinessAgegroupRankRequest() {
  return { type: FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_RANK_REQUEST };
}
export function fetchMemberHealthRecordHappinessAgegroupRankSuccess(data) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_RANK_SUCCESS, data };
}
export function fetchMemberHealthRecordHappinessAgegroupRankError(error) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_HAPPINESS_AGEGROUP_RANK_ERROR, error };
}

//////////////////////////////////////
// 뇌인지BPI(인지측정지수)
export function fetchMemberHealthRecordBPIListRequest() {
  return { type: FETCH_MEMBER_HEALTH_RECORD_BPI_LIST_REQUEST };
}
export function fetchMemberHealthRecordBPIListSuccess(data) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_BPI_LIST_SUCCESS, data };
}
export function fetchMemberHealthRecordBPIListError(error) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_BPI_LIST_ERROR, error };
}
export function fetchMemberHealthRecordBPIAgegroupAvgRequest() {
  return { type: FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_AVG_REQUEST };
}
export function fetchMemberHealthRecordBPIAgegroupAvgSuccess(data) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_AVG_SUCCESS, data };
}
export function fetchMemberHealthRecordBPIAgegroupAvgError(error) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_AVG_ERROR, error };
}
export function fetchMemberHealthRecordBPIAgegroupRankRequest() {
  return { type: FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_RANK_REQUEST };
}
export function fetchMemberHealthRecordBPIAgegroupRankSuccess(data) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_RANK_SUCCESS, data };
}
export function fetchMemberHealthRecordBPIAgegroupRankError(error) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_BPI_AGEGROUP_RANK_ERROR, error };
}

// 정신건강(뇌기능척도-BPI)
export function fetchMemberHealthRecordMentalListRequest() {
  return { type: FETCH_MEMBER_HEALTH_RECORD_MENTAL_LIST_REQUEST };
}
export function fetchMemberHealthRecordMentalListSuccess(data) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_MENTAL_LIST_SUCCESS, data };
}
export function fetchMemberHealthRecordMentalListError(error) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_MENTAL_LIST_ERROR, error };
}
export function fetchMemberHealthRecordMentalAgegroupAvgRequest() {
  return { type: FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_AVG_REQUEST };
}
export function fetchMemberHealthRecordMentalAgegroupAvgSuccess(data) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_AVG_SUCCESS, data };
}
export function fetchMemberHealthRecordMentalAgegroupAvgError(error) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_AVG_ERROR, error };
}
export function fetchMemberHealthRecordMentalAgegroupRankRequest() {
  return { type: FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_RANK_REQUEST };
}
export function fetchMemberHealthRecordMentalAgegroupRankSuccess(data) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_RANK_SUCCESS, data };
}
export function fetchMemberHealthRecordMentalAgegroupRankError(error) {
  return { type: FETCH_MEMBER_HEALTH_RECORD_MENTAL_AGEGROUP_RANK_ERROR, error };
}
export function fetchMemberPrintRequest() {
  return { type: FETCH_MEMBER_PRINT_REQUEST };
}
export function fetchMemberPrintSuccess(data) {
  return { type: FETCH_MEMBER_PRINT_SUCCESS, data };
}
export function fetchMemberPrintError(error) {
  return { type: FETCH_MEMBER_PRINT_ERROR, error };
}

//////////////////////////////////////

// 일상생활체력 (일상생활체력지수)
export function addMemberHealthRecordFitnessRequest() {
  return { type: ADD_MEMBER_HEALTH_RECORD_FITNESS_REQUEST };
}
export function addMemberHealthRecordFitnessSuccess(data) {
  return { type: ADD_MEMBER_HEALTH_RECORD_FITNESS_SUCCESS, data };
}
export function addMemberHealthRecordFitnessError(error) {
  return { type: ADD_MEMBER_HEALTH_RECORD_FITNESS_ERROR, error };
}
export function modifyMemberHealthRecordFitnessRequest() {
  return { type: MODIFY_MEMBER_HEALTH_RECORD_FITNESS_REQUEST };
}
export function modifyMemberHealthRecordFitnessSuccess(data) {
  return { type: MODIFY_MEMBER_HEALTH_RECORD_FITNESS_SUCCESS, data };
}
export function modifyMemberHealthRecordFitnessError(error) {
  return { type: MODIFY_MEMBER_HEALTH_RECORD_FITNESS_ERROR, error };
}
export function removeMemberHealthRecordFitnessRequest() {
  return { type: REMOVE_MEMBER_HEALTH_RECORD_FITNESS_REQUEST };
}
export function removeMemberHealthRecordFitnessSuccess(data) {
  return { type: REMOVE_MEMBER_HEALTH_RECORD_FITNESS_SUCCESS, data };
}
export function removeMemberHealthRecordFitnessError(error) {
  return { type: REMOVE_MEMBER_HEALTH_RECORD_FITNESS_ERROR, error };
}
// 장수연령(장수연령)
export function addMemberHealthRecordLongevityRequest() {
  return { type: ADD_MEMBER_HEALTH_RECORD_LONGEVITY_REQUEST };
}
export function addMemberHealthRecordLongevitySuccess(data) {
  return { type: ADD_MEMBER_HEALTH_RECORD_LONGEVITY_SUCCESS, data };
}
export function addMemberHealthRecordLongevityError(error) {
  return { type: ADD_MEMBER_HEALTH_RECORD_LONGEVITY_ERROR, error };
}
export function modifyMemberHealthRecordLongevityRequest() {
  return { type: MODIFY_MEMBER_HEALTH_RECORD_LONGEVITY_REQUEST };
}
export function modifyMemberHealthRecordLongevitySuccess(data) {
  return { type: MODIFY_MEMBER_HEALTH_RECORD_LONGEVITY_SUCCESS, data };
}
export function modifyMemberHealthRecordLongevityError(error) {
  return { type: MODIFY_MEMBER_HEALTH_RECORD_LONGEVITY_ERROR, error };
}
export function removeMemberHealthRecordLongevityRequest() {
  return { type: REMOVE_MEMBER_HEALTH_RECORD_LONGEVITY_REQUEST };
}
export function removeMemberHealthRecordLongevitySuccess(data) {
  return { type: REMOVE_MEMBER_HEALTH_RECORD_LONGEVITY_SUCCESS, data };
}
export function removeMemberHealthRecordLongevityError(error) {
  return { type: REMOVE_MEMBER_HEALTH_RECORD_LONGEVITY_ERROR, error };
}
// 행복증진(행복요인)
export function addMemberHealthRecordHappinessRequest() {
  return { type: ADD_MEMBER_HEALTH_RECORD_HAPPINESS_REQUEST };
}
export function addMemberHealthRecordHappinessSuccess(data) {
  return { type: ADD_MEMBER_HEALTH_RECORD_HAPPINESS_SUCCESS, data };
}
export function addMemberHealthRecordHappinessError(error) {
  return { type: ADD_MEMBER_HEALTH_RECORD_HAPPINESS_ERROR, error };
}
export function modifyMemberHealthRecordHappinessRequest() {
  return { type: MODIFY_MEMBER_HEALTH_RECORD_HAPPINESS_REQUEST };
}
export function modifyMemberHealthRecordHappinessSuccess(data) {
  return { type: MODIFY_MEMBER_HEALTH_RECORD_HAPPINESS_SUCCESS, data };
}
export function modifyMemberHealthRecordHappinessError(error) {
  return { type: MODIFY_MEMBER_HEALTH_RECORD_HAPPINESS_ERROR, error };
}
export function removeMemberHealthRecordHappinessRequest() {
  return { type: REMOVE_MEMBER_HEALTH_RECORD_HAPPINESS_REQUEST };
}
export function removeMemberHealthRecordHappinessSuccess(data) {
  return { type: REMOVE_MEMBER_HEALTH_RECORD_HAPPINESS_SUCCESS, data };
}
export function removeMemberHealthRecordHappinessError(error) {
  return { type: REMOVE_MEMBER_HEALTH_RECORD_HAPPINESS_ERROR, error };
}

// 뇌인지BPI(인지측정지수)
export function addMemberHealthRecordBPIRequest() {
  return { type: ADD_MEMBER_HEALTH_RECORD_BPI_REQUEST };
}
export function addMemberHealthRecordBPISuccess(data) {
  return { type: ADD_MEMBER_HEALTH_RECORD_BPI_SUCCESS, data };
}
export function addMemberHealthRecordBPIError(error) {
  return { type: ADD_MEMBER_HEALTH_RECORD_BPI_ERROR, error };
}
export function modifyMemberHealthRecordBPIRequest() {
  return { type: MODIFY_MEMBER_HEALTH_RECORD_BPI_REQUEST };
}
export function modifyMemberHealthRecordBPISuccess(data) {
  return { type: MODIFY_MEMBER_HEALTH_RECORD_BPI_SUCCESS, data };
}
export function modifyMemberHealthRecordBPIError(error) {
  return { type: MODIFY_MEMBER_HEALTH_RECORD_BPI_ERROR, error };
}
export function removeMemberHealthRecordBPIRequest() {
  return { type: REMOVE_MEMBER_HEALTH_RECORD_BPI_REQUEST };
}
export function removeMemberHealthRecordBPISuccess(data) {
  return { type: REMOVE_MEMBER_HEALTH_RECORD_BPI_SUCCESS, data };
}
export function removeMemberHealthRecordBPIError(error) {
  return { type: REMOVE_MEMBER_HEALTH_RECORD_BPI_ERROR, error };
}

// 정신건강(뇌기능척도-BPI)
export function addMemberHealthRecordMentalRequest() {
  return { type: ADD_MEMBER_HEALTH_RECORD_MENTAL_REQUEST };
}
export function addMemberHealthRecordMentalSuccess(data) {
  return { type: ADD_MEMBER_HEALTH_RECORD_MENTAL_SUCCESS, data };
}
export function addMemberHealthRecordMentalError(error) {
  return { type: ADD_MEMBER_HEALTH_RECORD_MENTAL_ERROR, error };
}
export function modifyMemberHealthRecordMentalRequest() {
  return { type: MODIFY_MEMBER_HEALTH_RECORD_MENTAL_REQUEST };
}
export function modifyMemberHealthRecordMentalSuccess(data) {
  return { type: MODIFY_MEMBER_HEALTH_RECORD_MENTAL_SUCCESS, data };
}
export function modifyMemberHealthRecordMentalError(error) {
  return { type: MODIFY_MEMBER_HEALTH_RECORD_MENTAL_ERROR, error };
}
export function removeMemberHealthRecordMentalRequest() {
  return { type: REMOVE_MEMBER_HEALTH_RECORD_MENTAL_REQUEST };
}
export function removeMemberHealthRecordMentalSuccess(data) {
  return { type: REMOVE_MEMBER_HEALTH_RECORD_MENTAL_SUCCESS, data };
}
export function removeMemberHealthRecordMentalError(error) {
  return { type: REMOVE_MEMBER_HEALTH_RECORD_MENTAL_ERROR, error };
}
