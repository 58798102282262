/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';

export class PaginationMini extends Component {
  /**
   *
   * @param {*} props
   * {
   *  totalCount: 10,
   *  rowCount: 10,
   *  page: 1
   *  onPageMove: () => {}
   * }
   */
  constructor(props) {
    super(props);

    this._handlePageMove = this._handlePageMove.bind(this);
  }
  _handlePageMove(e, page) {
    e.preventDefault();
    if (page) {
      this.props.onPageMove(page);
    }
  }
  render() {
    const { totalCount, rowCount, page } = this.props;
    const pageCount = Math.ceil(totalCount / rowCount);
    return (
      <>
        <ul className="pagination phase">
          <li className="prev">
            <a href="" onClick={page === 1 ? e => this._handlePageMove(e) : e => this._handlePageMove(e, page - 1)}></a>
          </li>
          <li className="page">
            <span className="current_page">{page}</span>/<span className="total_page">{pageCount}</span>
          </li>
          <li className="next">
            <a
              href=""
              onClick={page === pageCount ? e => this._handlePageMove(e) : e => this._handlePageMove(e, page + 1)}
            ></a>
          </li>
        </ul>
      </>
    );
  }
}
