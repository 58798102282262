import { requestUrl, requestHeaders, requestParam, requestBody, handleResponse } from '_helpers';
import {
  fetchRecentExerciseClassUnitsRequest,
  fetchRecentExerciseClassUnitsSuccess,
  fetchRecentExerciseClassUnitsError,
  fetchRecentExerciseMembersRequest,
  fetchRecentExerciseMembersSuccess,
  fetchRecentExerciseMembersError,
  fetchClassListRequest,
  fetchClassListSuccess,
  fetchClassListError,
  fetchClassRequest,
  fetchClassSuccess,
  fetchClassError,
  fetchClassExerciseSummaryRequest,
  fetchClassExerciseSummarySuccess,
  fetchClassExerciseSummaryError,
  fetchMembersOfClassRequest,
  fetchMembersOfClassSuccess,
  fetchMembersOfClassError,
  fetchRecentExerciseClassUnitsOfClassRequest,
  fetchRecentExerciseClassUnitsOfClassSuccess,
  fetchRecentExerciseClassUnitsOfClassError,
  addClassRequest,
  addClassSuccess,
  addClassError,
  modifyClassRequest,
  modifyClassSuccess,
  modifyClassError,
  removeClassRequest,
  removeClassSuccess,
  removeClassError,
} from '_actions';

// 최근 운동한 클래스회차 목록
export function fetchRecentExerciseClassUnits(callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/classes/recent-exercise-classunits`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchRecentExerciseClassUnitsRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchRecentExerciseClassUnitsSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchRecentExerciseClassUnitsError(error));
      });
  };
}
// 최근 운동한 회원 목록
export function fetchRecentExerciseMembers(classId, classUnitNo, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(
      `${process.env.REACT_APP_APIURL}/api/classes/${classId}/classunits/${classUnitNo}/recent-exercise-members`,
    );
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchRecentExerciseMembersRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchRecentExerciseMembersSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchRecentExerciseMembersError(error));
      });
  };
}
// 클래스 목록 조회
export function fetchClassList(params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/classes?${requestParam(params)}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchClassListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response);
        dispatch(fetchClassListSuccess(response));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchClassListError(error));
      });
  };
}
// 클래스 상세 조회
export function fetchClass(classId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/classes/${classId}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchClassRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchClassSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchClassError(error));
      });
  };
}
// 클래스의 운동 요약
export function fetchClassExerciseSummary(classId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/classes/${classId}/exercises/summary`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchClassExerciseSummaryRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchClassExerciseSummarySuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchClassExerciseSummaryError(error));
      });
  };
}
// 클래스의 회원 목록
export function fetchMembersOfClass(classId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/classes/${classId}/members?${requestParam(params)}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMembersOfClassRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchMembersOfClassSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMembersOfClassError(error));
      });
  };
}
// 최근 운동한 클래스의 클래스회차 목록
export function fetchRecentExerciseClassUnitsOfClass(classId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/classes/${classId}/recent-exercise-classunits`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchRecentExerciseClassUnitsOfClassRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchRecentExerciseClassUnitsOfClassSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchRecentExerciseClassUnitsOfClassError(error));
      });
  };
}

// 클래스 등록
export function addClass(params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/classes`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(addClassRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(addClassSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(addClassError(error));
      });
  };
}
// 클래스 수정
export function modifyClass(classId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/classes/${classId}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'PUT',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(modifyClassRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(modifyClassSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(modifyClassError(error));
      });
  };
}
// 클래스 삭제
export function removeClass(classId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/classes/${classId}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(removeClassRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(removeClassSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(removeClassError(error));
      });
  };
}
