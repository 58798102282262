/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { pathConstant } from '_constants';
import { checkAuthSystemManager, checkAuthTenantManager, checkAuthTrainer } from '_services';

class SideBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settingsToggle: false,
    };

    this._handleMenuClick = this._handleMenuClick.bind(this);
  }
  _handleMenuClick(e, pathname) {
    e.preventDefault();

    this.props.history.push(pathname);
  }

  render() {
    const { t } = this.props;
    const { pathname } = this.props.location;
    return (
      <div className="side_bar">
        <ul id="metismenu" className="gnb">
          {(checkAuthTenantManager() || checkAuthTrainer()) && (
            <>
              <li className={pathname.startsWith(pathConstant.PATH_DASHBOARD_PREFIX) ? 'mm-active' : ''}>
                <a
                  href=""
                  onClick={e => {
                    this._handleMenuClick(e, pathConstant.PATH_DASHBOARD_DASHBOARD);
                  }}
                >
                  <span className="ico dashboard"></span>
                  <span className="text">{t('대시보드')}</span>
                </a>
              </li>
              <li className={pathname.startsWith(pathConstant.PATH_MEMBER_PREFIX) ? 'mm-active' : ''}>
                <a
                  href=""
                  onClick={e => {
                    this._handleMenuClick(e, pathConstant.PATH_MEMBER_MGT);
                  }}
                >
                  <span className="ico user"></span>
                  <span className="text">{t('사용자 관리')}</span>
                </a>
              </li>
              <li className={pathname.startsWith(pathConstant.PATH_CLASS_PREFIX) ? 'mm-active' : ''}>
                <a
                  href=""
                  onClick={e => {
                    this._handleMenuClick(e, pathConstant.PATH_CLASS_MGT);
                  }}
                >
                  <span className="ico class"></span>
                  <span className="text">{t('클래스 관리')}</span>
                </a>
              </li>
              <li className={pathname.startsWith(pathConstant.PATH_BOARD_PREFIX) ? 'mm-active' : ''}>
                <a
                  href=""
                  onClick={e => {
                    this._handleMenuClick(e, pathConstant.PATH_BOARD_MGT);
                  }}
                >
                  <span className="ico notice"></span>
                  <span className="text">{t('공지사항 관리')}</span>
                </a>
              </li>
              <li className={pathname.startsWith(pathConstant.PATH_TRAINER_PREFIX) ? 'mm-active' : ''}>
                <a
                  href=""
                  onClick={e => {
                    this._handleMenuClick(e, pathConstant.PATH_TRAINER_MGT);
                  }}
                >
                  <span className="ico trainer"></span>
                  <span className="text">{t('트레이너 관리')}</span>
                </a>
              </li>
              <li className={pathname.startsWith(pathConstant.PATH_PATTERNGROUP_PREFIX) ? 'mm-active' : ''}>
                <a
                  href=""
                  onClick={e => {
                    this._handleMenuClick(e, pathConstant.PATH_PATTERNGROUP_MGT);
                  }}
                >
                  <span className="ico pattern"></span>
                  <span className="text">{t('패턴 그룹 관리')}</span>
                </a>
              </li>
            </>
          )}
          {checkAuthSystemManager() && (
            <>
              <li className={pathname.startsWith(pathConstant.PATH_TENANT_PREFIX) ? 'mm-active' : ''}>
                <a
                  href=""
                  onClick={e => {
                    this._handleMenuClick(e, pathConstant.PATH_TENANT_MGT);
                  }}
                >
                  <span className="ico facility"></span>
                  <span className="text">{t('시설 관리')}</span>
                </a>
              </li>
              <li className={pathname.startsWith(pathConstant.PATH_SYSTEMBOARD_PREFIX) ? 'mm-active' : ''}>
                <a
                  href=""
                  onClick={e => {
                    this._handleMenuClick(e, pathConstant.PATH_SYSTEMBOARD_MGT);
                  }}
                >
                  <span className="ico notice"></span>
                  <span className="text">{t('공지사항 관리')}</span>
                </a>
              </li>
            </>
          )}
        </ul>
      </div>
    );
  }
}
const connected = withTranslation()(SideBar);
export { connected as SideBar };
