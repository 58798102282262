/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Header, SideBar, PostCodeModal } from '_components';
import { openAlertDialog } from '_actions';
import { commonUtils } from '_helpers';
import { checkLoginId, checkEmail, addTenant, modifyTenant } from '_services';
import { pathConstant } from '_constants';

class TenantEdit extends Component {
  constructor(props) {
    super(props);

    const { data } = this.props.location.state;
    this.state = {
      tenantNm: data ? data.tenantNm : '',
      tel: data ? data.tel : '',
      address1: data ? data.address1 : '',
      address2: data ? data.address2 : '',
      zipcode: data ? data.zipcode : '',
      tenantCode: data ? data.tenantCode : '',
      email: data ? (data.email ? data.email : '') : '',
      emailValid: '',
      loginId: data ? data.tenantManager.loginId : '',
      loginIdValid: '',
      password: '',
      passwordConfirm: '',
      passwordValid: '',
      passwordConfirmValid: '',
      userNm: data ? data.tenantManager.userNm : '',
      managerTel: data ? data.tenantManager.tel : '',
      managerEmail: data ? data.tenantManager.email : '',
      managerEmailValid: '',
      isChanged: false,
      postCodeOpen: false,
    };

    this._handleChange = this._handleChange.bind(this);
    this._handleChangeLoginId = this._handleChangeLoginId.bind(this);
    this._handleLoginIdBlur = this._handleLoginIdBlur.bind(this);
    this._handleChangeEmail = this._handleChangeEmail.bind(this);
    this._handleChangeManagerEmail = this._handleChangeManagerEmail.bind(this);
    this._handleManagerEmailBlur = this._handleManagerEmailBlur.bind(this);
    this._handleChangePassword = this._handleChangePassword.bind(this);
    this._handleChangePasswordConfirm = this._handleChangePasswordConfirm.bind(this);
    this._handleModifyPassword = this._handleModifyPassword.bind(this);
    this._handleSave = this._handleSave.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
    this._handlePostCodeClose = this._handlePostCodeClose.bind(this);
  }
  componentDidMount() {
    window.$('.jquery_scrollbar').scrollbar();
  }
  _handleChange(e) {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value, isChanged: true });
  }
  _handleChangeLoginId(e) {
    const { value } = e.target;
    this.setState({ ...this.state, loginId: value, loginIdValid: '', isChanged: true });
  }
  _handleLoginIdBlur() {
    const { loginId } = this.state;
    if (loginId === '') {
      return;
    }
    const { data } = this.props.location.state;
    const params = { userId: data ? data.tenantManager.userId : '', loginId };
    this.props.checkLoginId(params, (error, result) => {
      let loginIdValid = '';
      if (!error) {
        if (result.used) {
          loginIdValid = 'invalid';
        } else {
          loginIdValid = 'valid';
        }
      }
      this.setState({
        ...this.state,
        loginIdValid,
      });
    });
  }
  _handleChangeEmail(e) {
    const { value } = e.target;
    let emailValid = '';
    if (value !== '' && !commonUtils.isValidEmail(value)) {
      emailValid = 'invalid';
      this.setState({
        ...this.state,
        emailValid,
      });
    }
    this.setState({ ...this.state, email: value, emailValid, isChanged: true });
  }
  _handleChangeManagerEmail(e) {
    const { value } = e.target;
    let managerEmailValid = '';
    if (value !== '' && !commonUtils.isValidEmail(value)) {
      managerEmailValid = 'invalidemail';
      this.setState({
        ...this.state,
        managerEmailValid,
      });
    }
    this.setState({ ...this.state, managerEmail: value, managerEmailValid, isChanged: true });
  }
  _handleManagerEmailBlur() {
    const { managerEmail } = this.state;
    let { managerEmailValid } = this.state;
    if (managerEmail === '') {
      return;
    }
    if (managerEmailValid !== 'invalidemail') {
      const { data } = this.props.location.state;
      const params = { userId: data ? data.tenantManager.userId : '', email: managerEmail };
      this.props.checkEmail(params, (error, result) => {
        if (!error) {
          if (result.used) {
            managerEmailValid = 'invalid';
          } else {
            managerEmailValid = 'valid';
          }
        }
        this.setState({
          ...this.state,
          managerEmailValid,
        });
      });
    }
  }
  _handleChangePassword(e) {
    const { value } = e.target;
    let passwordValid = '';
    if (value !== '' && !commonUtils.isValidPassword(value)) {
      passwordValid = 'invalid';
    }
    this.setState({
      ...this.state,
      password: value,
      passwordValid,
      passwordConfirm: '',
      passwordConfirmValid: '',
      isChanged: true,
    });
  }
  _handleChangePasswordConfirm(e) {
    const { value } = e.target;
    const { password } = this.state;
    let passwordConfirmValid = '';
    if (value !== '' && password !== value) {
      passwordConfirmValid = 'invalid';
    }
    this.setState({ ...this.state, passwordConfirm: value, passwordConfirmValid, isChanged: true });
  }
  _handlePostCodeClose(data) {
    if (data && data.zonecode) {
      this.setState({
        ...this.state,
        postCodeOpen: false,
        zipcode: data.zonecode,
        address1: data.address,
      });
    } else {
      this.setState({
        ...this.state,
        postCodeOpen: false,
      });
    }
  }

  _handleSave() {
    const { t } = this.props;
    const {
      tenantNm,
      tel,
      address1,
      address2,
      zipcode,
      tenantCode,
      email,
      emailValid,
      loginId,
      loginIdValid,
      password,
      passwordValid,
      passwordConfirm,
      passwordConfirmValid,
      userNm,
      managerTel,
      managerEmail,
      managerEmailValid,
      isChanged,
    } = this.state;
    const { data } = this.props.location.state;

    if (!isChanged) {
      this.props.dispatch(openAlertDialog(t('수정된 내용이 없습니다.')));
      return;
    }
    if (tenantNm === '') {
      this.props.dispatch(openAlertDialog(t('시설명을 입력하세요.')));
      return;
    }
    if (tel === '') {
      this.props.dispatch(openAlertDialog(t('대표 전화번호를 입력하세요.')));
      return;
    }
    if (address1 === '' || address2 === '') {
      this.props.dispatch(openAlertDialog(t('주소를 입력하세요.')));
      return;
    }
    if (tenantCode === '') {
      this.props.dispatch(openAlertDialog(t('기관코드를 입력하세요.')));
      return;
    }
    if (email !== '' && emailValid === 'invalid') {
      this.props.dispatch(openAlertDialog(t('이메일이 유효하지 않습니다.')));
      return;
    }
    if (loginId === '') {
      this.props.dispatch(openAlertDialog(t('아이디를 입력해주세요.')));
      return;
    }
    if ((data && loginIdValid === 'invalid') || (!data && loginIdValid !== 'valid')) {
      this.props.dispatch(openAlertDialog(t('아이디가 유효하지 않습니다.')));
      return;
    }
    if (!data) {
      if (password === '') {
        this.props.dispatch(openAlertDialog(t('비밀번호를 입력하세요.')));
        return;
      }
      if (passwordValid === 'invalid') {
        this.props.dispatch(openAlertDialog(t('비밀번호가 유효하지 않습니다.')));
        return;
      }
      if (passwordConfirm === '') {
        this.props.dispatch(openAlertDialog(t('비밀번호 재입력을 입력하세요.')));
        return;
      }
      if (passwordConfirmValid === 'invalid') {
        this.props.dispatch(openAlertDialog(t('비밀번호가 일치하지 않습니다.')));
        return;
      }
    }
    if (userNm === '') {
      this.props.dispatch(openAlertDialog(t('이름을 입력하세요.')));
      return;
    }
    if (managerTel === '') {
      this.props.dispatch(openAlertDialog(t('연락처를 입력하세요.')));
      return;
    }
    if (managerEmail !== '' && !data && managerEmailValid !== 'valid') {
      this.props.dispatch(openAlertDialog(t('담당자 이메일이 유효하지 않습니다.')));
      return;
    }
    if (managerEmail !== '' && data && managerEmail !== data.tenantManager.email && managerEmailValid !== 'valid') {
      this.props.dispatch(openAlertDialog(t('담당자 이메일이 유효하지 않습니다.')));
      return;
    }
    const params = {
      tenantNm,
      tel,
      address1,
      address2,
      zipcode,
      tenantCode,
      email,
      tenantManager: { userId: data ? data.tenantManager.userId : 0, loginId, userNm, tel: managerTel, email: managerEmail },
    };
    if (data) {
      this._modifyTenant(data.tenantId, params);
    } else {
      params.tenantManager.password = password;
      this._addTenant(params);
    }
  }
  _modifyTenant(userId, params) {
    const { t } = this.props;
    this.props.modifyTenant(userId, params, error => {
      if (!error) {
        this.props.dispatch(
          openAlertDialog(t('수정되었습니다.'), 'success', false, null, null, () => {
            // daum post code 검색시 history.length가 증가하므로 history.goBack()을 쓸수 없음.
            // this.props.history.goBack();
            this.props.history.replace(pathConstant.PATH_TENANT_MGT);
          }),
        );
      }
    });
  }
  _addTenant(params) {
    const { t } = this.props;
    this.props.addTenant(params, error => {
      if (!error) {
        this.props.dispatch(
          openAlertDialog(t('등록되었습니다.'), 'success', false, null, null, () => {
            // this.props.history.goBack();
            this.props.history.replace(pathConstant.PATH_TENANT_MGT);
          }),
        );
      }
    });
  }
  _handleCancel() {
    const { t } = this.props;
    const { isChanged } = this.state;
    if (isChanged) {
      this.props.dispatch(
        openAlertDialog(
          t('작성중인 내역이 있습니다.<br/>정말로 이전 화면으로 돌아가시겠습니까?'),
          'alert',
          true,
          null,
          null,
          () => {
            // this.props.history.goBack();
            this.props.history.replace(pathConstant.PATH_TENANT_MGT);
          },
        ),
      );
    } else {
      this.props.history.replace(pathConstant.PATH_TENANT_MGT);
    }
  }
  _handleModifyPassword() {
    this.props.history.push({
      pathname: pathConstant.PATH_TENANT_MANAGER_PASSWORD,
      state: { data: this.props.location.state.data, userType: 'TM' },
    });
  }
  render() {
    const { t } = this.props;
    const lang = commonUtils.getBrowserLanguage();
    const { data } = this.props.location.state;
    const {
      tenantNm,
      tel,
      address1,
      address2,
      tenantCode,
      email,
      emailValid,
      loginId,
      loginIdValid,
      password,
      passwordConfirm,
      passwordValid,
      passwordConfirmValid,
      userNm,
      managerTel,
      managerEmail,
      managerEmailValid,
      postCodeOpen,
    } = this.state;
    let breadcrumb = '';
    if (lang === 'ko') {
      document.title = `${t('시설')} ${data === null ? t('등록') : t('수정')} | ${t('모스텝')}`;
      breadcrumb = [t('시설 관리'), `${t('클래스')} ${data === null ? t('생성') : t('수정')}`];
    } else {
      document.title = `${data === null ? t('등록') : t('수정')} ${t('시설')} | ${t('모스텝')}`;
      breadcrumb = [t('시설 관리'), `${data === null ? t('등록') : t('수정')} ${t('시설')}`];
    }
    return (
      <>
        <Header {...this.props} breadcrumb={breadcrumb} />
        <SideBar {...this.props} />
        <main className="contents_wrapper">
          <div className="jquery_scrollbar">
            <div className="contents">
              <div className="contents_body card">
                <div className="card_header">
                  <h3 className="title">
                    <span>
                      {lang === 'ko' && `${t('시설')} ${data === null ? t('등록') : t('수정')}`}
                      {lang === 'en' && `${data === null ? t('등록') : t('수정')} ${t('시설')}`}
                    </span>
                    <span className="helper_text">
                      (<sup className="ico asterisk"></sup>
                      {t('표시는 필수입력항목입니다.')}))
                    </span>
                  </h3>
                </div>
                <div className="card_body form">
                  <ul className="form_section">
                    <li className="title">{t('시설 정보')}</li>
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('시설명')}
                        <sup className="ico asterisk"></sup>
                      </label>
                      <input
                        id="tenantNm"
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={tenantNm}
                        onChange={this._handleChange}
                      />
                    </li>
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('대표번호')}
                        <sup className="ico asterisk"></sup>
                      </label>
                      <input
                        id="tel"
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={tel}
                        onChange={this._handleChange}
                      />
                    </li>
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('주소')}
                        <sup className="ico asterisk"></sup>
                      </label>
                      <div className="address">
                        <input
                          id=""
                          onChange={event => this.setState({ ...this.state, address1: event.target.value })}
                          className="form-control"
                          type="text"
                          placeholder={t('주소를 검색해 주세요')}
                          value={address1}
                        />
                        {commonUtils.getBrowserLanguageCountry() === 'ko_KR' && (
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                postCodeOpen: true,
                              });
                            }}
                          >
                            {t('검색')}
                          </button>
                        )}
                      </div>
                    </li>
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('상세주소')}
                        <sup className="ico asterisk"></sup>
                      </label>
                      <div className="address">
                        <input
                          id="address2"
                          className="form-control"
                          type="text"
                          placeholder=""
                          value={address2}
                          onChange={this._handleChange}
                        />
                      </div>
                    </li>
                    <PostCodeModal open={postCodeOpen} onClose={this._handlePostCodeClose} />
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('기관코드')}
                        <sup className="ico asterisk"></sup>
                      </label>
                      <input
                        id="tenantCode"
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={tenantCode}
                        onChange={this._handleChange}
                      />
                    </li>
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('이메일')}
                      </label>
                      <input
                        id="email"
                        className={`form-control ${emailValid === 'invalid' ? 'is-invalid' : ''}`}
                        type="text"
                        placeholder=""
                        value={email}
                        onChange={this._handleChangeEmail}
                      />
                      {emailValid === 'invalid' && <p className="invalid-feedback">{t('이메일 형식이 아닙니다.')}</p>}
                    </li>
                  </ul>
                  <ul className="form_section">
                    <li className="title">{t('시설 담당자 정보')}</li>
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('아이디')}
                        <sup className="ico asterisk"></sup>
                      </label>
                      <input
                        id="loginId"
                        className={`form-control ${
                          loginIdValid === 'valid' ? 'is-valid' : loginIdValid === 'invalid' ? 'is-invalid' : ''
                        }`}
                        type="text"
                        placeholder=""
                        value={loginId}
                        onChange={this._handleChangeLoginId}
                        onBlur={this._handleLoginIdBlur}
                      />
                      {loginIdValid === 'valid' && <p className="valid-feedback">{t('사용 가능한 아이디입니다.')}</p>}
                      {loginIdValid === 'invalid' && (
                        <p className="invalid-feedback">{t('이미 사용 중인 아이디가 존재합니다.')}</p>
                      )}
                    </li>
                    {!data && (
                      <>
                        <li className="form-group">
                          <label htmlFor="" className="form-label">
                            {t('비밀번호')}
                            <sup className="ico asterisk"></sup>
                          </label>
                          <input
                            id="password"
                            className={`form-control ${passwordValid === 'invalid' ? 'is-invalid' : ''}`}
                            type="password"
                            placeholder=""
                            value={password}
                            onChange={this._handleChangePassword}
                          />
                          {passwordValid === 'invalid' && (
                            <p className="invalid-feedback">{t('특수문자, 영문대소문자, 숫자로 8글자 이상.')}</p>
                          )}
                        </li>
                        <li className="form-group">
                          <label htmlFor="passwordConfirm" className="form-label">
                            {t('비밀번호 재입력')}
                            <sup className="ico asterisk"></sup>
                          </label>
                          <input
                            id="passwordConfirm"
                            className={`form-control ${passwordConfirmValid === 'invalid' ? 'is-invalid' : ''}`}
                            type="password"
                            placeholder=""
                            value={passwordConfirm}
                            onChange={this._handleChangePasswordConfirm}
                          />
                          {passwordConfirmValid === 'invalid' && (
                            <p className="invalid-feedback">{t('비밀번호가 일치하지 않습니다.')}</p>
                          )}
                        </li>
                      </>
                    )}
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('이름')}
                        <sup className="ico asterisk"></sup>
                      </label>
                      <input
                        id="userNm"
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={userNm}
                        onChange={this._handleChange}
                      />
                    </li>
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('연락처(휴대전화)')}
                        <sup className="ico asterisk"></sup>
                      </label>
                      <input
                        id="managerTel"
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={managerTel}
                        onChange={this._handleChange}
                      />
                    </li>
                    <li className="form-group">
                      <label htmlFor="" className="form-label">
                        {t('담당자 이메일')}
                      </label>
                      <input
                        id="managerEmail"
                        className={`form-control ${
                          managerEmailValid === 'valid'
                            ? 'is-valid'
                            : managerEmailValid === 'invalid' || managerEmailValid === 'invalidemail'
                            ? 'is-invalid'
                            : ''
                        }`}
                        type="text"
                        placeholder=""
                        value={managerEmail}
                        onChange={this._handleChangeManagerEmail}
                        onBlur={this._handleManagerEmailBlur}
                      />
                      {managerEmailValid === 'valid' && <p className="valid-feedback">{t('사용 가능한 이메일입니다.')}</p>}
                      {managerEmailValid === 'invalid' && (
                        <p className="invalid-feedback">{t('이미 사용 중인 이메일이 존재합니다.')}</p>
                      )}
                      {managerEmailValid === 'invalidemail' && (
                        <p className="invalid-feedback">{t('이메일 형식이 아닙니다.')}</p>
                      )}
                    </li>
                  </ul>
                </div>
                <div className="card_footer">
                  <div className="btn_area">
                    <button type="button" className="btn btn-lg btn-primary" onClick={this._handleSave}>
                      {t('저장')}
                    </button>
                    {data && (
                      <button type="button" className="btn btn-lg btn-primary" onClick={this._handleModifyPassword}>
                        {t('암호변경')}
                      </button>
                    )}
                    <button type="button" className="btn btn-lg btn-outline-primary" onClick={this._handleCancel}>
                      {t('취소')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { user } = state;
  return {
    checkLoginIdData: user.checkLoginIdData,
    checkEmailData: user.checkEmailData,
  };
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      checkLoginId,
      checkEmail,
      addTenant,
      modifyTenant,
      dispatch,
    },
    dispatch,
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TenantEdit));
export { connected as TenantEdit };
