import {
  FETCH_CLASS_AI_LRBIAS_DIAGNOSIS_LIST_REQUEST,
  FETCH_CLASS_AI_LRBIAS_DIAGNOSIS_LIST_SUCCESS,
  FETCH_CLASS_AI_LRBIAS_DIAGNOSIS_LIST_ERROR,
  FETCH_CLASS_AI_MOMENTUM_DIAGNOSIS_LIST_REQUEST,
  FETCH_CLASS_AI_MOMENTUM_DIAGNOSIS_LIST_SUCCESS,
  FETCH_CLASS_AI_MOMENTUM_DIAGNOSIS_LIST_ERROR,
  FETCH_CLASS_AI_AWARENESS_DIAGNOSIS_LIST_REQUEST,
  FETCH_CLASS_AI_AWARENESS_DIAGNOSIS_LIST_SUCCESS,
  FETCH_CLASS_AI_AWARENESS_DIAGNOSIS_LIST_ERROR,
  FETCH_CLASS_AI_REACTION_DIAGNOSIS_LIST_REQUEST,
  FETCH_CLASS_AI_REACTION_DIAGNOSIS_LIST_SUCCESS,
  FETCH_CLASS_AI_REACTION_DIAGNOSIS_LIST_ERROR,
  FETCH_CLASS_AI_MEMORY_DIAGNOSIS_LIST_REQUEST,
  FETCH_CLASS_AI_MEMORY_DIAGNOSIS_LIST_SUCCESS,
  FETCH_CLASS_AI_MEMORY_DIAGNOSIS_LIST_ERROR,
} from '_actions';

const initialState = {
  pending: false,
  diagnosisClassAiLrbiasData: null,
  diagnosisClassAiMomentumData: null,
  diagnosisClassAwarenessData: null,
  diagnosisClassReactionData: null,
  diagnosisClassMemoryData: null,
  error: null,
};

export function diagnosis(state = initialState, action) {
  switch (action.type) {
    case FETCH_CLASS_AI_LRBIAS_DIAGNOSIS_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CLASS_AI_LRBIAS_DIAGNOSIS_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        diagnosisClassAiLrbiasData: action.data,
      };
    case FETCH_CLASS_AI_LRBIAS_DIAGNOSIS_LIST_ERROR:
      return {
        ...state,
        pending: false,
        diagnosisClassAiLrbiasData: null,
        error: action.error,
      };
    case FETCH_CLASS_AI_MOMENTUM_DIAGNOSIS_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CLASS_AI_MOMENTUM_DIAGNOSIS_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        diagnosisClassAiMomentumData: action.data,
      };
    case FETCH_CLASS_AI_MOMENTUM_DIAGNOSIS_LIST_ERROR:
      return {
        ...state,
        pending: false,
        diagnosisClassAiMomentumData: null,
        error: action.error,
      };
    case FETCH_CLASS_AI_AWARENESS_DIAGNOSIS_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CLASS_AI_AWARENESS_DIAGNOSIS_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        diagnosisClassAwarenessData: action.data,
      };
    case FETCH_CLASS_AI_AWARENESS_DIAGNOSIS_LIST_ERROR:
      return {
        ...state,
        pending: false,
        diagnosisClassAwarenessData: null,
        error: action.error,
      };
    case FETCH_CLASS_AI_REACTION_DIAGNOSIS_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CLASS_AI_REACTION_DIAGNOSIS_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        diagnosisClassReactionData: action.data,
      };
    case FETCH_CLASS_AI_REACTION_DIAGNOSIS_LIST_ERROR:
      return {
        ...state,
        pending: false,
        diagnosisClassReactionData: null,
        error: action.error,
      };
    case FETCH_CLASS_AI_MEMORY_DIAGNOSIS_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CLASS_AI_MEMORY_DIAGNOSIS_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        diagnosisClassMemoryData: action.data,
      };
    case FETCH_CLASS_AI_MEMORY_DIAGNOSIS_LIST_ERROR:
      return {
        ...state,
        pending: false,
        diagnosisClassMemoryData: null,
        error: action.error,
      };
    default:
      return state;
  }
}
