import { requestUrl, requestHeaders, requestParam, requestBody, handleResponse } from '_helpers';
import { checkTenantRequest, checkTenantSuccess, checkTenantError, loginRequest, loginSuccess, loginError } from '_actions';

export function checkTenant(params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/auth/check-tenant?${requestParam()}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(checkTenantRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response, url))
      .then(response => {
        if (response.data) {
          setLocalTenant(response.data);
          callback(null, response.data);
          dispatch(checkTenantSuccess(response.data));
        } else {
          callback('NOT FOUND');
          dispatch(checkTenantError('NOT FOUND'));
        }
      })
      .catch(error => {
        callback(error);
        dispatch(checkTenantError(error));
      });
  };
}
export function login(params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/auth/login`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(loginRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response, url))
      .then(response => {
        setLocalSession(response.data);
        callback(null, response.data);
        dispatch(loginSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(loginError(error));
      });
  };
}
export function logout(clearAll, callback = () => {}) {
  const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/auth/logout`);
  const requestOptions = {
    credentials: 'same-origin',
    method: 'GET',
    headers: requestHeaders({ 'Content-Type': 'application/json' }),
  };

  return fetch(url, requestOptions)
    .then(response => handleResponse(response, url))
    .then(() => {
      if (clearAll) {
        clearSession();
      } else {
        removeLocalSession();
      }
      callback();
    })
    .catch(() => {
      if (clearAll) {
        clearSession();
      } else {
        removeLocalSession();
      }
      callback();
    });
}
export function getLocalTenant() {
  const tenant = localStorage.getItem('tenant');
  if (tenant) {
    return JSON.parse(localStorage.getItem('tenant'));
  }
}
export function setLocalTenant(tenant) {
  localStorage.setItem('tenant', JSON.stringify(tenant));
}
export function getLocalSession() {
  const session = localStorage.getItem('session');
  if (session) {
    return JSON.parse(localStorage.getItem('session'));
  }
}
export function setLocalSession(session) {
  localStorage.setItem('session', JSON.stringify(session));
}
export function removeLocalSession() {
  localStorage.removeItem('session');
}
export function clearSession() {
  localStorage.clear();
}
export function checkAuthSystemManager() {
  const session = getLocalSession();
  if (session && session.userType.indexOf('SM') >= 0) {
    return true;
  }
  return false;
}
export function checkAuthTenantManager() {
  const session = getLocalSession();
  if (session && session.userType.indexOf('TM') >= 0) {
    return true;
  }
  return false;
}
export function checkAuthTrainer() {
  const session = getLocalSession();
  if (session && session.userType.indexOf('TR') >= 0) {
    return true;
  }
  return false;
}
