import {
  FETCH_BOARD_LIST_REQUEST,
  FETCH_BOARD_LIST_SUCCESS,
  FETCH_BOARD_LIST_ERROR,
  ADD_BOARD_REQUEST,
  ADD_BOARD_SUCCESS,
  ADD_BOARD_ERROR,
  MODIFY_BOARD_REQUEST,
  MODIFY_BOARD_SUCCESS,
  MODIFY_BOARD_ERROR,
  REMOVE_BOARD_REQUEST,
  REMOVE_BOARD_SUCCESS,
  REMOVE_BOARD_ERROR,
} from '_actions';

const initialState = {
  pending: false,
  systemBoardListData: null,
  tenantBoardListData: null,
  classBoardListData: null,
  error: null,
};

export function board(state = initialState, action) {
  switch (action.type) {
    case FETCH_BOARD_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_BOARD_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        systemBoardListData: action.boardType === 'S' ? action.data : state.systemBoardListData,
        tenantBoardListData: action.boardType === 'T' ? action.data : state.tenantBoardListData,
        classBoardListData: action.boardType === 'C' ? action.data : state.classBoardListData,
      };
    case FETCH_BOARD_LIST_ERROR:
      return {
        ...state,
        pending: false,
        systemBoardListData: action.boardType === 'S' ? null : state.systemBoardListData,
        tenantBoardListData: action.boardType === 'T' ? null : state.tenantBoardListData,
        classBoardListData: action.boardType === 'C' ? null : state.classBoardListData,
        error: action.error,
      };
    case ADD_BOARD_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_BOARD_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_BOARD_ERROR:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_BOARD_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case MODIFY_BOARD_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_BOARD_ERROR:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_BOARD_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_BOARD_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_BOARD_ERROR:
      return {
        ...state,
        pending: false,
      };
    default:
      return state;
  }
}
