import {
  FETCH_CLASSES_OF_TRAINER_REQUEST,
  FETCH_CLASSES_OF_TRAINER_SUCCESS,
  FETCH_CLASSES_OF_TRAINER_ERROR,
  FETCH_TRAINER_LIST_REQUEST,
  FETCH_TRAINER_LIST_SUCCESS,
  FETCH_TRAINER_LIST_ERROR,
  ADD_TRAINER_REQUEST,
  ADD_TRAINER_SUCCESS,
  ADD_TRAINER_ERROR,
  MODIFY_TRAINER_REQUEST,
  MODIFY_TRAINER_SUCCESS,
  MODIFY_TRAINER_ERROR,
  REMOVE_TRAINER_REQUEST,
  REMOVE_TRAINER_SUCCESS,
  REMOVE_TRAINER_ERROR,
} from '_actions';

const initialState = {
  pending: false,
  classesOfTrainerData: null,
  trainerListData: null,
  error: null,
};

export function trainer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CLASSES_OF_TRAINER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CLASSES_OF_TRAINER_SUCCESS:
      return {
        ...state,
        pending: false,
        classesOfTrainerData: action.data,
      };
    case FETCH_CLASSES_OF_TRAINER_ERROR:
      return {
        ...state,
        pending: false,
        classesOfTrainerData: null,
        error: action.error,
      };
    case FETCH_TRAINER_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_TRAINER_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        trainerListData: action.data,
      };
    case FETCH_TRAINER_LIST_ERROR:
      return {
        ...state,
        pending: false,
        trainerListData: null,
        error: action.error,
      };
    case ADD_TRAINER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_TRAINER_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_TRAINER_ERROR:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_TRAINER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case MODIFY_TRAINER_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_TRAINER_ERROR:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_TRAINER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_TRAINER_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_TRAINER_ERROR:
      return {
        ...state,
        pending: false,
      };
    default:
      return state;
  }
}
