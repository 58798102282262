/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { Header, SideBar, DateRange, Pagination, DurationPagination } from '_components';
import { commonUtils, chartColors } from '_helpers';
import {
  fetchPatternList,
  fetchClassesOfMember,
  fetchMemberExerciseSummary,
  fetchMemberExerciseSummaryHistoryList,
  getUserPictureLink,
  fetchClassAiLrbiasDiagnosisList,
  fetchClassAiMomentumDiagnosisList,
  fetchClassAiAwarenessDiagnosisList,
  fetchClassAiReactionDiagnosisList,
  fetchClassAiMemoryDiagnosisList,
  // fetchClassAiLrbiasRangeList,
  // fetchClassAiMomentumRangeList,
  fetchClassAiAwarenessRangeList,
  fetchClassAiReactionRangeList,
  fetchClassAiMemoryRangeList,
  fetchRecommendPattern,
} from '_services';
import { pathConstant } from '_constants';
import { HealthContentsView, HealthAddModal, MemoContentsView } from '_containers';
// import userDefaultImg from "_asset/images/img-user_default.png";
import userRatingWhiteImg from '_asset/images/level-white.svg';
import userRatingYellowImg from '_asset/images/level-yellow.svg';
import userRatingSkyImg from '_asset/images/level-sky.svg';
import userRatingBlueImg from '_asset/images/level-blue.svg';
import userRatingMasterImg from '_asset/images/level-master.svg';

function getUserRatingImage(userRating) {
  switch (userRating) {
    case 'Y':
      return userRatingYellowImg;
    case 'S':
      return userRatingSkyImg;
    case 'B':
      return userRatingBlueImg;
    case 'M':
      return userRatingMasterImg;
    default:
      return userRatingWhiteImg;
  }
}

class MemberDetail extends Component {
  constructor(props) {
    super(props);

    const { t } = this.props;
    const now = moment();
    this.state = {
      exTabIndex: 0,
      aiTabIndex: 0,
      hlTabIndex: 0,
      exStartDate: moment(now).add(-3, 'month').format('YYYY-MM-DD'),
      exEndDate: moment(now).format('YYYY-MM-DD'),
      from: moment(now).add(-3, 'month').format('YYYY-MM-DD'),
      to: moment(now).format('YYYY-MM-DD'),
      pageSize: 10,
      exPageNo: 1,
      healthAddOpen: false,
      healthParams: {},
      exIndicators: [
        { id: 'accuracy', ageId: 'ageAccuracy', label: t('정확도'), unit: '%' },
        { id: 'takenSecond', ageId: 'ageTakenSecond', label: t('운동시간'), unit: t('초') },
        { id: 'walkingSpeed', ageId: 'ageWalkingSpeed', label: t('평균보속'), unit: t('초') },
        { id: 'totalStepCount', ageId: 'ageTotalStepCount', label: t('스텝수'), unit: t('회') },
      ],
      aiIndicators: [
        {
          id: 'leftRightDeflection',
          ageId: 'aiLeftRightDeflection',
          label: t('좌우편향'),
          unit: t('점'),
          explanation: [t('좌편향'), t('균형점'), t('우편향')],
          message: t(t('패턴 별 좌/우 정답률과 개인의 운동정보를 활용하여 개인의 좌/우 편향 유형을 분류한 값입니다.')),
        },
        {
          id: 'exerciseAmount',
          ageId: 'aiExerciseAmount',
          label: t('운동량'),
          unit: t('스탭'),
          explanation: [t('높음'), t('보통'), t('낮음')],
          message: t('다양한 패턴을 수행하며 기록된 운동정보를 토대로 개인의 운동량 등급을 분류한 값입니다.'),
        },
        {
          id: 'awareness',
          ageId: 'aiAwareness',
          label: t('인지도'),
          unit: t('%'),
          explanation: ['', ''],
          message: t('운동 별 틀린 스텝의 비율의 평균(*100 하여야 백분율)이며, 수치가 낮을수록 인지도가 좋습니다.'),
        },
        {
          id: 'reactivity',
          ageId: 'aiReactivity',
          label: t('반응성'),
          unit: t('초'),
          explanation: ['', ''],
          message: t('하나의 정상 스텝을 밟는데까지 걸린 평균 시간(초 단위)이며, 수치가 낮을수록 반응성이 좋습니다.'),
        },
        {
          id: 'memory',
          ageId: 'aiMemory',
          label: t('기억력'),
          unit: t('초'),
          explanation: ['', ''],
          message: t(
            '다음 스텝 안내가 없는 운동에 대해서 스텝 하나당 평균 처리시간(초 단위)이며, 수치가 낮을수록 기억력이 좋습니다.',
          ),
        },
      ],
      healthTypes: [
        { id: 'Fitness', label: t('일상생활체력') },
        { id: 'Longevity', label: t('장수연령') },
        { id: 'BPI', label: t('뇌인지') + '(BPI)' },
        { id: 'Mental', label: t('정신건강') },
        { id: 'Happiness', label: t('행복증진') },
      ],
    };

    this._handleClickExTab = this._handleClickExTab.bind(this);
    this._handleClickAiTab = this._handleClickAiTab.bind(this);
    this._handleChangeExStartDate = this._handleChangeExStartDate.bind(this);
    this._handleChangeExEndDate = this._handleChangeExEndDate.bind(this);
    this._handleChangeAiStartDate = this._handleChangeAiStartDate.bind(this);
    this._handleChangeAiEndDate = this._handleChangeAiEndDate.bind(this);
    this._handleExPageMove = this._handleExPageMove.bind(this);
    this._handleClickHlTab = this._handleClickHlTab.bind(this);
    this._handleHealthAdd = this._handleHealthAdd.bind(this);
    this._handleHealthAddClose = this._handleHealthAddClose.bind(this);
    this._handlePrintPageMove = this._handlePrintPageMove.bind(this);
    this._healthCallback = this._healthCallback.bind(this);
  }

  componentDidMount() {
    window.$('.jquery_scrollbar').scrollbar();
    window.setAutoSizeTooltip();
    this._loadData();
  }
  _loadData() {
    const { exStartDate, exEndDate, exPageNo, from, to } = this.state;
    this._loadPatternListData();
    this._loadClassesOfMemberData();
    this._loadMemberExerciseSummaryData();
    this._loadMemberExerciseSummaryHistoryListData(exStartDate, exEndDate, exPageNo);
    this._loadClassAiRangeData();
    this._loadClassAiDiagnosisData(from, to);
  }
  _loadPatternListData() {
    const { userId } = this.props.location.state.data;
    this.props.fetchPatternList();
    this.props.fetchRecommendPattern(userId);
  }
  _loadClassesOfMemberData() {
    const { userId } = this.props.location.state.data;
    this.props.fetchClassesOfMember(userId);
  }
  _loadMemberExerciseSummaryData() {
    const { userId } = this.props.location.state.data;
    this.props.fetchMemberExerciseSummary(userId);
  }
  _loadMemberExerciseSummaryHistoryListData(startDate, endDate, pageNo) {
    const { pageSize } = this.state;
    const params = { startDate, endDate, pageNo, pageSize };
    const { userId } = this.props.location.state.data;
    this.props.fetchMemberExerciseSummaryHistoryList(userId, params);
  }
  _loadClassAiRangeData() {
    const { gender } = this.props.location.state.data;
    // this.props.fetchClassAiLrbiasRangeList(gender);
    // this.props.fetchClassAiMomentumRangeList(gender);
    this.props.fetchClassAiAwarenessRangeList(gender);
    this.props.fetchClassAiReactionRangeList(gender);
    this.props.fetchClassAiMemoryRangeList(gender);
  }
  _loadClassAiDiagnosisData(from, to) {
    const params = { from, to };
    const { userId } = this.props.location.state.data;
    this.props.fetchClassAiLrbiasDiagnosisList(userId, params);
    this.props.fetchClassAiMomentumDiagnosisList(userId, params);
    this.props.fetchClassAiAwarenessDiagnosisList(userId, params);
    this.props.fetchClassAiReactionDiagnosisList(userId, params);
    this.props.fetchClassAiMemoryDiagnosisList(userId, params);
  }
  _handleExPageMove(pageNo) {
    const { exStartDate, exEndDate } = this.state;
    this.setState({
      ...this.state,
      exPageNo: pageNo,
    });
    this._loadMemberExerciseSummaryHistoryListData(exStartDate, exEndDate, pageNo);
  }
  _handleClickExTab(e, exTabIndex) {
    e.preventDefault();

    this.setState({
      ...this.state,
      exTabIndex,
    });
  }
  _handleClickAiTab(e, aiTabIndex) {
    e.preventDefault();

    this.setState({
      ...this.state,
      aiTabIndex,
    });
  }
  _handleClickHlTab(e, hlTabIndex) {
    e.preventDefault();

    this.setState({
      ...this.state,
      hlTabIndex,
    });
  }
  _handleChangeExStartDate(date) {
    const { exEndDate } = this.state;
    this.setState({
      ...this.state,
      exStartDate: date,
      exPageNo: 1,
    });
    this._loadMemberExerciseSummaryHistoryListData(date, exEndDate, 1);
  }
  _handleChangeExEndDate(date) {
    const { exStartDate } = this.state;
    this.setState({
      ...this.state,
      exEndDate: date,
      exPageNo: 1,
    });
    this._loadMemberExerciseSummaryHistoryListData(exStartDate, date, 1);
  }
  _handleChangeAiStartDate(date) {
    const { from } = this.state;
    this.setState({
      ...this.state,
      from: date,
    });
    this._loadClassAiDiagnosisData(date, from);
  }
  _handleChangeAiEndDate(date) {
    const { to } = this.state;
    this.setState({
      ...this.state,
      to: date,
    });
    this._loadClassAiDiagnosisData(to, date);
  }
  _handleHealthAdd() {
    this.setState({
      ...this.state,
      healthAddOpen: true,
    });
  }
  _handleHealthAddClose() {
    this.setState({
      ...this.state,
      healthAddOpen: false,
    });
  }

  _healthCallback(params) {
    this.setState({
      ...this.state,
      healthParams: params,
    });
  }

  _handlePrintPageMove(e, data, memberExerciseSummaryData, classesOfMemberData) {
    e.preventDefault();
    const newTab = window.open(pathConstant.PATH_MEMBER_PRINT, '_blank');
    if (newTab) {
      newTab.data = data;
      newTab.memberExerciseSummaryData = memberExerciseSummaryData;
      newTab.classesOfMemberData = classesOfMemberData;
    }
  }

  render() {
    const { t } = this.props;
    const lang = commonUtils.getBrowserLanguage();
    const { data } = this.props.location.state;
    const {
      exTabIndex,
      aiTabIndex,
      hlTabIndex,
      exStartDate,
      exEndDate,
      from,
      to,
      exIndicators,
      aiIndicators,
      pageSize,
      exPageNo,
      healthTypes,
      healthAddOpen,
      healthParams,
    } = this.state;

    document.title = `${t('사용자 상세')} | ${t('모스텝')}`;
    const breadcrumb = [t('사용자 관리'), data.userNm];
    const {
      classesOfMemberData,
      memberExerciseSummaryData,
      memberExerciseSummaryHistoryListData,
      patternListData,
      recommendPatternData,
      diagnosisClassAiList,
      rangeClassesAiList,
    } = this.props;
    const userRatingStyle = {
      width: memberExerciseSummaryData
        ? commonUtils.getUserRatingProgress(data.userRating, memberExerciseSummaryData.totalExerciseCount) + '%'
        : '0%',
    };
    const exPagination = memberExerciseSummaryHistoryListData ? memberExerciseSummaryHistoryListData.pagination : null;
    const exDateList = [];
    if (memberExerciseSummaryHistoryListData) {
      memberExerciseSummaryHistoryListData.data.forEach(item => {
        exDateList.unshift(moment(item.exerciseDate).format('YYYY-MM-DD'));
      });
      memberExerciseSummaryHistoryListData.data = memberExerciseSummaryHistoryListData.data.sort(function (a, b) {
        return new Date(b.exerciseDate) - new Date(a.exerciseDate);
      });
    }

    return (
      <>
        <Header {...this.props} breadcrumb={breadcrumb} />
        <SideBar {...this.props} />
        <main className="contents_wrapper">
          <div className="jquery_scrollbar">
            <div className="contents">
              <div className="contents_header">
                <div className="card summary_user">
                  <img src={getUserPictureLink(data.userId)} alt="유저 이미지" />
                  <ul className="info_list">
                    <li>
                      <p className="name">{data.userNm}</p>
                      <p className="id">{data.loginId}</p>
                    </li>
                    <li className="division">
                      <p>
                        <span className={`badge ${data.gender === 'M' ? 'male' : 'female'}`}>
                          {data.gender === 'M' ? t('남') : t('여')}
                        </span>
                      </p>
                      <p>{data.height === 0 ? '' : data.height}cm</p>
                      <p>{data.weight === 0 ? '' : data.weight}kg</p>
                      <p>
                        {data.age}
                        {t('세')}
                      </p>
                    </li>
                    <li>
                      <p className="user_phone">{data.tel}</p>
                    </li>
                    <li>
                      <p className="record_date">
                        {data.createdTime} {t('등록 완료')}
                      </p>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary"
                        onClick={() => {
                          this.props.history.push({
                            pathname: pathConstant.PATH_MEMBER_EDIT,
                            state: { data, caller: 'detail' },
                          });
                        }}
                      >
                        <span className="ico edit"></span>
                        <span>{t('수정')}</span>
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="card summary_attendance">
                  <h3 className="title">{t('참여 클래스')}</h3>
                  <ul className={`info_list ${classesOfMemberData && classesOfMemberData.length > 0 ? '' : 'none_data'}`}>
                    {(!classesOfMemberData || classesOfMemberData.length === 0) && (
                      <li>
                        <p>{t('참여하는 클래스가 없습니다.')}</p>
                      </li>
                    )}
                    {classesOfMemberData &&
                      classesOfMemberData.map((item, index) => (
                        <li key={index}>
                          <a
                            href=""
                            className="btn-link btn-sm"
                            onClick={e => {
                              e.preventDefault();
                              this.props.history.push({
                                pathname: pathConstant.PATH_CLASS_DETAIL,
                                state: { data: item },
                              });
                            }}
                          >
                            {item.classNm}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="card summary_exercise">
                  <div className="level">
                    <img src={getUserRatingImage(data.userRating)} alt="등급" />
                    <p className="text">
                      <span>{t(commonUtils.getUserRatingName(data.userRating))}</span>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id="" className="exercise_level">
                            <h3>{t('모스텝 운동등급 일람표')}</h3>
                            <ul>
                              <li>
                                <img src={getUserRatingImage('W')} alt="운동등급" />
                                <p className="name">{t('화이트')}</p>
                                <p className="description">
                                  {t('총 운동 횟수')} {lang === 'en' && t('미만')}{' '}
                                  <span className="text-primary">100{t('회')}</span> {lang === 'ko' && t('미만')}
                                </p>
                              </li>
                              <li>
                                <img src={getUserRatingImage('Y')} alt="운동등급" />
                                <p className="name">{t('옐로우')}</p>
                                <p className="description">
                                  {t('총 운동 횟수')} <span className="text-primary">100{t('회')}</span> {t('이상')}
                                </p>
                              </li>
                              <li>
                                <img src={getUserRatingImage('S')} alt="운동등급" />
                                <p className="name">{t('스카이')}</p>
                                <p className="description">
                                  {t('총 운동 횟수')} <span className="text-primary">500{t('회')}</span> {t('이상')}
                                </p>
                              </li>
                              <li>
                                <img src={getUserRatingImage('B')} alt="운동등급" />
                                {lang === 'ko' && (
                                  <p className="name">
                                    <span>{t('블루')}</span>
                                    <span>{t('블루2')}</span>
                                  </p>
                                )}
                                {lang === 'en' && <p className="name">{t('블루')}</p>}
                                {lang === 'ko' && (
                                  <p className="description">
                                    {t('중,상급 패턴')} <span className="text-primary">2,000{t('회')}</span> {t('이상2')}
                                  </p>
                                )}
                                {lang === 'en' && (
                                  <p className="description">
                                    {t('이상2')} <span className="text-primary">2,000{t('회')}</span> {t('중,상급 패턴')}
                                  </p>
                                )}
                              </li>
                              <li>
                                <img src={getUserRatingImage('M')} alt="운동등급" />
                                <p className="name">{t('마스터')}</p>
                                <p className="description">
                                  {lang === 'ko' && (
                                    <p className="description">
                                      {t('중,상급 패턴')} <span className="text-primary">10,000{t('회')}</span> {t('이상2')}
                                    </p>
                                  )}
                                  {lang === 'en' && (
                                    <p className="description">
                                      {t('이상2')} <span className="text-primary">10,000{t('회')}</span> {t('중,상급 패턴')}
                                    </p>
                                  )}
                                </p>
                              </li>
                            </ul>
                          </Tooltip>
                        }
                      >
                        <span className="ico info"></span>
                      </OverlayTrigger>
                    </p>
                    <div className="progress">
                      <div className="progress-bar" style={userRatingStyle}></div>
                    </div>
                  </div>
                  <div className="summary">
                    <h3 className="title">
                      <span>{t('운동 요약')}</span>
                      <span className="helper_text">({t('전체 운동 기간 결과입니다.')})</span>
                    </h3>
                    <ul className="info_list">
                      <li>
                        <p className="name">{t('총 스텝 수')}</p>
                        <p className="count">
                          <span className="num">
                            {commonUtils.numberWithCommas(
                              memberExerciseSummaryData ? memberExerciseSummaryData.totalStepCount : 0,
                            )}
                          </span>
                          <span>{t('회(스텝)')}</span>
                        </p>
                      </li>
                      <li>
                        <p className="name">{t('총 운동 횟수')}</p>
                        <p className="count">
                          <span className="num">
                            {commonUtils.numberWithCommas(
                              memberExerciseSummaryData ? memberExerciseSummaryData.totalExerciseCount : 0,
                            )}
                          </span>
                          <span>{t('회(운동)')}</span>
                        </p>
                      </li>
                      <li>
                        <p className="name">{t('평균 보속')}</p>
                        <p className="count">
                          <span className="num">
                            {commonUtils.numberWithCommas(
                              memberExerciseSummaryData ? memberExerciseSummaryData.walkingSpeed : 0,
                            )}
                          </span>
                          <span>{t('초')}</span>
                        </p>
                      </li>
                      <li>
                        <p className="name">{t('총 운동시간')}</p>
                        <p className="count">
                          <span className="num">
                            {commonUtils.numberWithCommas(
                              memberExerciseSummaryData ? (memberExerciseSummaryData.takenSecond / (60 * 60)).toFixed(2) : 0,
                            )}
                          </span>
                          <span>{t('시간')}</span>
                        </p>
                      </li>
                    </ul>
                    <a
                      id="myLink"
                      href=""
                      className="btn btn-outline-primary"
                      onClick={e => this._handlePrintPageMove(e, data, memberExerciseSummaryData, classesOfMemberData)}
                    >
                      {t('요약본 인쇄')}
                    </a>
                  </div>
                </div>
              </div>
              <div className="contents_body">
                <div className="card detail_record">
                  <div className="card_header">
                    <h3 className="title">{t('운동기록')}</h3>
                  </div>
                  <div className="card_body">
                    <div className="contents_tool">
                      <div className="main">
                        <ul className="nav nav-pills">
                          <li className="nav-item">
                            <a
                              href=""
                              className={`nav-link ${exTabIndex === 0 ? 'active' : ''}`}
                              data-bs-toggle="tab"
                              onClick={e => this._handleClickExTab(e, 0)}
                            >
                              {t('정확도')}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href=""
                              className={`nav-link ${exTabIndex === 1 ? 'active' : ''}`}
                              data-bs-toggle="tab"
                              onClick={e => this._handleClickExTab(e, 1)}
                            >
                              {t('운동시간')}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href=""
                              className={`nav-link ${exTabIndex === 2 ? 'active' : ''}`}
                              data-bs-toggle="tab"
                              onClick={e => this._handleClickExTab(e, 2)}
                            >
                              {t('평균보속')}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href=""
                              className={`nav-link ${exTabIndex === 3 ? 'active' : ''}`}
                              data-bs-toggle="tab"
                              onClick={e => this._handleClickExTab(e, 3)}
                            >
                              {t('스텝수')}
                            </a>
                          </li>
                        </ul>
                        <div className="date_range">
                          <h4 className="title">{t('기간 설정')}</h4>
                          <DateRange
                            className="date start"
                            minYear={2020}
                            maxYear={2040}
                            date={exStartDate}
                            onChange={this._handleChangeExStartDate}
                          />
                          <DateRange
                            className="date end"
                            minYear={2020}
                            maxYear={2040}
                            date={exEndDate}
                            onChange={this._handleChangeExEndDate}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="tab-content">
                      {exIndicators.map((item, index) => (
                        <div key={index} className={`tab-pane fade ${exTabIndex === index ? 'show active' : ''}`}>
                          <div className="graph_wrapper">
                            <DurationPagination
                              dateList={exDateList}
                              totalCount={exPagination ? exPagination.totalCount : 0}
                              rowCount={exPagination ? exPagination.pageSize : pageSize}
                              page={exPagination ? exPagination.pageNo : exPageNo}
                              initStartDate={exStartDate}
                              initEndDate={exEndDate}
                              onPageMove={this._handleExPageMove}
                            />
                            <div className="graph">
                              <ExDataChart
                                t={t}
                                data={memberExerciseSummaryHistoryListData}
                                label={item.label}
                                field={item.id}
                                ageField={item.ageId}
                                age={data.age}
                                unit={item.unit}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <table
                      className={`table ${
                        !memberExerciseSummaryHistoryListData || memberExerciseSummaryHistoryListData.data.length === 0
                          ? 'none_data'
                          : ''
                      }`}
                    >
                      <caption className="visually-hidden">{t('테이블')}</caption>
                      <thead>
                        <tr>
                          <th scope="col">
                            <p className="d-flex">
                              <span>{t('운동날짜')}</span>
                              {/* <span className="ico descending"></span> */}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="d-flex">
                              <span>{t('정확도')}</span>
                              {/* <span className="ico descending"></span> */}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="d-flex">
                              <span>{t('운동시간')}</span>
                              {/* <span className="ico ascending"></span> */}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="d-flex">
                              <span>{t('스텝수')}</span>
                              {/* <span className="ico descending"></span> */}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="d-flex">
                              <span>{t('평균보속')}</span>
                              {/* <span className="ico descending"></span> */}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="d-flex">
                              <span>{t('틀린 횟수')}</span>
                              {/* <span className="ico descending"></span> */}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="d-flex">
                              <span>{t('패턴 그룹')}</span>
                              {/* <span className="ico descending"></span> */}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="d-flex">
                              <span>{t('수업명')}</span>
                              {/* <span className="ico descending"></span> */}
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(!memberExerciseSummaryHistoryListData ||
                          memberExerciseSummaryHistoryListData.data.length === 0) && (
                          <tr>
                            <td colSpan="9">
                              <p>{t('운동기록이 없습니다.')}</p>
                            </td>
                          </tr>
                        )}
                        {memberExerciseSummaryHistoryListData &&
                          memberExerciseSummaryHistoryListData.data.map((item, index) => (
                            <tr key={index}>
                              <td>{moment(item.exerciseDate).format('YYYY-MM-DD')}</td>
                              <td>
                                <span className="text-primary">{item.accuracy}</span>
                              </td>
                              <td>{item.takenSecond}</td>
                              <td>{commonUtils.numberWithCommas(item.totalStepCount)}</td>
                              <td>{item.walkingSpeed}</td>
                              <td>{commonUtils.numberWithCommas(item.wrongStepCount)}</td>
                              <td>{item.patternGroupNm}</td>
                              <td>{item.classUnitNm}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <Pagination
                      totalCount={exPagination ? exPagination.totalCount : 0}
                      rowCount={exPagination ? exPagination.pageSize : pageSize}
                      page={exPagination ? exPagination.pageNo : exPageNo}
                      onPageMove={this._handleExPageMove}
                    />
                  </div>
                </div>

                <div className="card detail_ai">
                  <div className="card_header">
                    <h3 className="title">{t('AI 분석 리포트')}</h3>
                  </div>
                  <div className="card_body">
                    <div className="contents_tool">
                      <div className="main">
                        <ul className="nav nav-pills">
                          <li className="nav-item">
                            <a
                              href=""
                              className={`nav-link ${aiTabIndex === 0 ? 'active' : ''}`}
                              data-bs-toggle="tab"
                              onClick={e => this._handleClickAiTab(e, 0)}
                            >
                              {t('좌우편향')}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href=""
                              className={`nav-link ${aiTabIndex === 1 ? 'active' : ''}`}
                              data-bs-toggle="tab"
                              onClick={e => this._handleClickAiTab(e, 1)}
                            >
                              {t('운동량')}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href=""
                              className={`nav-link ${aiTabIndex === 2 ? 'active' : ''}`}
                              data-bs-toggle="tab"
                              onClick={e => this._handleClickAiTab(e, 2)}
                            >
                              {t('인지도')}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href=""
                              className={`nav-link ${aiTabIndex === 3 ? 'active' : ''}`}
                              data-bs-toggle="tab"
                              onClick={e => this._handleClickAiTab(e, 3)}
                            >
                              {t('반응성')}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href=""
                              className={`nav-link ${aiTabIndex === 4 ? 'active' : ''}`}
                              data-bs-toggle="tab"
                              onClick={e => this._handleClickAiTab(e, 4)}
                            >
                              {t('기억력')}
                            </a>
                          </li>
                        </ul>
                        <div className="date_range">
                          <h4 className="title">{t('기간 설정')}</h4>
                          <DateRange
                            className="date start"
                            minYear={2020}
                            maxYear={2040}
                            date={from}
                            onChange={this._handleChangeAiStartDate}
                          />
                          <DateRange
                            className="date end"
                            minYear={2020}
                            maxYear={2040}
                            date={to}
                            onChange={this._handleChangeAiEndDate}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="tab-content">
                      {aiIndicators.map((item, index) => (
                        <div key={index} className={`tab-pane fade ${aiTabIndex === index ? 'show active' : ''}`}>
                          <div className="graph_wrapper">
                            <div className="graph">
                              <AiDataChart
                                t={t}
                                data={diagnosisClassAiList[index]}
                                ranges={rangeClassesAiList[index]}
                                label={item.label}
                                explanation={item.explanation}
                                message={item.message}
                                field={item.id}
                                ageField={item.ageId}
                                age={data.age}
                                unit={item.unit}
                                sort={index}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="card detail_index">
                  <div className="card_header">
                    <ul className="nav nav-tabs">
                      {healthTypes.map((item, index) => (
                        <li key={index} className="nav-item">
                          <a
                            href=""
                            className={`nav-link ${hlTabIndex === index ? 'active' : ''}`}
                            data-bs-toggle="tab"
                            onClick={e => this._handleClickHlTab(e, index)}
                          >
                            {item.label}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <div className="btn_area">
                      <button type="button" className="btn btn-outline-primary" onClick={this._handleHealthAdd}>
                        {t('기록추가')}
                      </button>
                    </div>
                    <HealthAddModal
                      {...this.props}
                      healthType={healthTypes[hlTabIndex].id}
                      healthTypeLabel={healthTypes[hlTabIndex].label}
                      healthParams={healthParams}
                      memberData={data}
                      open={healthAddOpen}
                      onClose={this._handleHealthAddClose}
                    />
                  </div>
                  <div className="card_body">
                    <div className="tab-content">
                      {healthTypes.map((item, index) => (
                        <div key={index} className={`tab-pane fade ${hlTabIndex === index ? 'show active' : ''}`}>
                          <HealthContentsView
                            {...this.props}
                            healthType={item.id}
                            healthTypeLabel={item.label}
                            healthCallback={this._healthCallback}
                            memberData={data}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <MemoContentsView {...this.props} memberData={data} />

                <div className="card pattern_recommend">
                  <RecommendPattern recommendPatternData={recommendPatternData} t={t} />
                </div>

                <div className="card pattern_wrong">
                  <div className="card_header">
                    <h3 className="title">
                      {t('틀린 위치')}
                      <span className="helper_text">
                        (<sup className="ico asterisk"></sup>
                        {t('시작 위치 색 : ')}
                        <span className="text_red">{t('빨간색')}</span> / {t('틀린 위치 색 : ')}
                        <span className="text_blue">{t('파란색')}</span> )
                      </span>
                    </h3>
                    <span
                      className="ico info_tooltip"
                      data-bs-toggle="tooltip"
                      data-bs-offset="0, 4"
                      data-bs-html="true"
                      title={t('직전 운동에 대한 틀린 위치의 데이터를 보여줍니다.')}
                    ></span>
                  </div>
                  <div className="card_body">
                    <div className="jquery_scrollbar">
                      <ul className="mat_list">
                        {memberExerciseSummaryHistoryListData && memberExerciseSummaryHistoryListData.data.length > 0 ? (
                          memberExerciseSummaryHistoryListData.data.map((item, index) => (
                            <li key={index}>
                              <WrongPosition item={item} patternList={patternListData} t={t} />
                            </li>
                          ))
                        ) : (
                          <li>
                            <div className="not_data">{t('현재 사용자는 데이터가 없습니다.')}</div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

class ExDataChart extends Component {
  render() {
    const { t } = this.props;
    const lang = commonUtils.getBrowserLanguage();
    const { label, field, ageField, age, unit } = this.props;
    const labels = [];
    const myData = [];
    const myAvgData = [];
    const ageAvgData = [];
    let dataList = [];
    let mySumData = 0;
    let ageSumData = 0;

    if (this.props.data && this.props.data.data.length > 0) {
      let copyDataList = JSON.parse(JSON.stringify(this.props.data.data));
      dataList = copyDataList.sort(function (a, b) {
        return new Date(a.exerciseDate) - new Date(b.exerciseDate);
      });
    }

    dataList.forEach((item, index) => {
      mySumData += item[field];
      ageSumData += item[ageField];
    });
    mySumData = dataList.length === 0 ? 0 : mySumData / dataList.length;
    ageSumData = dataList.length === 0 ? 0 : ageSumData / dataList.length;
    dataList.forEach((item, index) => {
      labels[index] = moment(item.exerciseDate).format('MM.DD');
      myData[index] = item[field];
      myAvgData[index] = mySumData.toFixed(2);
      ageAvgData[index] = ageSumData.toFixed(2);
    });
    const options = {
      responsive: false,
      legend: {
        display: true,
        position: 'chartArea',
      },
      tooltips: {
        mode: 'nearest',
        intersect: false,
      },
      hover: {
        mode: 'nearest',
        intersect: true,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              precision: 0,
              callback: function (value, index, values) {
                return commonUtils.numberWithCommas(value) + unit;
              },
            },
          },
        ],
      },
    };
    const data = {
      labels,
      datasets: [
        {
          label: label,
          fill: true,
          lineTension: 0.1,
          backgroundColor: chartColors.background,
          borderColor: chartColors.border,
          borderWidth: 1,
          radius: 3,
          pointHoverRadius: 5,
          // pointBackgroundColor: chartColors.point,
          // pointHoverBackgroundColor: chartColors.primary,
          // pointBorderColor: chartColors.point,
          // pointHoverBorderColor: chartColors.primary,
          data: myData,
        },
        {
          label: t('나의 평균'),
          fill: false,
          lineTension: 0.1,
          backgroundColor: chartColors.black,
          borderColor: chartColors.black,
          borderWidth: 1,
          radius: 3,
          pointHoverRadius: 5,
          // pointBackgroundColor: chartColors.black,
          // pointHoverBackgroundColor: chartColors.black,
          // pointBorderColor: chartColors.black,
          // pointHoverBorderColor: chartColors.black,
          data: myAvgData,
        },
        {
          label:
            lang === 'ko'
              ? Math.floor((age || 0) / 10) * 10 + t('대 평균')
              : t('대 평균') + ' ' + Math.floor((age || 0) / 10) * 10,
          fill: false,
          lineTension: 0.1,
          backgroundColor: chartColors.gray,
          borderColor: chartColors.gray,
          borderWidth: 1,
          radius: 3,
          pointHoverRadius: 5,
          // pointBackgroundColor: chartColors.gray,
          // pointHoverBackgroundColor: chartColors.gray,
          // pointBorderColor: chartColors.gray,
          // pointHoverBorderColor: chartColors.gray,
          borderDash: [2, 2],
          data: ageAvgData,
        },
      ],
    };

    return (
      <div className="graph_area">
        <Line width={1080} height={466} data={data} options={options} redraw />
      </div>
    );
  }
}

class AiDataChart extends Component {
  render() {
    const { t, label, unit, sort, age } = this.props;
    const { explanation, message, field, ranges } = this.props;
    const lang = commonUtils.getBrowserLanguage();
    const labels = [];
    const myData = [];
    const averageLowData = [];
    const averageHighData = [];
    let dataList = [];
    let myAverageData = [];
    let mySumData = 0;

    if (this.props.data && this.props.data.length > 0) {
      dataList = this.props.data.sort(function (a, b) {
        return new Date(a.reportTime) - new Date(b.reportTime);
      });
    }

    let averageAge =
      lang === 'ko'
        ? Math.floor((age || 0) / 10) * 10 + t('대 평균 범위')
        : t('대 평균 범위') + ' ' + Math.floor((age || 0) / 10) * 10;
    let legends = '';
    let avgRanges = [];
    // ranges.reverse();
    if (ranges) {
      for (var i = 0; i < ranges.length; i++) {
        if (ranges[i].ageFrom <= age && ranges[i].ageTo >= age) {
          avgRanges.push(ranges[i]);
        }
      }
    }

    // avgRanges.reverse();

    dataList.forEach((item, index) => {
      labels[index] = moment(item.reportTime).format('MM.DD');
      const itemDate = moment(item.reportTime);
      const avgRange = avgRanges.find(cur => {
        const fromDate = moment(cur.reportTime).subtract(7, 'days');
        const toDate = moment(cur.reportTime);
        return itemDate >= fromDate && itemDate <= toDate;
      });
      if (avgRange) {
        averageLowData.push(avgRange.result_1 * (sort == 2 ? 100 : 1));
        averageHighData.push(avgRange.result_2 * (sort == 2 ? 100 : 1));
      }
      switch (sort) {
        case 0:
          let result = item.result[0] - item.result[1];
          result = result || result === 0 ? result.toFixed(2) : undefined;
          myData[index] = result;
          break;
        case 2:
          myData[index] = item.result[0] * 100;
          mySumData += myData[index];
          myAverageData.push(mySumData / (myAverageData.length + 1));
          break;
        default:
          myData[index] = item.result[0];
          mySumData += myData[index];
          myAverageData.push(mySumData / (myAverageData.length + 1));
      }
    });

    if (dataList.length < 2) {
      labels.unshift('');
      labels.push('');
      myData.unshift(null);
      myData.push(null);
    }
    let ticks = {
      precision: 0,
      callback: function (value, index, values) {
        return commonUtils.numberWithCommas(value) + unit;
      },
    };

    switch (sort) {
      case 0:
        legends = [{ className: 'legend_value', classText: label }];
        ticks = {
          precision: 0,
          stepSize: 1,
          min: -100,
          max: 100,
          callback: function (value, index, values) {
            return '';
          },
        };
        break;
      case 1:
        legends = [{ className: 'legend_value', classText: label }];
        ticks = {
          precision: 0,
          stepSize: 1,
          min: -1,
          max: 3,
          callback: function (value, index, values) {
            return '';
          },
        };
        break;
      case 2:
        legends = [
          { className: 'legend_value', classText: label },
          { className: 'legend_myAverage', classText: t('나의 평균') },
          { className: 'legend_ageAverage', classText: averageAge },
        ];
        ticks = {
          // precision: 0,
          min: 0,
          max: 100,
          callback: function (value, index, values) {
            return commonUtils.numberWithCommas(value) + unit;
          },
        };
        break;
      default:
        legends = [
          { className: 'legend_value', classText: label },
          { className: 'legend_myAverage', classText: t('나의 평균') },
          {
            className: 'legend_ageAverage',
            classText: averageAge,
          },
        ];
        ticks = {
          precision: 0,
          min: 0,
          beginAtZero: false,
          enabled: false,
          callback: function (value, index, values) {
            return commonUtils.numberWithCommas(value) + unit;
          },
        };
    }

    const options = {
      responsive: false,
      legend: {
        display: false,
      },
      tooltips: {
        mode: 'nearest',
        intersect: false,
        displayColors: false,
        callbacks: {
          label: function (item, data) {
            switch (sort) {
              case 0:
                if (item.value > 0) {
                  return t('좌편향') + ': ' + item.value;
                } else if (item.value < 0) {
                  return t('우편향') + ': ' + (item.value || item.value === 0 ? Math.abs(item.value) : undefined);
                } else {
                  return t('균형점') + ': 0';
                }
              case 1:
                if (item.value > 1) {
                  return t('높음') + ': ' + item.value;
                } else if (item.value < 1) {
                  return t('낮음') + ': ' + (item.value || item.value === 0 ? Math.abs(item.value) : undefined);
                } else {
                  return t('보통') + ': 1';
                }
              default:
                let label = data.datasets[item.datasetIndex].label || '';
                let value = item.yLabel || item.yLabel === 0 ? (parseFloat(item.yLabel).toFixed(1) * 10) / 10 : undefined;
                return t(label) + ': ' + value;
            }
          },
        },
      },
      hover: {
        mode: 'nearest',
        intersect: true,
      },
      layout: {
        padding: {
          top: 30,
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: true, // X축의 그리드 선을 표시함
              drawBorder: true, // X축의 바깥쪽 선을 제거함
              drawOnChartArea: false, // 차트 영역 내부의 그리드 선을 제거함
            },
          },
        ],
        yAxes: [
          {
            ticks: ticks,
            gridLines: {
              display: true, // X축의 그리드 선을 표시함
              drawBorder: true, // X축의 바깥쪽 선을 제거함
              drawOnChartArea: false, // 차트 영역 내부의 그리드 선을 제거함
            },
          },
        ],
      },
    };
    const data = {
      labels,
      datasets: [
        {
          label: averageAge,
          fill: true,
          lineTension: 0.1,
          backgroundColor: chartColors.white,
          borderColor: chartColors.border,
          borderWidth: 1,
          radius: 0,
          pointHoverRadius: 0,
          data: averageLowData,
        },
        {
          label: averageAge,
          fill: true,
          lineTension: 0.1,
          backgroundColor: chartColors.background,
          borderColor: chartColors.border,
          borderWidth: 1,
          radius: 0,
          pointHoverRadius: 0,
          data: averageHighData,
        },
        {
          label: label,
          fill: false,
          lineTension: 0.1,
          backgroundColor: chartColors.gray,
          borderColor: chartColors.gray,
          borderWidth: 1.5,
          radius: 3,
          pointHoverRadius: 5,
          data: myData,
        },
        {
          label: t('나의 평균'),
          fill: false,
          lineTension: 0.1,
          backgroundColor: chartColors.black,
          borderColor: chartColors.black,
          borderWidth: 1.5,
          radius: 3,
          pointHoverRadius: 5,
          data: myAverageData,
        },
      ],
    };

    return (
      <>
        <div className="graph_explanation">
          {message ? (
            <>
              <sup className="ico asterisk"></sup> {t(message)}
              <div className="graph_legend">
                {legends.map((item, index) => (
                  <>
                    <div className={item.className} key={index}></div>
                    {item.classText}
                  </>
                ))}
              </div>
            </>
          ) : (
            ''
          )}
        </div>
        <div className="graph_area">
          <div className="graph_sub_text">
            {explanation.map((item, index) => (
              <div className={field} key={index}>
                {item}
              </div>
            ))}
          </div>
          <Line width={1080} height={466} data={data} options={options} redraw />
        </div>
      </>
    );
  }
}

class RecommendPattern extends Component {
  render() {
    const { t, recommendPatternData } = this.props;

    function getRecommendPatternDataItem() {
      const wrongPositionItems = new Array(40).fill(<p className="mat_item"></p>);
      const arrayCount = [];
      const sortArray = [];
      try {
        for (let i = 39; i > 0; i -= 4) {
          for (let j = 3; j >= 0; j--) {
            wrongPositionItems[i + j - 3] = <p className="mat_item" key={'mate_item_' + i + j}></p>;
            arrayCount.push(i + j - 3);
            sortArray.push(39 + j - i);
          }
        }
        const repeat = recommendPatternData.stepCycle;
        const patterndArray = recommendPatternData.stepSequence.split(',');
        let firstIndex = '';
        let textArray = [];

        patterndArray.map(function (item, index) {
          let patternIndex = parseInt(item);
          let arrayIndex = 39 - sortArray.indexOf(patternIndex);
          let count = repeat ? (index % repeat) + 1 : index + 1;
          if (index === 0) {
            firstIndex = arrayIndex;
          }
          textArray[arrayIndex] = textArray[arrayIndex] ? textArray[arrayIndex] + '/' + count : count;

          wrongPositionItems[arrayIndex] = (
            <p className={'mat_item'} key={index}>
              {textArray[arrayIndex]}
            </p>
          );
        });

        wrongPositionItems[firstIndex] = (
          <div className={'mat_item started'} key="started">
            {wrongPositionItems[firstIndex].props.children}
          </div>
        );

        return wrongPositionItems;
      } catch (error) {
        return wrongPositionItems;
      }
    }
    return (
      <>
        <div className="card_header">
          <h3 className="title">
            {t('추천 패턴')}
            <span className="helper_text">
              (<sup className="ico asterisk"></sup>
              {t('시작 위치 색 : ')}
              <span className="text_red">{t('빨간색')}</span> )
            </span>
          </h3>
          <p className="last_date">
            <span>{t('마지막 추천일자')}</span>{' '}
            <span className="date">
              {recommendPatternData ? moment(recommendPatternData.reportTime || moment()).format('YYYY-MM-DD') : ''}
            </span>
          </p>
        </div>
        <div className="card_body">
          <div className="jquery_scrollbar">
            <ul className="mat_list">
              {recommendPatternData ? (
                <>
                  <li>
                    <div className="mat_header">
                      <p className="title">
                        {t(recommendPatternData.patternNm.split('-')[0]) +
                          '-' +
                          recommendPatternData.patternNm.split('-')[1]}
                      </p>
                    </div>
                    <div className="mat_body">{getRecommendPatternDataItem()}</div>
                    <div className="mat_footer">
                      <div className="coaching">
                        <p className="num">1</p>
                        <p className="text">{t(recommendPatternData.description)}</p>
                      </div>
                    </div>
                  </li>
                </>
              ) : (
                <div className="not_data">{t('현재 사용자는 데이터가 없습니다.')}</div>
              )}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

class WrongPosition extends Component {
  render() {
    const { t, item, patternList } = this.props;
    let patternData = [];
    let sortArray = [];
    if (item && item.exercise && patternList) {
      patternData = patternList.filter(e => e.patternId === item.exercise.patternId);
    }
    let isWrongPosition = '';
    if (item && item.exercise) {
      isWrongPosition = item.exercise.stepList.some(item => item.wrongYn === 'Y');
    }

    function getWrongPositionItems() {
      const wrongPositionItems = new Array(40).fill(<p className="mat_item"></p>);
      const arrayCount = [];
      for (let i = 39; i > 0; i -= 4) {
        for (let j = 3; j >= 0; j--) {
          wrongPositionItems[i + j - 3] = <p className="mat_item" key={'mate_item_' + i + j}></p>;
          //{40 + j - i}
          arrayCount.push(i + j - 3);
          sortArray.push(39 + j - i);
        }
      }

      if (!item.exercise || !patternData[0]) {
        return wrongPositionItems;
      }

      try {
        const stepList = item.exercise.stepList;

        const checkData = [];
        if (stepList.length > 0) {
          for (let i = 0; i < stepList.length; i++) {
            if (stepList[i].wrongYn === 'Y') {
              checkData.push(stepList[i].spaceNo);
            }
          }
        }

        const patterndArray = patternData[0].stepSequence.split(',');
        const repeat = patternData[0].stepCycle;
        const patternId = patternData[0].patternId;

        let firstIndex = '';
        const textArray = [];
        const checkTextArray = [];
        patterndArray.forEach((item, index) => {
          const patternIndex = parseInt(item);
          const arrayIndex = 39 - sortArray.indexOf(patternIndex);
          const count = repeat ? (index % repeat) + 1 : index + 1;
          if (index === 0) {
            firstIndex = arrayIndex;
          }

          if (![141, 142].includes(patternId)) {
            textArray[arrayIndex] = textArray[arrayIndex] ? (
              <>
                {textArray[arrayIndex]} / <p>{count}</p>
              </>
            ) : (
              <p>{count}</p>
            );
            checkTextArray[arrayIndex] = checkTextArray[arrayIndex] ? checkTextArray[arrayIndex] + '/' + count : count;
            wrongPositionItems[arrayIndex] = (
              <p className={'mat_item'} key={index}>
                {textArray[arrayIndex]}
              </p>
            );
            return;
          }

          // 패턴 141, 142는 두번째 사이클에 표시를 해주는 로직을 적용한다.
          if ([141, 142].includes(patternId)) {
            // 첫번째 사이클인 경우.
            if (index - repeat < 0) {
              textArray[arrayIndex] = textArray[arrayIndex] ? (
                <>
                  {textArray[arrayIndex]} / <p>{count}</p>
                </>
              ) : (
                <p>{count}</p>
              );
              checkTextArray[arrayIndex] = checkTextArray[arrayIndex] ? checkTextArray[arrayIndex] + '/' + count : count;
              wrongPositionItems[arrayIndex] = (
                <p className={'mat_item'} key={index}>
                  {textArray[arrayIndex]}
                </p>
              );
            }

            // 두번째 사이클인 경우.
            if (index - repeat >= 0) {
              if (!textArray[arrayIndex]) {
                textArray[arrayIndex] = <span className="text-danger">{count}</span>;
                checkTextArray[arrayIndex] = count;
                wrongPositionItems[arrayIndex] = (
                  <p className={'mat_item'} key={index}>
                    {textArray[arrayIndex]}
                  </p>
                );
                return;
              }

              wrongPositionItems[arrayIndex] = (
                <p className={'mat_item'} key={index}>
                  {textArray[arrayIndex]}/<span className="text-danger">{count}</span>
                </p>
              );
              textArray[arrayIndex] = (
                <>
                  {textArray[arrayIndex]}/<span className="text-danger">{count}</span>
                </>
              );
              checkTextArray[arrayIndex] = checkTextArray[arrayIndex] + '/' + count;
            }
          }
        });

        // 패턴에 포함되지 않은 격자판을 잘못눌렀을 경우 표시해주기 위한 로직.
        checkData.forEach(index => {
          const arrayIndex = 39 - sortArray.indexOf(index);

          if (!textArray[arrayIndex]) {
            textArray[arrayIndex] = <p className={'mat_item checked'}></p>;
          }

          wrongPositionItems[arrayIndex] = (
            <div className="mat_item" key={`non_pattern_${arrayIndex}`}>
              {textArray[arrayIndex]}
            </div>
          );
        });

        // 사용자가 틀린 위치를 격자판의 index로 변환.
        const convertedCheckData = checkData.map(data => {
          return 39 - sortArray.indexOf(data);
        });

        // 표시하는 숫자가 4개 이상인 경우 글자 크기를 작게 처리하면서 틀린 체크 표시를 하는 로직. e.g) 1/2/3/4
        checkTextArray.forEach((item, index) => {
          if (!item) {
            return;
          }

          const parts = item.toString().split('/');
          const isLargeText = parts.length >= 4;
          let pattenClassName = isLargeText ? 'mat_item_small' : 'mat_item';
          if (convertedCheckData.includes(index)) {
            pattenClassName = `${pattenClassName} checked`;
          }

          wrongPositionItems[index] = (
            <div className={index === firstIndex ? `mat_item started ${pattenClassName}` : pattenClassName} key={index}>
              {wrongPositionItems[index].props.children}
            </div>
          );
        });

        return wrongPositionItems;
      } catch (error) {
        console.error(error);
        return wrongPositionItems;
      }
    }
    return (
      <>
        {item && patternData[0] ? (
          <>
            <div className="mat_header">
              <p className="date">{item.exerciseDate && moment(item.exerciseDate).format('YYYY-MM-DD')}</p>
              <p className="title">
                {t(patternData[0].patternNm.split('-')[0]) + '-' + patternData[0].patternNm.split('-')[1]}
              </p>
            </div>
            <div className="mat_body">{getWrongPositionItems()}</div>
            <div className="mat_footer">
              <div className="coaching">
                <p className="num">1</p>
                <p className="text">{t(patternData[0].description)}</p>
              </div>
              {!isWrongPosition && <p className="result">{t('틀린 위치가 없습니다.')}</p>}
            </div>
          </>
        ) : (
          <div className="not_data">{t('현재 사용자는 데이터가 없습니다.')}</div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { member, pattern, diagnosis, range } = state;
  return {
    patternListData: pattern.patternListData,
    recommendPatternData: pattern.recommendPattern,
    classesOfMemberData: member.classesOfMemberData,
    memberExerciseSummaryData: member.memberExerciseSummaryData,
    memberExerciseSummaryHistoryListData: member.memberExerciseSummaryHistoryListData,
    rangeClassesAiList: [
      '', // range.rangeClassesAiLrbiasData,
      '', // range.rangeClassesAiMomentumData,
      range.rangeClassesAwarenessData,
      range.rangeClassesReactionData,
      range.rangeClassesMemoryData,
    ],
    diagnosisClassAiList: [
      diagnosis.diagnosisClassAiLrbiasData,
      diagnosis.diagnosisClassAiMomentumData,
      diagnosis.diagnosisClassAwarenessData,
      diagnosis.diagnosisClassReactionData,
      diagnosis.diagnosisClassMemoryData,
    ],
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPatternList,
      fetchClassesOfMember,
      fetchMemberExerciseSummary,
      fetchMemberExerciseSummaryHistoryList,
      fetchClassAiLrbiasDiagnosisList,
      fetchClassAiMomentumDiagnosisList,
      fetchClassAiAwarenessDiagnosisList,
      fetchClassAiReactionDiagnosisList,
      fetchClassAiMemoryDiagnosisList,
      // fetchClassAiLrbiasRangeList,
      // fetchClassAiMomentumRangeList,
      fetchClassAiAwarenessRangeList,
      fetchClassAiReactionRangeList,
      fetchClassAiMemoryRangeList,
      fetchRecommendPattern,
      dispatch,
    },
    dispatch,
  );

const connected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MemberDetail));

export { connected as MemberDetail };
