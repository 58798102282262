import { requestUrl, requestHeaders, requestParam, requestBody, handleResponse } from '_helpers';
import {
  fetchBoardListRequest,
  fetchBoardListSuccess,
  fetchBoardListError,
  addBoardRequest,
  addBoardSuccess,
  addBoardError,
  modifyBoardRequest,
  modifyBoardSuccess,
  modifyBoardError,
  removeBoardRequest,
  removeBoardSuccess,
  removeBoardError,
} from '_actions';

// 공지사항 목록조회
export function fetchBoardList(boardType, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(
      `${process.env.REACT_APP_APIURL}/api/boards/${
        boardType === 'S' ? 'system' : boardType === 'T' ? 'tenant' : 'class'
      }?${requestParam(params)}`,
    );
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchBoardListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response);
        dispatch(fetchBoardListSuccess(boardType, response));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchBoardListError(error));
      });
  };
}
// 공지사항 등록
export function addBoard(boardType, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/boards/${boardType === 'S' ? 'system' : 'tenant'}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(addBoardRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(addBoardSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(addBoardError(error));
      });
  };
}
// 공지사항 수정
export function modifyBoard(boardId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/boards/${boardId}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'PUT',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(modifyBoardRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(modifyBoardSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(modifyBoardError(error));
      });
  };
}
// 공지사항 삭제
export function removeBoard(boardId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/boards/${boardId}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(removeBoardRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(removeBoardSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(removeBoardError(error));
      });
  };
}
