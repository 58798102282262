/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import { commonUtils, chartColors } from '_helpers';
import { openAlertDialog } from '_actions';
import { DateRange, Pagination, DurationPagination } from '_components';
import { codeConstant } from '_constants';
import {
  fetchMemberHealthRecordFitnessList,
  fetchMemberHealthRecordFitnessAgegroupAvg,
  fetchMemberHealthRecordFitnessAgegroupRank,
  fetchMemberHealthRecordLongevityList,
  fetchMemberHealthRecordLongevityAgegroupAvg,
  fetchMemberHealthRecordLongevityAgegroupRank,
  fetchMemberHealthRecordHappinessList,
  fetchMemberHealthRecordHappinessAgegroupAvg,
  fetchMemberHealthRecordHappinessAgegroupRank,
  fetchMemberHealthRecordBPIList,
  fetchMemberHealthRecordBPIAgegroupAvg,
  fetchMemberHealthRecordBPIAgegroupRank,
  fetchMemberHealthRecordMentalList,
  fetchMemberHealthRecordMentalAgegroupAvg,
  fetchMemberHealthRecordMentalAgegroupRank,
  addMemberHealthRecordFitness,
  modifyMemberHealthRecordFitness,
  removeMemberHealthRecordFitness,
  addMemberHealthRecordLongevity,
  modifyMemberHealthRecordLongevity,
  removeMemberHealthRecordLongevity,
  addMemberHealthRecordHappiness,
  modifyMemberHealthRecordHappiness,
  removeMemberHealthRecordHappiness,
  addMemberHealthRecordBPI,
  modifyMemberHealthRecordBPI,
  removeMemberHealthRecordBPI,
  addMemberHealthRecordMental,
  modifyMemberHealthRecordMental,
  removeMemberHealthRecordMental,
} from '_services';

class HealthContentsView extends Component {
  constructor(props) {
    super(props);

    const now = moment();
    this.state = {
      startDate: moment(now).add(-3, 'month').format('YYYY-MM-DD'),
      endDate: moment(now).format('YYYY-MM-DD'),
      pageSize: 10,
      pageNo: 1,
      isEditMode: false,
      formData: [],
      healthDataFitness: codeConstant.healthDataFitness,
      healthDataLongevity: codeConstant.healthDataLongevity,
      healthDataBPI: codeConstant.healthDataBPI,
      healthDataMental: codeConstant.healthDataMental,
      healthDataHappiness: codeConstant.healthDataHappiness,
    };

    this._handleChangeStartDate = this._handleChangeStartDate.bind(this);
    this._handleChangeEndDate = this._handleChangeEndDate.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handlePageMove = this._handlePageMove.bind(this);
    this._handleCheckHealthData = this._handleCheckHealthData.bind(this);
    this._handleEdit = this._handleEdit.bind(this);
    this._handleDelete = this._handleDelete.bind(this);
    this._handleModify = this._handleModify.bind(this);
  }
  componentDidMount() {
    window.$('.jquery_scrollbar').scrollbar();
    this._loadData();
  }
  componentDidUpdate(prevProps) {
    const curData = this._getMemberHealthRecordListData(this.props);
    const prevData = this._getMemberHealthRecordListData(prevProps);
    if (curData && curData !== prevData) {
      let formData = [];
      curData.data.forEach(item => {
        formData.push(Object.assign({}, item));
      });
      this.setState({
        ...this.state,
        formData,
      });
    }
  }
  _loadData() {
    const { startDate, endDate, pageNo } = this.state;
    this._loadMemberHealthRecordListData(startDate, endDate, pageNo);
  }
  _loadMemberHealthRecordListData(startDate, endDate, pageNo) {
    const { memberData, healthType, healthCallback } = this.props;
    const { pageSize } = this.state;
    const params = { startDate, endDate, pageNo, pageSize };
    healthCallback(params);
    if (healthType === 'Fitness') {
      this.props.fetchMemberHealthRecordFitnessList(memberData.userId, params);
      this.props.fetchMemberHealthRecordFitnessAgegroupAvg(memberData.userId);
      this.props.fetchMemberHealthRecordFitnessAgegroupRank(memberData.userId);
    } else if (healthType === 'Longevity') {
      this.props.fetchMemberHealthRecordLongevityList(memberData.userId, params);
      this.props.fetchMemberHealthRecordLongevityAgegroupAvg(memberData.userId);
      this.props.fetchMemberHealthRecordLongevityAgegroupRank(memberData.userId);
    } else if (healthType === 'Happiness') {
      this.props.fetchMemberHealthRecordHappinessList(memberData.userId, params);
      this.props.fetchMemberHealthRecordHappinessAgegroupAvg(memberData.userId);
      this.props.fetchMemberHealthRecordHappinessAgegroupRank(memberData.userId);
    } else if (healthType === 'BPI') {
      this.props.fetchMemberHealthRecordBPIList(memberData.userId, params);
      this.props.fetchMemberHealthRecordBPIAgegroupAvg(memberData.userId);
      this.props.fetchMemberHealthRecordBPIAgegroupRank(memberData.userId);
    } else if (healthType === 'Mental') {
      this.props.fetchMemberHealthRecordMentalList(memberData.userId, params);
      this.props.fetchMemberHealthRecordMentalAgegroupAvg(memberData.userId);
      this.props.fetchMemberHealthRecordMentalAgegroupRank(memberData.userId);
    }
  }
  _handleChangeStartDate(date) {
    const { endDate } = this.state;
    this.setState({
      ...this.state,
      startDate: date,
      pageNo: 1,
    });
    this._loadMemberHealthRecordListData(date, endDate, 1);
  }
  _handleChangeEndDate(date) {
    const { startDate } = this.state;
    this.setState({
      ...this.state,
      endDate: date,
      pageNo: 1,
    });
    this._loadMemberHealthRecordListData(startDate, date, 1);
  }
  _handleChange(e) {
    const { id, value } = e.target;
    let { formData } = this.state;

    const splitArr = id.split(':');
    formData[splitArr[0]][splitArr[1]] = value;
    formData[splitArr[0]].isChanged = true;
    this.setState({ ...this.state, formData });
  }
  _handlePageMove(pageNo) {
    const { startDate, endDate } = this.state;
    this.setState({
      ...this.state,
      pageNo: pageNo,
    });
    this._loadMemberHealthRecordListData(startDate, endDate, pageNo);
  }
  _handleCheckHealthData(e) {
    const { healthType } = this.props;
    const healthData = this.state['healthData' + healthType];
    const id = e.target.id;
    const checked = e.target.checked;

    if (checked) {
      const checkedDatas = healthData.filter(e => e.checked === true);
      if (checkedDatas.length >= 4) {
        return;
      }
    }
    let index = healthData.findIndex(e => e.id === id);
    healthData[index].checked = checked;

    this.setState({
      ...this.state,
      ['healthData' + healthType]: healthData,
    });
  }
  _handleEdit() {
    this.setState({
      ...this.state,
      isEditMode: true,
    });
  }
  _handleDelete(recordDate) {
    const { t } = this.props;
    this.props.dispatch(
      openAlertDialog(
        t("해당 날짜의 데이터는<br/><span class='text-primary'>모두 삭제</span>됩니다.<br/>그래도 삭제하시겠습니까?"),
        'alert',
        true,
        t('확인'),
        t('취소'),
        () => {
          const { formData } = this.state;
          formData.find(e => e.recordDate === recordDate).deleted = true;
          this.setState({ ...this.state, formData });
        },
      ),
    );
  }
  _handleModify() {
    const { t } = this.props;
    const { pageNo } = this.state;
    if (this._checkChanged()) {
      this.props.dispatch(
        openAlertDialog(
          t('입력된 항목을 저장하시겠습니까?'),
          'alert',
          true,
          t('확인'),
          t('취소'),
          () => {
            this._saveData();
          },
          () => {
            this._reload(pageNo);
          },
        ),
      );
    } else {
      this._reload(pageNo);
    }
  }
  _checkChanged() {
    const { formData } = this.state;
    if (formData.find(e => e.deleted === true)) {
      return true;
    }
    if (formData.find(e => e.isChanged === true)) {
      return true;
    }
    return false;
  }
  _saveData() {
    const { t } = this.props;
    const { data } = this.props.location.state;
    const { healthType } = this.props;
    const { formData } = this.state;
    const modifyData = formData.filter(e => e.deleted !== true && e.isChanged === true);
    const modifyParams = {};
    modifyParams['userHealth' + healthType + 'List'] = modifyData;
    const modifyFunction = this._getModifyFunction();
    modifyFunction(data.userId, modifyParams, error => {
      if (!error) {
        const removeData = formData.filter(e => e.deleted === true);
        if (removeData.length > 0) {
          this._removeData();
        } else {
          this.props.dispatch(
            openAlertDialog(t('저장되었습니다.'), 'success', false, null, null, () => {
              this._reload(1);
            }),
          );
        }
      }
    });
  }
  _removeData() {
    const { formData } = this.state;
    const removeData = formData.filter(e => e.deleted === true);
    this._removeHealth(removeData, 0);
  }
  _removeHealth(removeData, removeIndex) {
    const { t } = this.props;
    const { data } = this.props.location.state;
    const removeFunction = this._getRemoveFunction();
    removeFunction(data.userId, removeData[removeIndex].recordDate, error => {
      if (!error) {
        if (removeData.length === removeIndex + 1) {
          this.props.dispatch(
            openAlertDialog(t('저장되었습니다.'), 'success', false, null, null, () => {
              this._reload(1);
            }),
          );
        } else {
          this._removeHealth(removeData, ++removeIndex);
        }
      }
    });
  }
  _reload(pageNo) {
    const { startDate, endDate } = this.state;
    this._loadMemberHealthRecordListData(startDate, endDate, pageNo);
    this.setState({
      ...this.state,
      isEditMode: false,
    });
  }
  _getModifyFunction() {
    const { healthType } = this.props;
    if (healthType === 'Fitness') {
      return this.props.modifyMemberHealthRecordFitness;
    } else if (healthType === 'Longevity') {
      return this.props.modifyMemberHealthRecordLongevity;
    } else if (healthType === 'Happiness') {
      return this.props.modifyMemberHealthRecordHappiness;
    } else if (healthType === 'BPI') {
      return this.props.modifyMemberHealthRecordBPI;
    } else if (healthType === 'Mental') {
      return this.props.modifyMemberHealthRecordMental;
    }
  }
  _getRemoveFunction() {
    const { healthType } = this.props;
    if (healthType === 'Fitness') {
      return this.props.removeMemberHealthRecordFitness;
    } else if (healthType === 'Longevity') {
      return this.props.removeMemberHealthRecordLongevity;
    } else if (healthType === 'Happiness') {
      return this.props.removeMemberHealthRecordHappiness;
    } else if (healthType === 'BPI') {
      return this.props.removeMemberHealthRecordBPI;
    } else if (healthType === 'Mental') {
      return this.props.removeMemberHealthRecordMental;
    }
  }
  _getMemberHealthRecordListData(props) {
    const {
      healthType,
      memberHealthRecordFitnessListData,
      memberHealthRecordLongevityListData,
      memberHealthRecordHappinessListData,
      memberHealthRecordBPIListData,
      memberHealthRecordMentalListData,
    } = props;
    if (healthType === 'Fitness') {
      return memberHealthRecordFitnessListData;
    } else if (healthType === 'Longevity') {
      return memberHealthRecordLongevityListData;
    } else if (healthType === 'Happiness') {
      return memberHealthRecordHappinessListData;
    } else if (healthType === 'BPI') {
      return memberHealthRecordBPIListData;
    } else if (healthType === 'Mental') {
      return memberHealthRecordMentalListData;
    }
  }
  _getMemberHealthRecordAgegroupAvgData() {
    const {
      healthType,
      memberHealthRecordFitnessAgegroupAvgData,
      memberHealthRecordLongevityAgegroupAvgData,
      memberHealthRecordHappinessAgegroupAvgData,
      memberHealthRecordBPIAgegroupAvgData,
      memberHealthRecordMentalAgegroupAvgData,
    } = this.props;
    if (healthType === 'Fitness') {
      return memberHealthRecordFitnessAgegroupAvgData;
    } else if (healthType === 'Longevity') {
      return memberHealthRecordLongevityAgegroupAvgData;
    } else if (healthType === 'Happiness') {
      return memberHealthRecordHappinessAgegroupAvgData;
    } else if (healthType === 'BPI') {
      return memberHealthRecordBPIAgegroupAvgData;
    } else if (healthType === 'Mental') {
      return memberHealthRecordMentalAgegroupAvgData;
    }
  }
  _getMemberHealthRecordAgegroupRankData() {
    const {
      healthType,
      memberHealthRecordFitnessAgegroupRankData,
      memberHealthRecordLongevityAgegroupRankData,
      memberHealthRecordHappinessAgegroupRankData,
      memberHealthRecordBPIAgegroupRankData,
      memberHealthRecordMentalAgegroupRankData,
    } = this.props;
    if (healthType === 'Fitness') {
      return memberHealthRecordFitnessAgegroupRankData;
    } else if (healthType === 'Longevity') {
      return memberHealthRecordLongevityAgegroupRankData;
    } else if (healthType === 'Happiness') {
      return memberHealthRecordHappinessAgegroupRankData;
    } else if (healthType === 'BPI') {
      return memberHealthRecordBPIAgegroupRankData;
    } else if (healthType === 'Mental') {
      return memberHealthRecordMentalAgegroupRankData;
    }
  }
  render() {
    const { t } = this.props;
    const { data } = this.props.location.state;
    const { healthType, healthTypeLabel } = this.props;
    const { startDate, endDate, pageSize, pageNo, isEditMode, formData } = this.state;
    let memberHealthRecordListData = this._getMemberHealthRecordListData(this.props);
    let memberHealthRecordAgegroupAvgData = this._getMemberHealthRecordAgegroupAvgData();
    let memberHealthRecordAgegroupRankData = this._getMemberHealthRecordAgegroupRankData();
    const healthData = this.state['healthData' + healthType];
    const pagination = memberHealthRecordListData ? memberHealthRecordListData.pagination : null;
    const dateList = [];
    if (memberHealthRecordListData) {
      memberHealthRecordListData.data.forEach(item => {
        dateList.push(moment(item.recordDate).format('YYYY-MM-DD'));
      });
    }
    return (
      <>
        <div className="contents_tool">
          <div className="main">
            <div className="btn_area">
              <button type="button" className="btn btn-link">
                <span className="ico refresh"></span>
                <span>{t('전체해제')}</span>
              </button>
              <p className="helper_text">{t('최대 4개까지 선택 가능합니다.')}</p>
            </div>
            <div className="date_range">
              <h4 className="title">{t('기간 설정')}</h4>
              <DateRange
                className="date start"
                minYear={2020}
                maxYear={2040}
                date={startDate}
                onChange={this._handleChangeStartDate}
              />
              <DateRange
                className="date end"
                minYear={2020}
                maxYear={2040}
                date={endDate}
                onChange={this._handleChangeEndDate}
              />
            </div>
          </div>
          <ul className="check_list">
            {healthData.map((item, index) => (
              <li key={index}>
                <div className="form-check input_btn">
                  <input
                    type="checkbox"
                    id={item.id}
                    className="form-check-input"
                    value={item.id}
                    checked={item.checked}
                    onChange={this._handleCheckHealthData}
                  />
                  <label htmlFor={item.id} className="form-check-label">
                    {t(item.label)}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="graph_wrapper">
          <DurationPagination
            dateList={dateList}
            totalCount={pagination ? pagination.totalCount : 0}
            rowCount={pagination ? pagination.pageSize : pageSize}
            page={pagination ? pagination.pageNo : pageNo}
            initStartDate={startDate}
            initEndDate={endDate}
            onPageMove={this._handlePageMove}
          />
          <div className="graph_list">
            {healthData
              .filter(e => e.checked === true)
              .map((item, index) => (
                <div key={index} className="graph_item">
                  <h4 className="title">
                    {t(item.label)}
                    <span className="unit">{item.unit === '' ? '' : '(' + t(item.unit) + ')'}</span>
                  </h4>
                  <div className="graph">
                    <HlDataChart
                      t={t}
                      data={memberHealthRecordListData}
                      agegroupAvgData={memberHealthRecordAgegroupAvgData}
                      agegroupRankData={memberHealthRecordAgegroupRankData}
                      label={t(item.label)}
                      field={item.id}
                      age={data.age}
                      unit={t(item.unit)}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <table
          className={`table ${
            !memberHealthRecordListData || memberHealthRecordListData.data.length === 0
              ? 'none_data'
              : isEditMode
              ? 'edit'
              : ''
          }`}
        >
          <caption className="visually-hidden">테이블</caption>
          <colgroup>
            <col style={{ width: '210px' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>{healthTypeLabel}</th>
              {formData
                .filter(e => e.deleted !== true)
                .map((item, index) => (
                  <td key={index}>
                    <p className="d-flex">
                      <span>{item.recordDate}</span>
                      <button type="button" className="btn" onClick={() => this._handleDelete(item.recordDate)}>
                        <span className="ico delete"></span>
                      </button>
                    </p>
                  </td>
                ))}
            </tr>
            {healthData.map((dataItem, dataIndex) => (
              <tr key={dataIndex}>
                <th>
                  {t(dataItem.label)}
                  {dataItem.unit === '' ? '' : '(' + t(dataItem.unit) + ')'}
                </th>
                {formData.filter(e => e.deleted !== true).length === 0 && (
                  <td>
                    <div className="d-flex">
                      <input type="number" className="form-control" value="" readOnly />
                    </div>
                  </td>
                )}
                {formData
                  .filter(e => e.deleted !== true)
                  .map((item, index) => (
                    <td key={index}>
                      <div className="d-flex">
                        {dataItem.id === 'hyperlipidemiaYn' && (
                          <select
                            id={index + ':' + dataItem.id}
                            className="form-control"
                            value={item[dataItem.id] ? item[dataItem.id] : ''}
                            disabled={!isEditMode}
                            onChange={this._handleChange}
                          >
                            <option value=""></option>
                            <option value="Y">{t('유')}</option>
                            <option value="N">{t('무')}</option>
                          </select>
                        )}
                        {dataItem.id !== 'hyperlipidemiaYn' && (
                          <input
                            id={index + ':' + dataItem.id}
                            type="number"
                            className="form-control"
                            value={item[dataItem.id] ? item[dataItem.id] : ''}
                            readOnly={!isEditMode}
                            onChange={this._handleChange}
                          />
                        )}
                      </div>
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          totalCount={pagination ? pagination.totalCount : 0}
          rowCount={pagination ? pagination.pageSize : pageSize}
          page={pagination ? pagination.pageNo : pageNo}
          onPageMove={this._handlePageMove}
        />
        <div className="btn_area">
          {isEditMode && (
            <button type="button" className="btn btn-outline-secondary active" onClick={this._handleModify}>
              {t('편집완료')}
            </button>
          )}
          {!isEditMode && (
            <button type="button" className="btn btn-outline-secondary" onClick={this._handleEdit}>
              {t('편집하기')}
            </button>
          )}
        </div>
      </>
    );
  }
}
class HlDataChart extends Component {
  render() {
    const { t } = this.props;
    const lang = commonUtils.getBrowserLanguage();
    const { agegroupAvgData, agegroupRankData, field, label, age, unit } = this.props;
    const dataList = this.props.data && this.props.data.data.length > 0 ? this.props.data.data : [];
    const labels = [];
    const myData = [];
    const myAvgData = [];
    const ageAvgData = [];
    let mySumData = 0;
    let minLabel = '';
    let maxLabel = '';

    if (field === 'hyperlipidemiaYn') {
      dataList.forEach((item, index) => {
        labels[index] = moment(item.recordDate).format('MM.DD');
        myData[index] = item[field] === 'Y' ? 1 : 0;
      });
    } else {
      dataList.forEach((item, index) => {
        mySumData += item[field];
      });
      mySumData = dataList.length === 0 ? 0 : mySumData / dataList.length;
      dataList.forEach((item, index) => {
        labels[index] = moment(item.recordDate).format('MM.DD');
        if (index === 0) {
          minLabel = moment(item.recordDate).format('MM.DD');
        }
        if (index === dataList.length - 1) {
          maxLabel = moment(item.recordDate).format('MM.DD');
        }
        myData[index] = item[field];
        myAvgData[index] = mySumData.toFixed();
        ageAvgData[index] = agegroupAvgData && agegroupAvgData[field] ? agegroupAvgData[field].toFixed() : 0;
      });
      if (dataList.length > 0) {
        labels.splice(0, 0, 'dummy-first');
        labels.push('dummy-last');
        myData.splice(0, 0, 0);
        myData.push(0);
        myAvgData.splice(0, 0, mySumData.toFixed());
        myAvgData.push(mySumData.toFixed());
        ageAvgData.splice(0, 0, agegroupAvgData && agegroupAvgData[field] ? agegroupAvgData[field].toFixed() : 0);
        ageAvgData.push(agegroupAvgData && agegroupAvgData[field] ? agegroupAvgData[field].toFixed() : 0);
      }
    }
    const myAge = Math.floor((age || 0) / 10) * 10;
    const data = {
      labels,
      datasets: [
        {
          label:
            lang === 'ko'
              ? `${myAge}${t('대 상위')} ${agegroupRankData ? agegroupRankData[field] || 0 : ''}%`
              : `${t('대 상위')} ${agegroupRankData ? agegroupRankData[field] || 0 : ''}%`,
          data: myData,
          backgroundColor: chartColors.border,
          hoverBackgroundColor: chartColors.indigo,
          barThickness: 20,
          order: 3,
        },
        {
          label: myAvgData.length > 0 ? `${t('나의 평균')} ${myAvgData[0]}${unit}` : t('나의 평균'),
          data: myAvgData,
          text: unit,
          type: 'line',
          fill: false,
          borderColor: chartColors.black,
          order: 2,
        },
        {
          label:
            (lang === 'ko' ? `${myAge}${t('대 평균')} ` : `${t('대 평균')} ${myAge}'s `) +
            (ageAvgData.length > 0 ? `${ageAvgData[0]}${unit}` : ''),
          data: ageAvgData,
          type: 'line',
          fill: false,
          borderColor: chartColors.gray,
          pointStyle: 'line',
          order: 1,
        },
      ],
    };
    var options = {
      legend: {
        display: true,
      },
      tooltips: {
        mode: 'nearest',
        intersect: true,
        callbacks: {
          label: function (tooltipItem, data) {
            if (!tooltipItem || (data && data.datasets.length === 0)) {
              return '';
            }
            return label + ': ' + tooltipItem.value + data.datasets[1].text;
          },
        },
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              min: minLabel,
              max: maxLabel,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              autoSkip: true,
            },
          },
        ],
      },
    };
    if (field === 'hyperlipidemiaYn') {
      data.datasets.splice(1, 2);
      options.scales.yAxes[0].ticks.stepSize = 1;
      options.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return value === 1 ? '유' : '무';
      };
    }

    return (
      <div className="graph_area">
        <Bar data={data} options={options} redraw />
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { member } = state;
  return {
    memberHealthRecordFitnessListData: member.memberHealthRecordFitnessListData,
    memberHealthRecordFitnessAgegroupAvgData: member.memberHealthRecordFitnessAgegroupAvgData,
    memberHealthRecordFitnessAgegroupRankData: member.memberHealthRecordFitnessAgegroupRankData,
    memberHealthRecordLongevityListData: member.memberHealthRecordLongevityListData,
    memberHealthRecordLongevityAgegroupAvgData: member.memberHealthRecordLongevityAgegroupAvgData,
    memberHealthRecordLongevityAgegroupRankData: member.memberHealthRecordLongevityAgegroupRankData,
    memberHealthRecordHappinessListData: member.memberHealthRecordHappinessListData,
    memberHealthRecordHappinessAgegroupAvgData: member.memberHealthRecordHappinessAgegroupAvgData,
    memberHealthRecordHappinessAgegroupRankData: member.memberHealthRecordHappinessAgegroupRankData,
    memberHealthRecordBPIListData: member.memberHealthRecordBPIListData,
    memberHealthRecordBPIAgegroupAvgData: member.memberHealthRecordBPIAgegroupAvgData,
    memberHealthRecordBPIAgegroupRankData: member.memberHealthRecordBPIAgegroupRankData,
    memberHealthRecordMentalListData: member.memberHealthRecordMentalListData,
    memberHealthRecordMentalAgegroupAvgData: member.memberHealthRecordMentalAgegroupAvgData,
    memberHealthRecordMentalAgegroupRankData: member.memberHealthRecordMentalAgegroupRankData,
  };
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchMemberHealthRecordFitnessList,
      fetchMemberHealthRecordFitnessAgegroupAvg,
      fetchMemberHealthRecordFitnessAgegroupRank,
      fetchMemberHealthRecordLongevityList,
      fetchMemberHealthRecordLongevityAgegroupAvg,
      fetchMemberHealthRecordLongevityAgegroupRank,
      fetchMemberHealthRecordHappinessList,
      fetchMemberHealthRecordHappinessAgegroupAvg,
      fetchMemberHealthRecordHappinessAgegroupRank,
      fetchMemberHealthRecordBPIList,
      fetchMemberHealthRecordBPIAgegroupAvg,
      fetchMemberHealthRecordBPIAgegroupRank,
      fetchMemberHealthRecordMentalList,
      fetchMemberHealthRecordMentalAgegroupAvg,
      fetchMemberHealthRecordMentalAgegroupRank,
      addMemberHealthRecordFitness,
      modifyMemberHealthRecordFitness,
      removeMemberHealthRecordFitness,
      addMemberHealthRecordLongevity,
      modifyMemberHealthRecordLongevity,
      removeMemberHealthRecordLongevity,
      addMemberHealthRecordHappiness,
      modifyMemberHealthRecordHappiness,
      removeMemberHealthRecordHappiness,
      addMemberHealthRecordBPI,
      modifyMemberHealthRecordBPI,
      removeMemberHealthRecordBPI,
      addMemberHealthRecordMental,
      modifyMemberHealthRecordMental,
      removeMemberHealthRecordMental,
      dispatch,
    },
    dispatch,
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HealthContentsView));
export { connected as HealthContentsView };
