/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Header, SideBar, Pagination } from '_components';
import { openAlertDialog } from '_actions';
import { fetchTrainerList, removeTrainer } from '_services';
import { pathConstant } from '_constants';

class TrainerMgt extends Component {
  constructor(props) {
    super(props);

    this.state = { keyword: '', pageSize: 10, pageNo: 1, isCheckedAll: false, checkList: [] };

    this._handleChange = this._handleChange.bind(this);
    this._handleSearch = this._handleSearch.bind(this);
    this._handlePageMove = this._handlePageMove.bind(this);
    this._handleCheckItem = this._handleCheckItem.bind(this);
    this._handleCheckAll = this._handleCheckAll.bind(this);
    this._handleModify = this._handleModify.bind(this);
    this._handleAdd = this._handleAdd.bind(this);
    this._handleRemove = this._handleRemove.bind(this);
  }
  componentDidMount() {
    window.$('.jquery_scrollbar').scrollbar();
    const { pageNo } = this.state;
    this._loadData(pageNo);
  }
  static getDerivedStateFromProps(props, state) {
    const listData = props.trainerListData;
    if (
      listData &&
      listData.pagination &&
      (listData.pagination.pageNo !== state.pageNo || listData.data.length !== state.checkList.length)
    ) {
      let checkList = [];
      listData.data.forEach(() => {
        checkList.push(false);
      });
      return {
        pageNo: listData.pagination.pageNo,
        checkList,
      };
    }
    return null;
  }
  _loadData(pageNo) {
    const { pageSize, keyword } = this.state;
    const params = { pageNo, pageSize, keyword };
    this.props.fetchTrainerList(params);
  }
  _handleChange(e) {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value });
  }
  _handleSearch(e) {
    e.preventDefault();
    const { pageNo } = this.state;
    this._loadData(pageNo);
  }
  _handlePageMove(pageNo) {
    this._loadData(pageNo);
  }
  _handleCheckItem(e) {
    const index = e.target.value;
    const { checkList } = this.state;

    checkList[index] = e.target.checked;
    const isCheckedAll = checkList.length !== 0 && checkList.filter(item => item === false).length === 0 ? true : false;

    this.setState({
      ...this.state,
      isCheckedAll,
      checkList,
    });
  }
  _handleCheckAll(e) {
    const { checkList } = this.state;
    let newCheckList = [];

    checkList.forEach(() => {
      newCheckList.push(e.target.checked);
    });
    this.setState({
      ...this.state,
      isCheckedAll: e.target.checked,
      checkList: newCheckList,
    });
  }
  _handleModify(item) {
    this.props.history.push({ pathname: pathConstant.PATH_TRAINER_EDIT, state: { data: item } });
  }
  _handleAdd() {
    this.props.history.push({ pathname: pathConstant.PATH_TRAINER_EDIT, state: { data: null } });
  }
  _handleRemove() {
    const { t } = this.props;
    this.props.dispatch(
      openAlertDialog(t('정말로 삭제하시겠습니까?'), 'alert', true, null, null, () => {
        const { checkList } = this.state;
        const { trainerListData } = this.props;
        let removeList = [];
        checkList.forEach((item, index) => {
          if (item) {
            removeList.push(trainerListData.data[index]);
          }
        });
        this._removeTrainer(removeList, 0);
      }),
    );
  }
  _removeTrainer(removeList, removeIndex) {
    const { t } = this.props;
    this.props.removeTrainer(removeList[removeIndex].userId, error => {
      if (!error) {
        if (removeList.length === removeIndex + 1) {
          this.props.dispatch(
            openAlertDialog(t('삭제되었습니다.'), 'success', false, null, null, () => {
              window.location.reload();
            }),
          );
        } else {
          this._removeTrainer(removeList, ++removeIndex);
        }
      }
    });
  }
  render() {
    const { t } = this.props;
    document.title = `${t('트레이너 관리')} | ${t('모스텝')}`;
    const breadcrumb = [t('트레이너 관리')];
    const { keyword, pageSize, pageNo, isCheckedAll, checkList } = this.state;
    const { trainerListData } = this.props;
    const pagination = trainerListData ? trainerListData.pagination : null;
    return (
      <>
        <Header {...this.props} breadcrumb={breadcrumb} />
        <SideBar {...this.props} />

        <main className="contents_wrapper">
          <div className="jquery_scrollbar">
            <div className="contents">
              <div className="contents_body card">
                <div className="card_header">
                  <div className="contents_tool">
                    <form className="search_bar" onSubmit={this._handleSearch}>
                      <input
                        id="keyword"
                        className="form-control"
                        type="text"
                        placeholder={t('트레이너 이름이나 아이디를 입력해 검색하세요.')}
                        value={keyword}
                        onChange={this._handleChange}
                      />
                      <button type="submit" className="btn btn-outline-primary">
                        {t('검색')}
                      </button>
                    </form>
                  </div>
                  <div className="btn_area">
                    <button
                      type="button"
                      className="btn btn-outline-danger active"
                      disabled={!checkList.find(e => e === true)}
                      onClick={this._handleRemove}
                    >
                      {t('선택 삭제')}
                    </button>
                    <button type="button" className="btn btn-outline-secondary" onClick={this._handleAdd}>
                      <span className="ico add_user"></span>
                      <span>{t('신규')}</span>
                    </button>
                  </div>
                </div>
                <div className="card_body">
                  <table className={`table ${!trainerListData || trainerListData.data.length === 0 ? 'none_data' : ''}`}>
                    <caption className="visually-hidden">테이블</caption>
                    <thead>
                      <tr>
                        <th scope="col">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              id=""
                              className="form-check-input"
                              checked={isCheckedAll}
                              onChange={this._handleCheckAll}
                            />
                          </div>
                        </th>
                        <th scope="col">
                          <p className="d-flex">
                            <span>{t('이름')}</span>
                            {/* <span className="ico descending"></span> */}
                          </p>
                        </th>
                        <th scope="col">
                          <p className="d-flex">
                            <span>{t('아이디')}</span>
                            {/* <span className="ico descending"></span> */}
                          </p>
                        </th>
                        <th scope="col">
                          <p className="d-flex">
                            <span>{t('연락처')}</span>
                            {/* <span className="ico descending"></span> */}
                          </p>
                        </th>
                        <th scope="col">
                          <p className="d-flex">
                            <span>{t('이메일')}</span>
                            {/* <span className="ico descending"></span> */}
                          </p>
                        </th>
                        <th scope="col">
                          <p className="d-flex">
                            <span>{t('클래스')}</span>
                            {/* <span className="ico descending"></span> */}
                          </p>
                        </th>
                        <th scope="col">
                          <p className="d-flex">
                            <span>{t('등록일')}</span>
                            {/* <span className="ico descending"></span> */}
                          </p>
                        </th>
                        <th scope="col">
                          <span>{t('트레이너 수정')}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(!trainerListData || trainerListData.data.length === 0) && (
                        <tr>
                          <td colSpan="8">
                            <p>{t('트레이너가 없습니다.')}</p>
                          </td>
                        </tr>
                      )}
                      {trainerListData &&
                        trainerListData.data.map((item, index) => (
                          <tr key={index} className={checkList[index] ? 'checked' : ''}>
                            <td>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  id=""
                                  className="form-check-input"
                                  value={index}
                                  checked={checkList[index]}
                                  onChange={this._handleCheckItem}
                                />
                              </div>
                            </td>
                            <td>{item.userNm}</td>
                            <td>{item.loginId}</td>
                            <td>{item.tel}</td>
                            <td>{item.email}</td>
                            <td>
                              {item.classNm ? item.classNm + ' ' + t('외') + ' ' + (item.classCount - 1) + t('개') : ''}
                            </td>
                            <td>{item.createdTime}</td>
                            <td className="py-0">
                              <button
                                type="button"
                                className="btn btn-sm btn-outline-secondary"
                                onClick={() => this._handleModify(item)}
                              >
                                <span className="ico edit"></span>
                                <span>{t('수정')}</span>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <Pagination
                    totalCount={pagination ? pagination.totalCount : 0}
                    rowCount={pagination ? pagination.pageSize : pageSize}
                    page={pagination ? pagination.pageNo : pageNo}
                    onPageMove={this._handlePageMove}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { trainer } = state;
  return {
    trainerListData: trainer.trainerListData,
  };
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTrainerList,
      removeTrainer,
      dispatch,
    },
    dispatch,
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TrainerMgt));
export { connected as TrainerMgt };
