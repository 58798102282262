export const FETCH_MEMO_LIST_REQUEST = 'FETCH_MEMO_LIST_REQUEST';
export const FETCH_MEMO_LIST_SUCCESS = 'FETCH_MEMO_LIST_SUCCESS';
export const FETCH_MEMO_LIST_ERROR = 'FETCH_MEMO_LIST_ERROR';
export const ADD_MEMO_REQUEST = 'ADD_MEMO_REQUEST';
export const ADD_MEMO_SUCCESS = 'ADD_MEMO_SUCCESS';
export const ADD_MEMO_ERROR = 'ADD_MEMO_ERROR';
export const MODIFY_MEMO_REQUEST = 'MODIFY_MEMO_REQUEST';
export const MODIFY_MEMO_SUCCESS = 'MODIFY_MEMO_SUCCESS';
export const MODIFY_MEMO_ERROR = 'MODIFY_MEMO_ERROR';

export function fetchMemoListRequest() {
  return { type: FETCH_MEMO_LIST_REQUEST };
}
export function fetchMemoListSuccess(data) {
  return { type: FETCH_MEMO_LIST_SUCCESS, data };
}
export function fetchMemoListError(error) {
  return { type: FETCH_MEMO_LIST_ERROR, error };
}

export function addMemoRequest() {
  return { type: ADD_MEMO_REQUEST };
}
export function addMemoSuccess(data) {
  return { type: ADD_MEMO_SUCCESS, data };
}
export function addMemoError(error) {
  return { type: ADD_MEMO_ERROR, error };
}
export function modifyMemoRequest() {
  return { type: MODIFY_MEMO_REQUEST };
}
export function modifyMemoSuccess(data) {
  return { type: MODIFY_MEMO_SUCCESS, data };
}
export function modifyMemoError(error) {
  return { type: MODIFY_MEMO_ERROR, error };
}
