import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  CHECK_TENANT_REQUEST,
  CHECK_TENANT_SUCCESS,
  CHECK_TENANT_ERROR,
} from '_actions';

const initialState = {
  pending: false,
};

export function auth(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        pending: false,
      };
    case CHECK_TENANT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CHECK_TENANT_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case CHECK_TENANT_ERROR:
      return {
        ...state,
        pending: false,
      };
    default:
      return state;
  }
}
