/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { commonUtils } from '_helpers';
import { Header, SideBar } from '_components';
import { pathConstant } from '_constants';
import {
  getLocalTenant,
  getLocalSession,
  fetchStatisticsTotal,
  fetchRecentExerciseClassUnits,
  fetchRecentExerciseMembers,
  fetchBoardList,
  fetchMember,
} from '_services';

class DashBoard extends Component {
  constructor(props) {
    super(props);

    this.state = { classId: '', keyword: '' };

    this._handleChangeClassUnit = this._handleChangeClassUnit.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleSearch = this._handleSearch.bind(this);
    this._handleBoardMgt = this._handleBoardMgt.bind(this);
    this._handleBoardDetail = this._handleBoardDetail.bind(this);
    this._handleMemberDetail = this._handleMemberDetail.bind(this);
  }
  componentDidMount() {
    window.$('.jquery_scrollbar').scrollbar();
    window.setTooltip();

    this._loadData();
  }
  _loadData() {
    this.props.fetchStatisticsTotal();
    this.props.fetchRecentExerciseClassUnits((error, data) => {
      if (!error && data.length > 0) {
        this.setState({
          ...this.state,
          classId: data[0].classId,
        });
        this.props.fetchRecentExerciseMembers(data[0].classId, data[0].classUnitNo);
      }
    });
    const params = { pageNo: 1, pageSize: 5 };
    this.props.fetchBoardList('T', params);
    this.props.fetchBoardList('S', params);
  }
  _handleChangeClassUnit(e) {
    const classUnitSplit = e.target.value.split(',');
    this.setState({
      ...this.state,
      classId: classUnitSplit[0],
    });
    this.props.fetchRecentExerciseMembers(classUnitSplit[0], classUnitSplit[1]);
  }
  _handleChange(e) {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value });
  }
  _handleSearch(e) {
    e.preventDefault();
    const { keyword } = this.state;
    const params = { keyword, pageNo: 1, pageSize: 5 };
    this.props.fetchBoardList('T', params);
    this.props.fetchBoardList('S', params);
  }
  _handleBoardMgt() {
    this.props.history.push({ pathname: pathConstant.PATH_BOARD_MGT, state: { boardType: 'T' } });
  }
  _handleBoardDetail(e, boardType, item) {
    e.preventDefault();
    this.props.history.push({
      pathname: pathConstant.PATH_BOARD_DETAIL,
      state: { caller: 'dashboard', boardType, data: item },
    });
  }
  _handleMemberDetail(e, item) {
    e.preventDefault();

    this.props.fetchMember(item.userId, (error, result) => {
      if (!error) {
        this.props.history.push({ pathname: pathConstant.PATH_MEMBER_DETAIL, state: { data: result } });
      }
    });
  }
  render() {
    const { t } = this.props;
    const lang = commonUtils.getBrowserLanguage();
    document.title = `${t('대시보드')} | ${t('모스텝')}`;
    const breadcrumb = [t('대시보드')];
    const tenant = getLocalTenant();
    const session = getLocalSession();
    const { keyword } = this.state;
    const {
      statisticsTotalData,
      recentExerciseClassUnitsData,
      recentExerciseMembersData,
      tenantBoardListData,
      systemBoardListData,
    } = this.props;
    return (
      <>
        <Header {...this.props} breadcrumb={breadcrumb} />
        <SideBar {...this.props} />

        <main className="contents_wrapper">
          <div className="jquery_scrollbar">
            <div className="contents">
              <div className="grid_container dashboard">
                <section className="grid_item welcome">
                  {lang === 'ko' && (
                    <p>
                      <span>
                        {tenant.tenantNm} <strong>{session.userNm}</strong>님,
                      </span>
                      <span>{t('모스텝 관리자에 오신 것을 환영합니다.')}</span>
                    </p>
                  )}
                  {lang === 'en' && (
                    <p>
                      <span>{t('모스텝 관리자에 오신 것을 환영합니다.')},</span>
                      <span>
                        <strong>{session.userNm}</strong> at {tenant.tenantNm}
                      </span>
                    </p>
                  )}
                </section>

                <section className="grid_item contents_tool">
                  <form className="search_bar" onSubmit={this._handleSearch}>
                    <input
                      id="keyword"
                      className="form-control"
                      type="text"
                      placeholder={t('제목이나 작성자 이름을 입력해 주세요.')}
                      value={keyword}
                      onChange={this._handleChange}
                    />
                    <button type="submit" className="btn btn-outline-primary">
                      {t('검색')}
                    </button>
                  </form>
                </section>

                <section className="grid_item summary">
                  <div className="card main">
                    <ul>
                      <li>
                        <p className="title">
                          <span className="ico user"></span>
                          <span className="text">{t('사용자 수')}</span>
                        </p>
                        <p className="value">
                          <span className="num">{statisticsTotalData && statisticsTotalData.memberTotalCount}</span>
                          <span
                            className={
                              statisticsTotalData &&
                              statisticsTotalData.memberTodayCount - statisticsTotalData.memberYesterdayCount > 0
                                ? 'ico increase'
                                : statisticsTotalData &&
                                  statisticsTotalData.memberTodayCount - statisticsTotalData.memberYesterdayCount < 0
                                ? 'ico decrease'
                                : 'ico maintain'
                            }
                          >
                            {Math.abs(
                              statisticsTotalData &&
                                statisticsTotalData.memberTodayCount - statisticsTotalData.memberYesterdayCount,
                            )}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p className="title">
                          <span className="ico class"></span>
                          <span className="text">{t('클래스 수')}</span>
                        </p>
                        <p className="value">
                          <span className="num">{statisticsTotalData && statisticsTotalData.classTotalCount}</span>
                          <span
                            className={
                              statisticsTotalData &&
                              statisticsTotalData.classTodayCount - statisticsTotalData.classYesterdayCount > 0
                                ? 'ico increase'
                                : statisticsTotalData &&
                                  statisticsTotalData.classTodayCount - statisticsTotalData.classYesterdayCount < 0
                                ? 'ico decrease'
                                : 'ico maintain'
                            }
                          >
                            {Math.abs(
                              statisticsTotalData &&
                                statisticsTotalData.classTodayCount - statisticsTotalData.classYesterdayCount,
                            )}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p className="title">
                          <span className="ico exercise"></span>
                          <span className="text">{t('운동횟수')}</span>
                        </p>
                        <p className="value">
                          <span className="num">{statisticsTotalData && statisticsTotalData.exerciseTotalCount}</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="card sub">
                    <div className="card_header">
                      <h3 className="title">{t('최근 운동한 클래스/회원')}</h3>
                      <div className="contents_tool">
                        <select className="form-select" onChange={this._handleChangeClassUnit}>
                          {recentExerciseClassUnitsData &&
                            recentExerciseClassUnitsData.map((item, index) => (
                              <option key={index} value={item.classId + ',' + item.classUnitNo}>
                                {item.classNm} ({item.exerciseDate})
                              </option>
                            ))}
                        </select>
                        <button type="button" className="btn btn-outline-secondary">
                          {t('클래스 관리')}
                        </button>
                      </div>
                    </div>
                    <div className="card_body">
                      <div className="jquery_scrollbar">
                        <div className="scroll_inner">
                          <table
                            className={`table ${
                              !recentExerciseClassUnitsData ||
                              recentExerciseClassUnitsData.length === 0 ||
                              !recentExerciseMembersData ||
                              recentExerciseMembersData.length === 0
                                ? 'none_data'
                                : ''
                            }`}
                          >
                            <caption className="visually-hidden">테이블</caption>
                            <thead>
                              <tr>
                                <th>{t('아이디')}</th>
                                <th>{t('이름')}</th>
                                <th>{t('나이')}</th>
                                <th>{t('스텝수')}</th>
                                <th>
                                  <div className="d-flex">
                                    <span>{t('정확도')}</span>
                                    <span
                                      className="ico info"
                                      data-bs-toggle="tooltip"
                                      data-bs-offset="0, 4"
                                      data-bs-html="true"
                                      title={`<div className='helper_text'><p className='name'>${t(
                                        '정확도는 전체 스텝 중에서 올바르게 밟은 스텝의 비율을 나타냅니다.',
                                      )}</p><p className='unit'>(${t('단위')}: %)</p></div>`}
                                    ></span>
                                  </div>
                                </th>
                                <th>
                                  <p className="d-flex">
                                    <span>{t('평균보속')}</span>
                                    <span
                                      className="ico info"
                                      data-bs-toggle="tooltip"
                                      data-bs-offset="0, 4"
                                      data-bs-html="true"
                                      title={`<div className='helper_text'><p className='name'>${t(
                                        '평균보속은 한 라운드를 걷는 시간을 올바르게 밟은 스텝의 수로 나눈 수치입니다.',
                                      )}</p><p className='unit'>(${t('단위')}: ${t('초')})</p></div>`}
                                    ></span>
                                  </p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {(!recentExerciseClassUnitsData || recentExerciseClassUnitsData.length === 0) && (
                                <tr>
                                  <td colSpan="6">
                                    <p>{t('최근 운동한 클래스가 없습니다.')}</p>
                                  </td>
                                </tr>
                              )}
                              {recentExerciseClassUnitsData &&
                                recentExerciseClassUnitsData.length > 0 &&
                                (!recentExerciseMembersData || recentExerciseMembersData.length === 0) && (
                                  <tr>
                                    <td colSpan="6">
                                      <p>{t('최근 운동한 회원이 없습니다.')}</p>
                                    </td>
                                  </tr>
                                )}
                              {recentExerciseMembersData &&
                                recentExerciseMembersData.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <a href="" onClick={e => this._handleMemberDetail(e, item)}>
                                        {item.loginId}
                                      </a>
                                    </td>
                                    <td>{item.userNm}</td>
                                    <td>{item.age}</td>
                                    <td>{commonUtils.numberWithCommas(item.totalStepCount)}</td>
                                    <td>{item.accuracy}</td>
                                    <td>{item.walkingSpeed}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="grid_item notice">
                  <BoardView
                    t={t}
                    boardType="T"
                    data={tenantBoardListData && tenantBoardListData.data}
                    onBoardMgt={this._handleBoardMgt}
                    onDetail={this._handleBoardDetail}
                  />
                  <BoardView
                    t={t}
                    boardType="S"
                    data={systemBoardListData && systemBoardListData.data}
                    onDetail={this._handleBoardDetail}
                  />
                </section>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
class BoardView extends Component {
  render() {
    const { t } = this.props;
    const { boardType, data, onBoardMgt, onDetail } = this.props;
    const now = moment();
    return (
      <div className={`card ${boardType === 'S' ? 'system' : 'facility'}`}>
        <div className="card_header">
          <h3 className="title">
            {boardType === 'S' ? `${t('시스템')} ${t('공지사항')}` : `${t('시설')} ${t('공지사항')}`}
          </h3>
          {boardType === 'T' && (
            <div className="contents_tool">
              <button type="button" className="btn btn-outline-secondary" onClick={onBoardMgt}>
                {t('전체 보기')}
              </button>
            </div>
          )}
        </div>
        <div className="card_body">
          <div className="jquery_scrollbar">
            <div className="scroll_inner">
              {(!data || data.length === 0) && (
                <ul className="notice_list none_data">
                  <li>
                    <p>
                      {t('최근 등록된')} {boardType === 'S' ? t('시스템') : t('시설')} {t('공지사항이 없습니다.2')}
                    </p>
                  </li>
                </ul>
              )}
              {data && data.length > 0 && (
                <ul className="notice_list">
                  {data &&
                    data.map((item, index) => (
                      <li key={index}>
                        <a href="" onClick={e => onDetail(e, boardType, item)}>
                          <span className={`title ${now.diff(item.createdTime, 'days') <= 3 ? 'new' : ''}`}>
                            {item.title}
                          </span>
                          <span className="date">{item.createdTime}</span>
                        </a>
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { statistics, exclass, board, member } = state;
  return {
    statisticsTotalData: statistics.statisticsTotalData,
    recentExerciseClassUnitsData: exclass.recentExerciseClassUnitsData,
    recentExerciseMembersData: exclass.recentExerciseMembersData,
    systemBoardListData: board.systemBoardListData,
    tenantBoardListData: board.tenantBoardListData,
    memberData: member.memberData,
  };
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchStatisticsTotal,
      fetchRecentExerciseClassUnits,
      fetchRecentExerciseMembers,
      fetchBoardList,
      fetchMember,
      dispatch,
    },
    dispatch,
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashBoard));
export { connected as DashBoard };
