export const ADD_BOARD_REQUEST = 'ADD_BOARD_REQUEST';
export const ADD_BOARD_SUCCESS = 'ADD_BOARD_SUCCESS';
export const ADD_BOARD_ERROR = 'ADD_BOARD_ERROR';
export const MODIFY_BOARD_REQUEST = 'MODIFY_BOARD_REQUEST';
export const MODIFY_BOARD_SUCCESS = 'MODIFY_BOARD_SUCCESS';
export const MODIFY_BOARD_ERROR = 'MODIFY_BOARD_ERROR';
export const REMOVE_BOARD_REQUEST = 'REMOVE_BOARD_REQUEST';
export const REMOVE_BOARD_SUCCESS = 'REMOVE_BOARD_SUCCESS';
export const REMOVE_BOARD_ERROR = 'REMOVE_BOARD_ERROR';
export const FETCH_BOARD_LIST_REQUEST = 'FETCH_BOARD_LIST_REQUEST';
export const FETCH_BOARD_LIST_SUCCESS = 'FETCH_BOARD_LIST_SUCCESS';
export const FETCH_BOARD_LIST_ERROR = 'FETCH_BOARD_LIST_ERROR';

export function addBoardRequest() {
  return { type: ADD_BOARD_REQUEST };
}
export function addBoardSuccess(data) {
  return { type: ADD_BOARD_SUCCESS, data };
}
export function addBoardError(error) {
  return { type: ADD_BOARD_ERROR, error };
}
export function modifyBoardRequest() {
  return { type: MODIFY_BOARD_REQUEST };
}
export function modifyBoardSuccess(data) {
  return { type: MODIFY_BOARD_SUCCESS, data };
}
export function modifyBoardError(error) {
  return { type: MODIFY_BOARD_ERROR, error };
}
export function removeBoardRequest() {
  return { type: REMOVE_BOARD_REQUEST };
}
export function removeBoardSuccess(data) {
  return { type: REMOVE_BOARD_SUCCESS, data };
}
export function removeBoardError(error) {
  return { type: REMOVE_BOARD_ERROR, error };
}
export function fetchBoardListRequest() {
  return { type: FETCH_BOARD_LIST_REQUEST };
}
export function fetchBoardListSuccess(boardType, data) {
  return { type: FETCH_BOARD_LIST_SUCCESS, boardType, data };
}
export function fetchBoardListError(error) {
  return { type: FETCH_BOARD_LIST_ERROR, error };
}
