import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import DaumPostcode from 'react-daum-postcode';

class PostCodeModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this._handleAddressOnComplete = this._handleAddressOnComplete.bind(this);
  }

  _handleAddressOnComplete = data => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    this.props.onClose({
      zonecode: data.zonecode,
      address: fullAddress,
    });
  };

  render() {
    const postCodeModalBodyHeigth = {
      height: '490px',
      width: '100%',
    };
    const postCodeStyle = {
      display: 'block',
      width: '100%',
      height: '500px',
    };
    return (
      <Modal onShow={this._handleShow} show={this.props.open} backdrop="static" dialogClassName="modal-dialog-centered">
        <Modal.Header>
          <h5 className="modal-title">주소 검색</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              this.props.onClose(true);
            }}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="withdrawal" style={postCodeModalBodyHeigth}>
            <div className="flex_area">
              <div className={'flex_item org_data'}>
                <DaumPostcode style={postCodeStyle} onComplete={this._handleAddressOnComplete} />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatch,
    },
    dispatch,
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(PostCodeModal);
export { connected as PostCodeModal };
