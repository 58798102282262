import {
  FETCH_CLASS_AI_LRBIAS_RANGE_LIST_REQUEST,
  FETCH_CLASS_AI_LRBIAS_RANGE_LIST_SUCCESS,
  FETCH_CLASS_AI_LRBIAS_RANGE_LIST_ERROR,
  FETCH_CLASS_AI_MOMENTUM_RANGE_LIST_REQUEST,
  FETCH_CLASS_AI_MOMENTUM_RANGE_LIST_SUCCESS,
  FETCH_CLASS_AI_MOMENTUM_RANGE_LIST_ERROR,
  FETCH_CLASS_AI_AWARENESS_RANGE_LIST_REQUEST,
  FETCH_CLASS_AI_AWARENESS_RANGE_LIST_SUCCESS,
  FETCH_CLASS_AI_AWARENESS_RANGE_LIST_ERROR,
  FETCH_CLASS_AI_REACTION_RANGE_LIST_REQUEST,
  FETCH_CLASS_AI_REACTION_RANGE_LIST_SUCCESS,
  FETCH_CLASS_AI_REACTION_RANGE_LIST_ERROR,
  FETCH_CLASS_AI_MEMORY_RANGE_LIST_REQUEST,
  FETCH_CLASS_AI_MEMORY_RANGE_LIST_SUCCESS,
  FETCH_CLASS_AI_MEMORY_RANGE_LIST_ERROR,
} from '_actions';

const initialState = {
  pending: false,
  rangeClassesAiLrbiasData: null,
  rangeClassesAiMomentumData: null,
  rangeClassesAwarenessData: null,
  rangeClassesReactionData: null,
  rangeClassesMemoryData: null,
  error: null,
};

export function range(state = initialState, action) {
  switch (action.type) {
    case FETCH_CLASS_AI_LRBIAS_RANGE_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CLASS_AI_LRBIAS_RANGE_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        rangeClassesAiLrbiasData: action.data,
      };
    case FETCH_CLASS_AI_LRBIAS_RANGE_LIST_ERROR:
      return {
        ...state,
        pending: false,
        rangeClassesAiLrbiasData: null,
        error: action.error,
      };
    case FETCH_CLASS_AI_MOMENTUM_RANGE_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CLASS_AI_MOMENTUM_RANGE_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        rangeClassesAiMomentumData: action.data,
      };
    case FETCH_CLASS_AI_MOMENTUM_RANGE_LIST_ERROR:
      return {
        ...state,
        pending: false,
        rangeClassesAiMomentumData: null,
        error: action.error,
      };
    case FETCH_CLASS_AI_AWARENESS_RANGE_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CLASS_AI_AWARENESS_RANGE_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        rangeClassesAwarenessData: action.data,
      };
    case FETCH_CLASS_AI_AWARENESS_RANGE_LIST_ERROR:
      return {
        ...state,
        pending: false,
        rangeClassesAwarenessData: null,
        error: action.error,
      };
    case FETCH_CLASS_AI_REACTION_RANGE_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CLASS_AI_REACTION_RANGE_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        rangeClassesReactionData: action.data,
      };
    case FETCH_CLASS_AI_REACTION_RANGE_LIST_ERROR:
      return {
        ...state,
        pending: false,
        rangeClassesReactionData: null,
        error: action.error,
      };
    case FETCH_CLASS_AI_MEMORY_RANGE_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CLASS_AI_MEMORY_RANGE_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        rangeClassesMemoryData: action.data,
      };
    case FETCH_CLASS_AI_MEMORY_RANGE_LIST_ERROR:
      return {
        ...state,
        pending: false,
        rangeClassesMemoryData: null,
        error: action.error,
      };
    default:
      return state;
  }
}
