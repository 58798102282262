import { requestUrl, requestHeaders, requestParam2, handleResponse } from '_helpers';
import {
  fetchClassAiLrbiasRangeListRequest,
  fetchClassAiLrbiasRangeListSuccess,
  fetchClassAiLrbiasRangeListError,
  fetchClassAiMomentumRangeListRequest,
  fetchClassAiMomentumRangeListSuccess,
  fetchClassAiMomentumRangeListError,
  fetchClassAiAwarenessRangeListRequest,
  fetchClassAiAwarenessRangeListSuccess,
  fetchClassAiAwarenessRangeListError,
  fetchClassAiReactionRangeListRequest,
  fetchClassAiReactionRangeListSuccess,
  fetchClassAiReactionRangeListError,
  fetchClassAiMemoryRangeListRequest,
  fetchClassAiMemoryRangeListSuccess,
  fetchClassAiMemoryRangeListError,
} from '_actions';

// AI분석 리포트 좌우편향성 나이대별 평균 목록 조회
export function fetchClassAiLrbiasRangeList(gender, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/classaimodel/range/lrbias-diagnosis?gender=${gender}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchClassAiLrbiasRangeListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchClassAiLrbiasRangeListSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchClassAiLrbiasRangeListError(error));
      });
  };
}

// AI분석 리포트 좌우편향성 나이대별 평균 목록 조회
export function fetchClassAiMomentumRangeList(gender, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/classaimodel/range/momentum-diagnosis?gender=${gender}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchClassAiMomentumRangeListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchClassAiMomentumRangeListSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchClassAiMomentumRangeListError(error));
      });
  };
}

// AI분석 리포트 운동량 나이대별 평균 목록 조회
export function fetchClassAiAwarenessRangeList(gender, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/classaimodel/range/awareness-diagnosis?gender=${gender}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchClassAiAwarenessRangeListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchClassAiAwarenessRangeListSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchClassAiAwarenessRangeListError(error));
      });
  };
}

// AI분석 리포트 인지도 나이대별 평균 목록 조회
export function fetchClassAiReactionRangeList(gender, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/classaimodel/range/reaction-diagnosis?gender=${gender}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchClassAiReactionRangeListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchClassAiReactionRangeListSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchClassAiReactionRangeListError(error));
      });
  };
}

// AI분석 리포트 기억력 나이대별 평균 목록 조회
export function fetchClassAiMemoryRangeList(gender, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/classaimodel/range/memory-diagnosis?gender=${gender}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchClassAiMemoryRangeListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchClassAiMemoryRangeListSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchClassAiMemoryRangeListError(error));
      });
  };
}
