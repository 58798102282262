import {
  FETCH_RECENT_EXERCISE_CLASSUNITS_REQUEST,
  FETCH_RECENT_EXERCISE_CLASSUNITS_SUCCESS,
  FETCH_RECENT_EXERCISE_CLASSUNITS_ERROR,
  FETCH_RECENT_EXERCISE_MEMBERS_REQUEST,
  FETCH_RECENT_EXERCISE_MEMBERS_SUCCESS,
  FETCH_RECENT_EXERCISE_MEMBERS_ERROR,
  FETCH_CLASS_LIST_REQUEST,
  FETCH_CLASS_LIST_SUCCESS,
  FETCH_CLASS_LIST_ERROR,
  FETCH_CLASS_REQUEST,
  FETCH_CLASS_SUCCESS,
  FETCH_CLASS_ERROR,
  FETCH_CLASS_EXERCISE_SUMMARY_REQUEST,
  FETCH_CLASS_EXERCISE_SUMMARY_SUCCESS,
  FETCH_CLASS_EXERCISE_SUMMARY_ERROR,
  FETCH_MEMBERS_OF_CLASS_REQUEST,
  FETCH_MEMBERS_OF_CLASS_SUCCESS,
  FETCH_MEMBERS_OF_CLASS_ERROR,
  FETCH_RECENT_EXERCISE_CLASSUNITS_OF_CLASS_REQUEST,
  FETCH_RECENT_EXERCISE_CLASSUNITS_OF_CLASS_SUCCESS,
  FETCH_RECENT_EXERCISE_CLASSUNITS_OF_CLASS_ERROR,
  ADD_CLASS_REQUEST,
  ADD_CLASS_SUCCESS,
  ADD_CLASS_ERROR,
  MODIFY_CLASS_REQUEST,
  MODIFY_CLASS_SUCCESS,
  MODIFY_CLASS_ERROR,
  REMOVE_CLASS_REQUEST,
  REMOVE_CLASS_SUCCESS,
  REMOVE_CLASS_ERROR,
} from '_actions';

const initialState = {
  pending: false,
  recentExerciseClassUnitsData: null,
  recentExerciseMembersData: null,
  classListData: null,
  classData: null,
  classExerciseSummaryData: null,
  membersOfClassData: null,
  recentExerciseClassUnitsOfClassData: null,
  error: null,
};

export function exclass(state = initialState, action) {
  switch (action.type) {
    case FETCH_RECENT_EXERCISE_CLASSUNITS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_RECENT_EXERCISE_CLASSUNITS_SUCCESS:
      return {
        ...state,
        pending: false,
        recentExerciseClassUnitsData: action.data,
      };
    case FETCH_RECENT_EXERCISE_CLASSUNITS_ERROR:
      return {
        ...state,
        pending: false,
        recentExerciseClassUnitsData: null,
        error: action.error,
      };
    case FETCH_RECENT_EXERCISE_MEMBERS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_RECENT_EXERCISE_MEMBERS_SUCCESS:
      return {
        ...state,
        pending: false,
        recentExerciseMembersData: action.data,
      };
    case FETCH_RECENT_EXERCISE_MEMBERS_ERROR:
      return {
        ...state,
        pending: false,
        recentExerciseMembersData: null,
        error: action.error,
      };
    case FETCH_CLASS_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CLASS_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        classListData: action.data,
      };
    case FETCH_CLASS_LIST_ERROR:
      return {
        ...state,
        pending: false,
        classListData: null,
        error: action.error,
      };
    case FETCH_CLASS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CLASS_SUCCESS:
      return {
        ...state,
        pending: false,
        classData: action.data,
      };
    case FETCH_CLASS_ERROR:
      return {
        ...state,
        pending: false,
        classData: null,
        error: action.error,
      };
    case FETCH_CLASS_EXERCISE_SUMMARY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CLASS_EXERCISE_SUMMARY_SUCCESS:
      return {
        ...state,
        pending: false,
        classExerciseSummaryData: action.data,
      };
    case FETCH_CLASS_EXERCISE_SUMMARY_ERROR:
      return {
        ...state,
        pending: false,
        classExerciseSummaryData: null,
        error: action.error,
      };
    case FETCH_MEMBERS_OF_CLASS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBERS_OF_CLASS_SUCCESS:
      return {
        ...state,
        pending: false,
        membersOfClassData: action.data,
      };
    case FETCH_MEMBERS_OF_CLASS_ERROR:
      return {
        ...state,
        pending: false,
        membersOfClassData: null,
        error: action.error,
      };
    case FETCH_RECENT_EXERCISE_CLASSUNITS_OF_CLASS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_RECENT_EXERCISE_CLASSUNITS_OF_CLASS_SUCCESS:
      return {
        ...state,
        pending: false,
        recentExerciseClassUnitsOfClassData: action.data,
      };
    case FETCH_RECENT_EXERCISE_CLASSUNITS_OF_CLASS_ERROR:
      return {
        ...state,
        pending: false,
        recentExerciseClassUnitsOfClassData: null,
        error: action.error,
      };
    case ADD_CLASS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_CLASS_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_CLASS_ERROR:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_CLASS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case MODIFY_CLASS_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_CLASS_ERROR:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_CLASS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_CLASS_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_CLASS_ERROR:
      return {
        ...state,
        pending: false,
      };
    default:
      return state;
  }
}
