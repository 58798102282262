export const FETCH_CLASS_AI_LRBIAS_DIAGNOSIS_LIST_REQUEST = 'FETCH_CLASS_AI_LRBIAS_DIAGNOSIS_LIST_REQUEST';
export const FETCH_CLASS_AI_LRBIAS_DIAGNOSIS_LIST_SUCCESS = 'FETCH_CLASS_AI_LRBIAS_DIAGNOSIS_LIST_SUCCESS';
export const FETCH_CLASS_AI_LRBIAS_DIAGNOSIS_LIST_ERROR = 'FETCH_CLASS_AI_LRBIAS_DIAGNOSIS_LIST_ERROR';
export const FETCH_CLASS_AI_MOMENTUM_DIAGNOSIS_LIST_REQUEST = 'FETCH_CLASS_AI_MOMENTUM_DIAGNOSIS_LIST_REQUEST';
export const FETCH_CLASS_AI_MOMENTUM_DIAGNOSIS_LIST_SUCCESS = 'FETCH_CLASS_AI_MOMENTUM_DIAGNOSIS_LIST_SUCCESS';
export const FETCH_CLASS_AI_MOMENTUM_DIAGNOSIS_LIST_ERROR = 'FETCH_CLASS_AI_MOMENTUM_DIAGNOSIS_LIST_ERROR';
export const FETCH_CLASS_AI_AWARENESS_DIAGNOSIS_LIST_REQUEST = 'FETCH_CLASS_AI_AWARENESS_DIAGNOSIS_LIST_REQUEST';
export const FETCH_CLASS_AI_AWARENESS_DIAGNOSIS_LIST_SUCCESS = 'FETCH_CLASS_AI_AWARENESS_DIAGNOSIS_LIST_SUCCESS';
export const FETCH_CLASS_AI_AWARENESS_DIAGNOSIS_LIST_ERROR = 'FETCH_CLASS_AI_AWARENESS_DIAGNOSIS_LIST_ERROR';
export const FETCH_CLASS_AI_REACTION_DIAGNOSIS_LIST_REQUEST = 'FETCH_CLASS_AI_REACTION_DIAGNOSIS_LIST_REQUEST';
export const FETCH_CLASS_AI_REACTION_DIAGNOSIS_LIST_SUCCESS = 'FETCH_CLASS_AI_REACTION_DIAGNOSIS_LIST_SUCCESS';
export const FETCH_CLASS_AI_REACTION_DIAGNOSIS_LIST_ERROR = 'FETCH_CLASS_AI_REACTION_DIAGNOSIS_LIST_ERROR';
export const FETCH_CLASS_AI_MEMORY_DIAGNOSIS_LIST_REQUEST = 'FETCH_CLASS_AI_MEMORY_DIAGNOSIS_LIST_REQUEST';
export const FETCH_CLASS_AI_MEMORY_DIAGNOSIS_LIST_SUCCESS = 'FETCH_CLASS_AI_MEMORY_DIAGNOSIS_LIST_SUCCESS';
export const FETCH_CLASS_AI_MEMORY_DIAGNOSIS_LIST_ERROR = 'FETCH_CLASS_AI_MEMORY_DIAGNOSIS_LIST_ERROR';

export function fetchClassAiLrbiasDiagnosisListRequest() {
  return { type: FETCH_CLASS_AI_LRBIAS_DIAGNOSIS_LIST_REQUEST };
}
export function fetchClassAiLrbiasDiagnosisListSuccess(data) {
  return { type: FETCH_CLASS_AI_LRBIAS_DIAGNOSIS_LIST_SUCCESS, data };
}
export function fetchClassAiLrbiasDiagnosisListError(error) {
  return { type: FETCH_CLASS_AI_LRBIAS_DIAGNOSIS_LIST_ERROR, error };
}

export function fetchClassAiMomentumDiagnosisListRequest() {
  return { type: FETCH_CLASS_AI_MOMENTUM_DIAGNOSIS_LIST_REQUEST };
}
export function fetchClassAiMomentumDiagnosisListSuccess(data) {
  return { type: FETCH_CLASS_AI_MOMENTUM_DIAGNOSIS_LIST_SUCCESS, data };
}
export function fetchClassAiMomentumDiagnosisListError(error) {
  return { type: FETCH_CLASS_AI_MOMENTUM_DIAGNOSIS_LIST_ERROR, error };
}

export function fetchClassAiAwarenessDiagnosisListRequest() {
  return { type: FETCH_CLASS_AI_AWARENESS_DIAGNOSIS_LIST_REQUEST };
}
export function fetchClassAiAwarenessDiagnosisListSuccess(data) {
  return { type: FETCH_CLASS_AI_AWARENESS_DIAGNOSIS_LIST_SUCCESS, data };
}
export function fetchClassAiAwarenessDiagnosisListError(error) {
  return { type: FETCH_CLASS_AI_AWARENESS_DIAGNOSIS_LIST_ERROR, error };
}

export function fetchClassAiReactionDiagnosisListRequest() {
  return { type: FETCH_CLASS_AI_REACTION_DIAGNOSIS_LIST_REQUEST };
}
export function fetchClassAiReactionDiagnosisListSuccess(data) {
  return { type: FETCH_CLASS_AI_REACTION_DIAGNOSIS_LIST_SUCCESS, data };
}
export function fetchClassAiReactionDiagnosisListError(error) {
  return { type: FETCH_CLASS_AI_REACTION_DIAGNOSIS_LIST_ERROR, error };
}

export function fetchClassAiMemoryDiagnosisListRequest() {
  return { type: FETCH_CLASS_AI_MEMORY_DIAGNOSIS_LIST_REQUEST };
}
export function fetchClassAiMemoryDiagnosisListSuccess(data) {
  return { type: FETCH_CLASS_AI_MEMORY_DIAGNOSIS_LIST_SUCCESS, data };
}
export function fetchClassAiMemoryDiagnosisListError(error) {
  return { type: FETCH_CLASS_AI_MEMORY_DIAGNOSIS_LIST_ERROR, error };
}
