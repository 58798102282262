import {
  FETCH_PATTERNGROUP_LIST_REQUEST,
  FETCH_PATTERNGROUP_LIST_SUCCESS,
  FETCH_PATTERNGROUP_LIST_ERROR,
  FETCH_PATTERNGROUP_REQUEST,
  FETCH_PATTERNGROUP_SUCCESS,
  FETCH_PATTERNGROUP_ERROR,
  ADD_PATTERNGROUP_REQUEST,
  ADD_PATTERNGROUP_SUCCESS,
  ADD_PATTERNGROUP_ERROR,
  MODIFY_PATTERNGROUP_REQUEST,
  MODIFY_PATTERNGROUP_SUCCESS,
  MODIFY_PATTERNGROUP_ERROR,
  REMOVE_PATTERNGROUP_REQUEST,
  REMOVE_PATTERNGROUP_SUCCESS,
  REMOVE_PATTERNGROUP_ERROR,
  FETCH_PATTERN_LIST_REQUEST,
  FETCH_PATTERN_LIST_SUCCESS,
  FETCH_PATTERN_LIST_ERROR,
  FETCH_PATTERNLEVEL_LIST_REQUEST,
  FETCH_PATTERNLEVEL_LIST_SUCCESS,
  FETCH_PATTERNLEVEL_LIST_ERROR,
  FETCH_RECOMMEND_PATTERN_REQUEST,
  FETCH_RECOMMEND_PATTERN_ERROR,
  FETCH_PATTERNRE_QUEST,
  FETCH_PATTERN_SUCCESS,
  FETCH_PATTERN_ERROR,
} from '_actions';

const initialState = {
  pending: false,
  patternGroupListData: null,
  patternGroupData: null,
  patternListData: null,
  patternLevelListData: null,
  recommendPattern: null,
  error: null,
};

export function pattern(state = initialState, action) {
  switch (action.type) {
    case FETCH_PATTERNGROUP_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_PATTERNGROUP_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        patternGroupListData: action.data,
      };
    case FETCH_PATTERNGROUP_LIST_ERROR:
      return {
        ...state,
        pending: false,
        patternGroupListData: null,
        error: action.error,
      };
    case FETCH_PATTERNGROUP_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_PATTERNGROUP_SUCCESS:
      return {
        ...state,
        pending: false,
        patternGroupData: action.data,
      };
    case FETCH_PATTERNGROUP_ERROR:
      return {
        ...state,
        pending: false,
        patternGroupData: null,
        error: action.error,
      };
    case ADD_PATTERNGROUP_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_PATTERNGROUP_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_PATTERNGROUP_ERROR:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_PATTERNGROUP_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case MODIFY_PATTERNGROUP_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_PATTERNGROUP_ERROR:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_PATTERNGROUP_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_PATTERNGROUP_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_PATTERNGROUP_ERROR:
      return {
        ...state,
        pending: false,
      };
    case FETCH_PATTERN_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_PATTERN_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        patternListData: action.data,
      };
    case FETCH_PATTERN_LIST_ERROR:
      return {
        ...state,
        pending: false,
        patternListData: null,
        error: action.error,
      };
    case FETCH_PATTERNLEVEL_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_PATTERNLEVEL_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        patternLevelListData: action.data,
      };
    case FETCH_PATTERNLEVEL_LIST_ERROR:
      return {
        ...state,
        pending: false,
        patternLevelListData: null,
        error: action.error,
      };
    case FETCH_RECOMMEND_PATTERN_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_RECOMMEND_PATTERN_ERROR:
      return {
        ...state,
        pending: false,
        recommendPattern: null,
        error: action.error,
      };
    case FETCH_PATTERNRE_QUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_PATTERN_SUCCESS:
      return {
        ...state,
        pending: false,
        recommendPattern: action.data,
      };
    case FETCH_PATTERN_ERROR:
      return {
        ...state,
        pending: false,
        recommendPattern: null,
        error: action.error,
      };
    default:
      return state;
  }
}
