import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { closeAlertDialog } from '_actions';

class Alert extends Component {
  constructor(props) {
    super(props);

    window.fetch.dispatch = this.props.dispatch;
  }
  onClose = () => {
    this.props.dispatch(closeAlertDialog());
  };
  render() {
    const { t } = this.props;
    return (
      <Modal
        show={this.props.open}
        onHide={() => {
          this.onClose();
          this.props.handleNo && this.props.handleNo();
        }}
        backdrop={this.props.confirm ? 'static' : true}
        dialogClassName="modal-dialog modal-sm modal-dialog-centered"
      >
        <Modal.Body>
          <div className="message_area">
            <p className={`ico ${this.props.icon ? this.props.icon : 'alert'}`}></p>
            <p dangerouslySetInnerHTML={{ __html: this.props.message }}></p>
          </div>
          <div className="btn_area">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                this.onClose();
                this.props.handleOk && this.props.handleOk();
              }}
            >
              {this.props.labelOk ? this.props.labelOk : t('확인')}
            </button>

            {this.props.confirm && (
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => {
                  this.onClose();
                  this.props.handleNo && this.props.handleNo();
                }}
              >
                {this.props.labelNo ? this.props.labelNo : t('취소')}
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { open, message, icon, confirm, labelOk, labelNo, handleOk, handleNo } = state.alert;
  return {
    open,
    message,
    icon,
    confirm,
    labelOk,
    labelNo,
    handleOk,
    handleNo,
  };
}
const connected = connect(mapStateToProps)(withTranslation()(Alert));
export { connected as Alert };
