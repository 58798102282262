import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { openAlertDialog } from '_actions';
import { DatePicker } from '_components';
import { commonUtils } from '_helpers';
import { codeConstant } from '_constants';
import {
  addMemberHealthRecordFitness,
  addMemberHealthRecordLongevity,
  addMemberHealthRecordHappiness,
  addMemberHealthRecordBPI,
  addMemberHealthRecordMental,
  fetchMemberHealthRecordFitnessList,
  fetchMemberHealthRecordFitnessAgegroupAvg,
  fetchMemberHealthRecordFitnessAgegroupRank,
  fetchMemberHealthRecordLongevityList,
  fetchMemberHealthRecordLongevityAgegroupAvg,
  fetchMemberHealthRecordLongevityAgegroupRank,
  fetchMemberHealthRecordHappinessList,
  fetchMemberHealthRecordHappinessAgegroupAvg,
  fetchMemberHealthRecordHappinessAgegroupRank,
  fetchMemberHealthRecordBPIList,
  fetchMemberHealthRecordBPIAgegroupAvg,
  fetchMemberHealthRecordBPIAgegroupRank,
  fetchMemberHealthRecordMentalList,
  fetchMemberHealthRecordMentalAgegroupAvg,
  fetchMemberHealthRecordMentalAgegroupRank,
} from '_services';

class HealthAddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: [],
    };
    this._handleShow = this._handleShow.bind(this);
    this._handleClose = this._handleClose.bind(this);
    this._handleChangeDate = this._handleChangeDate.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleSave = this._handleSave.bind(this);
  }
  // componentDidUpdate(prevProps) {
  //     if (this.props.open && this.props.open !== prevProps.open) {
  //         this._handleShow();
  //     }
  // }
  _handleShow() {
    const { healthType } = this.props;
    const healthData = codeConstant['healthData' + healthType];
    const formData = [];
    const formObj = { recordDate: '' };
    healthData.forEach(item => {
      formObj[item.id] = '';
    });
    for (let i = 0; i < 10; i++) {
      formData.push(Object.assign({}, formObj));
    }
    this.setState({
      ...this.state,
      formData,
    });
  }
  _handleChangeDate(e) {
    const { id, value } = e.target;
    let { formData } = this.state;
    const splitArr = id.split('-');
    formData[splitArr[1]][splitArr[0]] = moment(value, 'YY.MM.DD').format('YYYY-MM-DD');
    formData[splitArr[1]].isChanged = true;
    this.setState({ ...this.state, formData });
  }
  _handleChange(e) {
    const { id, value } = e.target;
    let { formData } = this.state;
    const splitArr = id.split('-');
    formData[splitArr[1]][splitArr[0]] = value;
    formData[splitArr[1]].isChanged = true;
    this.setState({ ...this.state, formData });
  }
  _handleSave() {
    const { t } = this.props;
    if (this._checkChanged()) {
      this.props.dispatch(
        openAlertDialog(
          t('입력된 항목을 저장하시겠습니까?'),
          'alert',
          true,
          t('확인'),
          t('취소'),
          () => {
            this._saveData();
          },
          () => {
            this._handleClose();
          },
        ),
      );
    } else {
      this._handleClose();
    }
  }
  _checkChanged() {
    const { formData } = this.state;
    if (formData.find(e => e.isChanged === true)) {
      return true;
    }
    return false;
  }
  _saveData() {
    const { t } = this.props;
    const { data } = this.props.location.state;
    const { healthType } = this.props;
    const { formData } = this.state;
    const addData = formData.filter(e => e.isChanged === true && e.recordDate !== '');
    const addParams = {};
    addParams['userHealth' + healthType + 'List'] = addData;
    const addFunction = this._getAddFunction();
    addFunction(data.userId, addParams, error => {
      if (!error) {
        this.props.dispatch(
          openAlertDialog(t('저장되었습니다.'), 'success', false, null, null, () => {
            this._reload();
          }),
        );
      }
    });
  }

  _reload() {
    this._loadData();
    this._handleClose();
  }
  _loadData() {
    const { memberData, healthType, healthParams } = this.props;
    if (healthType === 'Fitness') {
      this.props.fetchMemberHealthRecordFitnessList(memberData.userId, healthParams);
      this.props.fetchMemberHealthRecordFitnessAgegroupAvg(memberData.userId);
      this.props.fetchMemberHealthRecordFitnessAgegroupRank(memberData.userId);
    } else if (healthType === 'Longevity') {
      this.props.fetchMemberHealthRecordLongevityList(memberData.userId, healthParams);
      this.props.fetchMemberHealthRecordLongevityAgegroupAvg(memberData.userId);
      this.props.fetchMemberHealthRecordLongevityAgegroupRank(memberData.userId);
    } else if (healthType === 'Happiness') {
      this.props.fetchMemberHealthRecordHappinessList(memberData.userId, healthParams);
      this.props.fetchMemberHealthRecordHappinessAgegroupAvg(memberData.userId);
      this.props.fetchMemberHealthRecordHappinessAgegroupRank(memberData.userId);
    } else if (healthType === 'BPI') {
      this.props.fetchMemberHealthRecordBPIList(memberData.userId, healthParams);
      this.props.fetchMemberHealthRecordBPIAgegroupAvg(memberData.userId);
      this.props.fetchMemberHealthRecordBPIAgegroupRank(memberData.userId);
    } else if (healthType === 'Mental') {
      this.props.fetchMemberHealthRecordMentalList(memberData.userId, healthParams);
      this.props.fetchMemberHealthRecordMentalAgegroupAvg(memberData.userId);
      this.props.fetchMemberHealthRecordMentalAgegroupRank(memberData.userId);
    }
  }
  _getAddFunction() {
    const { healthType } = this.props;
    if (healthType === 'Fitness') {
      return this.props.addMemberHealthRecordFitness;
    } else if (healthType === 'Longevity') {
      return this.props.addMemberHealthRecordLongevity;
    } else if (healthType === 'Happiness') {
      return this.props.addMemberHealthRecordHappiness;
    } else if (healthType === 'BPI') {
      return this.props.addMemberHealthRecordBPI;
    } else if (healthType === 'Mental') {
      return this.props.addMemberHealthRecordMental;
    }
  }
  _handleClose() {
    let { formData } = this.state;
    formData = [];
    this.setState({
      ...this.state,
      formData,
    });
    this.props.onClose();
  }
  render() {
    const { t } = this.props;
    const lang = commonUtils.getBrowserLanguage();
    const { healthType, healthTypeLabel } = this.props;
    const { formData } = this.state;
    var healthData = codeConstant['healthData' + healthType];

    if (healthType === 'Happiness') {
      healthData = healthData.filter(function (item) {
        return item.id !== 'total';
      });
    }
    return (
      <Modal
        show={this.props.open}
        onShow={this._handleShow}
        onHide={this._handleClose}
        backdrop="static"
        dialogClassName="modal-xl modal-dialog-scrollable modal-dialog-centered"
      >
        <Modal.Header>
          <h2 className="title">{healthTypeLabel}</h2>
        </Modal.Header>
        <Modal.Body>
          <div className="detail_index">
            <table className="table edit">
              <caption className="visually-hidden">테이블</caption>
              <colgroup>
                <col style={{ width: '210px' }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>{healthTypeLabel}</th>
                  {formData.map((item, index) => (
                    <th key={index}>
                      <div className="d-flex">
                        <DatePicker
                          id={'recordDate-' + index}
                          type={'date'}
                          value={item.recordDate ? moment(item.recordDate).format('YY.MM.DD') : ''}
                          onChange={this._handleChangeDate}
                          dateformat="yy.mm.dd"
                          lang={lang}
                        />
                      </div>
                    </th>
                  ))}
                </tr>
                {healthData.map((dataItem, dataIndex) => (
                  <tr key={dataIndex}>
                    <th>
                      {t(dataItem.label)}
                      {dataItem.unit === '' ? '' : '(' + t(dataItem.unit) + ')'}
                    </th>
                    {formData.map((item, index) => (
                      <th key={index}>
                        <div className="d-flex">
                          {dataItem.id === 'hyperlipidemiaYn' && (
                            <select
                              id={dataItem.id + '-' + index}
                              className="form-control"
                              value={item[dataItem.id]}
                              onChange={this._handleChange}
                            >
                              <option value=""></option>
                              <option value="Y">{t('유')}</option>
                              <option value="N">{t('무')}</option>
                            </select>
                          )}
                          {dataItem.id !== 'hyperlipidemiaYn' && (
                            <input
                              id={dataItem.id + '-' + index}
                              type="number"
                              className="form-control"
                              value={item[dataItem.id]}
                              onChange={this._handleChange}
                            />
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn_area">
            <button type="button" className="btn btn-primary" onClick={this._handleSave}>
              {t('확인')}
            </button>
            <button type="button" className="btn btn-outline-secondary" onClick={this._handleClose}>
              {t('취소')}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addMemberHealthRecordFitness,
      addMemberHealthRecordLongevity,
      addMemberHealthRecordHappiness,
      addMemberHealthRecordBPI,
      addMemberHealthRecordMental,
      fetchMemberHealthRecordFitnessList,
      fetchMemberHealthRecordFitnessAgegroupAvg,
      fetchMemberHealthRecordFitnessAgegroupRank,
      fetchMemberHealthRecordLongevityList,
      fetchMemberHealthRecordLongevityAgegroupAvg,
      fetchMemberHealthRecordLongevityAgegroupRank,
      fetchMemberHealthRecordHappinessList,
      fetchMemberHealthRecordHappinessAgegroupAvg,
      fetchMemberHealthRecordHappinessAgegroupRank,
      fetchMemberHealthRecordBPIList,
      fetchMemberHealthRecordBPIAgegroupAvg,
      fetchMemberHealthRecordBPIAgegroupRank,
      fetchMemberHealthRecordMentalList,
      fetchMemberHealthRecordMentalAgegroupAvg,
      fetchMemberHealthRecordMentalAgegroupRank,
      dispatch,
    },
    dispatch,
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HealthAddModal));
export { connected as HealthAddModal };
