import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { loading } from './loading.reducer';
import { alert } from './alert.reducer';
import { auth } from './auth.reducer';
import { exclass } from './class.reducer';
import { board } from './board.reducer';
import { trainer } from './trainer.reducer';
import { member } from './member.reducer';
import { user } from './user.reducer';
import { pattern } from './pattern.reducer';
import { tenant } from './tenant.reducer';
import { memo } from './memo.reducer';
import { statistics } from './statistics.reducer';
import { diagnosis } from './diagnosis.reducer';
import { range } from './range.reducer';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    loading,
    alert,
    auth,
    exclass,
    board,
    trainer,
    member,
    user,
    pattern,
    tenant,
    memo,
    statistics,
    diagnosis,
    range,
  });

export default createRootReducer;
