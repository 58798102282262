import { requestUrl, requestHeaders, requestParam, requestBody, requestFormData, handleResponse } from '_helpers';
import {
  fetchMemberListRequest,
  fetchMemberListSuccess,
  fetchMemberListError,
  fetchMemberRequest,
  fetchMemberSuccess,
  fetchMemberError,
  addMemberRequest,
  addMemberSuccess,
  addMemberError,
  modifyMemberRequest,
  modifyMemberSuccess,
  modifyMemberError,
  removeMemberRequest,
  removeMemberSuccess,
  removeMemberError,
  fetchClassesOfMemberRequest,
  fetchClassesOfMemberSuccess,
  fetchClassesOfMemberError,
  fetchMemberExerciseSummaryRequest,
  fetchMemberExerciseSummarySuccess,
  fetchMemberExerciseSummaryError,
  fetchMemberExerciseSummaryHistoryListRequest,
  fetchMemberExerciseSummaryHistoryListSuccess,
  fetchMemberExerciseSummaryHistoryListError,
  fetchMemberHealthRecordFitnessListRequest,
  fetchMemberHealthRecordFitnessListSuccess,
  fetchMemberHealthRecordFitnessListError,
  fetchMemberHealthRecordFitnessAgegroupAvgRequest,
  fetchMemberHealthRecordFitnessAgegroupAvgSuccess,
  fetchMemberHealthRecordFitnessAgegroupAvgError,
  fetchMemberHealthRecordFitnessAgegroupRankRequest,
  fetchMemberHealthRecordFitnessAgegroupRankSuccess,
  fetchMemberHealthRecordFitnessAgegroupRankError,
  fetchMemberHealthRecordLongevityListRequest,
  fetchMemberHealthRecordLongevityListSuccess,
  fetchMemberHealthRecordLongevityListError,
  fetchMemberHealthRecordLongevityAgegroupAvgRequest,
  fetchMemberHealthRecordLongevityAgegroupAvgSuccess,
  fetchMemberHealthRecordLongevityAgegroupAvgError,
  fetchMemberHealthRecordLongevityAgegroupRankRequest,
  fetchMemberHealthRecordLongevityAgegroupRankSuccess,
  fetchMemberHealthRecordLongevityAgegroupRankError,
  fetchMemberHealthRecordHappinessListRequest,
  fetchMemberHealthRecordHappinessListSuccess,
  fetchMemberHealthRecordHappinessListError,
  fetchMemberHealthRecordHappinessAgegroupAvgRequest,
  fetchMemberHealthRecordHappinessAgegroupAvgSuccess,
  fetchMemberHealthRecordHappinessAgegroupAvgError,
  fetchMemberHealthRecordHappinessAgegroupRankRequest,
  fetchMemberHealthRecordHappinessAgegroupRankSuccess,
  fetchMemberHealthRecordHappinessAgegroupRankError,
  fetchMemberHealthRecordBPIListRequest,
  fetchMemberHealthRecordBPIListSuccess,
  fetchMemberHealthRecordBPIListError,
  fetchMemberHealthRecordBPIAgegroupAvgRequest,
  fetchMemberHealthRecordBPIAgegroupAvgSuccess,
  fetchMemberHealthRecordBPIAgegroupAvgError,
  fetchMemberHealthRecordBPIAgegroupRankRequest,
  fetchMemberHealthRecordBPIAgegroupRankSuccess,
  fetchMemberHealthRecordBPIAgegroupRankError,
  fetchMemberHealthRecordMentalListRequest,
  fetchMemberHealthRecordMentalListSuccess,
  fetchMemberHealthRecordMentalListError,
  fetchMemberHealthRecordMentalAgegroupAvgRequest,
  fetchMemberHealthRecordMentalAgegroupAvgSuccess,
  fetchMemberHealthRecordMentalAgegroupAvgError,
  fetchMemberHealthRecordMentalAgegroupRankRequest,
  fetchMemberHealthRecordMentalAgegroupRankSuccess,
  fetchMemberHealthRecordMentalAgegroupRankError,
  fetchMemberPrintRequest,
  fetchMemberPrintSuccess,
  fetchMemberPrintError,
  addMemberHealthRecordFitnessRequest,
  addMemberHealthRecordFitnessSuccess,
  addMemberHealthRecordFitnessError,
  modifyMemberHealthRecordFitnessRequest,
  modifyMemberHealthRecordFitnessSuccess,
  modifyMemberHealthRecordFitnessError,
  removeMemberHealthRecordFitnessRequest,
  removeMemberHealthRecordFitnessSuccess,
  removeMemberHealthRecordFitnessError,
  addMemberHealthRecordLongevityRequest,
  addMemberHealthRecordLongevitySuccess,
  addMemberHealthRecordLongevityError,
  modifyMemberHealthRecordLongevityRequest,
  modifyMemberHealthRecordLongevitySuccess,
  modifyMemberHealthRecordLongevityError,
  removeMemberHealthRecordLongevityRequest,
  removeMemberHealthRecordLongevitySuccess,
  removeMemberHealthRecordLongevityError,
  addMemberHealthRecordHappinessRequest,
  addMemberHealthRecordHappinessSuccess,
  addMemberHealthRecordHappinessError,
  modifyMemberHealthRecordHappinessRequest,
  modifyMemberHealthRecordHappinessSuccess,
  modifyMemberHealthRecordHappinessError,
  removeMemberHealthRecordHappinessRequest,
  removeMemberHealthRecordHappinessSuccess,
  removeMemberHealthRecordHappinessError,
  addMemberHealthRecordBPIRequest,
  addMemberHealthRecordBPISuccess,
  addMemberHealthRecordBPIError,
  modifyMemberHealthRecordBPIRequest,
  modifyMemberHealthRecordBPISuccess,
  modifyMemberHealthRecordBPIError,
  removeMemberHealthRecordBPIRequest,
  removeMemberHealthRecordBPISuccess,
  removeMemberHealthRecordBPIError,
  addMemberHealthRecordMentalRequest,
  addMemberHealthRecordMentalSuccess,
  addMemberHealthRecordMentalError,
  modifyMemberHealthRecordMentalRequest,
  modifyMemberHealthRecordMentalSuccess,
  modifyMemberHealthRecordMentalError,
  removeMemberHealthRecordMentalRequest,
  removeMemberHealthRecordMentalSuccess,
  removeMemberHealthRecordMentalError,
} from '_actions';

// 회원 목록조회
export function fetchMemberList(params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members?${requestParam(params)}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemberListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response);
        dispatch(fetchMemberListSuccess(response));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemberListError(error));
      });
  };
}
// 회원 조회
export function fetchMember(userId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemberRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchMemberSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemberError(error));
      });
  };
}
// 회원 등록
export function addMember(params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({}),
      body: requestFormData(params),
    };
    dispatch(addMemberRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(addMemberSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(addMemberError(error));
      });
  };
}
// 회원 수정
export function modifyMember(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'PUT',
      headers: requestHeaders({}),
      body: requestFormData(params),
    };
    dispatch(modifyMemberRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(modifyMemberSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(modifyMemberError(error));
      });
  };
}
// 회원 삭제
export function removeMember(userId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(removeMemberRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(removeMemberSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(removeMemberError(error));
      });
  };
}
// 사용자 클래스 목록
export function fetchClassesOfMember(userId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/classes`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchClassesOfMemberRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchClassesOfMemberSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchClassesOfMemberError(error));
      });
  };
}
// 사용자의 운동 요약
export function fetchMemberExerciseSummary(userId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/exercises/summary`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemberExerciseSummaryRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchMemberExerciseSummarySuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemberExerciseSummaryError(error));
      });
  };
}
// 사용자의 운동 요약 목록
export function fetchMemberExerciseSummaryHistoryList(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(
      `${process.env.REACT_APP_APIURL}/api/members/${userId}/exercises/summary-history?${requestParam(params)}`,
    );
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemberExerciseSummaryHistoryListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response);
        dispatch(fetchMemberExerciseSummaryHistoryListSuccess(response));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemberExerciseSummaryHistoryListError(error));
      });
  };
}
// 사용자의 건강기록 일상생활체력 목록
export function fetchMemberHealthRecordFitnessList(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(
      `${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/fitness?${requestParam(params)}`,
    );
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemberHealthRecordFitnessListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response);
        dispatch(fetchMemberHealthRecordFitnessListSuccess(response));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemberHealthRecordFitnessListError(error));
      });
  };
}
// 사용자의 건강기록 일상생활체력 나이대평균
export function fetchMemberHealthRecordFitnessAgegroupAvg(userId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/fitness/agegroup-avg`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemberHealthRecordFitnessAgegroupAvgRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchMemberHealthRecordFitnessAgegroupAvgSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemberHealthRecordFitnessAgegroupAvgError(error));
      });
  };
}
// 사용자의 건강기록 일상생활체력 나이대랭킹
export function fetchMemberHealthRecordFitnessAgegroupRank(userId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/fitness/agegroup-rank`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemberHealthRecordFitnessAgegroupRankRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchMemberHealthRecordFitnessAgegroupRankSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemberHealthRecordFitnessAgegroupRankError(error));
      });
  };
}
// 사용자의 건강기록 장수연령 목록
export function fetchMemberHealthRecordLongevityList(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(
      `${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/longevity?${requestParam(params)}`,
    );
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemberHealthRecordLongevityListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response);
        dispatch(fetchMemberHealthRecordLongevityListSuccess(response));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemberHealthRecordLongevityListError(error));
      });
  };
}
// 사용자의 건강기록 장수연령 나이대평균
export function fetchMemberHealthRecordLongevityAgegroupAvg(userId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/longevity/agegroup-avg`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemberHealthRecordLongevityAgegroupAvgRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchMemberHealthRecordLongevityAgegroupAvgSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemberHealthRecordLongevityAgegroupAvgError(error));
      });
  };
}
// 사용자의 건강기록 장수연령 나이대랭킹
export function fetchMemberHealthRecordLongevityAgegroupRank(userId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/longevity/agegroup-rank`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemberHealthRecordLongevityAgegroupRankRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchMemberHealthRecordLongevityAgegroupRankSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemberHealthRecordLongevityAgegroupRankError(error));
      });
  };
}
// 사용자의 건강기록 행복증진 목록
export function fetchMemberHealthRecordHappinessList(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(
      `${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/happiness?${requestParam(params)}`,
    );
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemberHealthRecordHappinessListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response);
        dispatch(fetchMemberHealthRecordHappinessListSuccess(response));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemberHealthRecordHappinessListError(error));
      });
  };
}
// 사용자의 건강기록 행복증진 나이대평균
export function fetchMemberHealthRecordHappinessAgegroupAvg(userId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/happiness/agegroup-avg`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemberHealthRecordHappinessAgegroupAvgRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchMemberHealthRecordHappinessAgegroupAvgSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemberHealthRecordHappinessAgegroupAvgError(error));
      });
  };
}
// 사용자의 건강기록 행복증진 나이대랭킹
export function fetchMemberHealthRecordHappinessAgegroupRank(userId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/happiness/agegroup-rank`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemberHealthRecordHappinessAgegroupRankRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchMemberHealthRecordHappinessAgegroupRankSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemberHealthRecordHappinessAgegroupRankError(error));
      });
  };
}
// 사용자의 건강기록 일상생활체력 등록
export function addMemberHealthRecordFitness(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/fitness`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(addMemberHealthRecordFitnessRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(addMemberHealthRecordFitnessSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(addMemberHealthRecordFitnessError(error));
      });
  };
}
// 사용자의 건강기록 일상생활체력 수정
export function modifyMemberHealthRecordFitness(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/fitness`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'PUT',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(modifyMemberHealthRecordFitnessRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(modifyMemberHealthRecordFitnessSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(modifyMemberHealthRecordFitnessError(error));
      });
  };
}
// 사용자의 건강기록 일상생활체력 삭제
export function removeMemberHealthRecordFitness(userId, recordDate, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/fitness/${recordDate}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(removeMemberHealthRecordFitnessRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(removeMemberHealthRecordFitnessSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(removeMemberHealthRecordFitnessError(error));
      });
  };
}
// 사용자의 건강기록 장수연령 등록
export function addMemberHealthRecordLongevity(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/longevity`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(addMemberHealthRecordLongevityRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(addMemberHealthRecordLongevitySuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(addMemberHealthRecordLongevityError(error));
      });
  };
}
// 사용자의 건강기록 장수연령 수정
export function modifyMemberHealthRecordLongevity(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/longevity`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'PUT',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(modifyMemberHealthRecordLongevityRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(modifyMemberHealthRecordLongevitySuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(modifyMemberHealthRecordLongevityError(error));
      });
  };
}
// 사용자의 건강기록 장수연령 삭제
export function removeMemberHealthRecordLongevity(userId, recordDate, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/longevity/${recordDate}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(removeMemberHealthRecordLongevityRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(removeMemberHealthRecordLongevitySuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(removeMemberHealthRecordLongevityError(error));
      });
  };
}
// 사용자의 건강기록 행복증진 등록
export function addMemberHealthRecordHappiness(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/happiness`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(addMemberHealthRecordHappinessRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(addMemberHealthRecordHappinessSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(addMemberHealthRecordHappinessError(error));
      });
  };
}
// 사용자의 건강기록 행복증진 수정
export function modifyMemberHealthRecordHappiness(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/happiness`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'PUT',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(modifyMemberHealthRecordHappinessRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(modifyMemberHealthRecordHappinessSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(modifyMemberHealthRecordHappinessError(error));
      });
  };
}
// 사용자의 건강기록 행복증진 삭제
export function removeMemberHealthRecordHappiness(userId, recordDate, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/happiness/${recordDate}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(removeMemberHealthRecordHappinessRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(removeMemberHealthRecordHappinessSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(removeMemberHealthRecordHappinessError(error));
      });
  };
}

// 사용자의 건강기록 뇌인지 목록
export function fetchMemberHealthRecordBPIList(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(
      `${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/bpi?${requestParam(params)}`,
    );
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemberHealthRecordBPIListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response);
        dispatch(fetchMemberHealthRecordBPIListSuccess(response));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemberHealthRecordBPIListError(error));
      });
  };
}
// 사용자의 건강기록 뇌인지 나이대평균
export function fetchMemberHealthRecordBPIAgegroupAvg(userId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/bpi/agegroup-avg`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemberHealthRecordBPIAgegroupAvgRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchMemberHealthRecordBPIAgegroupAvgSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemberHealthRecordBPIAgegroupAvgError(error));
      });
  };
}
// 사용자의 건강기록 뇌인지 나이대랭킹
export function fetchMemberHealthRecordBPIAgegroupRank(userId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/bpi/agegroup-rank`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemberHealthRecordBPIAgegroupRankRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchMemberHealthRecordBPIAgegroupRankSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemberHealthRecordBPIAgegroupRankError(error));
      });
  };
}
// 사용자의 건강기록 뇌인지 등록
export function addMemberHealthRecordBPI(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/bpi`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(addMemberHealthRecordBPIRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(addMemberHealthRecordBPISuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(addMemberHealthRecordBPIError(error));
      });
  };
}
// 사용자의 건강기록 뇌인지 수정
export function modifyMemberHealthRecordBPI(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/bpi`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'PUT',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(modifyMemberHealthRecordBPIRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(modifyMemberHealthRecordBPISuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(modifyMemberHealthRecordBPIError(error));
      });
  };
}
// 사용자의 건강기록 뇌인지 삭제
export function removeMemberHealthRecordBPI(userId, recordDate, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/bpi/${recordDate}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(removeMemberHealthRecordBPIRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(removeMemberHealthRecordBPISuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(removeMemberHealthRecordBPIError(error));
      });
  };
}
// 사용자의 건강기록 뇌인지 목록
export function fetchMemberHealthRecordMentalList(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(
      `${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/mental?${requestParam(params)}`,
    );
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemberHealthRecordMentalListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response);
        dispatch(fetchMemberHealthRecordMentalListSuccess(response));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemberHealthRecordMentalListError(error));
      });
  };
}
// 사용자의 건강기록 뇌인지 나이대평균
export function fetchMemberHealthRecordMentalAgegroupAvg(userId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/mental/agegroup-avg`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemberHealthRecordMentalAgegroupAvgRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchMemberHealthRecordMentalAgegroupAvgSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemberHealthRecordMentalAgegroupAvgError(error));
      });
  };
}
// 사용자의 건강기록 뇌인지 나이대랭킹
export function fetchMemberHealthRecordMentalAgegroupRank(userId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/mental/agegroup-rank`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemberHealthRecordMentalAgegroupRankRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchMemberHealthRecordMentalAgegroupRankSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemberHealthRecordMentalAgegroupRankError(error));
      });
  };
}
// 사용자의 건강기록 뇌인지 등록
export function addMemberHealthRecordMental(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/mental`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(addMemberHealthRecordMentalRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(addMemberHealthRecordMentalSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(addMemberHealthRecordMentalError(error));
      });
  };
}
// 사용자의 건강기록 뇌인지 수정
export function modifyMemberHealthRecordMental(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/mental`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'PUT',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(modifyMemberHealthRecordMentalRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(modifyMemberHealthRecordMentalSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(modifyMemberHealthRecordMentalError(error));
      });
  };
}
// 사용자의 건강기록 뇌인지 삭제
export function removeMemberHealthRecordMental(userId, recordDate, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/mental/${recordDate}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(removeMemberHealthRecordMentalRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(removeMemberHealthRecordMentalSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(removeMemberHealthRecordMentalError(error));
      });
  };
}

// 사용자 요약본 인쇄
export function fetchMemberPrint(userId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/members/${userId}/health-records/summary`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchMemberPrintRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchMemberPrintSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchMemberPrintError(error));
      });
  };
}
