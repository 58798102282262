/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Header, SideBar, Pagination } from '_components';
import { fetchBoardList } from '_services';
import { pathConstant } from '_constants';

class SystemBoardMgt extends Component {
  constructor(props) {
    super(props);

    this.state = { keyword: '', pageSize: 10, pageNo: 1 };

    this._handleChange = this._handleChange.bind(this);
    this._handleSearch = this._handleSearch.bind(this);
    this._handlePageMove = this._handlePageMove.bind(this);
    this._handleDetail = this._handleDetail.bind(this);
    this._handleAdd = this._handleAdd.bind(this);
  }
  componentDidMount() {
    window.$('.jquery_scrollbar').scrollbar();
    const { pageNo } = this.state;
    this._loadData(pageNo);
  }
  static getDerivedStateFromProps(props, state) {
    const listData = props.systemBoardListData;
    if (listData && listData.pagination.pageNo !== state.pageNo) {
      return {
        pageNo: listData.pagination.pageNo,
      };
    }
    return null;
  }
  _loadData(pageNo) {
    const { pageSize, keyword } = this.state;
    const params = { pageNo, pageSize, keyword };
    this.props.fetchBoardList('S', params);
  }
  _handleChange(e) {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value });
  }
  _handleSearch(e) {
    e.preventDefault();
    const { pageNo } = this.state;
    this._loadData(pageNo);
  }
  _handlePageMove(pageNo) {
    this._loadData(pageNo);
  }
  _handleDetail(e, item) {
    e.preventDefault();
    this.props.history.push({
      pathname: pathConstant.PATH_BOARD_DETAIL,
      state: { caller: 'systemboardmgt', boardType: 'S', data: item },
    });
  }
  _handleAdd() {
    this.props.history.push({
      pathname: pathConstant.PATH_BOARD_EDIT,
      state: { caller: 'systemboardmgt', mode: 'add', boardType: 'S' },
    });
  }
  render() {
    const { t } = this.props;
    document.title = `${t('공지사항 관리')} | ${t('모스텝')}`;
    const breadcrumb = [t('공지사항 관리')];
    const { keyword, pageSize, pageNo } = this.state;
    const { systemBoardListData } = this.props;
    const pagination = systemBoardListData ? systemBoardListData.pagination : null;
    return (
      <>
        <Header {...this.props} breadcrumb={breadcrumb} />
        <SideBar {...this.props} />

        <main className="contents_wrapper">
          <div className="jquery_scrollbar">
            <div className="contents">
              <div className="contents_body card">
                <div className="card_header">
                  <div className="contents_tool">
                    <form className="search_bar" onSubmit={this._handleSearch}>
                      <input
                        id="keyword"
                        className="form-control"
                        type="text"
                        placeholder={t('제목이나 작성자 이름을 입력해 주세요.')}
                        value={keyword}
                        onChange={this._handleChange}
                      />
                      <button type="submit" className="btn btn-outline-primary">
                        {t('검색')}
                      </button>
                    </form>
                  </div>
                  <div className="btn_area">
                    <button type="button" className="btn btn-outline-secondary" onClick={this._handleAdd}>
                      <span className="ico edit"></span>
                      <span>{t('공지사항 작성')}</span>
                    </button>
                  </div>
                </div>

                <div className="card_body">
                  <BoardView
                    t={t}
                    data={systemBoardListData && systemBoardListData.data}
                    pagination={pagination}
                    pageSize={pageSize}
                    pageNo={pageNo}
                    onDetail={this._handleDetail}
                    onPageMove={this._handlePageMove}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
class BoardView extends Component {
  render() {
    const { t } = this.props;
    const { data, pagination, pageSize, pageNo, onDetail, onPageMove } = this.props;
    return (
      <>
        <table className={`table ${data && data.length > 0 ? '' : 'none_data'}`}>
          <caption className="visually-hidden">테이블</caption>
          <colgroup>
            <col style={{ width: '800px' }} />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">
                <p className="d-flex justify-content-start">{t('제목')}</p>
              </th>
              <th scope="col">{t('작성자')}</th>
              <th scope="col">
                <p className="d-flex">
                  <span>{t('날짜')}</span>
                  {/* <span className="ico descending"></span> */}
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {(!data || data.length === 0) && (
              <tr>
                <td colSpan="3">
                  <p>{t('공지사항이 없습니다.')}</p>
                </td>
              </tr>
            )}
            {data &&
              data.map((item, index) => (
                <tr key={index}>
                  <td className="text-start">
                    <a href="" onClick={e => onDetail(e, item)} className="ellipsis">
                      {item.title}
                    </a>
                  </td>
                  <td>{item.userNm}</td>
                  <td>{item.createdTime}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <Pagination
          totalCount={pagination ? pagination.totalCount : 0}
          rowCount={pagination ? pagination.pageSize : pageSize}
          page={pagination ? pagination.pageNo : pageNo}
          onPageMove={onPageMove}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  const { board } = state;
  return {
    systemBoardListData: board.systemBoardListData,
  };
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchBoardList,
      dispatch,
    },
    dispatch,
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SystemBoardMgt));
export { connected as SystemBoardMgt };
