import { requestUrl, requestHeaders, requestParam, requestFormData, handleResponse } from '_helpers';
import {
  fetchClassesOfTrainerRequest,
  fetchClassesOfTrainerSuccess,
  fetchClassesOfTrainerError,
  fetchTrainerListRequest,
  fetchTrainerListSuccess,
  fetchTrainerListError,
  addTrainerRequest,
  addTrainerSuccess,
  addTrainerError,
  modifyTrainerRequest,
  modifyTrainerSuccess,
  modifyTrainerError,
  removeTrainerRequest,
  removeTrainerSuccess,
  removeTrainerError,
} from '_actions';

// 트레이너의 클래스 목록
export function fetchClassesOfTrainer(userId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/trainers/${userId}/classes`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchClassesOfTrainerRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(fetchClassesOfTrainerSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchClassesOfTrainerError(error));
      });
  };
}
// 트레이너 목록조회
export function fetchTrainerList(params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/trainers?${requestParam(params)}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchTrainerListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response);
        dispatch(fetchTrainerListSuccess(response));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchTrainerListError(error));
      });
  };
}
// 트레이너 등록
export function addTrainer(params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/trainers`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({}),
      body: requestFormData(params),
    };
    dispatch(addTrainerRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(addTrainerSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(addTrainerError(error));
      });
  };
}
// 트레이너 수정
export function modifyTrainer(userId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/trainers/${userId}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'PUT',
      headers: requestHeaders({}),
      body: requestFormData(params),
    };
    dispatch(modifyTrainerRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(modifyTrainerSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(modifyTrainerError(error));
      });
  };
}
// 트레이너 삭제
export function removeTrainer(userId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/trainers/${userId}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(removeTrainerRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(removeTrainerSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(removeTrainerError(error));
      });
  };
}
