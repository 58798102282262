import {
  FETCH_MEMO_LIST_REQUEST,
  FETCH_MEMO_LIST_SUCCESS,
  FETCH_MEMO_LIST_ERROR,
  ADD_MEMO_REQUEST,
  ADD_MEMO_SUCCESS,
  ADD_MEMO_ERROR,
  MODIFY_MEMO_REQUEST,
  MODIFY_MEMO_SUCCESS,
  MODIFY_MEMO_ERROR,
} from '_actions';

const initialState = {
  pending: false,
  memoListData: null,
  error: null,
};

export function memo(state = initialState, action) {
  switch (action.type) {
    case FETCH_MEMO_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMO_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        memoListData: action.data,
      };
    case FETCH_MEMO_LIST_ERROR:
      return {
        ...state,
        pending: false,
        memoListData: null,
        error: action.error,
      };
    case ADD_MEMO_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_MEMO_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_MEMO_ERROR:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_MEMO_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case MODIFY_MEMO_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case MODIFY_MEMO_ERROR:
      return {
        ...state,
        pending: false,
      };
    default:
      return state;
  }
}
