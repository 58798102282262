import React, { Component } from 'react';
import moment from 'moment';

export class DateRange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      years: [],
      months: [],
      days: [],
      year: '',
      month: '',
      day: '',
    };
    this._handlChangeYear = this._handlChangeYear.bind(this);
    this._handlChangeMonth = this._handlChangeMonth.bind(this);
    this._handlChangeDay = this._handlChangeDay.bind(this);
  }
  componentDidMount() {
    const { maxYear, minYear } = this.props;
    const years = [];
    const months = [];
    const days = [];
    const date = this.props.date ? moment(this.props.date) : null;
    for (var i = minYear; i <= maxYear; i++) {
      years.push(i);
    }
    for (i = 1; i <= 12; i++) {
      months.push(i);
    }
    if (date) {
      const dayCount = date.daysInMonth();
      for (i = 1; i <= dayCount; i++) {
        days.push(i);
      }
    }
    this.setState({
      ...this.state,
      years,
      months,
      days,
      year: date ? date.format('YYYY') : '',
      month: date ? date.format('M') : '',
      day: date ? date.format('D') : '',
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.date !== this.props.date) {
      const date = moment(this.props.date);
      this._setDate(date.format('YYYY'), date.format('M'), date.format('D'));
    }
  }
  _handlChangeYear(e) {
    const { month, day } = this.state;
    this._setDate(e.target.value, month, day, true);
  }
  _handlChangeMonth(e) {
    const { year, day } = this.state;
    this._setDate(year, e.target.value, day, true);
  }
  _handlChangeDay(e) {
    const { year, month } = this.state;
    this._setDate(year, month, e.target.value, true);
  }
  _setDate(year, month, day, isChanged) {
    let date = moment(year + '-' + month + '-' + day, 'YYYY-M-D');
    if (!date.isValid()) {
      day = 1;
      date = moment(year + '-' + month + '-' + day, 'YYYY-M-D');
    }
    const days = [];
    const dayCount = date.daysInMonth();
    for (var i = 1; i <= dayCount; i++) {
      days.push(i);
    }
    this.setState({
      ...this.state,
      days,
      year,
      month,
      day,
    });

    if (isChanged) {
      this.props.onChange(date.format('YYYY-MM-DD'));
    }
  }
  render() {
    const { className } = this.props;
    const { years, months, days, year, month, day } = this.state;
    return (
      <>
        <div className={className}>
          <select className="form-select" onChange={this._handlChangeYear} value={year}>
            {years.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
          <select className="form-select" onChange={this._handlChangeMonth} value={month}>
            {months.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
          <select className="form-select" onChange={this._handlChangeDay} value={day}>
            {days.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  }
}
