import { FETCH_STATISTICS_TOTAL_REQUEST, FETCH_STATISTICS_TOTAL_SUCCESS, FETCH_STATISTICS_TOTAL_ERROR } from '_actions';

const initialState = {
  pending: false,
  statisticsTotalData: null,
  error: null,
};

export function statistics(state = initialState, action) {
  switch (action.type) {
    case FETCH_STATISTICS_TOTAL_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_STATISTICS_TOTAL_SUCCESS:
      return {
        ...state,
        pending: false,
        statisticsTotalData: action.data,
      };
    case FETCH_STATISTICS_TOTAL_ERROR:
      return {
        ...state,
        pending: false,
        statisticsTotalData: null,
        error: action.error,
      };
    default:
      return state;
  }
}
