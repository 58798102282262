export const FETCH_STATISTICS_TOTAL_REQUEST = 'FETCH_STATISTICS_TOTAL_REQUEST';
export const FETCH_STATISTICS_TOTAL_SUCCESS = 'FETCH_STATISTICS_TOTAL_SUCCESS';
export const FETCH_STATISTICS_TOTAL_ERROR = 'FETCH_STATISTICS_TOTAL_ERROR';

export function fetchStatisticsTotalRequest() {
  return { type: FETCH_STATISTICS_TOTAL_REQUEST };
}
export function fetchStatisticsTotalSuccess(data) {
  return { type: FETCH_STATISTICS_TOTAL_SUCCESS, data };
}
export function fetchStatisticsTotalError(error) {
  return { type: FETCH_STATISTICS_TOTAL_ERROR, error };
}
