export const CHECK_LOGIN_ID_REQUEST = 'CHECK_LOGIN_ID_REQUEST';
export const CHECK_LOGIN_ID_REQUEST_SUCCESS = 'CHECK_LOGIN_ID_REQUEST_SUCCESS';
export const CHECK_LOGIN_ID_REQUEST_ERROR = 'CHECK_LOGIN_ID_REQUEST_ERROR';
export const CHECK_EMAIL_REQUEST = 'CHECK_EMAIL_REQUEST';
export const CHECK_EMAIL_REQUEST_SUCCESS = 'CHECK_EMAIL_REQUEST_SUCCESS';
export const CHECK_EMAIL_REQUEST_ERROR = 'CHECK_EMAIL_REQUEST_ERROR';
export const CHANGEPASSWORD_USER_REQUEST = 'CHANGEPASSWORD_USER_REQUEST';
export const CHANGEPASSWORD_USER_SUCCESS = 'CHANGEPASSWORD_USER_SUCCESS';
export const CHANGEPASSWORD_USER_ERROR = 'CHANGEPASSWORD_USER_ERROR';

export function checkLoginIdRequest() {
  return { type: CHECK_LOGIN_ID_REQUEST };
}
export function checkLoginIdSuccess(data) {
  return { type: CHECK_LOGIN_ID_REQUEST_SUCCESS, data };
}
export function checkLoginIdError(error) {
  return { type: CHECK_LOGIN_ID_REQUEST_ERROR, error };
}
export function checkEmailRequest() {
  return { type: CHECK_EMAIL_REQUEST };
}
export function checkEmailSuccess(data) {
  return { type: CHECK_EMAIL_REQUEST_SUCCESS, data };
}
export function checkEmailError(error) {
  return { type: CHECK_EMAIL_REQUEST_ERROR, error };
}
export function changePasswordUserRequest() {
  return { type: CHANGEPASSWORD_USER_REQUEST };
}
export function changePasswordUserSuccess(data) {
  return { type: CHANGEPASSWORD_USER_SUCCESS, data };
}
export function changePasswordUserError(error) {
  return { type: CHANGEPASSWORD_USER_ERROR, error };
}
