/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { commonUtils } from '_helpers';
import { Header, SideBar } from '_components';
import { openAlertDialog } from '_actions';
import { addPatternGroup, modifyPatternGroup, fetchPatternGroup, fetchPatternLevelList, fetchPatternList } from '_services';

class PatternGroupEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      patternGroupNm: '',
      description: '',
      selectedAllPatternIndex: -1,
      selectedUsedPatternIndex: -1,
      patternAllData: '',
      patternUsedData: '',
      usedPatternList: [],
      patternLevelId: '',
      keyword: '',
      isChanged: false,
    };

    this._handleChange = this._handleChange.bind(this);
    this._handleChangePatternLevel = this._handleChangePatternLevel.bind(this);
    this._handleSearch = this._handleSearch.bind(this);
    this._handleSave = this._handleSave.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
  }
  componentDidMount() {
    window.$('.jquery_scrollbar').scrollbar();
    this._loadData();
  }
  _loadData() {
    this._loadPatternGroup();
    this._loadPatternLevelList();
    this._loadPatternList('', '');
  }
  _loadPatternGroup() {
    const { data } = this.props.location.state;
    if (data) {
      this.props.fetchPatternGroup(data.patternGroupId, (error, result) => {
        if (!error) {
          this.setState({
            patternGroupNm: result.patternGroupNm,
            description: result.description,
            usedPatternList: result.patternGroupRoundList,
          });
        }
      });
    }
  }
  _loadPatternLevelList() {
    const { patternLevelListData } = this.props;
    if (!patternLevelListData) {
      this.props.fetchPatternLevelList();
    }
  }
  _loadPatternList(patternLevelId, keyword) {
    this.setState({
      selectedAllPatternIndex: -1,
    });
    const params = {
      patternLevelId,
      keyword,
    };
    this.props.fetchPatternList(params);
  }
  _handleChange(e) {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value, isChanged: id === 'keyword' ? this.state.isChanged : true });
  }
  _handleSearch(e) {
    e.preventDefault();
    const { patternLevelId, keyword } = this.state;
    this._loadPatternList(patternLevelId, keyword);
  }
  _handleChangePatternLevel(e) {
    const { keyword } = this.state;
    const patternLevelId = e.target.value;
    this.setState({
      ...this.state,
      patternLevelId,
    });
    this._loadPatternList(patternLevelId, keyword);
  }
  _handleSave() {
    const { patternGroupNm, description, usedPatternList } = this.state;
    const { data } = this.props.location.state;

    const editableYn = usedPatternList.filter(item => item.editableYn === 'N');
    if (editableYn.length > 0) {
      const { t } = this.props;
      this.props.dispatch(openAlertDialog(t('현재 수업 진행 (예정)중이거나, 수업이 종료된 패턴은 수정이 불가능 합니다.')));
      return;
    }

    let patternGroupRoundList = [];
    usedPatternList.forEach((e, i) => {
      patternGroupRoundList.push({
        patternGroupRoundNo: i + 1,
        patternId: e.patternId,
      });
    });
    const params = { patternGroupNm, description, patternGroupRoundList };
    if (data) {
      this._modifyPatternGroup(data.patternGroupId, params);
    } else {
      this._addPatternGroup(params);
    }
  }
  _modifyPatternGroup(patternGroupId, params) {
    const { t } = this.props;
    this.props.modifyPatternGroup(patternGroupId, params, error => {
      if (!error) {
        this.props.dispatch(
          openAlertDialog(t('수정되었습니다.'), 'success', false, null, null, () => {
            this.props.history.goBack();
          }),
        );
      }
    });
  }
  _addPatternGroup(params) {
    const { t } = this.props;
    this.props.addPatternGroup(params, error => {
      if (!error) {
        this.props.dispatch(
          openAlertDialog(t('등록되었습니다.'), 'success', false, null, null, () => {
            this.props.history.goBack();
          }),
        );
      }
    });
  }
  _handleCancel() {
    const { t } = this.props;
    const { isChanged } = this.state;
    if (isChanged) {
      this.props.dispatch(
        openAlertDialog(
          t('작성중인 내역이 있습니다.<br/>정말로 이전 화면으로 돌아가시겠습니까?'),
          'alert',
          true,
          null,
          null,
          () => {
            this.props.history.goBack();
          },
        ),
      );
    } else {
      this.props.history.goBack();
    }
  }
  render() {
    const { t } = this.props;
    const lang = commonUtils.getBrowserLanguage();
    const { data } = this.props.location.state;
    const {
      patternGroupNm,
      description,
      usedPatternList,
      selectedUsedPatternIndex,
      selectedAllPatternIndex,
      patternLevelId,
      keyword,
      isChanged,
    } = this.state;
    const { patternLevelListData, patternListData } = this.props;
    let breadcrumb = '';
    if (lang === 'ko') {
      document.title = `${t('패턴 그룹')} ${data === null ? t('등록') : t('수정')} | ${t('모스텝')}`;
      breadcrumb = [t('패턴 그룹 관리'), `${t('패턴 그룹')} ${data === null ? t('등록') : t('수정')}`];
    } else {
      document.title = `${data === null ? t('등록') : t('수정')} ${t('패턴 그룹')} | ${t('모스텝')}`;
      breadcrumb = [t('패턴 그룹 관리'), `${data === null ? t('등록') : t('수정')} ${t('패턴 그룹')}`];
    }
    return (
      <>
        <Header {...this.props} breadcrumb={breadcrumb} />
        <SideBar {...this.props} />
        <main className="contents_wrapper">
          <div className="jquery_scrollbar">
            <div className="contents">
              <div className="contents_body card">
                <div className="card_header">
                  <h3 className="title">
                    <span>
                      {lang === 'ko' && `${t('패턴 그룹')} ${data === null ? t('등록') : t('수정')}`}
                      {lang === 'en' && `${data === null ? t('등록') : t('수정')} ${t('패턴 그룹')}`}
                    </span>
                    <span className="helper_text">
                      (<sup className="ico asterisk"></sup>
                      {t('표시는 필수입력항목입니다.')})
                    </span>
                  </h3>
                </div>
                <div className="card_body form form_wide">
                  <ul className="form_section">
                    <li className="form-group">
                      <label htmlFor="patternGroupNm" className="form-label">
                        {t('패턴 그룹 제목')}
                        <sup className="ico asterisk"></sup>
                      </label>
                      <input
                        id="patternGroupNm"
                        className="form-control"
                        type="text"
                        value={patternGroupNm}
                        onChange={this._handleChange}
                      />
                    </li>
                    <li className="form-group">
                      <label htmlFor="description" className="form-label">
                        {t('패턴 그룹 설명')}
                        <sup className="ico asterisk"></sup>
                      </label>
                      <textarea
                        id="description"
                        className="form-control"
                        rows="8"
                        value={description ? description.replaceAll('<br/>', '\n') : ''}
                        onChange={this._handleChange}
                      ></textarea>
                    </li>
                  </ul>
                  <ul className="form_section">
                    <li className="form-group pattern_preview_box">
                      <div className="pattern_preview">
                        {this.state.patternAllData && (
                          <SelectPatternView
                            patternId={this.state.patternAllData.patternId}
                            patternData={this.state.patternAllData.stepSequence}
                            repeat={this.state.patternAllData.stepCycle}
                            description={this.state.patternAllData.description}
                            t={t}
                          />
                        )}
                      </div>
                      <div className="pattern_setting">
                        <div className="d-flex">
                          <label htmlFor="" className="form-label">
                            {t('패턴 그룹 편집')}
                            <sup className="ico asterisk"></sup>
                          </label>
                          <select
                            id=""
                            className="form-select"
                            onChange={this._handleChangePatternLevel}
                            value={patternLevelId}
                          >
                            <option value="">{t('전체')}</option>
                            {patternLevelListData &&
                              patternLevelListData.map((item, index) => (
                                <option key={index} value={item.patternLevelId}>
                                  {t(item.patternLevelNm)}
                                </option>
                              ))}
                          </select>
                          <form className="search_bar" onSubmit={this._handleSearch}>
                            <input
                              id="keyword"
                              className="form-control"
                              type="text"
                              placeholder={t('패턴명으로 검색하세요.')}
                              value={keyword}
                              onChange={this._handleChange}
                            />
                            <button type="button" className="btn btn-outline-primary">
                              {t('검색')}
                            </button>
                          </form>
                        </div>
                        <div className="sorting_wrapper">
                          <div className="list-group">
                            <h4 className="title">{t('전체 패턴')}</h4>
                            <div className="jquery_scrollbar">
                              <div className="scroll_inner">
                                <ul>
                                  {patternListData &&
                                    patternListData.map((item, index) => (
                                      <li
                                        key={index}
                                        className={selectedAllPatternIndex === index ? 'checked' : ''}
                                        onClick={() => {
                                          if (selectedAllPatternIndex === index) {
                                            this.setState({
                                              ...this.state,
                                              selectedAllPatternIndex: -1,
                                              patternAllData: '',
                                            });
                                          } else {
                                            this.setState({
                                              ...this.state,
                                              selectedAllPatternIndex: index,
                                              patternAllData: item,
                                            });
                                          }
                                        }}
                                      >
                                        {t(item.patternNm.split('-')[0]) + '-' + item.patternNm.split('-')[1]}
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="btn_area">
                            <button
                              className="btn"
                              onClick={() => {
                                if (selectedUsedPatternIndex >= 0) {
                                  usedPatternList.splice(selectedUsedPatternIndex, 1);
                                  this.setState({
                                    ...this.state,
                                    usedPatternList,
                                    selectedUsedPatternIndex:
                                      usedPatternList.length === 0
                                        ? -1
                                        : selectedUsedPatternIndex === 0
                                        ? 0
                                        : selectedUsedPatternIndex - 1,
                                    isChanged: true,
                                  });
                                }
                              }}
                            >
                              <span className="ico left"></span>
                            </button>
                            <button
                              className="btn"
                              onClick={() => {
                                if (selectedAllPatternIndex >= 0) {
                                  usedPatternList.splice(
                                    usedPatternList.length,
                                    0,
                                    patternListData[selectedAllPatternIndex],
                                  );
                                  this.setState({
                                    ...this.state,
                                    usedPatternList,
                                    selectedUsedPatternIndex: usedPatternList.length - 1,
                                    isChanged: true,
                                  });
                                }
                              }}
                            >
                              <span className="ico right"></span>
                            </button>
                          </div>
                          <div className="list-group selected">
                            <h4 className="title">{t('사용 패턴')}</h4>
                            <div className="jquery_scrollbar">
                              <div className="scroll_inner">
                                <ul>
                                  {usedPatternList.map((item, index) => (
                                    <li
                                      key={index}
                                      className={selectedUsedPatternIndex === index ? 'checked' : ''}
                                      onClick={() => {
                                        if (selectedUsedPatternIndex === index) {
                                          this.setState({
                                            ...this.state,
                                            selectedUsedPatternIndex: -1,
                                            patternUsedData: '',
                                          });
                                        } else {
                                          this.setState({
                                            ...this.state,
                                            selectedUsedPatternIndex: index,
                                            patternUsedData: item,
                                          });
                                        }
                                      }}
                                    >
                                      {t(item.patternNm.split('-')[0]) + '-' + item.patternNm.split('-')[1]}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                            <p className="helper_text">{t('가급적 10개 내외로 설정해주세요.')}</p>
                          </div>
                          <div className="btn_area">
                            <button
                              className="btn"
                              onClick={() => {
                                if (selectedUsedPatternIndex > 0) {
                                  const delArr = usedPatternList.splice(selectedUsedPatternIndex, 1);
                                  usedPatternList.splice(0, 0, delArr[0]);
                                  this.setState({
                                    ...this.state,
                                    usedPatternList,
                                    selectedUsedPatternIndex: 0,
                                    isChanged: true,
                                  });
                                }
                              }}
                            >
                              <span className="ico top"></span>
                            </button>
                            <button
                              className="btn"
                              onClick={() => {
                                if (selectedUsedPatternIndex > 0) {
                                  const delArr = usedPatternList.splice(selectedUsedPatternIndex, 1);
                                  usedPatternList.splice(selectedUsedPatternIndex - 1, 0, delArr[0]);
                                  this.setState({
                                    ...this.state,
                                    usedPatternList,
                                    selectedUsedPatternIndex: selectedUsedPatternIndex - 1,
                                    isChanged: true,
                                  });
                                }
                              }}
                            >
                              <span className="ico up"></span>
                            </button>
                            <button
                              className="btn"
                              onClick={() => {
                                if (selectedUsedPatternIndex < usedPatternList.length - 1) {
                                  const delArr = usedPatternList.splice(selectedUsedPatternIndex, 1);
                                  usedPatternList.splice(selectedUsedPatternIndex + 1, 0, delArr[0]);
                                  this.setState({
                                    ...this.state,
                                    usedPatternList,
                                    selectedUsedPatternIndex: selectedUsedPatternIndex + 1,
                                    isChanged: true,
                                  });
                                }
                              }}
                            >
                              <span className="ico down"></span>
                            </button>
                            <button
                              className="btn"
                              onClick={() => {
                                if (selectedUsedPatternIndex < usedPatternList.length - 1) {
                                  const delArr = usedPatternList.splice(selectedUsedPatternIndex, 1);
                                  usedPatternList.splice(usedPatternList.length, 0, delArr[0]);
                                  this.setState({
                                    ...this.state,
                                    usedPatternList,
                                    selectedUsedPatternIndex: usedPatternList.length - 1,
                                    isChanged: true,
                                  });
                                }
                              }}
                            >
                              <span className="ico bottom"></span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="pattern_preview">
                        {this.state.patternUsedData && (
                          <SelectPatternView
                            patternData={this.state.patternUsedData.stepSequence}
                            repeat={this.state.patternUsedData.stepCycle}
                            description={this.state.patternUsedData.description}
                            t={t}
                          />
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="card_footer">
                  <div className="btn_area">
                    <button
                      type="button"
                      className="btn btn-lg btn-primary"
                      onClick={this._handleSave}
                      disabled={!isChanged || patternGroupNm === '' || description === '' || usedPatternList.length === 0}
                    >
                      {data === null ? t('등록') : t('수정')}
                    </button>
                    <button type="button" className="btn btn-lg btn-outline-primary" onClick={this._handleCancel}>
                      {t('취소')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

class SelectPatternView extends Component {
  render() {
    const { t, patternData, repeat, description, patternId } = this.props;

    function setPatternItem() {
      if (!patternData) {
        this.setState({
          ...this.state,
          patternData: '',
        });
        return;
      }

      const wrongPositionItems = new Array(40).fill(<p className="mat_item"></p>);
      const arrayCount = [];
      const sortArray = [];
      try {
        for (let i = 39; i > 0; i -= 4) {
          for (let j = 3; j >= 0; j--) {
            wrongPositionItems[i + j - 3] = <p className="mat_item" key={'mate_item_' + i + j}></p>;
            arrayCount.push(i + j - 3);
            sortArray.push(39 + j - i);
          }
        }
        const patterndArray = patternData.split(',');
        let firstIndex = '';
        const textArray = [];
        const checkTextArray = [];
        patterndArray.forEach((item, index) => {
          const patternIndex = parseInt(item);
          const arrayIndex = 39 - sortArray.indexOf(patternIndex);
          const count = repeat ? (index % repeat) + 1 : index + 1;
          if (index === 0) {
            firstIndex = arrayIndex;
          }

          if (![141, 142].includes(patternId)) {
            textArray[arrayIndex] = textArray[arrayIndex] ? textArray[arrayIndex] + '/' + count : count;
            checkTextArray[arrayIndex] = checkTextArray[arrayIndex] ? checkTextArray[arrayIndex] + '/' + count : count;
            wrongPositionItems[arrayIndex] = (
              <p className={'mat_item'} key={index}>
                {textArray[arrayIndex]}
              </p>
            );
            return;
          }

          // 패턴 141, 142는 두번째 사이클에 표시를 해주는 로직을 적용한다.
          if ([141, 142].includes(patternId)) {
            // 첫번째 사이클인 경우.
            if (index - repeat < 0) {
              textArray[arrayIndex] = textArray[arrayIndex] ? textArray[arrayIndex] + '/' + count : count;
              checkTextArray[arrayIndex] = checkTextArray[arrayIndex] ? checkTextArray[arrayIndex] + '/' + count : count;
              wrongPositionItems[arrayIndex] = (
                <p className={'mat_item'} key={index}>
                  {textArray[arrayIndex]}
                </p>
              );
            }

            // 두번째 사이클인 경우.
            if (index - repeat >= 0) {
              if (!textArray[arrayIndex]) {
                textArray[arrayIndex] = count;
                checkTextArray[arrayIndex] = count;
                wrongPositionItems[arrayIndex] = (
                  <p className={'mat_item'} key={index}>
                    <span className="text-danger">{textArray[arrayIndex]}</span>
                  </p>
                );
                return;
              }

              wrongPositionItems[arrayIndex] = (
                <p className={'mat_item'} key={index}>
                  {textArray[arrayIndex]}/<span className="text-danger">{count}</span>
                </p>
              );
              textArray[arrayIndex] = (
                <>
                  {textArray[arrayIndex]}/<span className="text-danger">{count}</span>
                </>
              );
              checkTextArray[arrayIndex] = checkTextArray[arrayIndex] + '/' + count;
            }
          }
        });

        // 표시하는 숫자가 4개 이상인 경우 글자 크기를 작게 처리하는 로직. e.g) 1/2/3/4
        checkTextArray.forEach((item, index) => {
          if (!item) {
            return;
          }

          const parts = item.toString().split('/');
          const isLargeText = parts.length >= 4;
          const pattenClassName = isLargeText ? 'mat_item_small' : 'mat_item';

          wrongPositionItems[index] = (
            <div className={index === firstIndex ? `mat_item started ${pattenClassName}` : pattenClassName} key={index}>
              {wrongPositionItems[index].props.children}
            </div>
          );
        });

        return wrongPositionItems;
      } catch (error) {
        console.error(error);
        return '';
      }
    }
    return (
      <>
        {patternData && (
          <>
            <div className="mat_body">{setPatternItem()}</div>
            <div className="mat_footer">
              <div className="coaching">
                <p className="num">1</p>
                <p className="text">{t(description)}</p>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { pattern } = state;
  return {
    patternGroupData: pattern.patternGroupData,
    patternListData: pattern.patternListData,
    patternLevelListData: pattern.patternLevelListData,
  };
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addPatternGroup,
      modifyPatternGroup,
      fetchPatternGroup,
      fetchPatternLevelList,
      fetchPatternList,
      dispatch,
    },
    dispatch,
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PatternGroupEdit));
export { connected as PatternGroupEdit };
