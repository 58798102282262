import { requestUrl, requestHeaders, requestParam, requestBody, handleResponse } from '_helpers';
import {
  fetchTenantListRequest,
  fetchTenantListSuccess,
  fetchTenantListError,
  addTenantRequest,
  addTenantSuccess,
  addTenantError,
  modifyTenantRequest,
  modifyTenantSuccess,
  modifyTenantError,
  removeTenantRequest,
  removeTenantSuccess,
  removeTenantError,
} from '_actions';

// 시설 목록조회
export function fetchTenantList(params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/tenants?${requestParam(params)}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchTenantListRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response);
        dispatch(fetchTenantListSuccess(response));
      })
      .catch(error => {
        callback(error);
        dispatch(fetchTenantListError(error));
      });
  };
}
// 시설 등록
export function addTenant(params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/tenants`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(addTenantRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(addTenantSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(addTenantError(error));
      });
  };
}
// 시설 수정
export function modifyTenant(tenantId, params = {}, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/tenants/${tenantId}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'PUT',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(modifyTenantRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(modifyTenantSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(modifyTenantError(error));
      });
  };
}
// 시설 삭제
export function removeTenant(tenantId, callback = (error, result) => {}) {
  return dispatch => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/api/tenants/${tenantId}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(removeTenantRequest());
    return fetch(url, requestOptions)
      .then(response => handleResponse(response))
      .then(response => {
        callback(null, response.data);
        dispatch(removeTenantSuccess(response.data));
      })
      .catch(error => {
        callback(error);
        dispatch(removeTenantError(error));
      });
  };
}
